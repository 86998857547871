import { createModel } from "@rematch/core";
import { api } from "api";
import {
  ContractorVm,
  CreateRepairmanCommand,
  OrderByType,
  UpdateRepairmanCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface RepairmenCreateState {
  editingItemId?: string;
  contractors: ContractorVm[];
}

const initialState: RepairmenCreateState = {
  editingItemId: undefined,
  contractors: [],
};

export const repairmenCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    setContractors(state, data: ContractorVm[]) {
      state.contractors = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { repairmenCreate } = dispatch;

    return {
      async create(values: CreateRepairmanCommand): Promise<string> {
        return api.lumen.repairmen.apiRepairmenPost({
          createRepairmanCommand: values,
        });
      },

      async edit(values: UpdateRepairmanCommand): Promise<void> {
        return api.lumen.repairmen.apiRepairmenIdPut({
          id: values.id,
          updateRepairmanCommand: values,
        });
      },

      async fetchContractors(): Promise<void> {
        const data = await api.lumen.contractors.apiContractorsGetAllPost({
          getAllContractorsQuery: {
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
            pageSize: -1,
          },
        });

        const { items } = data;

        dispatch(repairmenCreate.setContractors(items || []));
      },
    };
  },
});
