import { mdiPencil } from "@mdi/js";
import { Button, Col, Input, ModalProps, Row, Switch } from "antd";
import { FailureTypeVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import DictionarySelect from "components/form/DictionarySelect";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useEditFormModal from "hooks/useEditModal";
import useFeature from "hooks/useFeature";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { FailureTypeCreateFormValues } from "../models";
import styles from "./FailureTypesCreateModal.module.scss";
import { ListRequestParams } from "widgets/table/useTableUtils";

export interface FailureTypesCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

const FailureTypesCreateModal: React.FC<FailureTypesCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<FailureTypeCreateFormValues>();
  const dispatch = useDispatch();
  const { editingFailureTypeId } = useSelector(
    (state) => state.failureTypesCreate
  );

  const { create, edit } = useSelector(
    (state) => state.loading.effects.failureTypesCreate
  );

  const { list } = useSelector((state) => state.failureTypesList);

  const {
    isEnabled: isFailureTypeSpecialCaseEnabled,
    label: failureTypeSpecialCaseLabel,
  } = useFeature("failureSpecialCase");

  const getInitialValues = useCallback(
    (itemData: FailureTypeVm): FailureTypeCreateFormValues => ({
      name: itemData.name || "",
      taskIds: itemData.tasks?.map(({ id }) => id || "") || [],
      specialCase: itemData.specialCase || undefined,
    }),
    []
  );

  const { formMode, setFormMode } = useEditFormModal<
    FailureTypeVm,
    FailureTypeCreateFormValues
  >({
    list,
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.failureTypesCreate.setEditingFailureTypeId,
    itemId: editingFailureTypeId,
  });

  const handleFinish = useCallback(
    async (values: FailureTypeCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.failureTypesCreate.create(values);
      }

      if (formMode === "Edit" && typeof editingFailureTypeId === "string") {
        await dispatch.failureTypesCreate.edit({
          ...values,
          id: editingFailureTypeId,
        });
      }

      dispatch.failureTypesList.fetchList(fetchDataParams);

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.failureTypesCreate,
      dispatch.failureTypesList,
      editingFailureTypeId,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={onCancel}
      title={
        formMode === "View"
          ? t("failureTypes.modal.title")
          : formMode === "Edit"
          ? t("failureTypes.editModal.title")
          : t("failureTypes.createModal.title")
      }
      size="large"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: create.loading || edit.loading,
        disabled: formMode === "View",
      }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        <Row justify="center">
          <Col span={21}>
            {formMode === "View" && (
              <Row justify="end" className={styles.editWrapper}>
                <Button
                  icon={<MaterialIcon path={mdiPencil} />}
                  className={styles.editButton}
                  onClick={() => setFormMode("Edit")}
                >
                  {t("common.edit")}
                </Button>
              </Row>
            )}

            <GrapeAntdForm.Item
              name="name"
              label={t("failureType.name")}
              rules={[requiredField(t)]}
            >
              <Input size="large" />
            </GrapeAntdForm.Item>

            <GrapeAntdForm.Item
              name="taskIds"
              label={t("failureType.relatedTasks")}
            >
              <DictionarySelect
                dictionaryGroupType="LumenFailureTasks"
                mode="multiple"
                dictionaryIsEditableInApp
              />
            </GrapeAntdForm.Item>

            {isFailureTypeSpecialCaseEnabled && (
              <GrapeAntdForm.Item
                name="specialCase"
                valuePropName="checked"
                label={failureTypeSpecialCaseLabel}
                initialValue={false}
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>
            )}
          </Col>
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default FailureTypesCreateModal;
