import { PhoneDto } from "api/generated/lumen";
import NameDisplay from "components/ui/NameDisplay";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface ReporterDataDisplayProps {
  notifierFamilyname: string | null | undefined;
  notifierForename: string | null | undefined;
  notifierMiddlename: string | null | undefined;
  notifierPhone: PhoneDto | null | undefined;
}

const ReporterDataDisplay: React.FC<ReporterDataDisplayProps> = ({
  notifierFamilyname,
  notifierForename,
  notifierMiddlename,
  notifierPhone,
}) => {
  return (
    <>
      {(notifierFamilyname || notifierForename || notifierMiddlename) && (
        <span>
          <NameDisplay
            value={{
              familyname: notifierFamilyname,
              forename: notifierForename,
              middlename: notifierMiddlename,
            }}
          />
          {notifierPhone && <span>,</span>}
        </span>
      )}
      {notifierPhone && (
        <a href={`tel:${notifierPhone.number}`}>{notifierPhone.number}</a>
      )}
      {!(
        notifierFamilyname ||
        notifierForename ||
        notifierMiddlename ||
        notifierPhone
      ) && <EmptyContent />}
    </>
  );
};

export default ReporterDataDisplay;
