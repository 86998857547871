import {
  Col,
  ConfigProvider,
  Empty,
  ModalProps,
  Row,
  Spin,
  Typography,
} from "antd";
import {
  InventoryVm,
  WorkbookTaskItem,
  WorkbookTaskVm,
} from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDictionaryItemLabel } from "utils";
import styles from "./AddTaskItemModal.module.scss";
import clsx from "clsx";
import ItemChooserModal from "../ItemChooserModal";
import ItemChooserSearch from "../ItemChooserSearch";
import ItemChooserTask from "../ItemChooserTask";
import SetItemCount from "./SetItemCount";
import ImageDisplay from "components/ui/ImageDisplay";
import { DeviceLayoutType } from "models/common";
import useInventoryCategoriesQuery from "hooks/queries/useInventoryCategoriesQuery";

interface AddTaskItemModalProps extends ModalProps {
  task: WorkbookTaskVm;
  workbookTaskItemToEdit: WorkbookTaskItem | undefined;
  deviceLayoutType?: DeviceLayoutType;
}

type ContentMode = "chooseItem" | "setCount";

const AddTaskItemModal: React.FC<AddTaskItemModalProps> = ({
  onCancel,
  visible,
  task,
  workbookTaskItemToEdit,
  deviceLayoutType,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchInventoryItems, addTaskItemToWorkbookTask, editTaskItemCount } =
    useSelector((state) => state.loading.effects.failureView);

  const { failure, inventoryItems } = useSelector((state) => state.failureView);
  const inventoryCategoriesQuery = useInventoryCategoriesQuery(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [contentMode, setContentMode] = useState<ContentMode>("chooseItem");
  const [activeCategoryId, setActiveCategoryId] = useState<string>();
  const [chosenItem, setChosenItem] = useState<InventoryVm>();

  const sortedInventoryCategories = useMemo(
    () =>
      [...(inventoryCategoriesQuery.data?.items || [])].sort((a, b) =>
        getDictionaryItemLabel(a).localeCompare(getDictionaryItemLabel(b))
      ),
    [inventoryCategoriesQuery.data?.items]
  );

  const filteredInventoryItems = useMemo(
    () =>
      inventoryItems
        .filter(
          (item) =>
            item.category?.id === activeCategoryId &&
            item.name?.toLowerCase().includes(searchKeyword.toLowerCase())
        )
        .sort((a, b) => (a.name || "").localeCompare(b.name || "")),
    [activeCategoryId, inventoryItems, searchKeyword]
  );

  const handleAddItem = useCallback(
    async (itemCount: number) => {
      if (typeof chosenItem?.id !== "string" || typeof task.id !== "string") {
        return;
      }

      if (
        workbookTaskItemToEdit &&
        typeof workbookTaskItemToEdit.id === "string"
      ) {
        await dispatch.failureView.editTaskItemCount({
          inventoryId: chosenItem.id,
          itemCount,
          workbookTaskId: task.id,
          id: workbookTaskItemToEdit.id,
        });
      } else {
        await dispatch.failureView.addTaskItemToWorkbookTask({
          inventoryId: chosenItem.id,
          itemCount,
          workbookTaskId: task.id,
        });
      }

      onCancel?.("" as any);

      if (typeof failure?.id === "string") {
        dispatch.failureView.fetchWorkbook(failure.id);
      }
    },
    [
      chosenItem?.id,
      dispatch.failureView,
      failure?.id,
      onCancel,
      task.id,
      workbookTaskItemToEdit,
    ]
  );

  const chooseItem = useCallback((item: InventoryVm) => {
    setChosenItem(item);

    setContentMode("setCount");
  }, []);

  useEffect(() => {
    if (sortedInventoryCategories.length) {
      setActiveCategoryId(sortedInventoryCategories[0].id);
    }
  }, [sortedInventoryCategories]);

  useEffect(() => {
    if (!visible && sortedInventoryCategories.length) {
      setActiveCategoryId(sortedInventoryCategories[0].id);
    }
  }, [sortedInventoryCategories, visible]);

  useEffect(() => {
    if (!visible) {
      setChosenItem(undefined);
      setSearchKeyword("");
      setContentMode("chooseItem");
    }
  }, [visible]);

  useEffect(() => {
    if (workbookTaskItemToEdit) {
      const taskItemToEdit = inventoryItems.find(
        ({ id }) => workbookTaskItemToEdit.inventoryId === id
      );

      setChosenItem(taskItemToEdit);
      setContentMode("setCount");
    }
  }, [inventoryItems, workbookTaskItemToEdit]);

  return (
    <ItemChooserModal
      {...rest}
      title={
        contentMode === "chooseItem"
          ? t("workbook.chooseTaskItem")
          : t("workbook.setTaskItemCount")
      }
      onCancel={onCancel}
      visible={visible}
      footer={
        contentMode === "chooseItem" ? (
          <div className={styles.categories}>
            {sortedInventoryCategories.map((category) => (
              <button
                key={category.id}
                className={clsx(styles.category, {
                  [styles.active]: activeCategoryId === category.id,
                })}
                onClick={() => setActiveCategoryId(category.id)}
              >
                <DictionaryDisplay dictionary={category} />
              </button>
            ))}
          </div>
        ) : null
      }
      className={clsx({
        [styles.desktop]: deviceLayoutType === "default",
      })}
      longModal
    >
      {contentMode === "chooseItem" && (
        <>
          <ItemChooserSearch
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
          />

          <Spin spinning={fetchInventoryItems.loading}>
            <Row gutter={[22, 22]}>
              {filteredInventoryItems.length ? (
                filteredInventoryItems.map((item) => (
                  <Col span={8} key={item.id} className={styles.itemChooserCol}>
                    <ItemChooserTask onClick={() => chooseItem(item)}>
                      <div className={styles.itemData}>
                        <div className={styles.itemDetails}>
                          <div className={styles.itemManufacturer}>
                            {item.manufacturer}
                          </div>

                          <Typography.Text ellipsis className={styles.itemName}>
                            {item.name}
                          </Typography.Text>
                        </div>

                        {!!item.image?.imageThumbnails?.length && (
                          <ImageDisplay
                            data={item.image?.imageThumbnails[0]}
                            className={styles.itemImage}
                          />
                        )}
                      </div>
                    </ItemChooserTask>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <ConfigProvider
                    locale={{
                      locale: "hu",
                      Empty: {
                        description: t("common.noDataForSearchValue"),
                      },
                    }}
                  >
                    <Empty />
                  </ConfigProvider>
                </Col>
              )}
            </Row>
          </Spin>
        </>
      )}

      {contentMode === "setCount" && (
        <SetItemCount
          item={chosenItem}
          onAddItem={handleAddItem}
          addButtonIsLoading={
            addTaskItemToWorkbookTask.loading || editTaskItemCount.loading
          }
          defaultItemCount={workbookTaskItemToEdit?.itemCount}
        />
      )}
    </ItemChooserModal>
  );
};

export default AddTaskItemModal;
