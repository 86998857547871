import { mdiDelete, mdiDownload, mdiUpload } from "@mdi/js";
import { Button, Modal, Space } from "antd";
import { DocumentMetadataVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import IconButton from "components/ui/IconButton";
import { mapContractorVmToUpdateContractorCommand } from "features/contractors/models";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ColumnConfigParams } from "widgets/table/useTableUtils";
import AddNewDocumentModal from "./AddNewDocumentModal";

const { confirm } = Modal;

interface DocumentsTabProps {
  afterDocumentUpload: () => void;
  afterDocumentDelete: () => void;
}

const DocumentsTab: React.FC<DocumentsTabProps> = ({
  afterDocumentUpload,
  afterDocumentDelete,
}) => {
  const { t } = useTranslation();
  const { contractor } = useSelector((state) => state.contractorView);
  const [addNewDocumentModalOpen, setAddNewDocumentModalOpen] = useState(false);
  const dispatch = useDispatch();

  const columnParams = useMemo<ColumnConfigParams<DocumentMetadataVm>[]>(
    () => [
      {
        title: t("document.title"),
        key: "name",
        sort: false,
      },
      {
        title: t("document.type"),
        key: "contentType",
        sort: false,
      },
      {
        title: t("document.size"),
        key: "sizeInMB",
        sort: false,
      },
    ],
    [t]
  );

  const handleDownloadButtonClick = useCallback(
    (id?: string) => {
      if (typeof id === "string") {
        dispatch.contractorView.downloadDocument(id);
      }
    },
    [dispatch.contractorView]
  );

  const handleDeleteButtonClick = useCallback(
    async (id?: string) => {
      if (typeof id === "string" && contractor) {
        confirm({
          okText: t("common.confirmDeleteModal.yes"),
          okType: "danger",
          cancelText: t("common.confirmDeleteModal.no"),
          title: t("common.confirmDeleteModal.title"),
          onOk: async () => {
            await dispatch.contractorView.deleteDocument({
              contractorData:
                mapContractorVmToUpdateContractorCommand(contractor),
              documentId: id,
            });

            afterDocumentDelete();
          },
        });
      }
    },
    [afterDocumentDelete, contractor, dispatch.contractorView, t]
  );

  const handleModalOk = useCallback(() => {
    afterDocumentUpload();

    setAddNewDocumentModalOpen(false);
  }, [afterDocumentUpload]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<DocumentMetadataVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiDownload}
              onClick={() => handleDownloadButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleDownloadButtonClick, t]
  );

  const customTableProps = useDumbTable({
    columnParams,
    list: contractor?.documents,
    actionColumnParams,
    headerOptions: (
      <Button
        type="primary"
        htmlType="submit"
        block
        icon={<MaterialIcon path={mdiUpload} />}
        onClick={() => setAddNewDocumentModalOpen(true)}
      >
        {t("document.addNew")}
      </Button>
    ),
  });

  return (
    <div>
      <CustomTable<DocumentMetadataVm>
        {...customTableProps}
        selectable={false}
        scroll={{ x: 1000 }}
      />

      <AddNewDocumentModal
        visible={addNewDocumentModalOpen}
        onCancel={() => setAddNewDocumentModalOpen(false)}
        onOk={() => handleModalOk()}
      />
    </div>
  );
};

export default DocumentsTab;
