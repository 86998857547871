import { mdiNetworkStrength4Cog } from "@mdi/js";
import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DarkContainerCard from "../DarkContainerCard";
import styles from "./FailureViewWorkbookTasksSection.module.scss";

const FailureViewWorkbookTasksSection: React.FC = () => {
  const { t } = useTranslation();
  const { failure, workbook } = useSelector((state) => state.failureView);
  const { fetchWorkbook } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof failure?.id === "string") {
      dispatch.failureView.fetchWorkbook(failure.id);
    }
  }, [dispatch.failureView, failure?.id]);

  return (
    <DarkContainerCard
      title={`${t("failure.workbookTasksToDo")} ${
        workbook?.tasks?.length ? `(${workbook?.tasks?.length})` : ""
      }`}
      icon={<MaterialIcon path={mdiNetworkStrength4Cog} />}
    >
      {fetchWorkbook.loading ? (
        <Row justify="center">
          <Spin spinning />
        </Row>
      ) : (
        <div>
          <Row gutter={[5, 5]}>
            {workbook?.tasks?.map((task) => (
              <Col span={8} key={task.id}>
                <div className={styles.task}>
                  <DictionaryDisplay dictionary={task.task} />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </DarkContainerCard>
  );
};

export default FailureViewWorkbookTasksSection;
