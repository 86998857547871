import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { queryKeys } from ".";

const refetchInterval =
  process.env.REACT_APP_FAILURE_DEADLINE_COUNT_INFO_REFETCH_INTERVAL &&
  process.env.REACT_APP_FAILURE_DEADLINE_COUNT_INFO_REFETCH_INTERVAL !== ""
    ? process.env.REACT_APP_FAILURE_DEADLINE_COUNT_INFO_REFETCH_INTERVAL
    : undefined;

const useFailureDeadlineInfoQuery = () => {
  const query = useQuery(
    queryKeys.failures.deadlineInfoCount,
    () =>
      api.lumen.failures.apiFailuresGetCountPost({ getFailureCountQuery: {} }),
    {
      refetchInterval: refetchInterval ? parseInt(refetchInterval, 10) || 0 : 0,
    }
  );

  return query;
};

export default useFailureDeadlineInfoQuery;
