import { mdiFilterRemove } from "@mdi/js";
import { Button, Divider, Typography } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./CustomTableHeader.module.scss";
import { TableSettingsDropdown } from "./table-settings/TableSettingsDropdown";
import { TableSettingsUtils } from "./table-settings/useTableSettingsUtils";

export interface CustomTableHeaderProps {
  title?: string;
  resultCount?: number;
  selectedCount?: number;
  extraContent?: React.ReactNode;
  tableSettingsUtils?: TableSettingsUtils<any>;
  isFiltered?: boolean;
  resetFilters?: () => void;
}

export const CustomTableHeader: FC<CustomTableHeaderProps> = ({
  title,
  extraContent,
  resultCount,
  selectedCount,
  tableSettingsUtils,
  isFiltered,
  resetFilters,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        title ? styles.headerContainerWithTitle : styles.headerContainer
      }
    >
      <div className={styles.titleContainer}>
        {title && <Typography.Title level={4}>{title}</Typography.Title>}

        <span className={styles.results}>
          {selectedCount ? (
            <>
              {t("common.selected")} (<b>{selectedCount.toLocaleString()}</b>/
              <sup>{resultCount?.toLocaleString() ?? 0}</sup>)
            </>
          ) : (
            <>
              {t("common.results")} ({resultCount ?? 0})
            </>
          )}
        </span>

        {isFiltered && resetFilters && (
          <Button
            className={styles.resetButton}
            type="link"
            onClick={resetFilters}
          >
            <MaterialIcon path={mdiFilterRemove} />
            {t("common.reset-filters")}
          </Button>
        )}
      </div>
      <div className={styles.rightContent}>
        {extraContent && (
          <>
            <span className={styles.extraContent}>{extraContent}</span>
            {tableSettingsUtils && (
              <Divider type="vertical" className={styles.divider} />
            )}
          </>
        )}
        {tableSettingsUtils && (
          <TableSettingsDropdown {...tableSettingsUtils} />
        )}
      </div>
    </div>
  );
};
