import { WorkBookTaskItemReportListItemVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import useTable from "hooks/useTable";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { DateFormat } from "widgets/table/DateDisplay";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import { ColumnState } from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  DataType,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

const TaskItemList: FC = () => {
  const { listParams, list } = useSelector((state) => state.taskItemList);
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.taskItemList
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnTitles = useMemo<string[]>(
    () => [
      t("report.failureIdentifier"),
      t("report.address"),
      t("report.failureReportedAt"),
      t("report.taskName"),
      t("report.inventoryCategoryName"),
      t("report.inventoryType"),
      t("report.inventoryName"),
      t("report.inventoryCount"),
    ],
    [t]
  );

  const columnParams = useMemo<
    ColumnConfigParams<WorkBookTaskItemReportListItemVm>[]
  >(
    () =>
      [
        {
          key: "failureIdentifier",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 200,
        },
        {
          key: "address.city",
          dataIndex: ["address", "city"],
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 200,
        },
        {
          key: "failureReportedAt",
          sort: true,
          defaultState: ColumnState.Visible,
          dateFormat: DateFormat.DateTime,
          dataType: DataType.DATE,
          filterMode: FilterMode.DATERANGEPICKER,
          width: 280,
        },
        {
          key: "taskName",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 200,
        },
        {
          key: "inventoryCategoryName",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 360,
        },
        {
          key: "inventoryType",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 220,
        },
        {
          key: "inventoryName",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 210,
        },
        {
          key: "inventoryCount",
          sort: true,
          defaultState: ColumnState.Visible,
          filterMode: FilterMode.SEARCH,
          width: 260,
        },
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [columnTitles]
  );

  const handleExportButtonClick = useCallback(() => {
    dispatch.taskItemList.generateExcel(columnTitles);
  }, [columnTitles, dispatch.taskItemList]);

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.taskItemList.fetchList(requestParams),
    [dispatch.taskItemList]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.REPORT,
    headerOptions: (
      <TableHeaderOptions
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.taskItemList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.taskItemList.reset,
  });

  return (
    <CustomTable<WorkBookTaskItemReportListItemVm>
      {...customTableProps}
      scrollX={1048}
      selectable={false}
    />
  );
};

export default TaskItemList;
