import { FC, useContext, useMemo, useState } from "react";
import { Menu, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import styles from "./SidebarMenu.module.scss";
import clsx from "clsx";
import { SidebarContext } from "..";
import config from "../config";
import { Role } from "models/common";

export type SideMenuOptionRole = Role;

export interface SideMenuOptionProps {
  label?: string;
  labelTooltip?: string;
  icon?: React.ReactNode;
  roles?: SideMenuOptionRole[];
  link?: string;
  onClick?: () => void;
  component?: JSX.Element;
  subItems?: SideMenuOptionProps[];
}

interface SidebarMenuProps {
  title?: string;
  options: SideMenuOptionProps[];
  onMenuItemClick?: () => void;
  menuItemClassName?: string;
  hasRole: (roles: SideMenuOptionRole[]) => boolean;
}

// Based on Optima
const SidebarMenu: FC<SidebarMenuProps> = ({
  onMenuItemClick,
  options,
  title,
  menuItemClassName,
  hasRole,
}) => {
  const { pathname: path } = useLocation();
  const { open } = useContext(SidebarContext);

  const pathRoot = useMemo(() => `/${path.split("/")[1]}`, [path]);

  const createSubMenuKey = (option: SideMenuOptionProps): string =>
    `submenu_${option.label?.replace(" ", "")}`;

  const mapItem = (option: SideMenuOptionProps, index: number): JSX.Element => (
    <Menu.Item
      key={option.link ?? `main_${index}`}
      onClick={() => {
        option.onClick?.();
        onMenuItemClick?.();
      }}
      className={clsx(menuItemClassName, {
        [styles.sideMenuNoLink]: !option.link && !option.onClick,
      })}
    >
      {option.icon}
      <Tooltip title={option.labelTooltip || option.label}>
        <>
          <span>{option.label}</span>
          {option.link && <Link to={option.link} />}
        </>
      </Tooltip>
    </Menu.Item>
  );

  const mapSubMenu = (option: SideMenuOptionProps): JSX.Element => {
    const key = createSubMenuKey(option);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const subItems = option.subItems?.map((subItem: SideMenuOptionProps) =>
      mapOptions([subItem])
    );

    return (
      <Menu.SubMenu
        key={key}
        popupOffset={[0, 0]}
        className={clsx({
          "ant-menu-item-selected": pathRoot === option.link,
        })}
        title={
          <>
            {option.icon}
            <Tooltip title={option.labelTooltip || option.label}>
              <>
                <span>{option.label}</span>
                {option.link && <Link to={option.link} />}
              </>
            </Tooltip>
          </>
        }
      >
        {open ? (
          subItems
        ) : (
          <Menu.ItemGroup key={`${key}_sub_title`} title={option.label}>
            {subItems}
          </Menu.ItemGroup>
        )}
      </Menu.SubMenu>
    );
  };

  const mapOptions = (items: SideMenuOptionProps[]): JSX.Element[] => {
    const filtered = items.filter((option) => hasRole(option.roles ?? []));

    return filtered.map((option, i) => {
      if (option.component) {
        return option.component;
      } else {
        return option.subItems ? mapSubMenu(option) : mapItem(option, i);
      }
    });
  };

  // fix for submenu opened state if a subitem is the pathroot
  const rootSubmenuState = useMemo(() => {
    return options
      .filter((option) => {
        const links = option.subItems?.map((sub) => sub.link);

        return links?.includes(pathRoot);
      })
      .map((option) => createSubMenuKey(option));
  }, [options, pathRoot]);

  const [openKeys, setOpenKeys] = useState<string[]>(rootSubmenuState);

  return (
    <div className={styles.menuWrapper}>
      {title && (
        <div className={styles.menuGroupTitle}>{open ? title : ""}</div>
      )}
      <Menu
        inlineIndent={config.menuInlineIndent}
        selectedKeys={[pathRoot]}
        mode={open ? "inline" : "vertical"}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
      >
        {mapOptions(options)}
      </Menu>
    </div>
  );
};

export default SidebarMenu;
