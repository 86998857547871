import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FailureTypeVm } from "api/generated/lumen";
import { useSelector } from "app/store";
import DictionarySelect from "components/form/DictionarySelect";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useEnvTranslation from "hooks/useEnvTranslation";
import useFeature from "hooks/useFeature";
import { FieldsetProps, ItemProps } from "models/common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, max, requiredField } from "widgets/form";

interface FailureAttributesFieldsetProps extends FieldsetProps {
  isReport?: boolean;
  editingFailuresType?: FailureTypeVm;
}

const FailureAttributesFieldset: React.FC<FailureAttributesFieldsetProps> = ({
  isReport,
  editingFailuresType,
  disabled,
}) => {
  const { t } = useTranslation();
  const { failureTypes } = useSelector((state) => state.failureCreate);
  const { fetchFailureTypes } = useSelector(
    (state) => state.loading.effects.failureCreate
  );
  const failureCommentExtraTranslation = useEnvTranslation(
    "failure.commentExtra"
  );

  const { failureTypes: reportFailureTypes } = useSelector(
    (state) => state.failureReport
  );
  const { fetchPublicFailureTypes } = useSelector(
    (state) => state.loading.effects.failureReport
  );

  const failureTypeOptions = useMemo<ItemProps[]>(() => {
    const relevantFailureTypes = isReport ? reportFailureTypes : failureTypes;

    const options = relevantFailureTypes.map((type) => ({
      label: type.name || "",
      value: type.id || "",
    })); // all available failure type options

    const optionForEditingFailuresType = editingFailuresType
      ? {
          label: `${editingFailuresType.name} (${t(
            "failure.deletedFailureTypeLabelPostfix"
          )})`,
          value: editingFailuresType.id || "",
        }
      : undefined; // option based on the current failure's failure type

    if (!optionForEditingFailuresType || !relevantFailureTypes.length) {
      return options;
    }

    // We need to add the current failure's failure type, because users can delete failure types, but we need to make sure the editing user can choose this deleted failure type, so he can set the status to closed with the selected failure type value.

    // If relevantFailureTypes already includes editingFailuresType
    if (
      relevantFailureTypes.find(
        (failureType) => failureType.id === editingFailuresType?.id
      )
    ) {
      return options;
    } else {
      return [...options, optionForEditingFailuresType];
    }
  }, [editingFailuresType, failureTypes, isReport, reportFailureTypes, t]);

  const {
    isEnabled: isFailureListParam1Enabled,
    label: failureListParam1Label,
    formRules: failureListParam1FormRules,
    placeholder: failureListParam1Placeholder,
  } = useFeature("failureListParam1");
  const {
    isEnabled: isFailureListParam2Enabled,
    label: failureListParam2Label,
    formRules: failureListParam2FormRules,
    placeholder: failureListParam2Placeholder,
  } = useFeature("failureListParam2");
  const {
    isEnabled: isFailureDescription1Enabled,
    label: failureDescription1Label,
    formRules: failureDescription1FormRules,
    placeholder: failureDescription1Placeholder,
  } = useFeature("failureDescription1");
  const {
    isEnabled: isFailureDescription2Enabled,
    label: failureDescription2Label,
    formRules: failureDescription2FormRules,
    placeholder: failureDescription2Placeholder,
  } = useFeature("failureDescription2");

  return (
    <fieldset disabled={disabled ?? false}>
      <legend>{t("failure.attributes")}</legend>
      <FieldsetFieldsWrapper>
        <GrapeAntdForm.Item
          label={t("failure.type")}
          name="typeId"
          rules={[requiredField(t)]}
        >
          <Select
            placeholder={t("failure.typePlaceholder")}
            size="large"
            options={failureTypeOptions}
            loading={
              fetchFailureTypes.loading ||
              (isReport && fetchPublicFailureTypes.loading)
            }
            showSearch
            optionFilterProp="label"
            filterOption={filterOptionsLabelFromBeginning}
          />
        </GrapeAntdForm.Item>

        {isFailureListParam1Enabled && (
          <GrapeAntdForm.Item
            label={failureListParam1Label}
            name="expanseId"
            rules={[requiredField(t), ...failureListParam1FormRules]}
          >
            <DictionarySelect
              placeholder={failureListParam1Placeholder}
              dictionaryGroupType="LumenFailureExpanses"
            />
          </GrapeAntdForm.Item>
        )}

        {isFailureListParam2Enabled && (
          <GrapeAntdForm.Item
            label={failureListParam2Label}
            name="natureId"
            rules={[requiredField(t), ...failureListParam2FormRules]}
          >
            <DictionarySelect
              placeholder={failureListParam2Placeholder}
              dictionaryGroupType="LumenFailureNatures"
            />
          </GrapeAntdForm.Item>
        )}

        {isFailureDescription1Enabled && (
          <GrapeAntdForm.Item
            label={failureDescription1Label}
            name="description1"
            rules={failureDescription1FormRules}
          >
            <Input size="large" placeholder={failureDescription1Placeholder} />
          </GrapeAntdForm.Item>
        )}

        {isFailureDescription2Enabled && (
          <GrapeAntdForm.Item
            label={failureDescription2Label}
            name="description2"
            rules={failureDescription2FormRules}
          >
            <Input size="large" placeholder={failureDescription2Placeholder} />
          </GrapeAntdForm.Item>
        )}

        <GrapeAntdForm.Item
          label={t("failure.comment")}
          name="comment"
          rules={[max(t, 200, "string")]}
          extra={failureCommentExtraTranslation}
        >
          <TextArea
            placeholder={t("failure.comment")}
            size="large"
            maxLength={200}
            rows={6}
          />
        </GrapeAntdForm.Item>
      </FieldsetFieldsWrapper>
    </fieldset>
  );
};

export default FailureAttributesFieldset;
