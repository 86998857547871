import useAuth from "features/auth/useAuth";
import { Outlet } from "react-router-dom";
import PrivateMobileHeader from "./PrivateMobileHeader";
import styles from "./PrivateMobileLayout.module.scss";

const PrivateMobileLayout = () => {
  const { userInfo } = useAuth();

  return (
    <div className={styles.wrapper}>
      <PrivateMobileHeader userName={userInfo?.userName} />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default PrivateMobileLayout;
