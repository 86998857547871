/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreetVm
 */
export interface StreetVm {
    /**
     * 
     * @type {string}
     * @memberof StreetVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetVm
     */
    townId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreetVm
     */
    streetName?: string | null;
}

export function StreetVmFromJSON(json: any): StreetVm {
    return StreetVmFromJSONTyped(json, false);
}

export function StreetVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreetVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
    };
}

export function StreetVmToJSON(value?: StreetVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'townId': value.townId,
        'streetName': value.streetName,
    };
}


