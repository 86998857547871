/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpecialPlace
 */
export interface SpecialPlace {
    /**
     * 
     * @type {string}
     * @memberof SpecialPlace
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlace
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpecialPlace
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlace
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SpecialPlace
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlace
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SpecialPlace
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Array<Device>}
     * @memberof SpecialPlace
     */
    devices?: Array<Device> | null;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof SpecialPlace
     */
    contractors?: Array<Contractor> | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlace
     */
    id?: string;
}

export function SpecialPlaceFromJSON(json: any): SpecialPlace {
    return SpecialPlaceFromJSONTyped(json, false);
}

export function SpecialPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'devices': !exists(json, 'devices') ? undefined : (json['devices'] === null ? null : (json['devices'] as Array<any>).map(DeviceFromJSON)),
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function SpecialPlaceToJSON(value?: SpecialPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'isDeleted': value.isDeleted,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceToJSON)),
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorToJSON)),
        'id': value.id,
    };
}


