/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FailureVm,
    FailureVmFromJSON,
    FailureVmFromJSONTyped,
    FailureVmToJSON,
    WorkbookTaskVm,
    WorkbookTaskVmFromJSON,
    WorkbookTaskVmFromJSONTyped,
    WorkbookTaskVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkbookVm
 */
export interface WorkbookVm {
    /**
     * 
     * @type {string}
     * @memberof WorkbookVm
     */
    id?: string;
    /**
     * 
     * @type {FailureVm}
     * @memberof WorkbookVm
     */
    failure?: FailureVm;
    /**
     * 
     * @type {Array<WorkbookTaskVm>}
     * @memberof WorkbookVm
     */
    tasks?: Array<WorkbookTaskVm> | null;
}

export function WorkbookVmFromJSON(json: any): WorkbookVm {
    return WorkbookVmFromJSONTyped(json, false);
}

export function WorkbookVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbookVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'failure': !exists(json, 'failure') ? undefined : FailureVmFromJSON(json['failure']),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(WorkbookTaskVmFromJSON)),
    };
}

export function WorkbookVmToJSON(value?: WorkbookVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'failure': FailureVmToJSON(value.failure),
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(WorkbookTaskVmToJSON)),
    };
}


