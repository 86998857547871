import { MaterialIcon } from "components/MaterialIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./EmptyContent.module.scss";

export interface EmptyContentProps {
  text?: string;
  iconPath?: string;
}

export const EmptyContent: FC<EmptyContentProps> = ({ iconPath, text }) => {
  const { t } = useTranslation();

  return (
    <span className={styles.emptyContent}>
      {iconPath && (
        <MaterialIcon className={styles.emptyIcon} path={iconPath} />
      )}
      <i>{text ?? t("common.emptyContent")}</i>
    </span>
  );
};
