/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionItemRight
 */
export interface PermissionItemRight {
    /**
     * 
     * @type {string}
     * @memberof PermissionItemRight
     */
    permissionItem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionItemRight
     */
    itemRight?: string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof PermissionItemRight
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {User}
     * @memberof PermissionItemRight
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof PermissionItemRight
     */
    modifier?: User;
    /**
     * 
     * @type {string}
     * @memberof PermissionItemRight
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PermissionItemRight
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PermissionItemRight
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PermissionItemRight
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionItemRight
     */
    id?: string;
}

export function PermissionItemRightFromJSON(json: any): PermissionItemRight {
    return PermissionItemRightFromJSONTyped(json, false);
}

export function PermissionItemRightFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionItemRight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissionItem': !exists(json, 'permissionItem') ? undefined : json['permissionItem'],
        'itemRight': !exists(json, 'itemRight') ? undefined : json['itemRight'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function PermissionItemRightToJSON(value?: PermissionItemRight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissionItem': value.permissionItem,
        'itemRight': value.itemRight,
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


