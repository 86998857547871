import { mdiMinus, mdiPlus } from "@mdi/js";
import { Button, InputNumber } from "antd";
import { InventoryVm } from "api/generated/lumen";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import ImageDisplay from "components/ui/ImageDisplay";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SetItemCount.module.scss";

interface SetItemCountProps {
  item?: InventoryVm;
  onAddItem: (itemCount: number) => void;
  addButtonIsLoading?: boolean;
  defaultItemCount?: number;
}

const SetItemCount: React.FC<SetItemCountProps> = ({
  item,
  onAddItem,
  addButtonIsLoading,
  defaultItemCount,
}) => {
  const { t } = useTranslation();
  const [itemCount, setItemCount] = useState(1);

  useEffect(() => {
    if (typeof defaultItemCount === "number") {
      setItemCount(defaultItemCount);
    } else {
      setItemCount(1);
    }
  }, [defaultItemCount]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <div className={styles.details}>
            <div className={styles.manufacturer}>{item?.manufacturer}</div>
            <div className={styles.name}>{item?.name}</div>

            {!!item?.image?.imageThumbnails?.[0] && (
              <ImageDisplay
                data={item.image.imageThumbnails[0]}
                className={styles.image}
              />
            )}
          </div>

          <div className={styles.inputWrapper}>
            <InputNumber
              value={itemCount}
              onChange={(newValue) =>
                setItemCount(typeof newValue === "number" ? newValue : 1)
              }
              min={1}
              max={2147483647}
              className={styles.input}
              controls={false}
            />

            <div className={styles.unit}>{t("common.units.pieces")}</div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            onClick={() => setItemCount((oldValue) => oldValue + 1)}
            className={clsx(styles.button, styles.increaseButton)}
          >
            <MaterialIcon path={mdiPlus} />
          </button>
          <button
            onClick={() =>
              setItemCount((oldValue) => (oldValue <= 1 ? 1 : oldValue - 1))
            }
            className={clsx(styles.button, styles.decreaseButton)}
          >
            <MaterialIcon path={mdiMinus} />
          </button>
        </div>
      </div>

      <Button
        size="large"
        type="primary"
        block
        className={styles.addButton}
        onClick={() => onAddItem(itemCount)}
        loading={addButtonIsLoading}
      >
        {t("workbook.addItem")}
      </Button>
    </div>
  );
};

export default SetItemCount;
