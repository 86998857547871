/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentMetadataVm
 */
export interface DocumentMetadataVm {
    /**
     * 
     * @type {string}
     * @memberof DocumentMetadataVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMetadataVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMetadataVm
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMetadataVm
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentMetadataVm
     */
    sizeInMB?: number;
}

export function DocumentMetadataVmFromJSON(json: any): DocumentMetadataVm {
    return DocumentMetadataVmFromJSONTyped(json, false);
}

export function DocumentMetadataVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentMetadataVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'sizeInMB': !exists(json, 'sizeInMB') ? undefined : json['sizeInMB'],
    };
}

export function DocumentMetadataVmToJSON(value?: DocumentMetadataVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fileName': value.fileName,
        'contentType': value.contentType,
        'sizeInMB': value.sizeInMB,
    };
}


