import { Descriptions, Space, Typography } from "antd";
import { FailureVm } from "api/generated/lumen";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import GoogleMapsAddressLink from "components/ui/GoogleMapsAddressLink";
import StringDisplay from "components/ui/StringDisplay";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import useFeature from "hooks/useFeature";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import styles from "../FailureViewDefault.module.scss";

interface FailureViewReportDataProps {
  failure?: FailureVm;
}

const FailureViewReportData: React.FC<FailureViewReportDataProps> = ({
  failure,
}) => {
  const { t } = useTranslation();
  const { displayName } = useLanguageSensitiveUtils();

  const {
    isEnabled: isFailureListParam1Enabled,
    label: failureListParam1Label,
  } = useFeature("failureListParam1");
  const {
    isEnabled: isFailureListParam2Enabled,
    label: failureListParam2Label,
  } = useFeature("failureListParam2");
  const {
    isEnabled: isFailureDescription1Enabled,
    label: failureDescription1Label,
  } = useFeature("failureDescription1");
  const {
    isEnabled: isFailureDescription2Enabled,
    label: failureDescription2Label,
  } = useFeature("failureDescription2");
  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  return (
    <Space direction="vertical" size="large">
      <Typography.Title level={5} className={styles.headerTitle}>
        {t("failure.reportData")}
      </Typography.Title>

      <VerticalDataDescriptions column={3}>
        <Descriptions.Item label={t("failure.reportingDate")}>
          <DateDisplay
            value={failure?.reportedAt}
            format={DateFormat.DateTime}
          />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.deadline")}>
          <DateDisplay value={failure?.deadline} format={DateFormat.Date} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.recommendedFixingDays")}>
          <DictionaryDisplay dictionary={failure?.optimalDeadlineDays} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.type")}>
          <StringDisplay value={failure?.type?.name} />
        </Descriptions.Item>

        {isFailureListParam1Enabled && (
          <Descriptions.Item label={failureListParam1Label}>
            <DictionaryDisplay dictionary={failure?.expanse} />
          </Descriptions.Item>
        )}

        {isFailureListParam2Enabled && (
          <Descriptions.Item label={failureListParam2Label}>
            <DictionaryDisplay dictionary={failure?.nature} />
          </Descriptions.Item>
        )}

        {isFailureDescription1Enabled && (
          <Descriptions.Item label={failureDescription1Label}>
            <StringDisplay value={failure?.description1} />
          </Descriptions.Item>
        )}

        {isFailureDescription2Enabled && (
          <Descriptions.Item label={failureDescription2Label}>
            <StringDisplay value={failure?.description2} />
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t("failure.contractor")}>
          <StringDisplay value={failure?.contractor?.name} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.repairmens")}>
          {failure?.repairmen?.length ? (
            failure?.repairmen
              .map((repairer) => displayName(repairer))
              .join(", ")
          ) : (
            <EmptyContent />
          )}
        </Descriptions.Item>

        {isMaintainedDevicesEnabled ? (
          <Descriptions.Item label={t("failure.device")}>
            <StringDisplay value={failure?.deviceName} />
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label={t("failure.address")}>
            <GoogleMapsAddressLink address={failure?.address} />
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t("failure.comment")}>
          {failure?.comment ? `"${failure?.comment}"` : <EmptyContent />}
        </Descriptions.Item>
      </VerticalDataDescriptions>
    </Space>
  );
};

export default FailureViewReportData;
