import { Descriptions, DescriptionsProps } from "antd";
import clsx from "clsx";
import React from "react";
import styles from "./VerticalDataDescriptions.module.scss";

interface VerticalDataDescriptionsProps
  extends Omit<DescriptionsProps, "layout"> {
  variant?: "normal" | "formItem";
}

const VerticalDataDescriptions: React.FC<VerticalDataDescriptionsProps> = ({
  children,
  colon = false,
  variant = "normal",
  ...rest
}) => {
  return (
    <Descriptions
      {...rest}
      layout="vertical"
      colon={colon}
      className={clsx(styles.wrapper, {
        [styles.formItemVariant]: variant === "formItem",
      })}
    >
      {children}
    </Descriptions>
  );
};

export default VerticalDataDescriptions;
