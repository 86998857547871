import { mdiDelete, mdiEye, mdiMapMarkerPlus } from "@mdi/js";
import { Modal, ModalFuncProps, notification, Space, Tag } from "antd";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  DataType,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

import { ContractorVm } from "api/generated/lumen";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import ListPageLayout from "components/layout/ListPageLayout";
import BooleanDisplay from "components/ui/BooleanDisplay";
import useContractorsListColumnTitles from "./useContractorsListColumnTitles";
import ContractorCreateModal from "../create/ContractorCreateModal";
import { useNavigate } from "react-router-dom";
import useFeature from "hooks/useFeature";
import AssignContractorToSpecialPlaceModal from "../assign/AssignContractorToSpecialPlaceModal";
import useAllSlenderTownsQuery from "hooks/queries/useAllSlenderTownsQuery";

const { confirm } = Modal;

const ContractorsList: React.FC = () => {
  const { listParams, list } = useSelector((state) => state.contractorsList);
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.contractorsList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const navigate = useNavigate();

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { columnTitles } = useContractorsListColumnTitles();
  const allSlenderTownsQuery = useAllSlenderTownsQuery();

  const columnParams = useMemo<ColumnConfigParams<ContractorVm>[]>(
    () =>
      [
        {
          key: "name",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "headquarterAddress.zip",
          dataIndex: ["headquarterAddress", "zip"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "headquarterAddress.city",
          dataIndex: ["headquarterAddress", "city"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.FILTER_SELECT,
          filters: (allSlenderTownsQuery.data?.items || [])
            .map((item) => ({
              text: item.name || "",
              value: item.name || "",
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
          filterOptionsLoading: allSlenderTownsQuery.isLoading,
        },
        {
          key: "headquarterAddress.street",
          dataIndex: ["headquarterAddress", "street"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "headquarterAddress.number",
          dataIndex: ["headquarterAddress", "number"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "isActive",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.ACTIVE_INACTIVE,
          dataType: DataType.ACTIVE_INACTIVE,
          render: (value: boolean) => {
            return (
              <BooleanDisplay
                value={value}
                trueDisplay={<Tag color="success">{t("common.active")}</Tag>}
                falseDisplay={<Tag color="error">{t("common.inactive")}</Tag>}
              />
            );
          },
        },
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [
      allSlenderTownsQuery.data?.items,
      allSlenderTownsQuery.isLoading,
      columnTitles,
      t,
    ]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/contractors/${id}`);
    },
    [navigate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.contractorsList.deleteItem(id);

          return dispatch.contractorsList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.contractorsList, t, listParams]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleExportButtonClick = useCallback(() => {
    dispatch.contractorsList.generateExcel(columnTitles);
  }, [columnTitles, dispatch.contractorsList]);

  const handleAssignSpecialPlaceClick = useCallback(
    (id?: string) => {
      setAssignModalOpen(true);

      const selectedContractor = list?.find(
        (contractor) => id === contractor.id
      );

      dispatch.assignContractor.setContractor(selectedContractor);
    },
    [dispatch.assignContractor, list]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<ContractorVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />

            {isMaintainedDevicesFeatureEnabled && (
              <IconButton
                path={mdiMapMarkerPlus}
                onClick={() => handleAssignSpecialPlaceClick(record.id)}
                title={t("contractor.assignSpecialPlaceButtonTitle")}
              />
            )}

            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [
      handleAssignSpecialPlaceClick,
      handleDeleteButtonClick,
      handleViewButtonClick,
      isMaintainedDevicesFeatureEnabled,
      t,
    ]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.contractorsList.fetchList(requestParams),
    [dispatch.contractorsList]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.CONTRACTORS,
    headerOptions: (
      <TableHeaderOptions
        onNewButtonClick={handleNewButtonClick}
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.contractorsList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.contractorsList.reset,
  });

  const handleModalOk = useCallback(() => {
    notification.success({
      message: t("contractor.assignSpecialPlaceModal.success"),
    });

    setAssignModalOpen(false);
  }, [t]);

  return (
    <ListPageLayout title={t("contractor.list.title")}>
      <CustomTable<ContractorVm>
        {...customTableProps}
        selectable={false}
        scrollX={1000}
      />

      <ContractorCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />

      <AssignContractorToSpecialPlaceModal
        visible={assignModalOpen}
        onCancel={() => setAssignModalOpen(false)}
        onOk={() => handleModalOk()}
      />
    </ListPageLayout>
  );
};

export default ContractorsList;
