import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface StringDisplayProps {
  value?: string | null;
}

const StringDisplay: React.FC<StringDisplayProps> = ({ value }) => {
  return typeof value === "string" && value !== "" ? (
    <>{value}</>
  ) : (
    <EmptyContent />
  );
};

export default StringDisplay;
