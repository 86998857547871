export const useFileInput = () => {
  const normFile = (event: any) => {
    if (Array.isArray(event)) {
      return event;
    }

    if (event.fileList.length > 1) {
      event.fileList.shift();
    }

    return event && event.fileList;
  };

  const normMultipleFiles = (event: any) => {
    if (Array.isArray(event)) {
      return event;
    }

    return event && event.fileList;
  };

  // https://levelup.gitconnected.com/managing-file-uploads-with-ant-design-6d78e592f2c4
  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fileSizeCheck = (file: any, maxMBSize: number) => {
    const fileSizeMB = file.size / (1024 * 1024);

    return fileSizeMB <= maxMBSize;
  };

  const fileExtensionCheck = (file: any, acceptedExtensions: any) => {
    const extensions = acceptedExtensions.split(", ");

    return extensions.includes(file.type);
  };

  return {
    normFile,
    normMultipleFiles,
    dummyRequest,
    fileSizeCheck,
    fileExtensionCheck,
  };
};

export default useFileInput;
