import { Descriptions, Spin, Tabs, Tag, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AddressDisplay from "components/ui/AddressDisplay";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import { mdiInformation, mdiPencil } from "@mdi/js";
import IconButton from "components/ui/IconButton";
import styles from "./RepairmenView.module.scss";
import RepairmenCreateModal from "../create/RepairmenCreateModal";
import { DateFormat } from "widgets/table/DateDisplay";
import { formatDate } from "utils/date";
import NameDisplay from "components/ui/NameDisplay";
import StretchToCardEdge from "components/StretchToCardEdge";
import useFeature from "hooks/useFeature";
import DocumentsTab from "./components/tabs/documents/DocumentsTab";
import ExamsTab from "./components/tabs/exams/ExamsTab";
import clsx from "clsx";
import { DeadlineStatusType } from "api/generated/lumen";
import { MaterialIcon } from "components/MaterialIcon";
import TabsWrapper from "components/ui/TabsWrapper";
import CardTitle from "components/ui/CardTitle";
import CardContent from "components/ui/CardContent";

const RepairmenView: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { repairman } = useSelector((state) => state.repairmenView);
  const { fetchRepairman } = useSelector(
    (state) => state.loading.effects.repairmenView
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { isEnabled: isExamFeatureEnabled } = useFeature("exam");

  const handleEditButtonClick = useCallback(() => {
    setEditModalOpen(true);
  }, []);

  const fetchRepairmenById = useCallback(
    (id?: string) => {
      if (typeof params.id === "string") {
        dispatch.repairmenView.fetchRepairman(params.id);
      }
    },
    [dispatch.repairmenView, params.id]
  );

  const birthPlaceAndDate = useMemo(
    () =>
      [repairman?.birthPlace, formatDate(repairman?.birthDate, DateFormat.Date)]
        .filter(Boolean)
        .join(", "),
    [repairman?.birthDate, repairman?.birthPlace]
  );

  const handleAfterDocumentUpload = useCallback(() => {
    fetchRepairmenById(params.id);

    setEditModalOpen(false);
  }, [fetchRepairmenById, params.id]);

  const handleAfterDocumentDelete = useCallback(() => {
    fetchRepairmenById(params.id);
  }, [fetchRepairmenById, params.id]);

  const handleAfterExamDelete = useCallback(() => {
    fetchRepairmenById(params.id);
  }, [fetchRepairmenById, params.id]);

  useEffect(() => {
    fetchRepairmenById(params.id);
  }, [fetchRepairmenById, params.id]);

  useEffect(() => {
    if (repairman?.contractor && repairman) {
      dispatch.common.setBreadcrumbs([
        {
          title: t("menu.repairmen"),
          to: "/repairmen",
        },
        {
          title: repairman?.contractor?.name || "",
        },
        {
          title: <NameDisplay value={repairman} />,
        },
      ]);
    }

    return () => {
      dispatch.common.setBreadcrumbs([]);
    };
  }, [dispatch.common, repairman, repairman?.contractor?.name, t]);

  useEffect(() => {
    return () => {
      dispatch.repairmenView.reset();
    };
  }, [dispatch.repairmenView]);

  return (
    <>
      <CardTitle className={styles.headerDescriptionsRow}>
        <VerticalDataDescriptions column={1}>
          <Descriptions.Item
            label={`${t("repairmen.repairman")} - ${
              repairman?.contractor?.name || ""
            }`}
          >
            <Typography.Title
              level={2}
              className={clsx(
                {
                  [styles.textWarning]:
                    isExamFeatureEnabled &&
                    repairman?.deadlineStatus ===
                      DeadlineStatusType.NearDeadline,
                  [styles.textError]:
                    isExamFeatureEnabled &&
                    repairman?.deadlineStatus === DeadlineStatusType.Expired,
                },
                styles.name
              )}
            >
              <NameDisplay value={repairman} />
              {isExamFeatureEnabled &&
                (repairman?.deadlineStatus ===
                  DeadlineStatusType.NearDeadline ||
                  repairman?.deadlineStatus === DeadlineStatusType.Expired) && (
                  <Tooltip
                    title={
                      repairman?.deadlineStatus ===
                      DeadlineStatusType.NearDeadline
                        ? t("repairmen.info.deadlineStatusNearDeadline")
                        : t("repairmen.info.deadlineStatusExpired")
                    }
                  >
                    <MaterialIcon
                      path={mdiInformation}
                      className={styles.infoIcon}
                    />
                  </Tooltip>
                )}
            </Typography.Title>
          </Descriptions.Item>
        </VerticalDataDescriptions>

        <div className={styles.tagWrapper}>
          {repairman?.isActive ? (
            <Tag color="success">{t("common.active")}</Tag>
          ) : (
            <Tag color="error">{t("common.inactive")}</Tag>
          )}
        </div>
        <IconButton path={mdiPencil} onClick={() => handleEditButtonClick()} />
      </CardTitle>

      <CardContent>
        <Spin spinning={fetchRepairman.loading}>
          <VerticalDataDescriptions column={4}>
            <Descriptions.Item label={t("repairmen.addressMerged")}>
              <AddressDisplay value={repairman?.address} />
            </Descriptions.Item>
            <Descriptions.Item label={t("repairmen.birthPlaceAndDate")}>
              {birthPlaceAndDate || <EmptyContent />}
            </Descriptions.Item>
            <Descriptions.Item label={t("repairmen.phone")}>
              {repairman?.phone?.number || <EmptyContent />}
            </Descriptions.Item>
            <Descriptions.Item label={t("repairmen.email")}>
              {repairman?.email || <EmptyContent />}
            </Descriptions.Item>
          </VerticalDataDescriptions>

          <StretchToCardEdge>
            <TabsWrapper extraMargin>
              <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane
                type="card"
                size="small"
              >
                <Tabs.TabPane tab={t("repairmen.tabs.documents")} key="1">
                  <DocumentsTab
                    afterDocumentUpload={handleAfterDocumentUpload}
                    afterDocumentDelete={handleAfterDocumentDelete}
                  />
                </Tabs.TabPane>
                {isExamFeatureEnabled && (
                  <Tabs.TabPane
                    tab={
                      <span
                        className={clsx({
                          [styles.textWarning]:
                            repairman?.deadlineStatus ===
                            DeadlineStatusType.NearDeadline,
                          [styles.textError]:
                            repairman?.deadlineStatus ===
                            DeadlineStatusType.Expired,
                        })}
                      >
                        {t("repairmen.tabs.exams")}
                      </span>
                    }
                    key="2"
                  >
                    <ExamsTab afterDelete={handleAfterExamDelete} />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </TabsWrapper>
          </StretchToCardEdge>
        </Spin>
      </CardContent>

      <RepairmenCreateModal
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
      />
    </>
  );
};

export default RepairmenView;
