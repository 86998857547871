import { Button, Card, Input, Typography } from "antd";
import { useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import useFeature from "hooks/useFeature";
import { useCallback, useState } from "react";
import { GrapeAntdForm, useGrapeAntdForm } from "widgets/form";
import FailureAttributesFieldset from "../create/components/FailureAttributesFieldset";
import FailureDeviceFieldset from "../create/components/FailureDeviceFieldset";
import FailureLocationFieldset from "../create/components/FailureLocationFieldset";
import { FailureReportFormValues } from "../models";
import styles from "./FailureReport.module.scss";
import { useTranslation } from "react-i18next";
import FailureNotifierFieldset from "../create/components/FailureNotifierFieldset";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface FailureReportContentProps {
  handleFinish: (
    values: FailureReportFormValues,
    recaptchaToken: string
  ) => Promise<void>;
}

const FailureReportContent: React.FC<FailureReportContentProps> = ({
  handleFinish,
}) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isGDPRAccepted, setGDPRAccepted] = useState<boolean>(false);

  const formUtils = useGrapeAntdForm<FailureReportFormValues>(
    "CreateFailureCommand"
  );

  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  const { report } = useSelector(
    (state) => state.loading.effects.failureReport
  );

  const onFinish = useCallback(
    async (values: FailureReportFormValues) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("failureReport");

      await handleFinish(values, token);

      setGDPRAccepted(false);
      formUtils.form.resetFields();
    },
    [executeRecaptcha, formUtils.form, handleFinish]
  );

  return (
    <Card className={styles.wrapper}>
      <Typography.Title className="text-center">
        {t("failure.report.title")}
      </Typography.Title>

      <p className="text-center">{t("failure.report.description")}</p>

      <CustomForm formUtils={formUtils} onFinish={onFinish}>
        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        {isMaintainedDevicesEnabled ? (
          <FailureDeviceFieldset disabled={report.loading} />
        ) : (
          <FailureLocationFieldset disabled={report.loading} />
        )}

        <FailureAttributesFieldset isReport={true} disabled={report.loading} />

        <FailureNotifierFieldset
          isEmailRequired={true}
          gdprCheckboxData={{ isGDPRAccepted, setGDPRAccepted }}
          disabled={report.loading}
        />

        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={report.loading}
            disabled={!isGDPRAccepted}
            title={t("failure.report.gdprRequired")}
          >
            {t("failure.report.submit")}
          </Button>
        </div>
      </CustomForm>
    </Card>
  );
};

export default FailureReportContent;
