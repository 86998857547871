/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FailureCreatorTypeVm
 */
export interface FailureCreatorTypeVm {
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorTypeVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorTypeVm
     */
    displayName?: string | null;
}

export function FailureCreatorTypeVmFromJSON(json: any): FailureCreatorTypeVm {
    return FailureCreatorTypeVmFromJSONTyped(json, false);
}

export function FailureCreatorTypeVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureCreatorTypeVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function FailureCreatorTypeVmToJSON(value?: FailureCreatorTypeVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
    };
}


