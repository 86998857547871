/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetachFailureCommand
 */
export interface DetachFailureCommand {
    /**
     * 
     * @type {string}
     * @memberof DetachFailureCommand
     */
    failureId: string;
    /**
     * 
     * @type {string}
     * @memberof DetachFailureCommand
     */
    failureToDetachId: string;
}

export function DetachFailureCommandFromJSON(json: any): DetachFailureCommand {
    return DetachFailureCommandFromJSONTyped(json, false);
}

export function DetachFailureCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetachFailureCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureId': json['failureId'],
        'failureToDetachId': json['failureToDetachId'],
    };
}

export function DetachFailureCommandToJSON(value?: DetachFailureCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureId': value.failureId,
        'failureToDetachId': value.failureToDetachId,
    };
}


