/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Phone,
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
    Town,
    TownFromJSON,
    TownFromJSONTyped,
    TownToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    forename?: string | null;
    /**
     * 
     * @type {Phone}
     * @memberof ContactPerson
     */
    phone?: Phone;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    contractorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ContactPerson
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactPerson
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Array<Town>}
     * @memberof ContactPerson
     */
    towns?: Array<Town> | null;
    /**
     * 
     * @type {Contractor}
     * @memberof ContactPerson
     */
    contractor?: Contractor;
    /**
     * 
     * @type {User}
     * @memberof ContactPerson
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    id?: string;
}

export function ContactPersonFromJSON(json: any): ContactPerson {
    return ContactPersonFromJSONTyped(json, false);
}

export function ContactPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'phone': !exists(json, 'phone') ? undefined : PhoneFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'contractorId': !exists(json, 'contractorId') ? undefined : json['contractorId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'towns': !exists(json, 'towns') ? undefined : (json['towns'] === null ? null : (json['towns'] as Array<any>).map(TownFromJSON)),
        'contractor': !exists(json, 'contractor') ? undefined : ContractorFromJSON(json['contractor']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ContactPersonToJSON(value?: ContactPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'phone': PhoneToJSON(value.phone),
        'email': value.email,
        'contractorId': value.contractorId,
        'userId': value.userId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'towns': value.towns === undefined ? undefined : (value.towns === null ? null : (value.towns as Array<any>).map(TownToJSON)),
        'contractor': ContractorToJSON(value.contractor),
        'user': UserToJSON(value.user),
        'id': value.id,
    };
}


