import { Col, ConfigProvider, Empty, ModalProps, Row, Spin } from "antd";
import { DictionaryVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import { DeviceLayoutType } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDictionaryItemLabel } from "utils";
import ItemChooserModal from "../components/ItemChooserModal";
import ItemChooserSearch from "./ItemChooserSearch";
import ItemChooserTask from "./ItemChooserTask";
import styles from "./AddNewWorkbookTaskModal.module.scss";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";

interface AddNewWorkbookTaskModalProps extends ModalProps {
  deviceLayoutType?: DeviceLayoutType;
}

const AddNewWorkbookTaskModal: React.FC<AddNewWorkbookTaskModalProps> = ({
  onCancel,
  visible,
  deviceLayoutType,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addTaskToWorkbook } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const failureTasksQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenFailureTasks",
    dictionaryIsEditableInApp: true,
    onlyActive: true,
  });

  const { workbook, failure } = useSelector((state) => state.failureView);
  const [searchKeyword, setSearchKeyword] = useState("");

  const filteredFailureTasks = useMemo(
    () =>
      (failureTasksQuery.data || [])
        .filter((task) =>
          getDictionaryItemLabel(task)
            ?.toLowerCase()
            .includes(searchKeyword.toLowerCase())
        )
        .sort((a, b) =>
          getDictionaryItemLabel(a).localeCompare(getDictionaryItemLabel(b))
        ),
    [failureTasksQuery.data, searchKeyword]
  );

  const addNewTask = useCallback(
    async (task: DictionaryVm) => {
      if (typeof task.id === "string" && typeof workbook?.id === "string") {
        await dispatch.failureView.addTaskToWorkbook({
          taskId: task.id,
          workbookId: workbook?.id,
        });

        onCancel?.("" as any);

        if (typeof failure?.id === "string") {
          dispatch.failureView.fetchWorkbook(failure.id);
        }
      }
    },
    [dispatch.failureView, failure?.id, onCancel, workbook?.id]
  );

  useEffect(() => {
    if (!visible) {
      setSearchKeyword("");
    }
  }, [visible]);

  return (
    <ItemChooserModal
      {...rest}
      title={t("workbook.chooseTask")}
      onCancel={onCancel}
      visible={visible}
      className={clsx({ [styles.desktop]: deviceLayoutType === "default" })}
    >
      <ItemChooserSearch
        onChange={(e) => setSearchKeyword(e.target.value)}
        value={searchKeyword}
      />

      <Spin spinning={failureTasksQuery.isLoading || addTaskToWorkbook.loading}>
        <Row gutter={[22, 22]}>
          {filteredFailureTasks.length ? (
            filteredFailureTasks.map((task) => (
              <Col span={8} key={task.id}>
                <ItemChooserTask
                  onClick={() => addNewTask(task)}
                  className={clsx(styles.itemTitle)}
                >
                  <DictionaryDisplay dictionary={task} />
                </ItemChooserTask>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <ConfigProvider
                locale={{
                  locale: "hu",
                  Empty: {
                    description: t("common.noDataForSearchValue"),
                  },
                }}
              >
                <Empty />
              </ConfigProvider>
            </Col>
          )}
        </Row>
      </Spin>
    </ItemChooserModal>
  );
};

export default AddNewWorkbookTaskModal;
