import { UserInfoVm } from "api/generated/lumen";
import { Role } from "models/common";
import { useCallback } from "react";
import { useSelector } from "../../app/store";
import jwt from "jwt-decode";

interface UseAuth {
  userIsLoggedIn: boolean;
  userInfo?: UserInfoVm;
  hasRole: (contentRoles?: Role[]) => boolean;
  hasClaim: (contentClaims?: string[]) => boolean;
  userIsAdmin: boolean;
}

const useAuth = (): UseAuth => {
  const { loginData } = useSelector((state) => state.auth);

  const userInfo = loginData?.userInfo;

  const userIsLoggedIn = !!userInfo;

  const userRole = userInfo?.role?.name as Role;
  const userIsAdmin =
    userRole === "LumenAdmin" || userRole === "Root_SUPER_ADMIN";

  const hasRole = useCallback(
    (contentRoles?: Role[]) => {
      return !contentRoles || !contentRoles.length
        ? true
        : contentRoles.some((routeRole) => routeRole === userRole);
    },
    [userRole]
  );

  const hasClaim = useCallback(
    (contentClaims?: string[]) => {
      const decoded = loginData?.accessToken
        ? jwt<any>(loginData?.accessToken)
        : undefined;

      const userClaims = decoded
        ? (decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ] as string[])
        : undefined;

      return !contentClaims || !contentClaims.length
        ? true
        : contentClaims.some((routeClaim) => userClaims?.includes(routeClaim));
    },
    [loginData?.accessToken]
  );

  return {
    userIsLoggedIn,
    userInfo: loginData?.userInfo,
    hasRole,
    hasClaim,
    userIsAdmin,
  };
};

export default useAuth;
