/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateFailureTypeCommand,
    UpdateFailureTypeCommandFromJSON,
    UpdateFailureTypeCommandFromJSONTyped,
    UpdateFailureTypeCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateSlaCommand
 */
export interface UpdateSlaCommand {
    /**
     * 
     * @type {Array<UpdateFailureTypeCommand>}
     * @memberof UpdateSlaCommand
     */
    failureTypes: Array<UpdateFailureTypeCommand>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlaCommand
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSlaCommand
     */
    deadlineDays?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlaCommand
     */
    optimalDeadlineDaysId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlaCommand
     */
    slaCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlaCommand
     */
    townId: string;
}

export function UpdateSlaCommandFromJSON(json: any): UpdateSlaCommand {
    return UpdateSlaCommandFromJSONTyped(json, false);
}

export function UpdateSlaCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSlaCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureTypes': ((json['failureTypes'] as Array<any>).map(UpdateFailureTypeCommandFromJSON)),
        'id': json['id'],
        'deadlineDays': !exists(json, 'deadlineDays') ? undefined : json['deadlineDays'],
        'optimalDeadlineDaysId': !exists(json, 'optimalDeadlineDaysId') ? undefined : json['optimalDeadlineDaysId'],
        'slaCategoryId': json['slaCategoryId'],
        'townId': json['townId'],
    };
}

export function UpdateSlaCommandToJSON(value?: UpdateSlaCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureTypes': ((value.failureTypes as Array<any>).map(UpdateFailureTypeCommandToJSON)),
        'id': value.id,
        'deadlineDays': value.deadlineDays,
        'optimalDeadlineDaysId': value.optimalDeadlineDaysId,
        'slaCategoryId': value.slaCategoryId,
        'townId': value.townId,
    };
}


