import { Button, Input, notification, Space, Typography } from "antd";
import { useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CardWithBrandLogo from "components/ui/CardWithBrandLogo";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { hashSHA256 } from "utils/crypto";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { LoginFormValues } from "../models";
import styles from "./Login.module.scss";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm<LoginFormValues>("LoginCommand");
  const { login } = useSelector((state) => state.loading.effects.auth);

  const handleFinish = useCallback(
    async (values: LoginFormValues): Promise<void> => {
      try {
        await dispatch.auth.login({
          username: values.username,
          passwordHash: await hashSHA256(values.password),
        });
      } catch (error) {
        // /login request errors aren't handled globally, but here:
        notification.error({
          message: t(`error.common.errorsTitle`),
          description: t(`error.login`),
          duration: 10,
        });
      }
    },
    [dispatch.auth, t]
  );

  return (
    <CardWithBrandLogo
      footerContent={
        <>
          <Link to="/forgotten-password/request">
            <Button type="link" className={styles.button}>
              {t("login.forgotPassword")}
            </Button>
          </Link>

          {!!process.env.REACT_APP_SHOW_BUILD_NUMBER && (
            <div>v{process.env.REACT_APP_BUILD_NUMBER}</div>
          )}
        </>
      }
      size="small"
    >
      <div>
        <Typography.Title>{t("login.title")}</Typography.Title>

        <CustomForm formUtils={formUtils} onFinish={handleFinish}>
          <Space direction="vertical" size="large" className={styles.space}>
            <div>
              <GrapeAntdForm.Item
                name="username"
                label={t("login.userName")}
                rules={[requiredField(t)]}
              >
                <Input size="large" type="email" />
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                name="password"
                label={t("login.password")}
                rules={[requiredField(t)]}
              >
                <Input.Password size="large" />
              </GrapeAntdForm.Item>
            </div>

            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={login.loading}
              block
            >
              {t("login.loginButton")}
            </Button>
          </Space>
        </CustomForm>
      </div>
    </CardWithBrandLogo>
  );
};

export default Login;
