import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateFailureTypeCommand,
  UpdateFailureTypeCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";
interface FailureTypesCreateState {
  editingFailureTypeId?: string;
}

const initialState: FailureTypesCreateState = {
  editingFailureTypeId: undefined,
};

export const failureTypesCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingFailureTypeId(state, data?: string) {
      state.editingFailureTypeId = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      async create(values: CreateFailureTypeCommand): Promise<void> {
        await api.lumen.failureTypes.apiFailureTypesPost({
          createFailureTypeCommand: values,
        });
      },

      async edit(values: UpdateFailureTypeCommand): Promise<void> {
        await api.lumen.failureTypes.apiFailureTypesIdPut({
          updateFailureTypeCommand: values,
          id: values.id,
        });
      },
    };
  },
});
