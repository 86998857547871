import { mdiDelete, mdiPlus } from "@mdi/js";
import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd";
import { useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import IconButton from "components/ui/IconButton";
import { SLAFieldsetValues, TownCreateFormValues } from "features/towns/models";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";
import { FormMode, ItemProps } from "models/common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dictionaryOptions, filterOptionsLabelFromBeginning } from "utils";
import { FormUtils, GrapeAntdForm, requiredField } from "widgets/form";
import styles from "./SLAFieldset.module.scss";

const { confirm } = Modal;
interface SLAFieldsetProps {
  formUtils: FormUtils<TownCreateFormValues>;
  formMode: FormMode;
}

interface GetAlreadyUsedOptionsProps {
  formUtils: FormUtils<TownCreateFormValues>;
  formListIndex: number;
  itemKey: keyof SLAFieldsetValues;
}

const getAlreadyUsedOptions = ({
  formListIndex,
  formUtils,
  itemKey,
}: GetAlreadyUsedOptionsProps) => {
  const alreadyUsedSlaValuesInOtherSelects =
    formUtils.form
      .getFieldsValue(true)
      .slas?.reduce(
        (
          previousValue: (string | undefined | string[])[],
          currentValue: SLAFieldsetValues,
          currentIndex: number
        ) => {
          // only add values from form list items that are not the current one
          if (currentIndex === formListIndex) {
            return previousValue;
          }

          return [
            ...previousValue,
            ...(Array.isArray(currentValue[itemKey]) // only add values from form list items that are not the current one
              ? (currentValue[itemKey] as string[])
              : [currentValue[itemKey]]),
          ];
        },
        []
      ) || [];

  return alreadyUsedSlaValuesInOtherSelects;
};

const SLAFieldset: React.FC<SLAFieldsetProps> = ({ formUtils, formMode }) => {
  const { t } = useTranslation();
  const { fetchFailureTypes } = useSelector(
    (state) => state.loading.effects.townCreate
  );
  const { failureTypes } = useSelector((state) => state.townCreate);
  const optimalDeadlineDaysQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenOptimalDeadlineDays",
  });
  const slaCategoriesQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenSlaCategory",
  });
  const slaDeadlinesQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenSlaDeadlines",
  });

  const failureTypeOptions = useMemo<ItemProps[]>(
    () =>
      failureTypes.map((type) => ({
        label: type.name || "",
        value: type.id || "",
      })),
    [failureTypes]
  );

  const deadlineOptions = useMemo(
    () => dictionaryOptions(slaDeadlinesQuery.data || [], undefined, "value"),
    [slaDeadlinesQuery.data]
  );

  /**
   * We use natural sort here, because of the category names: https://en.wikipedia.org/wiki/Natural_sort_order
   * Solution: https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
   */
  const failureCategoryOptions = useMemo(
    () =>
      dictionaryOptions(slaCategoriesQuery.data || []).sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      ),
    [slaCategoriesQuery.data]
  );

  const optimalFixingTimeOptions = useMemo(
    () => dictionaryOptions(optimalDeadlineDaysQuery.data || []),
    [optimalDeadlineDaysQuery.data]
  );

  const handleRemove = (
    index: number,
    removeFn: (index: number | number[]) => void
  ) => {
    const contactPersonsValues = formUtils.form.getFieldValue("slas");

    // If user deletes already saved contactPerson
    if (contactPersonsValues[index]?.id) {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          removeFn(index);
        },
      });

      return;
    }

    removeFn(index);
  };

  return (
    <>
      <fieldset>
        <Form.List name="slas">
          {(fields, { add, remove }) => (
            <>
              <legend>
                <Row justify="space-between">
                  <Col>{t("town.slaFieldset")}</Col>
                  <Col>
                    <Button
                      icon={<MaterialIcon path={mdiPlus} />}
                      onClick={add}
                      disabled={formMode === "View"}
                    >
                      {t("town.newSlaCategory")}
                    </Button>
                  </Col>
                </Row>
              </legend>

              <Space direction="vertical" className={styles.space}>
                {fields.length === 0 && (
                  <p>{t("town.slaCategoryNotSpecified")}</p>
                )}

                {fields.map(({ key, name, ...restField }, index) => (
                  <FieldsetFieldsWrapper key={key}>
                    <IconButton
                      path={mdiDelete}
                      onClick={() => handleRemove(name, remove)}
                      className={styles.deleteButton}
                      lightIcon
                      disabled={formMode === "View"}
                    />
                    <Row justify="center" gutter={[24, 8]}>
                      <Col span={10}>
                        <GrapeAntdForm.Item
                          {...restField}
                          name={[name, "id"]}
                          hidden
                        >
                          <Input hidden />
                        </GrapeAntdForm.Item>
                        <GrapeAntdForm.Item
                          {...restField}
                          name={[name, "slaCategoryId"]}
                          label={t("town.sla.category")}
                          rules={[requiredField(t)]}
                        >
                          <Select
                            size="large"
                            loading={slaCategoriesQuery.isLoading}
                            options={failureCategoryOptions.filter((item) => {
                              // Get all the used SLA values that are already selected, but not in the current <Select />
                              const alreadyUsedSlaValuesInOtherSelects =
                                getAlreadyUsedOptions({
                                  formUtils,
                                  formListIndex: index,
                                  itemKey: "slaCategoryId",
                                });

                              return !alreadyUsedSlaValuesInOtherSelects.includes(
                                item.value
                              );
                            })}
                          />
                        </GrapeAntdForm.Item>
                      </Col>
                      <Col span={7}>
                        <GrapeAntdForm.Item
                          {...restField}
                          name={[name, "deadlineDays"]}
                          label={t("town.sla.deadline")}
                          rules={[requiredField(t)]}
                        >
                          <Select
                            size="large"
                            options={deadlineOptions}
                            loading={slaDeadlinesQuery.isLoading}
                            showSearch
                            optionFilterProp="label"
                            filterOption={filterOptionsLabelFromBeginning}
                          />
                        </GrapeAntdForm.Item>
                      </Col>
                      <Col span={7}>
                        <GrapeAntdForm.Item
                          {...restField}
                          name={[name, "optimalDeadlineDaysId"]}
                          label={t("town.sla.optimalFixingTime")}
                        >
                          <Select
                            size="large"
                            options={optimalFixingTimeOptions}
                            loading={optimalDeadlineDaysQuery.isLoading}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            filterOption={filterOptionsLabelFromBeginning}
                          />
                        </GrapeAntdForm.Item>
                      </Col>
                      <Col span={24}>
                        <GrapeAntdForm.Item
                          {...restField}
                          name={[name, "failureTypes"]}
                          label={t("town.sla.failureTypes")}
                          rules={[
                            requiredField(t, undefined, { type: "array" }),
                          ]}
                        >
                          <Select
                            size="large"
                            mode="multiple"
                            loading={fetchFailureTypes.loading}
                            options={failureTypeOptions.filter((item) => {
                              // Get all the used SLA values that are already selected, but not in the current <Select />
                              const alreadyUsedSlaValuesInOtherSelects =
                                getAlreadyUsedOptions({
                                  formUtils,
                                  formListIndex: index,
                                  itemKey: "failureTypes",
                                });

                              return !alreadyUsedSlaValuesInOtherSelects.includes(
                                item.value
                              );
                            })}
                            showSearch
                            optionFilterProp="label"
                            filterOption={filterOptionsLabelFromBeginning}
                          />
                        </GrapeAntdForm.Item>
                      </Col>
                    </Row>
                  </FieldsetFieldsWrapper>
                ))}
              </Space>
            </>
          )}
        </Form.List>
      </fieldset>
    </>
  );
};

export default SLAFieldset;
