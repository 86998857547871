import React from "react";
import themeConfig from "themeConfig";

interface BrandImageProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src"
  > {
  subPath: string;
}

const BrandImage: React.FC<BrandImageProps> = ({ subPath, alt, ...rest }) => {
  return (
    <img src={`${themeConfig.imagesFolder}/${subPath}`} alt={alt} {...rest} />
  );
};

export default BrandImage;
