/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    token?: string;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    expires?: Date;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    userId?: string;
    /**
     * 
     * @type {User}
     * @memberof RefreshToken
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    id?: string;
}

export function RefreshTokenFromJSON(json: any): RefreshToken {
    return RefreshTokenFromJSONTyped(json, false);
}

export function RefreshTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'expires': !exists(json, 'expires') ? undefined : (new Date(json['expires'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function RefreshTokenToJSON(value?: RefreshToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'expires': value.expires === undefined ? undefined : (value.expires.toISOString()),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


