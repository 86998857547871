import { mdiChevronLeft } from "@mdi/js";
import { MaterialIcon } from "components/MaterialIcon";
import { Breadcrumb } from "models/common";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.scss";

export interface BreadcrumbsProps {
  items: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    (items.length && (
      <ul className={styles.wrapper}>
        {items.map((item, index) => (
          <li className={styles.item} key={index}>
            {item.to ? (
              <Link to={item.to} className={styles.link}>
                <MaterialIcon path={mdiChevronLeft} />
                {item.title}
              </Link>
            ) : (
              item.title
            )}
          </li>
        ))}
      </ul>
    )) ||
    null
  );
};

export default Breadcrumbs;
