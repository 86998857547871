import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CheckInvitationVm,
  LoginCommand,
  LoginVm,
  PasswordComplexityVm,
  RegisterDto,
} from "api/generated/lumen";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "utils/storage";
import { RootModel } from "../../app/store";

interface AuthState {
  loginData?: LoginVm;
  checkInvitationData?: CheckInvitationVm;
  passwordComplexity?: PasswordComplexityVm;
}

const getInitialState = (): AuthState => ({
  loginData: getLocalStorageItem("loginData"),
  checkInvitationData: undefined,
  passwordComplexity: undefined,
});

export const auth = createModel<RootModel>()({
  state: getInitialState(),
  reducers: {
    setLoginData(state, payload: LoginVm) {
      state.loginData = payload;

      setLocalStorageItem("loginData", payload);
    },
    clearLoginData(state) {
      removeLocalStorageItem("loginData");

      state.loginData = undefined;
    },
    setCheckInvitationData(state, payload?: CheckInvitationVm) {
      state.checkInvitationData = payload;
    },
    setPasswordComplexity(state, payload?: PasswordComplexityVm) {
      state.passwordComplexity = payload;
    },
  },
  effects: (dispatch) => {
    const { auth } = dispatch;

    return {
      async login(values: LoginCommand): Promise<void> {
        const data = await api.lumen.accounts.apiAccountsLoginPost({
          loginCommand: values,
        });

        auth.setLoginData(data);
      },

      async logout(): Promise<void> {
        auth.clearLoginData();
      },

      async checkInvitation(userId: string): Promise<void> {
        const data =
          await api.lumen.accounts.apiAccountsCheckInvitationCheckInvitationUserIdGet(
            {
              userId,
            }
          );

        auth.setCheckInvitationData(data);
      },

      async fetchPasswordComplexity(): Promise<void> {
        const data = await api.lumen.accounts.apiAccountsPasswordComplexityGet(
          {}
        );

        auth.setPasswordComplexity(data);
      },

      async register(registerDto: RegisterDto): Promise<void> {
        return api.lumen.accounts.apiAccountsRegisterPost({
          registerDto,
        });
      },

      async refreshAccessToken(values: any, state): Promise<void> {
        try {
          const data = await api.lumen.accounts.apiAccountsRefreshTokenPost({
            refreshTokenCommand: {
              refreshToken:
                getLocalStorageItem("loginData")?.refreshToken.token,
            },
          });

          const oldLoginData = state.auth.loginData;

          auth.setLoginData({
            ...oldLoginData,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
        } catch (error) {
          console.error(error);

          auth.logout();

          throw error;
        }
      },

      async requestForgottenPasswordEmail(username: string): Promise<void> {
        return api.lumen.accounts.apiAccountsLostPasswordPost({
          lostPasswordCommand: {
            username,
          },
        });
      },

      async forgottenPasswordSetNewPassword(
        registerDto: RegisterDto
      ): Promise<void> {
        return api.lumen.accounts.apiAccountsRegisterPost({
          registerDto,
        });
      },
    };
  },
});
