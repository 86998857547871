import { Button, Input, Space, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CardWithBrandLogo from "components/ui/CardWithBrandLogo";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { RequestForgottenPasswordEmailFormValues } from "../models";
import styles from "./RequestForgottenPasswordEmail.module.scss";

const RequestForgottenPasswordEmail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm<RequestForgottenPasswordEmailFormValues>(
    "LostPasswordCommand"
  );
  const { requestForgottenPasswordEmail } = useSelector(
    (state) => state.loading.effects.auth
  );

  const navigate = useNavigate();

  const handleFinish = useCallback(
    async (values: RequestForgottenPasswordEmailFormValues): Promise<void> => {
      await dispatch.auth.requestForgottenPasswordEmail(values.username);

      if (requestForgottenPasswordEmail.success) {
        navigate("success", { replace: true });
      }
    },
    [dispatch.auth, navigate, requestForgottenPasswordEmail.success]
  );

  return (
    <CardWithBrandLogo
      footerContent={
        <Link to="/login">
          <Button type="link" className={styles.button}>
            {t("forgottenPassword.backToLogin")}
          </Button>
        </Link>
      }
      size="small"
    >
      <div>
        <Typography.Title>{t("forgottenPassword.title")}</Typography.Title>

        <Space direction="vertical" size="middle">
          <Typography.Paragraph>
            {t("forgottenPassword.description")}
          </Typography.Paragraph>

          <CustomForm formUtils={formUtils} onFinish={handleFinish}>
            <Space direction="vertical" size="large" className={styles.space}>
              <GrapeAntdForm.Item
                name="username"
                label={t("forgottenPassword.userName")}
                rules={[requiredField(t)]}
              >
                <Input size="large" type="email" />
              </GrapeAntdForm.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={requestForgottenPasswordEmail.loading}
                block
              >
                {t("forgottenPassword.setNewPassword")}
              </Button>
            </Space>
          </CustomForm>
        </Space>
      </div>
    </CardWithBrandLogo>
  );
};

export default RequestForgottenPasswordEmail;
