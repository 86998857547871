import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import React, { cloneElement } from "react";

interface PermissionProps {
  acceptableRoles: Role[];
  acceptableClaims?: string[];
  children: any;
}

export const Permission: React.FC<PermissionProps> = ({
  acceptableRoles,
  acceptableClaims,
  children,
  ...rest
}) => {
  const { hasRole, hasClaim } = useAuth();

  if (!hasRole(acceptableRoles)) {
    return null;
  }

  if (acceptableClaims && !hasClaim(acceptableClaims)) {
    return null;
  }

  return Array.isArray(children) && children.length >= 2
    ? React.Children.map(children, (child) => {
        return React.cloneElement(child, { rest }, null);
      })
    : cloneElement(children, rest);
};
