import { FormMode } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { FormUtils } from "widgets/form";

interface UseEditFormModalProps<ItemType, FormValuesType> {
  list?: ItemType[];
  item?: ItemType;
  itemId?: string;
  modalIsVisible?: boolean;
  formUtils: FormUtils<FormValuesType>;
  getInitialValues: (itemData: ItemType) => RecursivePartial<FormValuesType>;
  resetItemId: () => any;
  hasViewMode?: boolean;
}

interface UseEditFormModal {
  formMode: FormMode;
  setFormMode: React.Dispatch<React.SetStateAction<FormMode>>;
}

export type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

function useEditFormModal<
  ItemType extends { id?: string },
  FormValuesType = any
>({
  list,
  item,
  itemId,
  modalIsVisible,
  formUtils,
  getInitialValues,
  resetItemId,
  hasViewMode = true,
}: UseEditFormModalProps<ItemType, FormValuesType>): UseEditFormModal {
  const [formMode, setFormMode] = useState<FormMode>("Create");

  const itemBeingEdited = useMemo(
    () => item || list?.find(({ id }) => id === itemId),
    [item, itemId, list]
  );

  useEffect(() => {
    if (itemBeingEdited && modalIsVisible) {
      const initialValues = getInitialValues(itemBeingEdited);

      formUtils.form.setFieldsValue(initialValues);

      setFormMode(hasViewMode ? "View" : "Edit");
    }
  }, [
    formUtils.form,
    getInitialValues,
    itemBeingEdited,
    modalIsVisible,
    hasViewMode,
  ]);

  useEffect(() => {
    if (!modalIsVisible) {
      resetItemId();

      formUtils.form.resetFields();

      setFormMode("Create");
    }
  }, [formUtils.form, modalIsVisible, resetItemId]);

  return {
    formMode,
    setFormMode,
  };
}

export default useEditFormModal;
