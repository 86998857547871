/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetachSpecialPlaceFromContractorCommand
 */
export interface DetachSpecialPlaceFromContractorCommand {
    /**
     * 
     * @type {string}
     * @memberof DetachSpecialPlaceFromContractorCommand
     */
    specialPlaceToDetachId: string;
    /**
     * 
     * @type {string}
     * @memberof DetachSpecialPlaceFromContractorCommand
     */
    contractorToDetachId: string;
}

export function DetachSpecialPlaceFromContractorCommandFromJSON(json: any): DetachSpecialPlaceFromContractorCommand {
    return DetachSpecialPlaceFromContractorCommandFromJSONTyped(json, false);
}

export function DetachSpecialPlaceFromContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetachSpecialPlaceFromContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialPlaceToDetachId': json['specialPlaceToDetachId'],
        'contractorToDetachId': json['contractorToDetachId'],
    };
}

export function DetachSpecialPlaceFromContractorCommandToJSON(value?: DetachSpecialPlaceFromContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specialPlaceToDetachId': value.specialPlaceToDetachId,
        'contractorToDetachId': value.contractorToDetachId,
    };
}


