/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamType
 */
export interface ExamType {
    /**
     * 
     * @type {string}
     * @memberof ExamType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamType
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExamType
     */
    warningDaysBeforeExpiration?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamType
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamType
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExamType
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamType
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExamType
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamType
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ExamType
     */
    id?: string;
}

export function ExamTypeFromJSON(json: any): ExamType {
    return ExamTypeFromJSONTyped(json, false);
}

export function ExamTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'warningDaysBeforeExpiration': !exists(json, 'warningDaysBeforeExpiration') ? undefined : json['warningDaysBeforeExpiration'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ExamTypeToJSON(value?: ExamType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'warningDaysBeforeExpiration': value.warningDaysBeforeExpiration,
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


