/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DevicePropertyType,
    DevicePropertyTypeFromJSON,
    DevicePropertyTypeFromJSONTyped,
    DevicePropertyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DevicePropertyOption
 */
export interface DevicePropertyOption {
    /**
     * 
     * @type {string}
     * @memberof DevicePropertyOption
     */
    key?: string | null;
    /**
     * 
     * @type {DevicePropertyType}
     * @memberof DevicePropertyOption
     */
    type?: DevicePropertyType;
}

export function DevicePropertyOptionFromJSON(json: any): DevicePropertyOption {
    return DevicePropertyOptionFromJSONTyped(json, false);
}

export function DevicePropertyOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevicePropertyOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'type': !exists(json, 'type') ? undefined : DevicePropertyTypeFromJSON(json['type']),
    };
}

export function DevicePropertyOptionToJSON(value?: DevicePropertyOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'type': DevicePropertyTypeToJSON(value.type),
    };
}


