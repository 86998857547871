import { Layout } from "antd";
import React from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import FullPageCardContent from "./FullPageCardContent";
import styles from "./SidebarHeaderLayout.module.scss";

interface SidebarHeaderLayoutProps {
  headerChildren?: React.ReactNode;
  collapser?: React.ReactNode;
  logo?: React.ReactNode;
  sidebarChildren?: React.ReactNode;
  sidebarFooter?: React.ReactNode;
  aboveMainContent?: React.ReactNode;
}

const SidebarHeaderLayout: React.FC<SidebarHeaderLayoutProps> = ({
  headerChildren,
  collapser,
  logo,
  sidebarChildren,
  sidebarFooter,
  aboveMainContent,
}) => {
  return (
    <Layout className={styles.layout}>
      <Sidebar collapser={collapser} logo={logo} footer={sidebarFooter}>
        {sidebarChildren}
      </Sidebar>
      <Layout>
        <Header>{headerChildren}</Header>
        <FullPageCardContent>{aboveMainContent}</FullPageCardContent>
      </Layout>
    </Layout>
  );
};

export default SidebarHeaderLayout;
