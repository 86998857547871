import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import StreetImportTab from "./StreetImportTab";
import TabsWrapper from "components/ui/TabsWrapper";
import CardContent from "components/ui/CardContent";

const DataImport = () => {
  const { t } = useTranslation();

  return (
    <CardContent>
      <TabsWrapper>
        <Tabs defaultActiveKey="1" type="card" size="small">
          <Tabs.TabPane tab={t("dataImport.streetImport.tabLabel")} key="1">
            <StreetImportTab />
          </Tabs.TabPane>
        </Tabs>
      </TabsWrapper>
    </CardContent>
  );
};

export default DataImport;
