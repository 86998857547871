import NameDisplay from "components/ui/NameDisplay";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import styles from "./ReporterDataDisplay.module.scss";

interface ReporterDataDisplayProps {
  notifierFamilyname?: string | null;
  notifierForename?: string | null;
  notifierMiddlename?: string | null;
  notifierEmail?: string | null;
  comment?: string | null;
}

const ReporterDataDisplay: React.FC<ReporterDataDisplayProps> = ({
  notifierFamilyname,
  notifierForename,
  notifierMiddlename,
  notifierEmail,
  comment,
}) => {
  return (
    <>
      {(notifierFamilyname || notifierForename || notifierMiddlename) && (
        <span className={styles.notifierName}>
          <NameDisplay
            value={{
              familyname: notifierFamilyname,
              forename: notifierForename,
              middlename: notifierMiddlename,
            }}
          />
          {notifierEmail && <span>,</span>}
        </span>
      )}
      {notifierEmail && (
        <a href={`mailto:${notifierEmail}`} className={styles.notifierEmail}>
          {notifierEmail}
        </a>
      )}
      {comment && <div className={styles.comment}>"{comment}"</div>}

      {!(
        notifierFamilyname ||
        notifierForename ||
        notifierMiddlename ||
        notifierEmail ||
        comment
      ) && <EmptyContent />}
    </>
  );
};

export default ReporterDataDisplay;
