import { saveAs } from "file-saver";

const getFileName = (disposition: string): string | null => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(
      utf8FilenameRegex.exec(disposition)?.[1] || ""
    );
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf("filename=");
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }

  return fileName;
};

export async function downloadFile(response: Response, extension = ".xlsx") {
  // Display the key/value pairs
  /* for (const pair of response.headers.entries() as any) {
    console.log(pair[0] + ": " + pair[1]);
  } */

  const blob = await response.blob();

  const disposition = response?.headers?.get("content-disposition");
  const extractedFileName = disposition ? getFileName(disposition) : null;
  const fileName =
    extractedFileName ?? `${new Date().toISOString()}.${extension}`;

  // Download the file
  saveAs(blob, fileName);
}

export async function blobToBase64Async(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = (e) => reject(fileReader.error);
    fileReader.onloadend = (e) => {
      const dataUrl = fileReader.result as string;
      // remove "data:mime/type;base64," prefix from data url
      const base64 = dataUrl.substring(dataUrl.indexOf(",") + 1);
      resolve(base64);
    };
    fileReader.readAsDataURL(blob);
  });
}
