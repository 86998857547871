/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignSpecialPlaceToContractorCommand
 */
export interface AssignSpecialPlaceToContractorCommand {
    /**
     * 
     * @type {string}
     * @memberof AssignSpecialPlaceToContractorCommand
     */
    specialPlaceId: string;
    /**
     * 
     * @type {string}
     * @memberof AssignSpecialPlaceToContractorCommand
     */
    contractorId: string;
}

export function AssignSpecialPlaceToContractorCommandFromJSON(json: any): AssignSpecialPlaceToContractorCommand {
    return AssignSpecialPlaceToContractorCommandFromJSONTyped(json, false);
}

export function AssignSpecialPlaceToContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignSpecialPlaceToContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialPlaceId': json['specialPlaceId'],
        'contractorId': json['contractorId'],
    };
}

export function AssignSpecialPlaceToContractorCommandToJSON(value?: AssignSpecialPlaceToContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specialPlaceId': value.specialPlaceId,
        'contractorId': value.contractorId,
    };
}


