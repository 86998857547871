/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkBookTaskItemReportListItemVm
 */
export interface WorkBookTaskItemReportListItemVm {
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    failureIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    city?: string | null;
    /**
     * 
     * @type {AddressVm}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    address?: AddressVm;
    /**
     * 
     * @type {Date}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    failureReportedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    taskName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    inventoryCategoryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    inventoryType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    inventoryName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkBookTaskItemReportListItemVm
     */
    inventoryCount?: number;
}

export function WorkBookTaskItemReportListItemVmFromJSON(json: any): WorkBookTaskItemReportListItemVm {
    return WorkBookTaskItemReportListItemVmFromJSONTyped(json, false);
}

export function WorkBookTaskItemReportListItemVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkBookTaskItemReportListItemVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureIdentifier': !exists(json, 'failureIdentifier') ? undefined : json['failureIdentifier'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : AddressVmFromJSON(json['address']),
        'failureReportedAt': !exists(json, 'failureReportedAt') ? undefined : (new Date(json['failureReportedAt'])),
        'taskName': !exists(json, 'taskName') ? undefined : json['taskName'],
        'inventoryCategoryName': !exists(json, 'inventoryCategoryName') ? undefined : json['inventoryCategoryName'],
        'inventoryType': !exists(json, 'inventoryType') ? undefined : json['inventoryType'],
        'inventoryName': !exists(json, 'inventoryName') ? undefined : json['inventoryName'],
        'inventoryCount': !exists(json, 'inventoryCount') ? undefined : json['inventoryCount'],
    };
}

export function WorkBookTaskItemReportListItemVmToJSON(value?: WorkBookTaskItemReportListItemVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureIdentifier': value.failureIdentifier,
        'city': value.city,
        'address': AddressVmToJSON(value.address),
        'failureReportedAt': value.failureReportedAt === undefined ? undefined : (value.failureReportedAt.toISOString()),
        'taskName': value.taskName,
        'inventoryCategoryName': value.inventoryCategoryName,
        'inventoryType': value.inventoryType,
        'inventoryName': value.inventoryName,
        'inventoryCount': value.inventoryCount,
    };
}


