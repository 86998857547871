import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatusDisplay } from "./StatusDisplay";
import styles from "./ActivenessDisplay.module.scss";

interface ActivenessDisplayProps {
  isActive?: boolean;
  text?: string;
}

export const ActivenessDisplay: FC<ActivenessDisplayProps> = ({
  isActive,
  text,
}) => {
  const { t } = useTranslation();
  const finalText = text ?? t(isActive ? "common.active" : "common.inactive");
  return (
    <StatusDisplay
      text={finalText}
      badgeClassName={isActive ? styles.statusActive : styles.statusInactive}
    />
  );
};
