import { createModel } from "@rematch/core";
import { api } from "api";
import { FailureTaskDto } from "api/generated/lumen";
import { RootModel } from "app/store";

interface FailureTaskCreateState {
  editingItemId?: string;
}

const initialState: FailureTaskCreateState = {
  editingItemId: undefined,
};

export const failureTaskCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    resetEditingItemData(state) {
      state.editingItemId = undefined;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      async create(values: FailureTaskDto): Promise<string> {
        return api.lumen.failureTask.apiFailureTasksFailureTaskPost({
          failureTaskDto: values,
        });
      },

      async edit({
        values,
        id,
      }: {
        values: FailureTaskDto;
        id: string;
      }): Promise<void> {
        return api.lumen.failureTask.apiFailureTasksFailureTaskIdPut({
          id,
          failureTaskDto: values,
        });
      },
    };
  },
});
