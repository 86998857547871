import { Descriptions, ModalProps, Select } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import StringDisplay from "components/ui/StringDisplay";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { AssignContractorToSpecialPlaceFormValues } from "../models";

const AssignContractorToSpecialPlaceModal: React.FC<ModalProps> = ({
  onCancel,
  onOk,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { assignContractorToSpecialPlace, fetchSpecialPlaces } = useSelector(
    (state) => state.loading.effects.assignContractor
  );
  const { contractor, specialPlaces } = useSelector(
    (state) => state.assignContractor
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const specialPlaceOptions = useMemo(
    () =>
      specialPlaces.map((specialPlace) => ({
        label: specialPlace.name || "",
        value: specialPlace.id || "",
      })),
    [specialPlaces]
  );

  const handleCancel = useCallback(() => {
    onCancel?.("" as any);

    if (!visible) {
      setTimeout(() => {
        formUtils.form.resetFields();

        dispatch.assignContractor.setContractor(undefined);
        dispatch.assignContractor.setSpecialPlaces([]);
      }, 300); // modal closing animation's length
    }
  }, [dispatch.assignContractor, formUtils.form, onCancel, visible]);

  const handleFinish = useCallback(
    async (values: AssignContractorToSpecialPlaceFormValues): Promise<void> => {
      if (typeof contractor?.id !== "string") {
        return;
      }

      await dispatch.assignContractor.assignContractorToSpecialPlace({
        contractorId: contractor?.id,
        specialPlaceId: values.specialPlaceId,
      });

      onOk?.("" as any);
      formUtils.form.resetFields();
    },
    [contractor?.id, dispatch.assignContractor, onOk, formUtils.form]
  );

  useEffect(() => {
    if (visible) {
      dispatch.assignContractor.fetchSpecialPlaces();
    }
  }, [dispatch.assignContractor, visible]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={handleCancel}
      title={t("contractor.assignSpecialPlaceModal.title")}
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: assignContractorToSpecialPlace.loading,
      }}
      cancelButtonProps={{
        disabled: assignContractorToSpecialPlace.loading,
      }}
      okText={t("contractor.assignSpecialPlaceModal.okText")}
      formInstance={formUtils.form}
    >
      <p>{t("contractor.assignSpecialPlaceModal.description")}</p>

      <VerticalDataDescriptions column={1} variant="formItem">
        <Descriptions.Item
          label={t("contractor.assignSpecialPlaceModal.selectedContractor")}
        >
          <StringDisplay value={contractor?.name} />
        </Descriptions.Item>
      </VerticalDataDescriptions>

      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item
          name="specialPlaceId"
          label={t("contractor.assignSpecialPlaceModal.specialPlace")}
          rules={[requiredField(t)]}
        >
          <Select
            size="large"
            options={specialPlaceOptions}
            loading={fetchSpecialPlaces.loading}
            showSearch
            optionFilterProp="label"
            filterOption={filterOptionsLabelFromBeginning}
          />
        </GrapeAntdForm.Item>
      </CustomForm>
    </CustomModal>
  );
};

export default AssignContractorToSpecialPlaceModal;
