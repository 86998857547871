import { useDispatch } from "app/store";
import DocumentTitle from "components/DocumentTitle";
import useAuth from "features/auth/useAuth";
import { FC, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export type PublicRouteProps = {
  onlyGuest?: boolean;
  title?: string;
  children?: ReactNode;
};

export const PublicRoute: FC<PublicRouteProps> = ({
  children,
  onlyGuest,
  title,
}) => {
  const { userIsLoggedIn } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const userCameFrom = (location.state as any)?.from
    ? (location.state as any).from.pathname +
      (location.state as any).from.search +
      (location.state as any).from.hash
    : "/";

  const redirectTo = userCameFrom === "/logout" ? "/" : userCameFrom;

  useEffect(() => {
    dispatch.common.setCurrentPageTitle(title);
  }, [dispatch.common, title]);

  // restricted meaning it can only be accessed, if the user is not logged in
  return userIsLoggedIn && onlyGuest ? (
    <Navigate to={redirectTo} replace />
  ) : (
    <>
      <DocumentTitle title={title} />
      {children}
    </>
  );
};
