/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    district?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    door?: string | null;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'city': json['city'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'street': json['street'],
        'number': json['number'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'door': !exists(json, 'door') ? undefined : json['door'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'county': value.county,
        'zip': value.zip,
        'city': value.city,
        'district': value.district,
        'street': value.street,
        'number': value.number,
        'floor': value.floor,
        'door': value.door,
    };
}


