/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigAllValidationRulesVm,
    ConfigAllValidationRulesVmFromJSON,
    ConfigAllValidationRulesVmFromJSONTyped,
    ConfigAllValidationRulesVmToJSON,
    ConfigFailureRulesVm,
    ConfigFailureRulesVmFromJSON,
    ConfigFailureRulesVmFromJSONTyped,
    ConfigFailureRulesVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigSettingRulesVm
 */
export interface ConfigSettingRulesVm {
    /**
     * 
     * @type {ConfigFailureRulesVm}
     * @memberof ConfigSettingRulesVm
     */
    failure?: ConfigFailureRulesVm;
    /**
     * 
     * @type {ConfigAllValidationRulesVm}
     * @memberof ConfigSettingRulesVm
     */
    validation?: ConfigAllValidationRulesVm;
}

export function ConfigSettingRulesVmFromJSON(json: any): ConfigSettingRulesVm {
    return ConfigSettingRulesVmFromJSONTyped(json, false);
}

export function ConfigSettingRulesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigSettingRulesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failure': !exists(json, 'failure') ? undefined : ConfigFailureRulesVmFromJSON(json['failure']),
        'validation': !exists(json, 'validation') ? undefined : ConfigAllValidationRulesVmFromJSON(json['validation']),
    };
}

export function ConfigSettingRulesVmToJSON(value?: ConfigSettingRulesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failure': ConfigFailureRulesVmToJSON(value.failure),
        'validation': ConfigAllValidationRulesVmToJSON(value.validation),
    };
}


