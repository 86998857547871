import { Select, SelectProps } from "antd";
import useDevicesQuery from "hooks/queries/useDevicesQuery";
import { ItemProps } from "models/common";
import { forwardRef, Ref, useMemo } from "react";
import { filterOptionsLabelFromBeginning } from "utils";

type DeviceSelectProps = Omit<
  SelectProps,
  "options" | "loading" | "filterOption"
>;

const DeviceSelect = forwardRef((props: DeviceSelectProps, ref?: Ref<any>) => {
  const query = useDevicesQuery();

  const options = useMemo<ItemProps[]>(
    () =>
      (query.data?.items || []).map((item) => ({
        label: item.name || "",
        value: item.id || "",
      })),
    [query.data?.items]
  );

  return (
    <Select
      ref={ref}
      size="large"
      showSearch
      optionFilterProp="label"
      options={options}
      loading={query.isLoading}
      filterOption={filterOptionsLabelFromBeginning}
      {...props}
    />
  );
});

export default DeviceSelect;
