import { Col, Input, Row, Switch } from "antd";
import { useWatch } from "antd/lib/form/Form";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import StreetInput from "components/form/StreetInput";
import TownInput from "components/form/TownInput";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useConfigurationValidationRules from "hooks/useConfigurationValidationRules";
import { ExtendedSelectValue, FormMode } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { extendedSelectValueRequired } from "utils/form";
import {
  GrapeAntdForm,
  email,
  min,
  phoneNumber,
  requiredField,
} from "widgets/form";
import styles from "./CompanyFieldset.module.scss";

interface CompanyFieldsetProps {
  formMode: FormMode;
}

const CompanyFieldset: React.FC<CompanyFieldsetProps> = ({ formMode }) => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const postalAddressCityFormValue = useWatch<ExtendedSelectValue | undefined>(
    ["postalAddress", "city"],
    form
  );
  const headquarterAddressCityFormValue = useWatch<
    ExtendedSelectValue | undefined
  >(["headquarterAddress", "city"], form);
  const taxNumberPatternRules =
    useConfigurationValidationRules("taxNumberPattern");
  const zipPatternRules = useConfigurationValidationRules("zipPattern");

  return (
    <div>
      <p className={styles.introText}>
        {formMode === "Create"
          ? t("contractor.companyFieldsetCreateIntro")
          : t("contractor.companyFieldsetEditIntro")}
      </p>
      <fieldset>
        <legend>{t("contractor.companyFieldset")}</legend>

        <FieldsetFieldsWrapper>
          <Row justify="center" gutter={[24, 24]}>
            <Col span={formMode === "Edit" ? 20 : 24}>
              <GrapeAntdForm.Item
                name="name"
                label={t("contractor.name")}
                rules={[requiredField(t)]}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            {formMode === "Edit" && (
              <Col span={4}>
                <GrapeAntdForm.Item
                  name="isActive"
                  valuePropName="checked"
                  label={t("contractor.status")}
                >
                  <Switch
                    checkedChildren={t("common.active")}
                    unCheckedChildren={t("common.inactive")}
                    className={styles.isActiveSwitch}
                  />
                </GrapeAntdForm.Item>
              </Col>
            )}
            <Col span={24}>
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <div className={styles.sectionTitle}>
                    {t("contractor.addressSection")}
                  </div>
                </Col>
                <Col span={4}>
                  <GrapeAntdForm.Item
                    name={["headquarterAddress", "zip"]}
                    label={t("contractor.address.zip")}
                    rules={zipPatternRules}
                  >
                    <Input size="large" />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={8}>
                  <GrapeAntdForm.Item
                    name={["headquarterAddress", "city"]}
                    label={t("contractor.address.city")}
                    rules={[extendedSelectValueRequired(t)]}
                  >
                    <TownInput size="large" />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={8}>
                  <GrapeAntdForm.Item
                    name={["headquarterAddress", "street"]}
                    label={t("contractor.address.street")}
                    rules={[requiredField(t)]}
                  >
                    <StreetInput
                      size="large"
                      townId={headquarterAddressCityFormValue?.value}
                    />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={4}>
                  <GrapeAntdForm.Item
                    name={["headquarterAddress", "number"]}
                    label={t("contractor.address.houseNumber")}
                    rules={[requiredField(t)]}
                  >
                    <Input size="large" />
                  </GrapeAntdForm.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <div className={styles.sectionTitle}>
                    {t("contractor.postalAddressSection")}
                  </div>
                </Col>
                <Col span={4}>
                  <GrapeAntdForm.Item
                    name={["postalAddress", "zip"]}
                    label={t("contractor.address.zip")}
                    rules={zipPatternRules}
                  >
                    <Input size="large" />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={8}>
                  <GrapeAntdForm.Item
                    name={["postalAddress", "city"]}
                    label={t("contractor.address.city")}
                    rules={[extendedSelectValueRequired(t)]}
                  >
                    <TownInput size="large" />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={8}>
                  <GrapeAntdForm.Item
                    name={["postalAddress", "street"]}
                    label={t("contractor.address.street")}
                    rules={[requiredField(t)]}
                  >
                    <StreetInput
                      size="large"
                      townId={postalAddressCityFormValue?.value}
                    />
                  </GrapeAntdForm.Item>
                </Col>
                <Col span={4}>
                  <GrapeAntdForm.Item
                    name={["postalAddress", "number"]}
                    label={t("contractor.address.houseNumber")}
                    rules={[requiredField(t)]}
                  >
                    <Input size="large" />
                  </GrapeAntdForm.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name="taxNumber"
                label={t("contractor.taxnumber")}
                rules={taxNumberPatternRules}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name="companyRegisterNumber"
                label={t("contractor.companyregisterNumber")}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name="email"
                label={t("contractor.email")}
                rules={[requiredField(t), email(t)]}
              >
                <Input size="large" type="email" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name={["phone", "number"]}
                label={t("contractor.phone")}
                rules={[requiredField(t), phoneNumber(t), min(t, 6, "string")]}
              >
                <Input size="large" type="phone" />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </FieldsetFieldsWrapper>
      </fieldset>
    </div>
  );
};

export default CompanyFieldset;
