import { notification } from "antd";
import i18n from "app/i18n";

export const globalErrorDisplay = (
  errorCode: number,
  errorKey: string,
  isFieldError: boolean
) => {
  notification.error({
    message: i18n.t(`error.common.errorsTitle`) as string,
    description: i18n.t(
      `error.${isFieldError ? "beField" : "beGlobal"}.${errorKey}`
    ) as string,
    duration: 10,
  });
};
