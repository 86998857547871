/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FilterLogicalOperator {
    And = 'And',
    Or = 'Or'
}

export function FilterLogicalOperatorFromJSON(json: any): FilterLogicalOperator {
    return FilterLogicalOperatorFromJSONTyped(json, false);
}

export function FilterLogicalOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterLogicalOperator {
    return json as FilterLogicalOperator;
}

export function FilterLogicalOperatorToJSON(value?: FilterLogicalOperator | null): any {
    return value as any;
}

