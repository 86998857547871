import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import useLanguageConfigurator from "./hooks/useLanguageConfigurator";
import { RouterView } from "components/router/RouterView";
import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import { useDispatch, useSelector } from "app/store";
import FullPageSpin from "components/FullPageSpin";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";
import FailureCreateModal from "features/failures/create/FailureCreateModal";

function App() {
  const { antDLocale } = useLanguageConfigurator();
  const { userInfo } = useAuth();
  const dispatch = useDispatch();
  const configurationQuery = useConfigurationQuery();
  const { isVisible: isFailureCreateModalVisible } = useSelector(
    (state) => state.failureCreate
  );

  useEffect(() => {
    if ((userInfo?.role?.name as Role) === "Repairman") {
      dispatch.common.setDeviceLayoutType("mobile");
    } else {
      dispatch.common.setDeviceLayoutType("default");
    }
  }, [dispatch.common, userInfo]);

  return configurationQuery.isSuccess ? (
    <BrowserRouter>
      <ConfigProvider locale={antDLocale}>
        <RouterView />

        {userInfo && (
          <FailureCreateModal
            visible={isFailureCreateModalVisible}
            onCancel={() => dispatch.failureCreate.setIsVisible(false)}
          />
        )}
      </ConfigProvider>
    </BrowserRouter>
  ) : (
    <FullPageSpin />
  );
}

export default App;
