import { createModel } from "@rematch/core";
import { RootModel } from "app/store";
import {
  ListRequestParams,
  mergeListParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { api } from "api";
import { mapToGetAllQuery } from "utils";
import { SpecialPlaceVm } from "api/generated/lumen";

interface SpecialPlacesListState {
  listParams: ListRequestParams;
  list: SpecialPlaceVm[];
}

const initialState: SpecialPlacesListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "name",
        orderByType: OrderByType.Ascending,
      },
    ],
  },
  list: [],
};

export const specialPlacesList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: SpecialPlaceVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { specialPlacesList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const mergedParams = mergeListParams(
          state.specialPlacesList.listParams,
          params
        );

        // API only returns non deleted special places by default
        const data = await api.lumen.specialPlaces.apiSpecialPlacesGetAllPost({
          getAllSpecialPlacesQuery: mapToGetAllQuery(mergedParams),
        });

        const { items, ...pagination } = data;

        dispatch(
          specialPlacesList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async delete(id: string): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesIdDelete({ id });
      },
    };
  },
});
