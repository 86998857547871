import { createModel } from "@rematch/core";
import { api } from "api";
import { ContractorVm, OrderByType, SpecialPlaceVm } from "api/generated/lumen";
import { RootModel } from "app/store";

interface AssignMaintainedDevicesState {
  specialPlaces: SpecialPlaceVm[];
  contractors: ContractorVm[];
}

const initialState: AssignMaintainedDevicesState = {
  specialPlaces: [],
  contractors: [],
};

export const assignMaintainedDevices = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSpecialPlaces(state, data: SpecialPlaceVm[]) {
      state.specialPlaces = data;
    },
    setContractors(state, data: ContractorVm[]) {
      state.contractors = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { assignMaintainedDevices } = dispatch;

    return {
      async fetchSpecialPlaces(): Promise<void> {
        // API only returns non deleted special places by default
        const data = await api.lumen.specialPlaces.apiSpecialPlacesGetAllPost({
          getAllSpecialPlacesQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
          },
        });

        const { items } = data;

        dispatch(assignMaintainedDevices.setSpecialPlaces(items || []));
      },

      async assignMaintainedDevicesToSpecialPlace({
        specialPlaceId,
        deviceIds,
      }: {
        specialPlaceId: string;
        deviceIds: string[];
      }): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesAssignSpecialPlaceToDevicesPut(
          { assignSpecialPlaceToDevicesCommand: { deviceIds, specialPlaceId } }
        );
      },

      async fetchContractors(): Promise<void> {
        // API only returns non deleted special places by default
        const data = await api.lumen.contractors.apiContractorsGetAllPost({
          getAllContractorsQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
          },
        });

        const { items } = data;

        dispatch(assignMaintainedDevices.setContractors(items || []));
      },

      async assignMaintainedDevicesToContractor({
        contractorId,
        deviceIds,
      }: {
        contractorId: string;
        deviceIds: string[];
      }): Promise<void> {
        return api.lumen.devices.apiDevicesAssignDevicesToContractorPost({
          assignDevicesToContractorCommand: {
            contractorId,
            deviceIds,
          },
        });
      },
    };
  },
});
