/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RepairmanDto,
    RepairmanDtoFromJSON,
    RepairmanDtoFromJSONTyped,
    RepairmanDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateRepairmanCommand
 */
export interface CreateRepairmanCommand {
    /**
     * 
     * @type {RepairmanDto}
     * @memberof CreateRepairmanCommand
     */
    repairman?: RepairmanDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRepairmanCommand
     */
    inviteRepairman?: boolean;
}

export function CreateRepairmanCommandFromJSON(json: any): CreateRepairmanCommand {
    return CreateRepairmanCommandFromJSONTyped(json, false);
}

export function CreateRepairmanCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRepairmanCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairman': !exists(json, 'repairman') ? undefined : RepairmanDtoFromJSON(json['repairman']),
        'inviteRepairman': !exists(json, 'inviteRepairman') ? undefined : json['inviteRepairman'],
    };
}

export function CreateRepairmanCommandToJSON(value?: CreateRepairmanCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairman': RepairmanDtoToJSON(value.repairman),
        'inviteRepairman': value.inviteRepairman,
    };
}


