import { mdiDelete, mdiEye } from "@mdi/js";
import { Modal, ModalFuncProps, Space, Tag } from "antd";
import { DeadlineStatusType, RepairmanListItemVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import ListPageLayout from "components/layout/ListPageLayout";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import BooleanDisplay from "components/ui/BooleanDisplay";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  DataType,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";
import RepairmenCreateModal from "../create/RepairmenCreateModal";
import styles from "./RepairmenList.module.scss";
import useRepairmanListColumnTitles from "./useRepairmenListColumnTitles";

const { confirm } = Modal;

interface RepairmenListProps {
  contractorId?: string;
}

const RepairmenList: React.FC<RepairmenListProps> = ({ contractorId }) => {
  const { listParams, list } = useSelector((state) => state.repairmenList);
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.repairmenList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { columnTitles, columnTitlesForExcel } =
    useRepairmanListColumnTitles(contractorId);

  const columnParams = useMemo<ColumnConfigParams<RepairmanListItemVm>[]>(
    () =>
      [
        {
          key: "familyname",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "forename",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "email",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "phone.number",
          dataIndex: ["phone", "number"],
          sort: false,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        ...(contractorId
          ? []
          : [
              {
                key: "contractor.name",
                dataIndex: ["contractor", "name"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
              },
            ]),
        {
          key: "isActive",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.ACTIVE_INACTIVE,
          dataType: DataType.ACTIVE_INACTIVE,
          render: (value: boolean) => {
            return (
              <BooleanDisplay
                value={value}
                trueDisplay={<Tag color="success">{t("common.active")}</Tag>}
                falseDisplay={<Tag color="error">{t("common.inactive")}</Tag>}
              />
            );
          },
        },
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [columnTitles, t, contractorId]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/repairmen/${id}`);
    },
    [navigate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.repairmenList.deleteItem(id);

          const params = {
            ...(listParams ?? {}),
            filter: {
              ...(listParams?.filter ?? {}),
              ...(contractorId ? { "contractor.id": contractorId } : {}),
            },
          };
          return dispatch.repairmenList.fetchList(params);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.repairmenList, t, listParams, contractorId]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleExportButtonClick = useCallback(() => {
    dispatch.repairmenList.generateExcel(columnTitlesForExcel);
  }, [columnTitlesForExcel, dispatch.repairmenList]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<RepairmanListItemVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) => {
      const params = {
        ...(requestParams ?? {}),
        filter: {
          ...(requestParams?.filter ?? {}),
          ...(contractorId ? { "contractor.id": contractorId } : {}),
        },
      };
      return dispatch.repairmenList.fetchList(params);
    },
    [dispatch.repairmenList, contractorId]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: contractorId
      ? ColumnStorageName.REPAIRMEN_BY_CONTRACTOR
      : ColumnStorageName.REPAIRMEN,
    headerOptions: (
      <TableHeaderOptions
        onNewButtonClick={handleNewButtonClick}
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.repairmenList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.repairmenList.reset,
  });

  const getRowClassName = useCallback(
    (record: RepairmanListItemVm, index: number) => {
      switch (record.deadlineStatus) {
        case DeadlineStatusType.Expired:
          return styles.expiredDeadlineRow;

        case DeadlineStatusType.NearDeadline:
          return styles.nearDeadlineRow;

        default:
          return "";
      }
    },
    []
  );

  const content = useMemo(
    () => (
      <>
        <CustomTable<RepairmanListItemVm>
          {...customTableProps}
          scrollX={1000}
          selectable={false}
          rowClassName={getRowClassName}
        />

        <RepairmenCreateModal
          contractorId={contractorId}
          visible={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
        />
      </>
    ),
    [contractorId, customTableProps, getRowClassName, createModalOpen]
  );

  return contractorId ? (
    content
  ) : (
    <ListPageLayout title={t("repairmen.list.title")}>{content}</ListPageLayout>
  );
};

export default RepairmenList;
