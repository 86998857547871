import { Input, ModalProps } from "antd";
import { SpecialPlaceVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import useEditFormModal from "hooks/useEditModal";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { SpecialPlacesCreateFormValues } from "../models";
import { ListRequestParams } from "widgets/table/useTableUtils";

const { TextArea } = Input;

export interface SpecialPlacesCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

const SpecialPlacesCreateModal: React.FC<SpecialPlacesCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<SpecialPlacesCreateFormValues>();
  const dispatch = useDispatch();
  const { editingItemId } = useSelector((state) => state.specialPlacesCreate);
  const { specialPlace } = useSelector((state) => state.specialPlacesView);
  const { create, edit } = useSelector(
    (state) => state.loading.effects.specialPlacesCreate
  );

  const getInitialValues = useCallback(
    (itemData: SpecialPlaceVm): SpecialPlacesCreateFormValues => {
      return {
        ...itemData,
        name: itemData.name || "",
        description: itemData.description || undefined,
      };
    },
    []
  );

  const { formMode, setFormMode } = useEditFormModal<
    SpecialPlaceVm,
    SpecialPlacesCreateFormValues
  >({
    item: specialPlace,
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.specialPlacesCreate.setEditingItemId,
    itemId: editingItemId,
  });

  const handleFinish = useCallback(
    async (values: SpecialPlacesCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.specialPlacesCreate.create(values);

        dispatch.specialPlacesList.fetchList(fetchDataParams);
      }

      if (formMode === "Edit" && typeof values.id === "string") {
        await dispatch.specialPlacesCreate.edit({ id: values.id, ...values });

        dispatch.specialPlacesView.fetchSpecialPlace(values.id);
      }

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.specialPlacesCreate,
      dispatch.specialPlacesList,
      dispatch.specialPlacesView,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  useEffect(() => {
    if (specialPlace && visible) {
      setFormMode("Edit");
    } else {
      setFormMode("Create");
    }
  }, [specialPlace, setFormMode, visible]);

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("specialPlace.createModal.title")
          : t("specialPlace.editModal.title")
      }
      size="large"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: create.loading || edit.loading }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
      visible={visible}
    >
      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="name"
          label={t("specialPlace.name")}
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="description"
          label={t("specialPlace.description")}
        >
          <TextArea size="large" maxLength={200} rows={6} />
        </GrapeAntdForm.Item>
      </CustomForm>
    </CustomModal>
  );
};

export default SpecialPlacesCreateModal;
