import { mdiLink } from "@mdi/js";
import { Button, Row } from "antd";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./FloatingAttachBar.module.scss";

interface FloatingAttachBarProps {
  selectedCount: number;
  onCancel: () => void;
  onAttach: () => void;
  attachButtonIsLoading: boolean | undefined;
}

const FloatingAttachBar: React.FC<FloatingAttachBarProps> = ({
  selectedCount,
  onAttach,
  onCancel,
  attachButtonIsLoading,
}) => {
  const { t } = useTranslation();

  return (
    <article className={styles.wrapper}>
      <header className={styles.header}>
        <Row justify="space-between" wrap={false} align="middle">
          <Button
            className={styles.button}
            onClick={() => onCancel()}
            disabled={attachButtonIsLoading}
          >
            {t("failureDetails.floatingAttachBar.cancel")}
          </Button>
          <div className={styles.headerText}>
            <MaterialIcon path={mdiLink} className={styles.icon} />

            {t("failureDetails.floatingAttachBar.attachFailures")}
          </div>
          <Button
            className={clsx(styles.button, styles.attachButton)}
            onClick={() => onAttach()}
            disabled={selectedCount === 0}
            loading={attachButtonIsLoading}
          >
            {t("failureDetails.floatingAttachBar.attach")}
          </Button>
        </Row>
      </header>
      <main className={styles.main}>
        {selectedCount > 0 ? (
          <div className={styles.selectedCount}>{selectedCount}</div>
        ) : (
          <div className={styles.mainHelpText}>
            {t("failureDetails.floatingAttachBar.helpText")}
          </div>
        )}
      </main>
    </article>
  );
};

export default FloatingAttachBar;
