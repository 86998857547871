import { mdiLink } from "@mdi/js";
import { Descriptions, Row } from "antd";
import { FailureListItemVm } from "api/generated/lumen";
import { MaterialIcon } from "components/MaterialIcon";
import AddressDisplay from "components/ui/AddressDisplay";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import React from "react";
import { useTranslation } from "react-i18next";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import styles from "./FailureCard.module.scss";

interface FailureCardProps {
  data: FailureListItemVm;
}

const FailureCard: React.FC<FailureCardProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Row justify="space-between" align="middle">
          <h1>
            {!!data.attachedFailuresCount && data.attachedFailuresCount > 0 && (
              <MaterialIcon
                path={mdiLink}
                className={styles.attachedFailuresIcon}
              />
            )}
            {data.identifier}

            {!!data.attachedFailuresCount && data.attachedFailuresCount > 0 && (
              <sup className={styles.attachedFailuresSup}>
                ({data.attachedFailuresCount})
              </sup>
            )}
          </h1>
          <Descriptions className={styles.topRightDescriptions}>
            <Descriptions.Item label={t("failureDetails.deadline")}>
              <DateDisplay value={data.deadline} />
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Descriptions
          className={styles.descriptions}
          column={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
        >
          <Descriptions.Item
            label={t("failureDetails.reportingDate")}
            className={styles.reportedAtItem}
          >
            <DateDisplay value={data.reportedAt} format={DateFormat.DateTime} />
          </Descriptions.Item>
          <Descriptions.Item label={t("failureDetails.address")}>
            <AddressDisplay value={data.address} />
          </Descriptions.Item>
          <Descriptions.Item
            label={t("failureDetails.recommendedFixingDays")}
            className={styles.recommendedFixingDaysItem}
          >
            {data.optimalDeadlineDays && <>~ </>}

            <DictionaryDisplay dictionary={data.optimalDeadlineDays} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default FailureCard;
