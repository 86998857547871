export default class Claims {
  static Root = {
    SUPER_ADMIN: "Root_SUPER_ADMIN",
  };

  /// <summary>
  /// FailureManagement permission item rights
  /// </summary>

  static FailureManagement = {
    VIEW: "FailureManagement_VIEW",
    CREATE: "FailureManagement_CREATE",
    EDIT: "FailureManagement_EDIT",
    DELETE: "FailureManagement_DELETE",
    EXPORT: "FailureManagement_EXPORT",
  };

  static Failures = {
    VIEW: "Failures_VIEW",
    CONTRACTORVIEW: "Failures_CONTRACTORVIEW",
    CREATE: "Failures_CREATE",
    EDIT: "Failures_EDIT",
    DELETE: "Failures_DELETE",
    EXPORT: "Failures_EXPORT",
    NOTIFIERVIEW: "Failures_NOTIFIERVIEW",
  };

  static FailureTypes = {
    VIEW: "FailureTypes_VIEW",
    CREATE: "FailureTypes_CREATE",
    EDIT: "FailureTypes_EDIT",
    DELETE: "FailureTypes_DELETE",
    EXPORT: "FailureTypes_EXPORT",
  };

  static ExamTypes = {
    VIEW: "ExamTypes_VIEW",
    CREATE: "ExamTypes_CREATE",
    EDIT: "ExamTypes_EDIT",
    DELETE: "ExamTypes_DELETE",
  };

  static Exams = {
    VIEW: "Exams_VIEW",
    CREATE: "Exams_CREATE",
    EDIT: "Exams_EDIT",
    DELETE: "Exams_DELETE",
  };

  static Towns = {
    VIEW: "Towns_VIEW",
    CREATE: "Towns_CREATE",
    EDIT: "Towns_EDIT",
    DELETE: "Towns_DELETE",
    EXPORT: "Towns_EXPORT",
  };

  static SpecialPlaces = {
    VIEW: "SpecialPlaces_VIEW",
    CREATE: "SpecialPlaces_CREATE",
    EDIT: "SpecialPlaces_EDIT",
    DELETE: "SpecialPlaces_DELETE",
    EXPORT: "SpecialPlaces_EXPORT",
  };

  static Inventory = {
    VIEW: "Inventory_VIEW",
    CREATE: "Inventory_CREATE",
    EDIT: "Inventory_EDIT",
    DELETE: "Inventory_DELETE",
    EXPORT: "Inventory_EXPORT",
  };

  static Contractors = {
    VIEW: "Contractors_VIEW",
    CREATE: "Contractors_CREATE",
    EDIT: "Contractors_EDIT",
    DELETE: "Contractors_DELETE",
    EXPORT: "Contractors_EXPORT",
  };

  static Devices = {
    VIEW: "Devices_VIEW",
  };

  static ContactPersons = {
    VIEW: "ContactPersons_VIEW",
    CREATE: "ContactPersons_CREATE",
    EDIT: "ContactPersons_EDIT",
    DELETE: "ContactPersons_DELETE",
    EXPORT: "ContactPersons_EXPORT",
  };

  static Workbook = {
    VIEW: "Workbook_VIEW",
    CREATE: "Workbook_CREATE",
    EDIT: "Workbook_EDIT",
    DELETE: "Workbook_DELETE",
    EXPORT: "Workbook_EXPORT",
  };

  static Repairmen = {
    VIEW: "Repairmen_VIEW",
    CREATE: "Repairmen_CREATE",
    EDIT: "Repairmen_EDIT",
    DELETE: "Repairmen_DELETE",
    EXPORT: "Repairmen_EXPORT",
  };

  static Documents = {
    VIEW: "Documents_VIEW",
    CREATE: "Documents_CREATE",
    EDIT: "Documents_EDIT",
    DELETE: "Documents_DELETE",
    EXPORT: "Documents_EXPORT",
  };

  static Images = {
    VIEW: "Images_VIEW",
    CREATE: "Images_CREATE",
    EDIT: "Images_EDIT",
    DELETE: "Images_DELETE",
    EXPORT: "Images_EXPORT",
  };

  static InventoryCategory = {
    VIEW: "InventoryCategory_VIEW",
    CREATE: "InventoryCategory_CREATE",
    EDIT: "InventoryCategory_EDIT",
  };

  static FailureTasks = {
    VIEW: "FailureTasks_VIEW",
    CREATE: "FailureTasks_CREATE",
    EDIT: "FailureTasks_EDIT",
  };

  static Roles = {
    EDIT: "Roles_EDIT",
  };

  static AuditLog = {
    DELETE: "AuditLog_DELETE",
  };
}
