import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateContractorCommand,
  UpdateContractorCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface ContractorCreateState {
  editingItemId?: string;
}

const initialState: ContractorCreateState = {
  editingItemId: undefined,
};

export const contractorCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      async create(values: CreateContractorCommand): Promise<string> {
        return api.lumen.contractors.apiContractorsPost({
          createContractorCommand: values,
        });
      },

      async edit(values: UpdateContractorCommand): Promise<void> {
        return api.lumen.contractors.apiContractorsIdPut({
          id: values.id,
          updateContractorCommand: values,
        });
      },
    };
  },
});
