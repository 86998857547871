import { createModel } from "@rematch/core";
import { api } from "api";
import {
  FailureTypeVm,
  OrderByType,
  ReportFailureCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface FailureReportState {
  failureTypes: FailureTypeVm[];
}

const initialState: FailureReportState = {
  failureTypes: [],
};

export const failureReport = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFailureTypes(state, data: FailureTypeVm[]) {
      state.failureTypes = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureReport } = dispatch;

    return {
      async fetchPublicFailureTypes(): Promise<void> {
        const data =
          await api.lumen.unauthenticated.failureTypes.apiFailureTypesGetAllPublicPost(
            {
              getAllFailureTypesPubliclyQuery: {
                pageSize: -1,
                sortings: [
                  {
                    orderByType: OrderByType.Ascend,
                    orderBy: "name",
                  },
                ],
              },
            }
          );

        const { items } = data;

        dispatch(failureReport.setFailureTypes(items || []));
      },

      async report({
        values,
        recaptchaToken,
      }: {
        values: ReportFailureCommand;
        recaptchaToken: string;
      }): Promise<string> {
        return api.lumen.unauthenticated.failures.apiFailuresReportPost({
          reportFailureCommand: values,
          captcha: recaptchaToken,
        });
      },
    };
  },
});
