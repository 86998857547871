import { RuleObject } from "antd/lib/form";
import { TFunction } from "react-i18next";

export const requiredField = (
  t: TFunction,
  field?: string,
  rest?: RuleObject,
  message?: string
): RuleObject => {
  const defaultMessage = field
    ? t("error.common.namedFieldRequired", { field })
    : t("error.common.fieldRequired");

  return {
    ...rest,
    required: true,
    whitespace: true,
    message: message || defaultMessage,
  };
};

export const email = (t: TFunction, message?: string): RuleObject => ({
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: message || t("error.common.emailInvalidFormat"),
});

export const phoneNumber = (t: TFunction, message?: string): RuleObject => ({
  pattern: /^[+]?[(]?[0-9]*[)]?[ ]?[-./]?[ ]?(?:[0-9][ |/|-]?)+[0-9]$/,
  message: message || t("error.common.phoneNumberInvalidFormat"),
});

export const max = (
  t: TFunction,
  max: number,
  type: "string" | "number",
  message?: string
): RuleObject => {
  const defaultMessage = {
    string: t("error.common.maxLength"),
    number: t("error.common.maxValue"),
  }[type];

  return {
    max,
    type,
    message: message || defaultMessage,
  };
};

export const min = (
  t: TFunction,
  min: number,
  type: "string" | "number",
  message?: string
): RuleObject => {
  const defaultMessage = {
    string: t("error.common.minLength"),
    number: t("error.common.minValue"),
  }[type];

  return {
    min,
    type,
    transform: (value: any) => `${value}`,
    message: message || defaultMessage,
  };
};

export const pattern = (
  t: TFunction,
  pattern: RegExp,
  message?: string
): RuleObject => {
  return {
    pattern,
    message: message || t("error.common.pattern"),
  };
};
