const storagePrefix = "failureHandlerApp";

export type localStorageKey = "loginData";

export const setLocalStorageItem = (key: localStorageKey, value: any) =>
  localStorage.setItem(`${storagePrefix}-${key}`, JSON.stringify(value));

export const getLocalStorageItem = (key: localStorageKey) => {
  const value = localStorage.getItem(`${storagePrefix}-${key}`);

  return (value && JSON.parse(value)) || undefined;
};

export const removeLocalStorageItem = (key: localStorageKey) =>
  localStorage.removeItem(`${storagePrefix}-${key}`);
