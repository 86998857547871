import { createModel } from "@rematch/core";
import { api } from "api";
import { InventoryVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { mapToGetAllQuery } from "utils";
import { downloadFile } from "utils/file";
import {
  ListRequestParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { getFilterParamsForInventoryGetRequests } from "../utils";

interface InventoryListState {
  listParams: ListRequestParams;
  list: InventoryVm[];
}

const initialState: InventoryListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "name",
        orderByType: OrderByType.Ascending,
      },
    ],
  },
  list: [],
};

export const inventoryList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: InventoryVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { inventoryList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const { payload, mergedParams } =
          getFilterParamsForInventoryGetRequests(
            params,
            state.inventoryList.listParams
          );

        const data = await api.lumen.inventory.apiInventoryGetAllPost({
          getAllInventoryItemsQuery: mapToGetAllQuery(payload),
        });

        const { items, ...pagination } = data;

        dispatch(
          inventoryList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async deleteItem(
        {
          id,
        }: {
          id?: string;
        },
        state
      ): Promise<void> {
        if (typeof id === "string") {
          return api.lumen.inventory.apiInventoryIdDelete({ id });
        }
      },

      async generateExcel(columnTitles: string[], state): Promise<void> {
        const { payload } = getFilterParamsForInventoryGetRequests(
          state.inventoryList.listParams,
          state.inventoryList.listParams
        );

        const data = await api.lumen.inventory.apiInventoryExportPostRaw({
          getAllInventoryItemsExcelQuery: {
            ...mapToGetAllQuery(payload),
            columnNames: columnTitles,
            pageSize: -1,
          },
        });

        downloadFile(data.raw);
      },
    };
  },
});
