import { FC, ReactNode } from "react";
import styles from "./CardContent.module.scss";
import { Card } from "antd";
import clsx from "clsx";

interface CardContentProps {
  children?: ReactNode;
  className?: string;
}

const CardContent: FC<CardContentProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Card className={clsx(styles.card, className)} {...rest}>
      {children}
    </Card>
  );
};

export default CardContent;
