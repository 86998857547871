import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateExamCommand,
  ExamVm,
  OrderByType,
  UpdateExamCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface RepairmenExamTabState {
  list: ExamVm[];
  editingItemId?: string;
}

const initialState: RepairmenExamTabState = {
  list: [],
  editingItemId: undefined,
};

export const repairmenExamTab = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setList(state, data: ExamVm[]) {
      state.list = data;
    },
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    resetEditingItemData(state) {
      state.editingItemId = undefined;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { repairmenExamTab } = dispatch;

    return {
      async fetchList(repairmanId: string): Promise<void> {
        const data = await api.lumen.exams.apiExamsGetAllPost({
          getAllExamsQuery: {
            repairmanId,
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "expirationDate",
              },
            ],
          },
        });

        dispatch(repairmenExamTab.setList(data.items || []));
      },

      async create(values: CreateExamCommand): Promise<string> {
        return api.lumen.exams.apiExamsPost({ createExamCommand: values });
      },

      async edit(values: UpdateExamCommand): Promise<void> {
        return api.lumen.exams.apiExamsIdPut({
          id: values.id,
          updateExamCommand: values,
        });
      },

      async delete(id: string): Promise<void> {
        return api.lumen.exams.apiExamsIdDelete({ id });
      },
    };
  },
});
