/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    ContactPerson,
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    Phone,
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
    Sla,
    SlaFromJSON,
    SlaFromJSONTyped,
    SlaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Town
 */
export interface Town {
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    municipalityName?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Town
     */
    municipalityAddress?: Address;
    /**
     * 
     * @type {Phone}
     * @memberof Town
     */
    phone?: Phone;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    sectionActionId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Town
     */
    isActivated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Town
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Town
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Dictionary}
     * @memberof Town
     */
    sectionAction?: Dictionary;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof Town
     */
    contactPersons?: Array<ContactPerson> | null;
    /**
     * 
     * @type {Array<Sla>}
     * @memberof Town
     */
    slas?: Array<Sla> | null;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Town
     */
    contractors?: Array<Contractor> | null;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    sectionFailureEmailTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Town
     */
    id?: string;
}

export function TownFromJSON(json: any): Town {
    return TownFromJSONTyped(json, false);
}

export function TownFromJSONTyped(json: any, ignoreDiscriminator: boolean): Town {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'municipalityName': !exists(json, 'municipalityName') ? undefined : json['municipalityName'],
        'municipalityAddress': !exists(json, 'municipalityAddress') ? undefined : AddressFromJSON(json['municipalityAddress']),
        'phone': !exists(json, 'phone') ? undefined : PhoneFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'sectionActionId': !exists(json, 'sectionActionId') ? undefined : json['sectionActionId'],
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'sectionAction': !exists(json, 'sectionAction') ? undefined : DictionaryFromJSON(json['sectionAction']),
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(ContactPersonFromJSON)),
        'slas': !exists(json, 'slas') ? undefined : (json['slas'] === null ? null : (json['slas'] as Array<any>).map(SlaFromJSON)),
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorFromJSON)),
        'sectionFailureEmailTo': !exists(json, 'sectionFailureEmailTo') ? undefined : json['sectionFailureEmailTo'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function TownToJSON(value?: Town | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'municipalityName': value.municipalityName,
        'municipalityAddress': AddressToJSON(value.municipalityAddress),
        'phone': PhoneToJSON(value.phone),
        'email': value.email,
        'sectionActionId': value.sectionActionId,
        'isActivated': value.isActivated,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'sectionAction': DictionaryToJSON(value.sectionAction),
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(ContactPersonToJSON)),
        'slas': value.slas === undefined ? undefined : (value.slas === null ? null : (value.slas as Array<any>).map(SlaToJSON)),
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorToJSON)),
        'sectionFailureEmailTo': value.sectionFailureEmailTo,
        'id': value.id,
    };
}


