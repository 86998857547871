/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllStreetsQuery,
    GetAllStreetsQueryFromJSON,
    GetAllStreetsQueryToJSON,
    GetAllTownsExcelQuery,
    GetAllTownsExcelQueryFromJSON,
    GetAllTownsExcelQueryToJSON,
    GetAllTownsPubliclyQuery,
    GetAllTownsPubliclyQueryFromJSON,
    GetAllTownsPubliclyQueryToJSON,
    GetAllTownsQuery,
    GetAllTownsQueryFromJSON,
    GetAllTownsQueryToJSON,
    GetAllTownsSlenderQuery,
    GetAllTownsSlenderQueryFromJSON,
    GetAllTownsSlenderQueryToJSON,
    PublicTownVmPaginatedSearchResponse,
    PublicTownVmPaginatedSearchResponseFromJSON,
    PublicTownVmPaginatedSearchResponseToJSON,
    SlenderTownVmPaginatedSearchResponse,
    SlenderTownVmPaginatedSearchResponseFromJSON,
    SlenderTownVmPaginatedSearchResponseToJSON,
    StreetVmPaginatedSearchResponse,
    StreetVmPaginatedSearchResponseFromJSON,
    StreetVmPaginatedSearchResponseToJSON,
    TownExcelVm,
    TownExcelVmFromJSON,
    TownExcelVmToJSON,
    TownVmPaginatedSearchResponse,
    TownVmPaginatedSearchResponseFromJSON,
    TownVmPaginatedSearchResponseToJSON,
    UpdateTownCommand,
    UpdateTownCommandFromJSON,
    UpdateTownCommandToJSON,
} from '../models';

export interface ApiTownsExportPostRequest {
    xLUMENTransactionGuid?: string;
    getAllTownsExcelQuery?: GetAllTownsExcelQuery;
}

export interface ApiTownsGetAllPostRequest {
    xLUMENTransactionGuid?: string;
    getAllTownsQuery?: GetAllTownsQuery;
}

export interface ApiTownsGetAllPublicPostRequest {
    xLUMENTransactionGuid?: string;
    getAllTownsPubliclyQuery?: GetAllTownsPubliclyQuery;
}

export interface ApiTownsGetAllSlenderPostRequest {
    xLUMENTransactionGuid?: string;
    getAllTownsSlenderQuery?: GetAllTownsSlenderQuery;
}

export interface ApiTownsGetAllStreetsPostRequest {
    xLUMENTransactionGuid?: string;
    getAllStreetsQuery?: GetAllStreetsQuery;
}

export interface ApiTownsIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateTownCommand?: UpdateTownCommand;
}

/**
 * 
 */
export class TownsApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of towns as Excel file
     */
    async apiTownsExportPostRaw(requestParameters: ApiTownsExportPostRequest): Promise<runtime.ApiResponse<TownExcelVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTownsExcelQueryToJSON(requestParameters.getAllTownsExcelQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TownExcelVmFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of towns as Excel file
     */
    async apiTownsExportPost(requestParameters: ApiTownsExportPostRequest): Promise<TownExcelVm> {
        const response = await this.apiTownsExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get towns. Yes, get by Post.
     */
    async apiTownsGetAllPostRaw(requestParameters: ApiTownsGetAllPostRequest): Promise<runtime.ApiResponse<TownVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTownsQueryToJSON(requestParameters.getAllTownsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TownVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get towns. Yes, get by Post.
     */
    async apiTownsGetAllPost(requestParameters: ApiTownsGetAllPostRequest): Promise<TownVmPaginatedSearchResponse> {
        const response = await this.apiTownsGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get towns publicly. Yes, get by Post.
     */
    async apiTownsGetAllPublicPostRaw(requestParameters: ApiTownsGetAllPublicPostRequest): Promise<runtime.ApiResponse<PublicTownVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/GetAllPublic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTownsPubliclyQueryToJSON(requestParameters.getAllTownsPubliclyQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicTownVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get towns publicly. Yes, get by Post.
     */
    async apiTownsGetAllPublicPost(requestParameters: ApiTownsGetAllPublicPostRequest): Promise<PublicTownVmPaginatedSearchResponse> {
        const response = await this.apiTownsGetAllPublicPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get a slender list of towns. Yes, get by Post.
     */
    async apiTownsGetAllSlenderPostRaw(requestParameters: ApiTownsGetAllSlenderPostRequest): Promise<runtime.ApiResponse<SlenderTownVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/GetAllSlender`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTownsSlenderQueryToJSON(requestParameters.getAllTownsSlenderQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlenderTownVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get a slender list of towns. Yes, get by Post.
     */
    async apiTownsGetAllSlenderPost(requestParameters: ApiTownsGetAllSlenderPostRequest): Promise<SlenderTownVmPaginatedSearchResponse> {
        const response = await this.apiTownsGetAllSlenderPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get streets of towns. Yes, get by Post.
     */
    async apiTownsGetAllStreetsPostRaw(requestParameters: ApiTownsGetAllStreetsPostRequest): Promise<runtime.ApiResponse<StreetVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/GetAllStreets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllStreetsQueryToJSON(requestParameters.getAllStreetsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreetVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get streets of towns. Yes, get by Post.
     */
    async apiTownsGetAllStreetsPost(requestParameters: ApiTownsGetAllStreetsPostRequest): Promise<StreetVmPaginatedSearchResponse> {
        const response = await this.apiTownsGetAllStreetsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a town
     */
    async apiTownsIdPutRaw(requestParameters: ApiTownsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTownsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Towns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTownCommandToJSON(requestParameters.updateTownCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a town
     */
    async apiTownsIdPut(requestParameters: ApiTownsIdPutRequest): Promise<void> {
        await this.apiTownsIdPutRaw(requestParameters);
    }

}
