/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExamCommand,
    CreateExamCommandFromJSON,
    CreateExamCommandToJSON,
    ExamVmPaginatedSearchResponse,
    ExamVmPaginatedSearchResponseFromJSON,
    ExamVmPaginatedSearchResponseToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllExamsQuery,
    GetAllExamsQueryFromJSON,
    GetAllExamsQueryToJSON,
    UpdateExamCommand,
    UpdateExamCommandFromJSON,
    UpdateExamCommandToJSON,
} from '../models';

export interface ApiExamsGetAllPostRequest {
    xLUMENTransactionGuid?: string;
    getAllExamsQuery?: GetAllExamsQuery;
}

export interface ApiExamsIdDeleteRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiExamsIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateExamCommand?: UpdateExamCommand;
}

export interface ApiExamsPostRequest {
    xLUMENTransactionGuid?: string;
    createExamCommand?: CreateExamCommand;
}

/**
 * 
 */
export class ExamsApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get exams of a repairman. Yes, get by Post.
     */
    async apiExamsGetAllPostRaw(requestParameters: ApiExamsGetAllPostRequest): Promise<runtime.ApiResponse<ExamVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Exams/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllExamsQueryToJSON(requestParameters.getAllExamsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get exams of a repairman. Yes, get by Post.
     */
    async apiExamsGetAllPost(requestParameters: ApiExamsGetAllPostRequest): Promise<ExamVmPaginatedSearchResponse> {
        const response = await this.apiExamsGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiExamsIdDeleteRaw(requestParameters: ApiExamsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExamsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Exams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiExamsIdDelete(requestParameters: ApiExamsIdDeleteRequest): Promise<void> {
        await this.apiExamsIdDeleteRaw(requestParameters);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiExamsIdPutRaw(requestParameters: ApiExamsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExamsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Exams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateExamCommandToJSON(requestParameters.updateExamCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiExamsIdPut(requestParameters: ApiExamsIdPutRequest): Promise<void> {
        await this.apiExamsIdPutRaw(requestParameters);
    }

    /**
     * Creates an exam
     */
    async apiExamsPostRaw(requestParameters: ApiExamsPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Exams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExamCommandToJSON(requestParameters.createExamCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates an exam
     */
    async apiExamsPost(requestParameters: ApiExamsPostRequest): Promise<string> {
        const response = await this.apiExamsPostRaw(requestParameters);
        return await response.value();
    }

}
