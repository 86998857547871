import clsx from "clsx";
import { FC } from "react";
import styles from "./StatusDisplay.module.scss";

interface StatusDisplayProps {
  badgeClassName: string;
  text?: string;
}

export const StatusDisplay: FC<StatusDisplayProps> = ({
  badgeClassName,
  text,
}) => {
  return (
    <div className={styles.statusDisplay}>
      <span className={clsx(badgeClassName, styles.statusBadge)} />
      <span>{text}</span>
    </div>
  );
};
