import { Descriptions, Spin, Tabs, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import { mdiPencil } from "@mdi/js";
import IconButton from "components/ui/IconButton";
import styles from "./SpecialPlacesView.module.scss";
import StretchToCardEdge from "components/StretchToCardEdge";
import StringDisplay from "components/ui/StringDisplay";
import SpecialPlacesCreateModal from "../create/SpecialPlacesCreateModal";
import ContractorsTab from "./components/ContractorsTab";
import MaintainedDevicesTab from "./components/MaintainedDevicesTab";
import TabsWrapper from "components/ui/TabsWrapper";
import CardTitle from "components/ui/CardTitle";
import CardContent from "components/ui/CardContent";

const SpecialPlacesView: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { specialPlace } = useSelector((state) => state.specialPlacesView);
  const { fetchSpecialPlace } = useSelector(
    (state) => state.loading.effects.specialPlacesView
  );
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleEditButtonClick = useCallback(() => {
    setEditModalOpen(true);
  }, []);

  const fetchSpecialPlaceById = useCallback(
    (id?: string) => {
      if (typeof id === "string") {
        dispatch.specialPlacesView.fetchSpecialPlace(id);
      }
    },
    [dispatch.specialPlacesView]
  );

  useEffect(() => {
    fetchSpecialPlaceById(params.id);
  }, [fetchSpecialPlaceById, params.id]);

  useEffect(() => {
    dispatch.common.setBreadcrumbs([
      {
        title: t("menu.specialPlaces"),
        to: "/special-places",
      },
      {
        title: specialPlace?.name || "",
      },
    ]);

    return () => {
      dispatch.common.setBreadcrumbs([]);
    };
  }, [specialPlace?.name, dispatch.common, t]);

  useEffect(() => {
    return () => {
      dispatch.specialPlacesView.reset();
    };
  }, [dispatch.specialPlacesView]);

  return (
    <>
      <CardTitle className={styles.headerDescriptionsRow}>
        <VerticalDataDescriptions column={1}>
          <Descriptions.Item label={t("specialPlace.name")}>
            {specialPlace?.name ? (
              <Typography.Title level={2}>
                {specialPlace?.name}
              </Typography.Title>
            ) : (
              <EmptyContent />
            )}
          </Descriptions.Item>
        </VerticalDataDescriptions>

        <IconButton path={mdiPencil} onClick={() => handleEditButtonClick()} />
      </CardTitle>

      <CardContent>
        <Spin spinning={fetchSpecialPlace.loading}>
          <VerticalDataDescriptions>
            <Descriptions.Item label={t("specialPlace.description")}>
              <StringDisplay value={specialPlace?.description} />
            </Descriptions.Item>
          </VerticalDataDescriptions>

          <StretchToCardEdge>
            <TabsWrapper>
              <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane
                type="card"
                size="small"
              >
                <Tabs.TabPane tab={t("specialPlace.tabs.contractors")} key="1">
                  <ContractorsTab />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("specialPlace.tabs.maintainedDevices")}
                  key="2"
                >
                  <MaintainedDevicesTab />
                </Tabs.TabPane>
              </Tabs>
            </TabsWrapper>
          </StretchToCardEdge>
        </Spin>
      </CardContent>

      <SpecialPlacesCreateModal
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
      />
    </>
  );
};

export default SpecialPlacesView;
