import { TabPaneProps, Tabs } from "antd";
import useFeature from "hooks/useFeature";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ExamTypeList from "./tabs/exam-type-tab/list/ExamTypeList";
import FailureTaskList from "./tabs/failure-task-tab/list/FailureTaskList";
import InventoryCategoryList from "./tabs/inventory-category-tab/list/InventoryCategoryList";
import TabsWrapper from "components/ui/TabsWrapper";
import styles from "./ManageDictionaries.module.scss";

type TabPane = Omit<TabPaneProps, "tabKey">;

const ManageDictionaries = () => {
  const { t } = useTranslation();

  const { isEnabled: isExamFeatureEnabled } = useFeature("exam");

  const tabs = useMemo<TabPane[]>(
    () => [
      {
        tab: t("dictionaries.inventoryCategory.tabLabel"),
        children: <InventoryCategoryList />,
      },
      {
        tab: t("dictionaries.failureTask.tabLabel"),
        children: <FailureTaskList />,
      },
      ...((isExamFeatureEnabled
        ? [
            {
              tab: t("dictionaries.examType.tabLabel"),
              children: <ExamTypeList />,
            },
          ]
        : []) as TabPane[]),
    ],
    [isExamFeatureEnabled, t]
  );

  return (
    <div className={styles.tabsWrapper}>
      <TabsWrapper>
        <Tabs
          defaultActiveKey="0"
          destroyInactiveTabPane
          type="card"
          size="small"
        >
          {tabs.map(({ tab, children }, index) => (
            <Tabs.TabPane tab={tab} key={`${index}`} tabKey={`${index}`}>
              {children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </TabsWrapper>
    </div>
  );
};

export default ManageDictionaries;
