/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
    ContactPersonVm,
    ContactPersonVmFromJSON,
    ContactPersonVmFromJSONTyped,
    ContactPersonVmToJSON,
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    SlaVm,
    SlaVmFromJSON,
    SlaVmFromJSONTyped,
    SlaVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface TownVm
 */
export interface TownVm {
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    municipalityName?: string | null;
    /**
     * 
     * @type {AddressVm}
     * @memberof TownVm
     */
    municipalityAddress?: AddressVm;
    /**
     * 
     * @type {PhoneVm}
     * @memberof TownVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    sectionActionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TownVm
     */
    isActivated?: boolean;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof TownVm
     */
    sectionAction?: DictionaryVm;
    /**
     * 
     * @type {string}
     * @memberof TownVm
     */
    sectionFailureEmailTo?: string | null;
    /**
     * 
     * @type {Array<ContactPersonVm>}
     * @memberof TownVm
     */
    contactPersons?: Array<ContactPersonVm> | null;
    /**
     * 
     * @type {Array<SlaVm>}
     * @memberof TownVm
     */
    slas?: Array<SlaVm> | null;
}

export function TownVmFromJSON(json: any): TownVm {
    return TownVmFromJSONTyped(json, false);
}

export function TownVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): TownVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'municipalityName': !exists(json, 'municipalityName') ? undefined : json['municipalityName'],
        'municipalityAddress': !exists(json, 'municipalityAddress') ? undefined : AddressVmFromJSON(json['municipalityAddress']),
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'sectionActionId': !exists(json, 'sectionActionId') ? undefined : json['sectionActionId'],
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
        'sectionAction': !exists(json, 'sectionAction') ? undefined : DictionaryVmFromJSON(json['sectionAction']),
        'sectionFailureEmailTo': !exists(json, 'sectionFailureEmailTo') ? undefined : json['sectionFailureEmailTo'],
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(ContactPersonVmFromJSON)),
        'slas': !exists(json, 'slas') ? undefined : (json['slas'] === null ? null : (json['slas'] as Array<any>).map(SlaVmFromJSON)),
    };
}

export function TownVmToJSON(value?: TownVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'municipalityName': value.municipalityName,
        'municipalityAddress': AddressVmToJSON(value.municipalityAddress),
        'phone': PhoneVmToJSON(value.phone),
        'email': value.email,
        'sectionActionId': value.sectionActionId,
        'isActivated': value.isActivated,
        'sectionAction': DictionaryVmToJSON(value.sectionAction),
        'sectionFailureEmailTo': value.sectionFailureEmailTo,
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(ContactPersonVmToJSON)),
        'slas': value.slas === undefined ? undefined : (value.slas === null ? null : (value.slas as Array<any>).map(SlaVmToJSON)),
    };
}


