import { DropResult } from "@hello-pangea/dnd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnStorageName } from "./ColumnStorageName";
import { ColumnState, ExtendedColumnType } from "./ExtendedColumnType";

export interface TableSettingsUtils<T> {
  visible: boolean;
  currentColumns: ExtendedColumnType<T>[];
  tempColumns: ExtendedColumnType<T>[];
  handleChangeColumnVisibility: (
    visible: boolean,
    column: ExtendedColumnType<T>
  ) => void;
  handleChangeVisibility: () => void;
  applyChangesNotAllowed: boolean;
  handleApplyChanges: () => void;
  handleResetToDefault: () => void;
  onDragEnd: (result: DropResult) => void;
  areColumnsChanged: boolean;
}

/**
 * Contains the logic for reordering, and saving columns order and columns visibility
 * Pass this hook as a prop to the TableSettingsDropdown component
 * LocalStorage only stores the non-action columns key and their currentState
 *
 * actionColumns don't have a key, and we don't want to rearrange them
 * (thats why we filter for key)
 *
 * tempColumns - used while rearranging inside the drag-and-drop component
 *                (all columns except actionColumns)
 * appliedColumns - used to set the internal column state after applying changes
 *                  (all columns except actionColumns)
 * currentColumn - the final state of the columns
 *                  (only visible, alwaysVisible columns with readded actionColumns)
 *
 * @param defaultColumns the default state of the columns, used for reset and initial values
 * @param storageName the name for the localStorage, to get and set the columns order
 */
export const useTableSettingsUtils = <T extends Record<string, unknown>>(
  defaultColumns: ExtendedColumnType<T>[],
  storageName: ColumnStorageName
): TableSettingsUtils<T> => {
  const [visible, setVisible] = useState<boolean>(false);
  const [tempColumns, setTempColumns] = useState<ExtendedColumnType<T>[]>([]);
  const [appliedColumns, setAppliedColumns] = useState<ExtendedColumnType<T>[]>(
    []
  );

  const defaultColumnsWithoutAction = useMemo(
    () =>
      defaultColumns
        .filter((column) => column.key)
        .map((column) => ({
          ...column,
          currentState: column.defaultState || ColumnState.Visible,
        })),
    [defaultColumns]
  );

  const getColumnStateFromStoredData = useCallback(
    (
      column: ExtendedColumnType<T>,
      storedColumns: ExtendedColumnType<T>[]
    ): ColumnState => {
      const foundColumn = storedColumns.find(
        (storedColumn) => storedColumn.key === column.key
      );
      return foundColumn
        ? foundColumn.currentState || ColumnState.Visible
        : column.defaultState || ColumnState.Visible;
    },
    []
  );

  const getCurrentColumns = useCallback(
    (storedColumns: any[]): ExtendedColumnType<T>[] => {
      const storedkeyes = storedColumns.flatMap((column) => column.key);

      return defaultColumnsWithoutAction
        .flatMap((column) => ({
          ...column,
          currentState: getColumnStateFromStoredData(column, storedColumns),
        }))
        .sort(
          (column1, column2) =>
            storedkeyes.indexOf(String(column1.key)) -
            storedkeyes.indexOf(String(column2.key))
        );
    },
    [defaultColumnsWithoutAction, getColumnStateFromStoredData]
  );

  const reorderColumnsFromStore = useCallback(
    (storedColumnInfosString: string): void => {
      const storedColumns = getCurrentColumns(
        JSON.parse(storedColumnInfosString)
      );
      setTempColumns(storedColumns);
      setAppliedColumns(storedColumns);
    },
    [getCurrentColumns]
  );

  const setDefaultColumns = useCallback((): void => {
    setTempColumns(defaultColumnsWithoutAction);
    setAppliedColumns(defaultColumnsWithoutAction);
  }, [defaultColumnsWithoutAction]);

  const reorderColumns = useCallback((): void => {
    const storedColumnInfosString = localStorage.getItem(storageName);

    if (storedColumnInfosString) {
      reorderColumnsFromStore(storedColumnInfosString);
    } else {
      setDefaultColumns();
    }
  }, [reorderColumnsFromStore, setDefaultColumns, storageName]);

  const storeColumnsOrder = useCallback((): void => {
    const storedColumnInfos = tempColumns
      .filter((column) => column.key)
      .flatMap((column) => ({
        key: column.key,
        currentState: column.currentState,
      }));

    localStorage.setItem(storageName, JSON.stringify(storedColumnInfos));
  }, [storageName, tempColumns]);

  const applyChangesNotAllowed = useMemo(() => {
    const noMoreVisibleColumn =
      tempColumns.filter(
        (column) =>
          column.currentState === ColumnState.AlwaysVisible ||
          column.currentState === ColumnState.Visible
      ).length === 0;

    let hasStateChange = false;
    let hasColumnOrderChange = false;
    tempColumns.forEach((tempColumn, index) => {
      if (tempColumn.title !== appliedColumns[index].title)
        hasColumnOrderChange = true;

      if (
        tempColumn.currentState !==
        appliedColumns.find((column) => column.title === tempColumn.title)
          ?.currentState
      )
        hasStateChange = true;
    });

    return noMoreVisibleColumn || !(hasStateChange || hasColumnOrderChange);
  }, [tempColumns, appliedColumns]);

  const applyChanges = useCallback((): void => {
    setAppliedColumns(tempColumns);
    storeColumnsOrder();
    setVisible(!visible);
  }, [storeColumnsOrder, tempColumns, visible]);

  const changeColumnVisibility = useCallback(
    (visible: boolean, column: ExtendedColumnType<T>): void => {
      const newColumn = {
        ...column,
        currentState: visible ? ColumnState.Visible : ColumnState.Hidden,
      };

      setTempColumns(
        tempColumns.flatMap((tempColumn) =>
          tempColumn.key === column.key ? newColumn : tempColumn
        )
      );
    },
    [tempColumns]
  );

  const onDragEnd = useCallback(
    (dropResult: DropResult): void => {
      if (!dropResult.destination) {
        return;
      }

      const resultArray = Array.from(tempColumns);
      const [removed] = resultArray.splice(dropResult.source.index, 1);
      resultArray.splice(dropResult.destination.index, 0, removed);

      setTempColumns(resultArray);
    },
    [tempColumns]
  );

  const resetToDefault = useCallback((): void => {
    setTempColumns(defaultColumnsWithoutAction);
  }, [defaultColumnsWithoutAction]);

  useEffect(() => {
    reorderColumns();
  }, [reorderColumns]);

  const currentColumns = useMemo(
    (): ExtendedColumnType<T>[] => {
      if (appliedColumns.length) {
        return [
          ...getCurrentColumns(appliedColumns).filter(
            (column) => column.currentState !== ColumnState.Hidden
          ),
          ...defaultColumns.filter((column) => !column.key),
        ];
      } else {
        return defaultColumns;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultColumns, appliedColumns]
  );

  const areColumnsChanged = useMemo(() => {
    return (
      defaultColumnsWithoutAction.length !== tempColumns.length ||
      defaultColumnsWithoutAction.some((col, idx) => {
        return (
          col.key !== tempColumns[idx].key ||
          col.currentState !== tempColumns[idx].currentState
        );
      })
    );
  }, [defaultColumnsWithoutAction, tempColumns]);

  return {
    visible,
    currentColumns,
    tempColumns,
    handleChangeColumnVisibility: changeColumnVisibility,
    handleChangeVisibility: () => setVisible(!visible),
    applyChangesNotAllowed,
    handleApplyChanges: applyChanges,
    handleResetToDefault: resetToDefault,
    onDragEnd,
    areColumnsChanged,
  };
};
