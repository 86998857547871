import { Col, Input, Row, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";
import {
  FormUtils,
  GrapeAntdForm,
  min,
  phoneNumber,
  requiredField,
} from "widgets/form";
import styles from "./ContactFieldset.module.scss";
import { enumTranslate } from "utils/enumTranslate";
import { RegistrationStates } from "api/generated/lumen";
import { RepairmenCreateFormValues } from "features/repairmen/models";
import { FC } from "react";

interface ContactsFieldsetProps {
  formUtils: FormUtils<RepairmenCreateFormValues>;
}

const ContactFieldset: FC<ContactsFieldsetProps> = ({ formUtils }) => {
  const { t } = useTranslation();

  const registrationState = formUtils.form.getFieldValue("registrationState");

  return (
    <Row gutter={[24, 16]} className={styles.row}>
      <Col span={24}>
        <div className={styles.sectionTitle}>
          {t("repairmen.contactSection")}
        </div>
      </Col>
      <Col span={12}>
        <GrapeAntdForm.Item
          name="email"
          label={t("repairmen.email")}
          rules={[requiredField(t)]}
        >
          <Input size="large" type="email" />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={12}>
        <GrapeAntdForm.Item
          name={["phone", "number"]}
          label={t("repairmen.phone")}
          rules={[phoneNumber(t), min(t, 6, "string")]}
        >
          <Input size="large" type="phone" />
        </GrapeAntdForm.Item>
      </Col>

      <Col span={24}>
        <Row justify="end">
          <Col span={12}>
            <Space
              align="center"
              size="small"
              className={styles.registrationState}
            >
              {t("contact.registrationState") + ": "}
              {enumTranslate(registrationState, "registrationState")}
            </Space>
          </Col>

          <Col span={12}>
            {registrationState !== RegistrationStates.Registered && (
              <div className={styles.invite}>
                <Space align="center" size="middle">
                  <span>{t("contact.toBeInvited")}</span>
                  <GrapeAntdForm.Item
                    name="inviteRepairman"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch />
                  </GrapeAntdForm.Item>
                </Space>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactFieldset;
