import { Spin } from "antd";
import { api } from "api";
import { ImageThumbnailMetadataVm } from "api/generated/lumen";
import React, { useCallback, useEffect, useState } from "react";
import { blobToBase64Async } from "utils/file";

interface ImageDisplayProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src"
  > {
  data: ImageThumbnailMetadataVm;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ data, ...rest }) => {
  const [src, setSrc] = useState("");
  const [dataIsLoading, setDataIsLoading] = useState(false);

  const fetchImage = useCallback(async () => {
    if (typeof data.id !== "string") {
      return;
    }

    setDataIsLoading(true);

    try {
      const response = await api.lumen.images.apiImagesThumbnailIdGetRaw({
        id: data.id,
      });

      const blobValue = await response.raw.blob();
      const base64Data = await blobToBase64Async(blobValue);

      setSrc(`data:${data.contentType};base64,${base64Data}`);
    } catch (error) {
      console.log(error);
    }

    setDataIsLoading(false);
  }, [data.contentType, data.id]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  return dataIsLoading ? (
    <Spin spinning />
  ) : (
    <img {...rest} src={src} alt={rest.alt || ""} />
  );
};

export default ImageDisplay;
