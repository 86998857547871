interface Config {
  siderWidth: number;
  siderCollapsedWidth: number;
  menuInlineIndent: number;
}

const config: Config = {
  siderWidth: 240,
  siderCollapsedWidth: 75,
  menuInlineIndent: 24,
};

export default config;
