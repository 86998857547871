/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationVm,
    AuthenticationVmFromJSON,
    AuthenticationVmToJSON,
    CheckInvitationVm,
    CheckInvitationVmFromJSON,
    CheckInvitationVmToJSON,
    LoginCommand,
    LoginCommandFromJSON,
    LoginCommandToJSON,
    LoginVm,
    LoginVmFromJSON,
    LoginVmToJSON,
    LostPasswordCommand,
    LostPasswordCommandFromJSON,
    LostPasswordCommandToJSON,
    PasswordComplexityVm,
    PasswordComplexityVmFromJSON,
    PasswordComplexityVmToJSON,
    RefreshTokenCommand,
    RefreshTokenCommandFromJSON,
    RefreshTokenCommandToJSON,
    RegisterDto,
    RegisterDtoFromJSON,
    RegisterDtoToJSON,
} from '../models';

export interface ApiAccountsCheckInvitationCheckInvitationUserIdGetRequest {
    userId: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiAccountsLoginPostRequest {
    xLUMENTransactionGuid?: string;
    loginCommand?: LoginCommand;
}

export interface ApiAccountsLostPasswordPostRequest {
    xLUMENTransactionGuid?: string;
    lostPasswordCommand?: LostPasswordCommand;
}

export interface ApiAccountsPasswordComplexityGetRequest {
    xLUMENTransactionGuid?: string;
}

export interface ApiAccountsRefreshTokenPostRequest {
    xLUMENTransactionGuid?: string;
    refreshTokenCommand?: RefreshTokenCommand;
}

export interface ApiAccountsRegisterPostRequest {
    xLUMENTransactionGuid?: string;
    registerDto?: RegisterDto;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     * Check if invitation is still valid
     */
    async apiAccountsCheckInvitationCheckInvitationUserIdGetRaw(requestParameters: ApiAccountsCheckInvitationCheckInvitationUserIdGetRequest): Promise<runtime.ApiResponse<CheckInvitationVm>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiAccountsCheckInvitationCheckInvitationUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/CheckInvitation/checkInvitation/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckInvitationVmFromJSON(jsonValue));
    }

    /**
     * Check if invitation is still valid
     */
    async apiAccountsCheckInvitationCheckInvitationUserIdGet(requestParameters: ApiAccountsCheckInvitationCheckInvitationUserIdGetRequest): Promise<CheckInvitationVm> {
        const response = await this.apiAccountsCheckInvitationCheckInvitationUserIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gives Microsoft.AspNetCore.Mvc.UnauthorizedResult in case user was not found and in case  any error occurs handles as Microsoft.AspNetCore.Mvc.BadRequestResult.
     * Tries to authenticate with the given information
     */
    async apiAccountsLoginPostRaw(requestParameters: ApiAccountsLoginPostRequest): Promise<runtime.ApiResponse<LoginVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCommandToJSON(requestParameters.loginCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginVmFromJSON(jsonValue));
    }

    /**
     * Gives Microsoft.AspNetCore.Mvc.UnauthorizedResult in case user was not found and in case  any error occurs handles as Microsoft.AspNetCore.Mvc.BadRequestResult.
     * Tries to authenticate with the given information
     */
    async apiAccountsLoginPost(requestParameters: ApiAccountsLoginPostRequest): Promise<LoginVm> {
        const response = await this.apiAccountsLoginPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sends an e-mail to the user who forgot their password that directs them to re-register their account
     */
    async apiAccountsLostPasswordPostRaw(requestParameters: ApiAccountsLostPasswordPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/LostPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LostPasswordCommandToJSON(requestParameters.lostPasswordCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends an e-mail to the user who forgot their password that directs them to re-register their account
     */
    async apiAccountsLostPasswordPost(requestParameters: ApiAccountsLostPasswordPostRequest): Promise<void> {
        await this.apiAccountsLostPasswordPostRaw(requestParameters);
    }

    /**
     * Gets the password complexity regex
     */
    async apiAccountsPasswordComplexityGetRaw(requestParameters: ApiAccountsPasswordComplexityGetRequest): Promise<runtime.ApiResponse<PasswordComplexityVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/PasswordComplexity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordComplexityVmFromJSON(jsonValue));
    }

    /**
     * Gets the password complexity regex
     */
    async apiAccountsPasswordComplexityGet(requestParameters: ApiAccountsPasswordComplexityGetRequest): Promise<PasswordComplexityVm> {
        const response = await this.apiAccountsPasswordComplexityGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gives Microsoft.AspNetCore.Mvc.UnauthorizedResult in case user was not found and in case  any error occurs handles as Microsoft.AspNetCore.Mvc.BadRequestResult.
     * Returns refresh token for the current user, for long term session.
     */
    async apiAccountsRefreshTokenPostRaw(requestParameters: ApiAccountsRefreshTokenPostRequest): Promise<runtime.ApiResponse<AuthenticationVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/RefreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenCommandToJSON(requestParameters.refreshTokenCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationVmFromJSON(jsonValue));
    }

    /**
     * Gives Microsoft.AspNetCore.Mvc.UnauthorizedResult in case user was not found and in case  any error occurs handles as Microsoft.AspNetCore.Mvc.BadRequestResult.
     * Returns refresh token for the current user, for long term session.
     */
    async apiAccountsRefreshTokenPost(requestParameters: ApiAccountsRefreshTokenPostRequest): Promise<AuthenticationVm> {
        const response = await this.apiAccountsRefreshTokenPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Register user
     */
    async apiAccountsRegisterPostRaw(requestParameters: ApiAccountsRegisterPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Accounts/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterDtoToJSON(requestParameters.registerDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Register user
     */
    async apiAccountsRegisterPost(requestParameters: ApiAccountsRegisterPostRequest): Promise<void> {
        await this.apiAccountsRegisterPostRaw(requestParameters);
    }

}
