/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmFromJSONTyped,
    ContractorVmToJSON,
    DeviceVm,
    DeviceVmFromJSON,
    DeviceVmFromJSONTyped,
    DeviceVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpecialPlaceVm
 */
export interface SpecialPlaceVm {
    /**
     * 
     * @type {string}
     * @memberof SpecialPlaceVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlaceVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialPlaceVm
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpecialPlaceVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<ContractorVm>}
     * @memberof SpecialPlaceVm
     */
    contractors?: Array<ContractorVm> | null;
    /**
     * 
     * @type {Array<DeviceVm>}
     * @memberof SpecialPlaceVm
     */
    devices?: Array<DeviceVm> | null;
}

export function SpecialPlaceVmFromJSON(json: any): SpecialPlaceVm {
    return SpecialPlaceVmFromJSONTyped(json, false);
}

export function SpecialPlaceVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialPlaceVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorVmFromJSON)),
        'devices': !exists(json, 'devices') ? undefined : (json['devices'] === null ? null : (json['devices'] as Array<any>).map(DeviceVmFromJSON)),
    };
}

export function SpecialPlaceVmToJSON(value?: SpecialPlaceVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'isDeleted': value.isDeleted,
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorVmToJSON)),
        'devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceVmToJSON)),
    };
}


