/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserFilterType,
    UserFilterTypeFromJSON,
    UserFilterTypeFromJSONTyped,
    UserFilterTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserVm
 */
export interface UserVm {
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    forename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    pwdHash?: string | null;
    /**
     * 
     * @type {UserFilterType}
     * @memberof UserVm
     */
    userFilterType?: UserFilterType;
    /**
     * 
     * @type {string}
     * @memberof UserVm
     */
    userFilterId?: string | null;
}

export function UserVmFromJSON(json: any): UserVm {
    return UserVmFromJSONTyped(json, false);
}

export function UserVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'pwdHash': !exists(json, 'pwdHash') ? undefined : json['pwdHash'],
        'userFilterType': !exists(json, 'userFilterType') ? undefined : UserFilterTypeFromJSON(json['userFilterType']),
        'userFilterId': !exists(json, 'userFilterId') ? undefined : json['userFilterId'],
    };
}

export function UserVmToJSON(value?: UserVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'username': value.username,
        'pwdHash': value.pwdHash,
        'userFilterType': UserFilterTypeToJSON(value.userFilterType),
        'userFilterId': value.userFilterId,
    };
}


