import { mdiInbox } from "@mdi/js";
import { Input, ModalProps, Space, Upload } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useFileInput from "hooks/useFileInput";
import { AddNewDocumentFormValues } from "models/documents";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import styles from "./AddNewDocumentModal.module.scss";
import { mapContractorVmToUpdateContractorCommand } from "features/contractors/models";

const AddNewDocumentModal: React.FC<ModalProps> = ({
  onCancel,
  onOk,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<AddNewDocumentFormValues>();
  const { addDocument } = useSelector(
    (state) => state.loading.effects.contractorView
  );
  const { contractor } = useSelector((state) => state.contractorView);
  const { dummyRequest, normFile } = useFileInput();
  const dispatch = useDispatch();

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const handleFinish = useCallback(
    async (values: AddNewDocumentFormValues): Promise<void> => {
      if (contractor && values.file[0].originFileObj) {
        await dispatch.contractorView.addDocument({
          contractorData: mapContractorVmToUpdateContractorCommand(contractor),
          file: values.file[0].originFileObj,
          fileName: values.fileName,
        });

        onOk?.("" as any);
        formUtils.form.resetFields();
      }
    },
    [contractor, dispatch.contractorView, formUtils.form, onOk]
  );

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={t("document.createModal.title")}
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: addDocument.loading }}
      cancelButtonProps={{ disabled: addDocument.loading }}
      formInstance={formUtils.form}
      visible={visible}
    >
      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item
          name="fileName"
          label={t("document.title")}
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          label={t("document.file")}
          rules={[requiredField(t, undefined, { type: "array" })]}
        >
          <Upload
            customRequest={dummyRequest}
            showUploadList={{
              showDownloadIcon: false,
              showRemoveIcon: true,
              showPreviewIcon: false,
            }}
            className={styles.upload}
          >
            <Space direction="vertical" className={styles.space}>
              <MaterialIcon path={mdiInbox} className={styles.uploadIcon} />
              <div>{t("document.addNewFileText")}</div>
            </Space>
          </Upload>
        </GrapeAntdForm.Item>
      </CustomForm>
    </CustomModal>
  );
};

export default AddNewDocumentModal;
