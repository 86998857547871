import React from "react";
import { CustomTableProps } from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import { ColumnConfigParams } from "widgets/table/useTableUtils";
import useTable, { UseTableProps } from "./useTable";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

const fetchTableData = () => Promise.resolve();

const listParams = {};

interface UseDumbTableProps<T>
  extends Omit<Partial<UseTableProps<T>>, "columnParams, list"> {
  columnParams: ColumnConfigParams[];
  list: any;
}

// No table settings, no filtering and sorting on BE side
function useDumbTable<T extends { [key: string]: any }>(
  props: UseDumbTableProps<T>
): CustomTableProps {
  const customTableProps = useTable({
    fetchTableData,
    listParams,
    columnStorageName: ColumnStorageName.DUMMY, // we dont't use table settings (disableTableSettings: true), so no storage is needed
    headerOptions: <></>,
    resetListParams: emptyFunc,
    resetStore: emptyFunc,
    disableTableSettings: true,
    ...props,
  });

  return customTableProps;
}

export default useDumbTable;
