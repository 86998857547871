import { PublicTownVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { ListRequestParams } from "widgets/table/useTableUtils";

export enum SupportedLanguage {
  Hu = "hu",
  En = "en",
}

export interface ItemProps {
  label: string;
  value: string;
}

export interface FieldsetProps {
  disabled?: boolean;
}

export interface ExtendedSelectValue<ValueType = string> {
  disabled?: boolean;
  key: string;
  label: string;
  title?: string;
  value: ValueType;
}

export const mapPublicTownVmToExtendedSelectValue = (
  townVm?: PublicTownVm
) => ({
  key: townVm?.id || "",
  label: townVm?.name || "",
  value: townVm?.id || "",
});

export type Role =
  | "LumenAdmin"
  | "Contractor"
  | "Repairman"
  | "Council"
  | "CallCenter"
  | "Root_SERVICE_ACCOUNT"
  | "Root_SUPER_ADMIN";

// manually added because these values are only available through API request, but not via enum
export type DictionaryGroupType =
  | "LumenFailureTypeCategories"
  | "LumenFailureCategories"
  | "LumenFailureStatuses"
  | "LumenInactiveFailureStatuses"
  | "LumenContractorStatuses"
  | "LumenFailureTasks"
  | "LumenFailureCategorysPriority"
  | "LumenFailurePriorities"
  | "LumenWorkbookStatuses"
  | "LumenRepairmanStatuses"
  | "LumenInventoryCategories"
  | "LumenFailureExpanses"
  | "LumenFailureNatures"
  | "LumenOptimalDeadlineDays"
  | "LumenSlaCategory"
  | "LumenSlaDeadlines"
  | "LumenWorkbookTaskReasons"
  | "LumenWorkbookTaskStatuses"
  | "LumenSectionActions";

// manually added because these values are only available through API request, but not via enum
export enum LumenWorkbookTaskStatuses {
  Done = "Done",
  ToDo = "ToDo",
  Rejected = "Rejected",
}

export enum LumenWorkbookTaskReasons {
  LackOfParts = "LackOfParts",
  NotNeeded = "NotNeeded",
  Postponed = "Postponed",
}

export type FormMode = "Create" | "Edit" | "View";

export type DeviceLayoutType = "default" | "mobile";

export interface Breadcrumb {
  title: React.ReactNode;
  to?: string;
}

export type FailureStatus =
  | "Fixed"
  | "Noticed"
  | "DidNotFix"
  | "SectionFailure"
  | "Suspended";

export interface GetFilterParamsForGetRequests {
  payload: FailureListRequestParams;
  mergedParams: ListRequestParams;
}
