import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateFailureCommand,
  FailureTypeVm,
  OrderByType,
  UpdateFailureCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";
import { FailureCreateFormValues } from "../models";

interface FailureCreateState {
  failureTypes: FailureTypeVm[];
  editingItemId?: string;
  closeDateBackwardDays?: number;
  isVisible: boolean;
  prePopulatedValues?: Partial<FailureCreateFormValues>;
  isSaved: boolean;
}

const initialState: FailureCreateState = {
  failureTypes: [],
  editingItemId: undefined,
  closeDateBackwardDays: undefined,
  isVisible: false,
  prePopulatedValues: undefined,
  isSaved: false,
};

export const failureCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFailureTypes(state, data: FailureTypeVm[]) {
      state.failureTypes = data;
    },
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    setCloseDateBackwardDays(state, data?: number) {
      state.closeDateBackwardDays = data;
    },
    setIsVisible(state, data: boolean) {
      state.isVisible = data;
    },
    setPrePopulatedValues(
      state,
      data: Partial<FailureCreateFormValues> | undefined
    ) {
      state.prePopulatedValues = data;
    },
    setIsSaved(state, data: boolean) {
      state.isSaved = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureCreate } = dispatch;

    return {
      async fetchFailureTypes(): Promise<void> {
        const data = await api.lumen.failureTypes.apiFailureTypesGetAllPost({
          getAllFailureTypesQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
          },
        });

        const { items } = data;

        dispatch(failureCreate.setFailureTypes(items || []));
      },

      async create(values: CreateFailureCommand): Promise<string> {
        return api.lumen.failures.apiFailuresPost({
          createFailureCommand: values,
        });
      },

      async edit(values: UpdateFailureCommand): Promise<void> {
        return api.lumen.failures.apiFailuresIdPut({
          updateFailureCommand: values,
          id: values.id,
        });
      },

      async fetchCloseDateBackwardDays(): Promise<void> {
        const data =
          await api.lumen.failures.apiFailuresGetCloseDateBackwardDaysGet({});

        dispatch(failureCreate.setCloseDateBackwardDays(+data));
      },
    };
  },
});
