import {
  mdiCalendarBlankOutline,
  mdiCloseCircleOutline,
  mdiTools,
} from "@mdi/js";
import { Button, ModalProps, Space, Spin, Typography } from "antd";
import { DictionaryVm, WorkbookTaskVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import MobileModal from "components/ui/MobileModal";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";
import {
  DeviceLayoutType,
  LumenWorkbookTaskReasons,
  LumenWorkbookTaskStatuses,
} from "models/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FailureViewWorbookTaskRejectModal.module.scss";

interface FailureViewWorbookTaskRejectModalProps extends ModalProps {
  task: WorkbookTaskVm;
  deviceLayoutType?: DeviceLayoutType;
}

const getIconPath = (reason: DictionaryVm) => {
  switch (reason.value) {
    case LumenWorkbookTaskReasons.NotNeeded:
      return mdiCloseCircleOutline;

    case LumenWorkbookTaskReasons.LackOfParts:
      return mdiTools;

    case LumenWorkbookTaskReasons.Postponed:
      return mdiCalendarBlankOutline;

    default:
      return "";
  }
};

const getButtonClassName = (reason: DictionaryVm) => {
  switch (reason.value) {
    case LumenWorkbookTaskReasons.NotNeeded:
      return styles.dangerButton;

    case LumenWorkbookTaskReasons.LackOfParts:
      return styles.normalButton;

    case LumenWorkbookTaskReasons.Postponed:
      return styles.warningButton;

    default:
      return "";
  }
};

const FailureViewWorbookTaskRejectModal: React.FC<
  FailureViewWorbookTaskRejectModalProps
> = ({ onCancel, visible, task, deviceLayoutType, ...rest }) => {
  const { t } = useTranslation();

  const workbookTaskStatusesQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenWorkbookTaskStatuses",
  });
  const workbookTaskReasonsQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenWorkbookTaskReasons",
  });

  const { workbook, failure } = useSelector((state) => state.failureView);
  const { setWorkbookTaskStatus } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const dispatch = useDispatch();

  const handleButtonClick = useCallback(
    async (reason: DictionaryVm) => {
      const rejectedStatus = workbookTaskStatusesQuery.data?.find(
        ({ value }) => value === LumenWorkbookTaskStatuses.Rejected
      );

      if (
        typeof task.id !== "string" ||
        typeof workbook?.id !== "string" ||
        typeof task.taskId !== "string" ||
        typeof rejectedStatus?.id !== "string" ||
        typeof reason.id !== "string"
      ) {
        return;
      }

      await dispatch.failureView.setWorkbookTaskStatus({
        id: task.id,
        statusId: rejectedStatus?.id,
        taskId: task.taskId,
        workbookId: workbook.id,
        reasonId: reason.id,
      });

      onCancel?.("" as any);

      if (typeof failure?.id === "string") {
        dispatch.failureView.fetchWorkbook(failure.id);
      }
    },
    [
      dispatch.failureView,
      failure?.id,
      onCancel,
      task.id,
      task.taskId,
      workbook?.id,
      workbookTaskStatusesQuery.data,
    ]
  );

  return (
    <MobileModal
      {...rest}
      onCancel={onCancel}
      visible={visible}
      className={clsx({ [styles.desktop]: deviceLayoutType === "default" })}
    >
      <div className={styles.content}>
        <Space direction="vertical" size="large">
          <Typography.Title level={2} className={styles.title}>
            {t("workbook.rejectTaskModal.title")}
          </Typography.Title>

          <Spin spinning={setWorkbookTaskStatus.loading}>
            <Space direction="vertical" size="middle" className={styles.space}>
              {workbookTaskReasonsQuery.data?.map((reason) => (
                <Button
                  className={clsx(styles.button, getButtonClassName(reason))}
                  block
                  size="large"
                  icon={<MaterialIcon path={getIconPath(reason)} />}
                  onClick={() => handleButtonClick(reason)}
                  key={reason.id}
                >
                  <DictionaryDisplay dictionary={reason} />
                </Button>
              ))}
            </Space>
          </Spin>
        </Space>
      </div>
    </MobileModal>
  );
};

export default FailureViewWorbookTaskRejectModal;
