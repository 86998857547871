/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldRequireTypes {
    Allowed = 'Allowed',
    Required = 'Required',
    NotAllowed = 'NotAllowed'
}

export function FieldRequireTypesFromJSON(json: any): FieldRequireTypes {
    return FieldRequireTypesFromJSONTyped(json, false);
}

export function FieldRequireTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldRequireTypes {
    return json as FieldRequireTypes;
}

export function FieldRequireTypesToJSON(value?: FieldRequireTypes | null): any {
    return value as any;
}

