/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignFailuresToContractorCommand
 */
export interface AssignFailuresToContractorCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignFailuresToContractorCommand
     */
    failureIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignFailuresToContractorCommand
     */
    contractorId: string;
}

export function AssignFailuresToContractorCommandFromJSON(json: any): AssignFailuresToContractorCommand {
    return AssignFailuresToContractorCommandFromJSONTyped(json, false);
}

export function AssignFailuresToContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignFailuresToContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureIds': json['failureIds'],
        'contractorId': json['contractorId'],
    };
}

export function AssignFailuresToContractorCommandToJSON(value?: AssignFailuresToContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureIds': value.failureIds,
        'contractorId': value.contractorId,
    };
}


