/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckInvitationVm
 */
export interface CheckInvitationVm {
    /**
     * 
     * @type {boolean}
     * @memberof CheckInvitationVm
     */
    canRegister?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckInvitationVm
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckInvitationVm
     */
    orgName?: string | null;
}

export function CheckInvitationVmFromJSON(json: any): CheckInvitationVm {
    return CheckInvitationVmFromJSONTyped(json, false);
}

export function CheckInvitationVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckInvitationVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canRegister': !exists(json, 'canRegister') ? undefined : json['canRegister'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
    };
}

export function CheckInvitationVmToJSON(value?: CheckInvitationVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canRegister': value.canRegister,
        'userName': value.userName,
        'orgName': value.orgName,
    };
}


