import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseTownsListColumnTitles {
  columnTitles: string[];
}

const useTownsListColumnTitles = (): UseTownsListColumnTitles => {
  const { t } = useTranslation();

  const columnTitles = useMemo(
    () => [
      t("town.name"),
      t("town.municipalityName"),
      t("town.municipalityAddress.zip"),
      t("town.municipalityAddress.city"),
      t("town.municipalityAddress.street"),
      t("town.municipalityAddress.number"),
      t("town.email"),
    ],
    [t]
  );

  return {
    columnTitles,
  };
};

export default useTownsListColumnTitles;
