import { createModel } from "@rematch/core";
import { api } from "api";
import { ContractorVm, DetailedDynamicDeviceVm } from "api/generated/lumen";
import { RootModel } from "app/store";

interface MaintainedDevicesViewState {
  device?: DetailedDynamicDeviceVm;
  assignedContractors: ContractorVm[];
}

const initialState: MaintainedDevicesViewState = {
  device: undefined,
  assignedContractors: [],
};

export const maintainedDevicesView = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setDevice(state, data: DetailedDynamicDeviceVm) {
      state.device = data;
    },
    setAssignedContractors(state, data: ContractorVm[]) {
      state.assignedContractors = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { maintainedDevicesView } = dispatch;

    return {
      async fetchDevice(id: string): Promise<void> {
        const data = await api.lumen.devices.apiDevicesIdGet({ id });

        dispatch(maintainedDevicesView.setDevice(data));
      },

      async fetchAssignedContractors(id: string): Promise<void> {
        const data =
          await api.lumen.devices.apiDevicesGetDeviceContractorsidGet({ id });

        dispatch(maintainedDevicesView.setAssignedContractors(data));
      },

      async detachContractor({
        deviceToDetachId,
        contractorToDetachId,
      }: {
        deviceToDetachId: string;
        contractorToDetachId: string;
      }): Promise<void> {
        return api.lumen.devices.apiDevicesDetachDeviceFromContractorDelete({
          detachDeviceFromContractorCommand: {
            contractorToDetachId,
            deviceToDetachId,
          },
        });
      },
    };
  },
});
