/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionVm,
    PermissionVmFromJSON,
    PermissionVmFromJSONTyped,
    PermissionVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleVm
 */
export interface RoleVm {
    /**
     * 
     * @type {string}
     * @memberof RoleVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleVm
     */
    description?: string | null;
    /**
     * 
     * @type {Array<PermissionVm>}
     * @memberof RoleVm
     */
    permissions?: Array<PermissionVm> | null;
}

export function RoleVmFromJSON(json: any): RoleVm {
    return RoleVmFromJSONTyped(json, false);
}

export function RoleVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionVmFromJSON)),
    };
}

export function RoleVmToJSON(value?: RoleVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionVmToJSON)),
    };
}


