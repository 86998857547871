/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkbookTaskItemDto
 */
export interface WorkbookTaskItemDto {
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItemDto
     */
    workbookTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItemDto
     */
    inventoryId: string;
    /**
     * 
     * @type {number}
     * @memberof WorkbookTaskItemDto
     */
    itemCount?: number;
}

export function WorkbookTaskItemDtoFromJSON(json: any): WorkbookTaskItemDto {
    return WorkbookTaskItemDtoFromJSONTyped(json, false);
}

export function WorkbookTaskItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbookTaskItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workbookTaskId': json['workbookTaskId'],
        'inventoryId': json['inventoryId'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
    };
}

export function WorkbookTaskItemDtoToJSON(value?: WorkbookTaskItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workbookTaskId': value.workbookTaskId,
        'inventoryId': value.inventoryId,
        'itemCount': value.itemCount,
    };
}


