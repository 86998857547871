import { ColumnFilterItem } from "antd/lib/table/interface";
import {
  DictionaryVm,
  FilterLogicalOperator,
  GetAllFailuresQuery,
  OrderByType as OrderByTypeEnum,
} from "api/generated/lumen";
import { ItemProps } from "models/common";
import {
  ListRequestParams,
  Order,
  OrderByType,
} from "widgets/table/useTableUtils";

const orderByTypes: { [key in OrderByType]: any } = {
  Ascending: OrderByTypeEnum.Ascend,
  Descending: OrderByTypeEnum.Descend,
};

export const mapToGetAllQuery = (
  values: ListRequestParams
): GetAllFailuresQuery => ({
  filterLogicalOperator: FilterLogicalOperator.And,
  filterKeys: values.filter,
  sortings: values.order?.map((item) => ({
    orderBy: item.orderBy,
    orderByType: item.orderByType ? orderByTypes[item.orderByType] : undefined,
  })),
  page: values.page,
  pageSize: values.pageSize,
});

export function calculateFilterRequestParams<T = any>(
  requestParams: ListRequestParams,
  valueFormatters?: { [K in keyof T]?: (value: T[K]) => any }
): object {
  const filterObjectWithoutUndefineds = Object.entries(
    requestParams.filter || {}
  )
    .filter(([key, value]) => typeof value !== undefined || value !== null)
    .reduce<any>((previousValue, [currentKey, currentValue]) => {
      const valueFormatter = valueFormatters?.[currentKey as keyof T];

      previousValue[currentKey] = valueFormatter
        ? valueFormatter(currentValue)
        : currentValue;

      return previousValue;
    }, {});

  return filterObjectWithoutUndefineds;
}

export function formatSortingRequestParams(
  requestParams: ListRequestParams,
  keyFormatters?: { [key: string]: string }
): Order[] | undefined {
  return requestParams.order?.map((item) => {
    const keyFormatter = keyFormatters?.[item.orderBy];

    return {
      orderBy: keyFormatter || item.orderBy,
      orderByType: item.orderByType,
    };
  });
}

// Dictionary items doesn't get translated
export const getDictionaryItemLabel = (dictionary: DictionaryVm): string =>
  dictionary.name || "";

export const dictionaryOptions = (
  value: DictionaryVm[],
  disableOption: (option: any) => boolean = (_: any) => false,
  valuePropertyName = "id"
): ItemProps[] =>
  value
    .map((item) => {
      let itemValue: any;
      if (valuePropertyName === "id") {
        itemValue = item.id;
      } else {
        type ObjectKey = keyof typeof item;

        const propertyName = valuePropertyName as ObjectKey;
        itemValue = item[propertyName];
      }

      return {
        label: getDictionaryItemLabel(item),
        value: itemValue || "",
        disabled: disableOption(item),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

export const dictionaryFilters = (
  value: DictionaryVm[]
): ColumnFilterItem[] | undefined =>
  value
    .map((item) => ({
      text: getDictionaryItemLabel(item),
      value: item.value || "",
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

export const filterOptionsLabelFromBeginning = (
  inputValue: string,
  option: ItemProps | undefined
) => {
  return inputValue && option
    ? option?.label.toUpperCase().startsWith(inputValue.toUpperCase())
    : true;
};
