/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    FailureTaskDto,
    FailureTaskDtoFromJSON,
    FailureTaskDtoToJSON,
    FailureTaskVmPaginatedSearchResponse,
    FailureTaskVmPaginatedSearchResponseFromJSON,
    FailureTaskVmPaginatedSearchResponseToJSON,
    GetAllFailureTasksQuery,
    GetAllFailureTasksQueryFromJSON,
    GetAllFailureTasksQueryToJSON,
} from '../models';

export interface ApiFailureTasksFailureTaskIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    failureTaskDto?: FailureTaskDto;
}

export interface ApiFailureTasksFailureTaskPostRequest {
    xLUMENTransactionGuid?: string;
    failureTaskDto?: FailureTaskDto;
}

export interface ApiFailureTasksPostRequest {
    xLUMENTransactionGuid?: string;
    getAllFailureTasksQuery?: GetAllFailureTasksQuery;
}

/**
 * 
 */
export class FailureTasksApi extends runtime.BaseAPI {

    /**
     * Update a failure task
     */
    async apiFailureTasksFailureTaskIdPutRaw(requestParameters: ApiFailureTasksFailureTaskIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailureTasksFailureTaskIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/FailureTasks/FailureTask/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FailureTaskDtoToJSON(requestParameters.failureTaskDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a failure task
     */
    async apiFailureTasksFailureTaskIdPut(requestParameters: ApiFailureTasksFailureTaskIdPutRequest): Promise<void> {
        await this.apiFailureTasksFailureTaskIdPutRaw(requestParameters);
    }

    /**
     * Create a failure task
     */
    async apiFailureTasksFailureTaskPostRaw(requestParameters: ApiFailureTasksFailureTaskPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/FailureTasks/FailureTask`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FailureTaskDtoToJSON(requestParameters.failureTaskDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a failure task
     */
    async apiFailureTasksFailureTaskPost(requestParameters: ApiFailureTasksFailureTaskPostRequest): Promise<string> {
        const response = await this.apiFailureTasksFailureTaskPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all failure tasks.
     */
    async apiFailureTasksPostRaw(requestParameters: ApiFailureTasksPostRequest): Promise<runtime.ApiResponse<FailureTaskVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/FailureTasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailureTasksQueryToJSON(requestParameters.getAllFailureTasksQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureTaskVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Get all failure tasks.
     */
    async apiFailureTasksPost(requestParameters: ApiFailureTasksPostRequest): Promise<FailureTaskVmPaginatedSearchResponse> {
        const response = await this.apiFailureTasksPostRaw(requestParameters);
        return await response.value();
    }

}
