import { max, min, pattern, requiredField } from "./formRules";
import OpenApiJSON from "api/docs/lumen.json";
import { TFunction } from "react-i18next";

const schema = OpenApiJSON.components.schemas;

export declare type SchemaType = typeof schema;
export declare type SchemaKey = keyof SchemaType;

export const getSchema = <T extends SchemaKey>(path: T): any => {
  return (schema as Record<string, any>)[path];
};

const getField = (props: any, fieldName: string): any => {
  const field = props?.properties?.[fieldName];

  // follow $ref
  if (field?.$ref) {
    const dtoName = field?.$ref.split("/").pop();
    const props = getSchema(dtoName);
    return getField(props, fieldName);
  }

  return field;
};

export const getAllRequired = <T extends SchemaKey>(path: T): string[] => {
  const props = getSchema(path);
  return props?.required
    ?.map((fieldName: string) => {
      const $ref = props?.properties?.[fieldName]?.$ref;

      // if required pointing to a $ref, follow and collect those required field with dot notation name
      if ($ref) {
        const dtoName = $ref.split("/").pop();
        return getAllRequired(dtoName).map((name) => `${fieldName}.${name}`);
      }

      return fieldName;
    })
    ?.flat();
};

export const getSchemaRules = <T extends SchemaKey>(
  t: TFunction,
  path: T,
  name: string,
  label: string
): any => {
  const props = getSchema(path);
  const allRequired = getAllRequired(path);
  const fieldName = Array.isArray(name) ? name.join(".") : name;
  const field = getField(props, fieldName);
  const required = Array.isArray(name)
    ? allRequired?.includes(name.join("."))
    : allRequired?.includes(name);

  const regexp = field?.pattern && new RegExp(field.pattern);
  const isDateFormat = field?.format === "date-time";

  // length & value
  const minLength =
    !isDateFormat && field?.minLength && min(t, field.minLength, "string");
  const minValue = field?.minimum && min(t, field.minimum, "number");
  const maxLength =
    !isDateFormat && field?.maxLength && max(t, field.maxLength, "string");
  const maxValue =
    field?.maximum &&
    Math.min(field?.maximum, Number.MAX_SAFE_INTEGER) &&
    max(t, field.maximum, "number");

  const rules = [
    required && requiredField(t, label),
    regexp && pattern(t, regexp),
    minLength,
    minValue,
    maxLength,
    maxValue,
  ].filter(Boolean);

  return rules;
};
