import { mdiEye, mdiLinkVariantOff } from "@mdi/js";
import { Modal, Space } from "antd";
import { ContractorVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ColumnConfigParams } from "widgets/table/useTableUtils";

const { confirm } = Modal;

const ContractorsTab: React.FC = () => {
  const { t } = useTranslation();
  const { specialPlace, assignedContractors } = useSelector(
    (state) => state.specialPlacesView
  );
  const { fetchAssignedContractors } = useSelector(
    (state) => state.loading.effects.specialPlacesView
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columnParams = useMemo<ColumnConfigParams<ContractorVm>[]>(
    () => [
      {
        key: "name",
        sort: false,
        title: t("contractor.name"),
      },
    ],
    [t]
  );

  const handleDetachButtonClick = useCallback(
    async (id?: string) => {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("contractor.confirmDeatachSpecialPlaceModal.title"),
        onOk: async () => {
          if (typeof specialPlace?.id !== "string" || typeof id !== "string") {
            return;
          }

          await dispatch.specialPlacesView.detachContractor({
            contractorToDetachId: id,
            specialPlaceToDetachId: specialPlace.id,
          });

          dispatch.specialPlacesView.fetchAssignedContractors(specialPlace.id);
        },
      });
    },
    [dispatch.specialPlacesView, specialPlace?.id, t]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/contractors/${id}`);
    },
    [navigate]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<ContractorVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiLinkVariantOff}
              onClick={() => handleDetachButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDetachButtonClick, handleViewButtonClick, t]
  );

  const customTableProps = useDumbTable({
    columnParams,
    list: assignedContractors,
    actionColumnParams,
    loading: fetchAssignedContractors.loading,
  });

  useEffect(() => {
    if (typeof specialPlace?.id !== "string") {
      return;
    }

    dispatch.specialPlacesView.fetchAssignedContractors(specialPlace?.id);

    return () => {
      dispatch.specialPlacesView.setAssignedContractors([]);
    };
  }, [dispatch.specialPlacesView, specialPlace?.id]);

  return (
    <CustomTable<ContractorVm>
      {...customTableProps}
      selectable={false}
      scroll={{ x: 1000 }}
    />
  );
};

export default ContractorsTab;
