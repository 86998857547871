import useAuth from "features/auth/useAuth";
import React from "react";
import FailureViewDefault from "./default/FailureViewDefault";
import FailureViewRepairer from "./repairer/FailureViewRepairer";

const FailureViewHub: React.FC = () => {
  const { hasRole } = useAuth();

  return hasRole(["Repairman"]) ? (
    <FailureViewRepairer />
  ) : (
    <FailureViewDefault />
  );
};

export default FailureViewHub;
