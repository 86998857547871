import { Rule } from "antd/lib/form";
import { ConfigAllValidationRulesVm } from "api/generated/lumen";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useConfigurationQuery from "./queries/useConfigurationQuery";

const currentBrand = process.env.REACT_APP_CURRENT_BRAND;

const useConfigurationValidationRules = (
  configValidationKey: keyof ConfigAllValidationRulesVm
): Rule[] => {
  const query = useConfigurationQuery();
  const { t } = useTranslation();

  const pattern = query.data?.rules?.validation?.[configValidationKey];

  const rule = useMemo<Rule | undefined>(
    () =>
      pattern && pattern !== ""
        ? {
            pattern: new RegExp(pattern),
            message: t(
              `config.errorMessage.${configValidationKey}.${currentBrand}` // Note: translation key string concatenation is acceptable here, because it gets generated in config-validation-translation-keys.ts file
            ),
          }
        : undefined,
    [configValidationKey, pattern, t]
  );

  return rule ? [rule] : [];
};

export default useConfigurationValidationRules;
