import DeviceSelect from "components/form/DeviceSelect";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import { FieldsetProps } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField } from "widgets/form";

const FailureDeviceFieldset: React.FC<FieldsetProps> = (props) => {
  const { t } = useTranslation();

  return (
    <fieldset {...props}>
      <legend>{t("failure.deviceFieldset")}</legend>
      <FieldsetFieldsWrapper>
        <GrapeAntdForm.Item
          label={t("failure.device")}
          name="deviceId"
          rules={[requiredField(t)]}
        >
          <DeviceSelect placeholder={t("failure.devicePlaceholder")} />
        </GrapeAntdForm.Item>
      </FieldsetFieldsWrapper>
    </fieldset>
  );
};

export default FailureDeviceFieldset;
