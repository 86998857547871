/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameVm
 */
export interface NameVm {
    /**
     * 
     * @type {string}
     * @memberof NameVm
     */
    forename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameVm
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameVm
     */
    familyname?: string | null;
}

export function NameVmFromJSON(json: any): NameVm {
    return NameVmFromJSONTyped(json, false);
}

export function NameVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
    };
}

export function NameVmToJSON(value?: NameVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'forename': value.forename,
        'middlename': value.middlename,
        'familyname': value.familyname,
    };
}


