/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FailureTypeVm,
    FailureTypeVmFromJSON,
    FailureTypeVmFromJSONTyped,
    FailureTypeVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface SlaVm
 */
export interface SlaVm {
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SlaVm
     */
    deadlineDays?: number;
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    optimalDeadlineDaysId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    slaCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    townId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlaVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof SlaVm
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SlaVm
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SlaVm
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Array<FailureTypeVm>}
     * @memberof SlaVm
     */
    failureTypes?: Array<FailureTypeVm> | null;
}

export function SlaVmFromJSON(json: any): SlaVm {
    return SlaVmFromJSONTyped(json, false);
}

export function SlaVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlaVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deadlineDays': !exists(json, 'deadlineDays') ? undefined : json['deadlineDays'],
        'optimalDeadlineDaysId': !exists(json, 'optimalDeadlineDaysId') ? undefined : json['optimalDeadlineDaysId'],
        'slaCategoryId': !exists(json, 'slaCategoryId') ? undefined : json['slaCategoryId'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'failureTypes': !exists(json, 'failureTypes') ? undefined : (json['failureTypes'] === null ? null : (json['failureTypes'] as Array<any>).map(FailureTypeVmFromJSON)),
    };
}

export function SlaVmToJSON(value?: SlaVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'deadlineDays': value.deadlineDays,
        'optimalDeadlineDaysId': value.optimalDeadlineDaysId,
        'slaCategoryId': value.slaCategoryId,
        'townId': value.townId,
        'isDeleted': value.isDeleted,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'failureTypes': value.failureTypes === undefined ? undefined : (value.failureTypes === null ? null : (value.failureTypes as Array<any>).map(FailureTypeVmToJSON)),
    };
}


