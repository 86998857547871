/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateContactPersonCommand
 */
export interface CreateContactPersonCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    townId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    familyname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    forename?: string | null;
    /**
     * 
     * @type {PhoneDto}
     * @memberof CreateContactPersonCommand
     */
    phone?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    contractorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    userRoleName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactPersonCommand
     */
    shouldInvite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContactPersonCommand
     */
    orgName?: string | null;
}

export function CreateContactPersonCommandFromJSON(json: any): CreateContactPersonCommand {
    return CreateContactPersonCommandFromJSONTyped(json, false);
}

export function CreateContactPersonCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContactPersonCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'familyname': json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'phone': !exists(json, 'phone') ? undefined : PhoneDtoFromJSON(json['phone']),
        'email': json['email'],
        'contractorId': !exists(json, 'contractorId') ? undefined : json['contractorId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userRoleName': !exists(json, 'userRoleName') ? undefined : json['userRoleName'],
        'shouldInvite': !exists(json, 'shouldInvite') ? undefined : json['shouldInvite'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
    };
}

export function CreateContactPersonCommandToJSON(value?: CreateContactPersonCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'townId': value.townId,
        'id': value.id,
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'phone': PhoneDtoToJSON(value.phone),
        'email': value.email,
        'contractorId': value.contractorId,
        'userId': value.userId,
        'userRoleName': value.userRoleName,
        'shouldInvite': value.shouldInvite,
        'orgName': value.orgName,
    };
}


