/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderByType,
    OrderByTypeFromJSON,
    OrderByTypeFromJSONTyped,
    OrderByTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SortingDto
 */
export interface SortingDto {
    /**
     * 
     * @type {string}
     * @memberof SortingDto
     */
    orderBy?: string | null;
    /**
     * 
     * @type {OrderByType}
     * @memberof SortingDto
     */
    orderByType?: OrderByType;
}

export function SortingDtoFromJSON(json: any): SortingDto {
    return SortingDtoFromJSONTyped(json, false);
}

export function SortingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderBy': !exists(json, 'orderBy') ? undefined : json['orderBy'],
        'orderByType': !exists(json, 'orderByType') ? undefined : OrderByTypeFromJSON(json['orderByType']),
    };
}

export function SortingDtoToJSON(value?: SortingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderBy': value.orderBy,
        'orderByType': OrderByTypeToJSON(value.orderByType),
    };
}


