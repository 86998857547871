/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFailureTypeCommand
 */
export interface CreateFailureTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateFailureTypeCommand
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateFailureTypeCommand
     */
    taskIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFailureTypeCommand
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFailureTypeCommand
     */
    specialCase?: boolean | null;
}

export function CreateFailureTypeCommandFromJSON(json: any): CreateFailureTypeCommand {
    return CreateFailureTypeCommandFromJSONTyped(json, false);
}

export function CreateFailureTypeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFailureTypeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'taskIds': !exists(json, 'taskIds') ? undefined : json['taskIds'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'specialCase': !exists(json, 'specialCase') ? undefined : json['specialCase'],
    };
}

export function CreateFailureTypeCommandToJSON(value?: CreateFailureTypeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'taskIds': value.taskIds,
        'description': value.description,
        'specialCase': value.specialCase,
    };
}


