import { mdiCog } from "@mdi/js";
import { Dropdown } from "antd";
import IconButton from "components/ui/IconButton";
import { ReactElement } from "react";
import TableSettingsLayout from "./TableSettingsLayout";
import { TableSettingsUtils } from "./useTableSettingsUtils";

export const TableSettingsDropdown: <T>(
  p: TableSettingsUtils<T>
) => ReactElement<TableSettingsUtils<T>> = (props) => {
  // TODO: antd 4.23 and above Dropdown has property name change on visible/onVisibleChange to open/onOpenChange
  return (
    <Dropdown
      overlay={<TableSettingsLayout {...props} />}
      trigger={["click"]}
      visible={props.visible}
      onVisibleChange={props.handleChangeVisibility}
      placement="bottomRight"
      arrow
    >
      <IconButton path={mdiCog} variant="alternative" />
    </Dropdown>
  );
};
