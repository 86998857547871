import { Divider, Spin, Tabs, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { mdiPencil } from "@mdi/js";
import IconButton from "components/ui/IconButton";
import styles from "./FailureViewDefault.module.scss";
import StretchToCardEdge from "components/StretchToCardEdge";
import FailureViewReportData from "./components/FailureViewReportData";
import FailureViewHistoryData from "./components/FailureViewHistoryData";
import FailureReporterData from "./components/FailureReporterData";
import FailureAttachedTab from "./components/tabs/FailureAttachedTab";
import FailureWorkbookTasksTab from "./components/tabs/FailureWorkbookTasksTab";
import StatusTag from "features/failures/list/components/StatusTag";
import TabsWrapper from "components/ui/TabsWrapper";
import CardContent from "components/ui/CardContent";
import CardTitle from "components/ui/CardTitle";

const FailureViewDefault: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { failure } = useSelector((state) => state.failureView);
  const { isSaved: isFailureCreateModalSaved } = useSelector(
    (state) => state.failureCreate
  );
  const { fetchFailure } = useSelector(
    (state) => state.loading.effects.failureView
  );

  const location = useLocation();

  const handleEditButtonClick = useCallback(() => {
    dispatch.failureCreate.setIsVisible(true);
  }, [dispatch.failureCreate]);

  const failureTitle = useMemo(
    () => (failure ? `${failure.identifier} - ${failure.type?.name}` : ""),
    [failure]
  );

  const fetchFailureData = useCallback(() => {
    if (typeof params.id === "string") {
      dispatch.failureView.fetchFailure(params.id);
    }
  }, [dispatch.failureView, params.id]);

  const fetchAttachedFailuresData = useCallback(() => {
    if (typeof params.id === "string") {
      dispatch.failureView.fetchAttachedFailures(params.id);
    }
  }, [dispatch.failureView, params.id]);

  useEffect(() => {
    if (isFailureCreateModalSaved) {
      fetchFailureData();
      fetchAttachedFailuresData();
    }
  }, [fetchFailureData, fetchAttachedFailuresData, isFailureCreateModalSaved]);

  useEffect(() => {
    fetchFailureData();
  }, [fetchFailureData]);

  useEffect(() => {
    dispatch.common.setBreadcrumbs([
      {
        title: t("menu.failures"),
        to: `/failures${location.state?.failuresListPageSearchParams || ""}`,
      },
      {
        title: failureTitle,
      },
    ]);

    return () => {
      dispatch.common.setBreadcrumbs([]);
    };
  }, [
    dispatch.common,
    failureTitle,
    location.state?.failuresListPageSearchParams,
    t,
  ]);

  useEffect(() => {
    return () => {
      dispatch.failureView.reset();
    };
  }, [dispatch.failureView]);

  return (
    <>
      <CardTitle>
        <Typography.Title level={2} className={styles.headerTitle}>
          {failureTitle}
        </Typography.Title>
        <div>
          {failure?.status && <StatusTag status={failure?.status} />}
          {!failure?.status?.isSealed && (
            <IconButton
              path={mdiPencil}
              onClick={() => handleEditButtonClick()}
            />
          )}
        </div>
      </CardTitle>

      <CardContent>
        <Spin spinning={fetchFailure.loading}>
          <FailureViewReportData failure={failure} />
          <Divider />
          <FailureViewHistoryData failure={failure} />
          <Divider />
          <FailureReporterData failure={failure} />

          <TabsWrapper extraMargin>
            <StretchToCardEdge>
              <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane
                type="card"
                size="small"
              >
                <Tabs.TabPane tab={t("failure.tabs.attachedFailures")} key="1">
                  <FailureAttachedTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("failure.tabs.workbookTasks")} key="2">
                  <FailureWorkbookTasksTab />
                </Tabs.TabPane>
              </Tabs>
            </StretchToCardEdge>
          </TabsWrapper>
        </Spin>
      </CardContent>
    </>
  );
};

export default FailureViewDefault;
