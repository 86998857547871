import { Modal, ModalProps } from "antd";
import React from "react";

type MobileModalProps = Omit<ModalProps, "footer">;

const MobileModal: React.FC<MobileModalProps> = (props) => {
  return <Modal width={650} {...props} footer={null} />;
};

export default MobileModal;
