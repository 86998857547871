import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { OrderByType } from "api/generated/lumen";
import { MILISECONDS_24H } from "models/constants";
import { queryKeys } from ".";

const useFailureStatusesQuery = (onlySealed?: boolean) => {
  const query = useQuery(
    queryKeys.failures.isSealed(onlySealed),
    async () => {
      const data = await api.lumen.failures.apiFailuresGetFailureStatuesesPost({
        getFailureStatuesesQuery: {
          pageSize: -1,
          filterKeys: onlySealed
            ? {
                isSealed: true,
              }
            : {},
          sortings: [
            {
              orderByType: OrderByType.Ascend,
              orderBy: "name",
            },
          ],
        },
      });

      return data.items || [];
    },
    {
      cacheTime: MILISECONDS_24H,
      staleTime: MILISECONDS_24H,
    }
  );

  return query;
};

export default useFailureStatusesQuery;
