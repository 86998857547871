import { mdiInbox } from "@mdi/js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Col, Modal, Row, Space, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { api } from "api";
import clsx from "clsx";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import { queryKeys } from "hooks/queries";
import useFileInput from "hooks/useFileInput";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import styles from "./StreetImportTab.module.scss";

export interface StreetImportFormValues {
  file: UploadFile[];
}

const StreetImportTab = () => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<StreetImportFormValues>();
  const { dummyRequest, normFile } = useFileInput();
  const streetImportMutation = useMutation((values: StreetImportFormValues) =>
    api.lumen.maintenance.apiMaintenanceStreetImportPost({
      excelFile: values.file[0].originFileObj,
    })
  );
  const queryClient = useQueryClient();

  const handleFinish = useCallback(
    async (values: StreetImportFormValues): Promise<void> => {
      try {
        const data = await streetImportMutation.mutateAsync(values);

        queryClient.invalidateQueries(queryKeys.streets._def); // invalid all queries starting with "street"

        Modal.success({
          content: t("dataImport.streetImport.successModal.description", {
            editedRowsCount: data,
          }),
          title: t("dataImport.streetImport.successModal.title"),
          okText: t("common.ok"),
        });

        formUtils.form.resetFields();
      } catch (error: any) {
        if (error.message === "Failed to fetch") {
          Modal.error({
            title: t("error.dataImport.commonError.title"),
            okText: t("common.ok"),
          });
        }
      }
    },
    [formUtils.form, queryClient, streetImportMutation, t]
  );

  return (
    <Row justify="center">
      <Col xl={10} lg={12} span={24}>
        <CustomForm formUtils={formUtils} onFinish={handleFinish}>
          <Space direction="vertical" size="large" className={styles.space}>
            <Alert
              message={t("common.importantNote")}
              description={t("dataImport.streetImport.description")}
              type="info"
              showIcon
            />

            <div>
              <GrapeAntdForm.Item
                name="file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[requiredField(t, undefined, { type: "array" })]}
              >
                <Upload
                  customRequest={dummyRequest}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                    showPreviewIcon: false,
                  }}
                  accept=".xls,.xlsx"
                >
                  <Space
                    direction="vertical"
                    className={clsx(styles.space, styles.uploadSpace)}
                  >
                    <MaterialIcon
                      path={mdiInbox}
                      className={styles.uploadIcon}
                    />
                    <div>{t("dataImport.streetImport.addNewFileText")}</div>
                  </Space>
                </Upload>
              </GrapeAntdForm.Item>

              <Button
                type="primary"
                block
                size="large"
                loading={streetImportMutation.isLoading}
                htmlType="submit"
              >
                {t("dataImport.streetImport.submitButtonText")}
              </Button>
            </div>
          </Space>
        </CustomForm>
      </Col>
    </Row>
  );
};

export default StreetImportTab;
