/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeadlineStatusType,
    DeadlineStatusTypeFromJSON,
    DeadlineStatusTypeFromJSONTyped,
    DeadlineStatusTypeToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    RegistrationStates,
    RegistrationStatesFromJSON,
    RegistrationStatesFromJSONTyped,
    RegistrationStatesToJSON,
    SimpleContractorVm,
    SimpleContractorVmFromJSON,
    SimpleContractorVmFromJSONTyped,
    SimpleContractorVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface RepairmanListItemVm
 */
export interface RepairmanListItemVm {
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    forename?: string | null;
    /**
     * 
     * @type {PhoneVm}
     * @memberof RepairmanListItemVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanListItemVm
     */
    userId?: string | null;
    /**
     * 
     * @type {SimpleContractorVm}
     * @memberof RepairmanListItemVm
     */
    contractor?: SimpleContractorVm;
    /**
     * 
     * @type {boolean}
     * @memberof RepairmanListItemVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {DeadlineStatusType}
     * @memberof RepairmanListItemVm
     */
    deadlineStatus?: DeadlineStatusType;
    /**
     * 
     * @type {RegistrationStates}
     * @memberof RepairmanListItemVm
     */
    registrationState?: RegistrationStates;
}

export function RepairmanListItemVmFromJSON(json: any): RepairmanListItemVm {
    return RepairmanListItemVmFromJSONTyped(json, false);
}

export function RepairmanListItemVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairmanListItemVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'contractor': !exists(json, 'contractor') ? undefined : SimpleContractorVmFromJSON(json['contractor']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'deadlineStatus': !exists(json, 'deadlineStatus') ? undefined : DeadlineStatusTypeFromJSON(json['deadlineStatus']),
        'registrationState': !exists(json, 'registrationState') ? undefined : RegistrationStatesFromJSON(json['registrationState']),
    };
}

export function RepairmanListItemVmToJSON(value?: RepairmanListItemVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'phone': PhoneVmToJSON(value.phone),
        'email': value.email,
        'userId': value.userId,
        'contractor': SimpleContractorVmToJSON(value.contractor),
        'isActive': value.isActive,
        'deadlineStatus': DeadlineStatusTypeToJSON(value.deadlineStatus),
        'registrationState': RegistrationStatesToJSON(value.registrationState),
    };
}


