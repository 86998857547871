import { createModel } from "@rematch/core";
import { api } from "api";
import { ContractorVm, DeviceVm, SpecialPlaceVm } from "api/generated/lumen";
import { RootModel } from "app/store";

interface SpecialPlacesViewState {
  specialPlace?: SpecialPlaceVm;
  assignedContractors: ContractorVm[];
  assignedMaintainedDevices: DeviceVm[];
}

const initialState: SpecialPlacesViewState = {
  specialPlace: undefined,
  assignedContractors: [],
  assignedMaintainedDevices: [],
};

export const specialPlacesView = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSpecialPlace(state, data: SpecialPlaceVm) {
      state.specialPlace = data;
    },
    setAssignedContractors(state, data: ContractorVm[]) {
      state.assignedContractors = data;
    },
    setAssignedMaintainedDevices(state, data: DeviceVm[]) {
      state.assignedMaintainedDevices = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { specialPlacesView } = dispatch;

    return {
      async fetchSpecialPlace(id: string): Promise<void> {
        const data = await api.lumen.specialPlaces.apiSpecialPlacesIdGet({
          id,
        });

        dispatch(specialPlacesView.setSpecialPlace(data));
      },

      async fetchAssignedContractors(id: string): Promise<void> {
        const data =
          await api.lumen.specialPlaces.apiSpecialPlacesGetSpecialPlaceContractorsidGet(
            { id }
          );

        dispatch(specialPlacesView.setAssignedContractors(data));
      },

      async detachContractor({
        specialPlaceToDetachId,
        contractorToDetachId,
      }: {
        specialPlaceToDetachId: string;
        contractorToDetachId: string;
      }): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesDetachSpecialPlaceFromContractorDelete(
          {
            detachSpecialPlaceFromContractorCommand: {
              contractorToDetachId,
              specialPlaceToDetachId,
            },
          }
        );
      },

      async fetchAssignedMaintainedDevices(id: string): Promise<void> {
        const data =
          await api.lumen.specialPlaces.apiSpecialPlacesGetSpecialPlaceDevicesidGet(
            { id }
          );

        dispatch(specialPlacesView.setAssignedMaintainedDevices(data));
      },

      async detachMaintainedDevices(deviceIds: string[]): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesDetachSpecialPlaceFromDevicesPut(
          { detachSpecialPlaceFromDevicesCommand: { deviceIds } }
        );
      },

      async detachAllMaintainedDevice(specialPlaceId: string): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPut(
          { detachSpecificSpecialPlaceFromDevicesCommand: { specialPlaceId } }
        );
      },
    };
  },
});
