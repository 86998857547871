/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    FailureType,
    FailureTypeFromJSON,
    FailureTypeFromJSONTyped,
    FailureTypeToJSON,
    Town,
    TownFromJSON,
    TownFromJSONTyped,
    TownToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sla
 */
export interface Sla {
    /**
     * 
     * @type {number}
     * @memberof Sla
     */
    deadlineDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    optimalDeadlineDaysId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    slaCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    townId?: string;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Sla
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Sla
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Sla
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Dictionary}
     * @memberof Sla
     */
    optimalDeadlineDays?: Dictionary;
    /**
     * 
     * @type {Dictionary}
     * @memberof Sla
     */
    slaCategory?: Dictionary;
    /**
     * 
     * @type {Town}
     * @memberof Sla
     */
    town?: Town;
    /**
     * 
     * @type {User}
     * @memberof Sla
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Sla
     */
    modifier?: User;
    /**
     * 
     * @type {Array<FailureType>}
     * @memberof Sla
     */
    failureTypes?: Array<FailureType> | null;
    /**
     * 
     * @type {string}
     * @memberof Sla
     */
    id?: string;
}

export function SlaFromJSON(json: any): Sla {
    return SlaFromJSONTyped(json, false);
}

export function SlaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sla {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deadlineDays': !exists(json, 'deadlineDays') ? undefined : json['deadlineDays'],
        'optimalDeadlineDaysId': !exists(json, 'optimalDeadlineDaysId') ? undefined : json['optimalDeadlineDaysId'],
        'slaCategoryId': !exists(json, 'slaCategoryId') ? undefined : json['slaCategoryId'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'optimalDeadlineDays': !exists(json, 'optimalDeadlineDays') ? undefined : DictionaryFromJSON(json['optimalDeadlineDays']),
        'slaCategory': !exists(json, 'slaCategory') ? undefined : DictionaryFromJSON(json['slaCategory']),
        'town': !exists(json, 'town') ? undefined : TownFromJSON(json['town']),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'failureTypes': !exists(json, 'failureTypes') ? undefined : (json['failureTypes'] === null ? null : (json['failureTypes'] as Array<any>).map(FailureTypeFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function SlaToJSON(value?: Sla | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deadlineDays': value.deadlineDays,
        'optimalDeadlineDaysId': value.optimalDeadlineDaysId,
        'slaCategoryId': value.slaCategoryId,
        'townId': value.townId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'isDeleted': value.isDeleted,
        'optimalDeadlineDays': DictionaryToJSON(value.optimalDeadlineDays),
        'slaCategory': DictionaryToJSON(value.slaCategory),
        'town': TownToJSON(value.town),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'failureTypes': value.failureTypes === undefined ? undefined : (value.failureTypes === null ? null : (value.failureTypes as Array<any>).map(FailureTypeToJSON)),
        'id': value.id,
    };
}


