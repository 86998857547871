/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateInventoryCategoryCommand
 */
export interface CreateInventoryCategoryCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryCategoryCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryCategoryCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryCategoryCommand
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryCategoryCommand
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInventoryCategoryCommand
     */
    isActive?: boolean;
}

export function CreateInventoryCategoryCommandFromJSON(json: any): CreateInventoryCategoryCommand {
    return CreateInventoryCategoryCommandFromJSONTyped(json, false);
}

export function CreateInventoryCategoryCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInventoryCategoryCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function CreateInventoryCategoryCommandToJSON(value?: CreateInventoryCategoryCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'description': value.description,
        'isActive': value.isActive,
    };
}


