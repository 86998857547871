import { useQuery } from "@tanstack/react-query";
import { AutoComplete, AutoCompleteProps } from "antd";
import { api } from "api";
import { queryKeys } from "hooks/queries";
import { ItemProps } from "models/common";
import { MILISECONDS_24H } from "models/constants";
import React, { useMemo } from "react";
import { filterOptionsLabelFromBeginning } from "utils";

interface StreetInputProps extends Omit<AutoCompleteProps, "options"> {
  townId?: string;
}

const StreetInput: React.FC<StreetInputProps> = ({ townId, ...rest }) => {
  const query = useQuery(
    queryKeys.streets.byTownId(townId),
    () =>
      api.lumen.towns.apiTownsGetAllStreetsPost({
        getAllStreetsQuery: {
          pageSize: -1,
          filterKeys: {
            townId,
          },
        },
      }),
    {
      cacheTime: MILISECONDS_24H,
      staleTime: MILISECONDS_24H,
      enabled: !!townId,
    }
  );

  const options = useMemo<ItemProps[]>(
    () =>
      query.data?.items
        ?.map((item) => ({
          label: item.streetName || "",
          value: item.streetName || "",
        }))
        .filter(
          (item, index, self) =>
            self.findIndex((v) => v.value === item.value) === index // TODO: this is a temporary solution for duplicate values. Should be fixed on backend
        )
        .sort((a, b) => a.value.localeCompare(b.value)) || [],
    [query.data]
  );

  return (
    <AutoComplete
      {...rest}
      options={options}
      filterOption={filterOptionsLabelFromBeginning}
    />
  );
};

export default StreetInput;
