import { mdiPencil } from "@mdi/js";
import { Button, Col, Input, ModalProps, Row, Select } from "antd";
import { ExamVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomDatePicker from "components/CustomDatePicker";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import dayjs from "dayjs";
import { ExamCreateFormValues } from "features/repairmen/models";
import useExamTypesQuery from "hooks/queries/useExamTypesQuery";
import useEditFormModal from "hooks/useEditModal";
import { ItemProps } from "models/common";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { DateFormat } from "widgets/table/DateDisplay";

const disabledDate = (current: dayjs.Dayjs): boolean => {
  return current.isBefore(dayjs());
};

const ExamCreateModal: React.FC<ModalProps> = ({
  onCancel,
  onOk,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<ExamCreateFormValues>();
  const { create, edit } = useSelector(
    (state) => state.loading.effects.repairmenExamTab
  );
  const { repairman } = useSelector((state) => state.repairmenView);
  const { list, editingItemId } = useSelector(
    (state) => state.repairmenExamTab
  );
  const dispatch = useDispatch();
  const examTypesQuery = useExamTypesQuery(true);

  const examTypeOptions = useMemo<ItemProps[]>(
    () =>
      (examTypesQuery.data?.items || []).map((type) => ({
        label: type.name || "",
        value: type.id || "",
      })),
    [examTypesQuery.data?.items]
  );

  const getInitialValues = useCallback(
    (itemData: ExamVm): ExamCreateFormValues => {
      return {
        examTypeId:
          itemData.examType?.isActive && itemData.examTypeId
            ? itemData.examTypeId
            : "",
        expirationDate: itemData.expirationDate
          ? dayjs(itemData.expirationDate)
          : undefined,
        id: itemData.id,
      };
    },
    []
  );

  const { formMode, setFormMode } = useEditFormModal<
    ExamVm,
    ExamCreateFormValues
  >({
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.repairmenExamTab.resetEditingItemData,
    itemId: editingItemId,
    list,
  });

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const handleFinish = useCallback(
    async (values: ExamCreateFormValues): Promise<void> => {
      if (!repairman?.id) {
        return;
      }

      if (formMode === "Create") {
        await dispatch.repairmenExamTab.create({
          ...values,
          repairmanId: repairman?.id,
          expirationDate: values.expirationDate
            ? values.expirationDate.toDate()
            : undefined,
        });
      }

      if (formMode === "Edit" && typeof values.id === "string") {
        await dispatch.repairmenExamTab.edit({
          ...values,
          id: values.id,
          repairmanId: repairman?.id,
          expirationDate: values.expirationDate
            ? values.expirationDate.toDate()
            : undefined,
        });
      }

      dispatch.repairmenExamTab.fetchList(repairman?.id);

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.repairmenExamTab,
      formMode,
      formUtils.form,
      onCancel,
      repairman?.id,
    ]
  );

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("exam.createModal.title")
          : t("exam.editModal.title")
      }
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: create.loading || edit.loading }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
      visible={visible}
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        {formMode === "View" && (
          <Row justify="end">
            <Button
              icon={<MaterialIcon path={mdiPencil} />}
              onClick={() => setFormMode("Edit")}
            >
              {t("common.edit")}
            </Button>
          </Row>
        )}

        <Row justify="center">
          <Col span={24}>
            <GrapeAntdForm.Item
              name="examTypeId"
              label={t("exam.examType")}
              rules={[requiredField(t)]}
            >
              <Select
                placeholder={t("exam.examTypePlaceholder")}
                size="large"
                options={examTypeOptions}
                loading={examTypesQuery.isLoading}
                showSearch
                optionFilterProp="label"
                filterOption={filterOptionsLabelFromBeginning}
              />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={24}>
            <GrapeAntdForm.Item
              name="expirationDate"
              label={t("exam.expirationDate")}
            >
              <CustomDatePicker
                size="large"
                format={DateFormat.Date}
                disabledDate={disabledDate}
              />
            </GrapeAntdForm.Item>
          </Col>
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default ExamCreateModal;
