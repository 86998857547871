/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExamTypeCommand,
    CreateExamTypeCommandFromJSON,
    CreateExamTypeCommandToJSON,
    ExamTypeVm,
    ExamTypeVmFromJSON,
    ExamTypeVmToJSON,
    ExamTypeVmPaginatedSearchResponse,
    ExamTypeVmPaginatedSearchResponseFromJSON,
    ExamTypeVmPaginatedSearchResponseToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllExamTypesQuery,
    GetAllExamTypesQueryFromJSON,
    GetAllExamTypesQueryToJSON,
    UpdateExamTypeCommand,
    UpdateExamTypeCommandFromJSON,
    UpdateExamTypeCommandToJSON,
} from '../models';

export interface ApiExamTypesGetAllPostRequest {
    xLUMENTransactionGuid?: string;
    getAllExamTypesQuery?: GetAllExamTypesQuery;
}

export interface ApiExamTypesIdDeleteRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiExamTypesIdGetRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiExamTypesIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateExamTypeCommand?: UpdateExamTypeCommand;
}

export interface ApiExamTypesPostRequest {
    xLUMENTransactionGuid?: string;
    createExamTypeCommand?: CreateExamTypeCommand;
}

/**
 * 
 */
export class ExamTypesApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get exam types. Yes, get by Post.
     */
    async apiExamTypesGetAllPostRaw(requestParameters: ApiExamTypesGetAllPostRequest): Promise<runtime.ApiResponse<ExamTypeVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ExamTypes/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllExamTypesQueryToJSON(requestParameters.getAllExamTypesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamTypeVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get exam types. Yes, get by Post.
     */
    async apiExamTypesGetAllPost(requestParameters: ApiExamTypesGetAllPostRequest): Promise<ExamTypeVmPaginatedSearchResponse> {
        const response = await this.apiExamTypesGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiExamTypesIdDeleteRaw(requestParameters: ApiExamTypesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExamTypesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ExamTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiExamTypesIdDelete(requestParameters: ApiExamTypesIdDeleteRequest): Promise<void> {
        await this.apiExamTypesIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiExamTypesIdGetRaw(requestParameters: ApiExamTypesIdGetRequest): Promise<runtime.ApiResponse<ExamTypeVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExamTypesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ExamTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamTypeVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiExamTypesIdGet(requestParameters: ApiExamTypesIdGetRequest): Promise<ExamTypeVm> {
        const response = await this.apiExamTypesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiExamTypesIdPutRaw(requestParameters: ApiExamTypesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExamTypesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ExamTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateExamTypeCommandToJSON(requestParameters.updateExamTypeCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiExamTypesIdPut(requestParameters: ApiExamTypesIdPutRequest): Promise<void> {
        await this.apiExamTypesIdPutRaw(requestParameters);
    }

    /**
     * Creates an exam type
     */
    async apiExamTypesPostRaw(requestParameters: ApiExamTypesPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ExamTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExamTypeCommandToJSON(requestParameters.createExamTypeCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates an exam type
     */
    async apiExamTypesPost(requestParameters: ApiExamTypesPostRequest): Promise<string> {
        const response = await this.apiExamTypesPostRaw(requestParameters);
        return await response.value();
    }

}
