/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetachDeviceFromContractorCommand
 */
export interface DetachDeviceFromContractorCommand {
    /**
     * 
     * @type {string}
     * @memberof DetachDeviceFromContractorCommand
     */
    deviceToDetachId: string;
    /**
     * 
     * @type {string}
     * @memberof DetachDeviceFromContractorCommand
     */
    contractorToDetachId: string;
}

export function DetachDeviceFromContractorCommandFromJSON(json: any): DetachDeviceFromContractorCommand {
    return DetachDeviceFromContractorCommandFromJSONTyped(json, false);
}

export function DetachDeviceFromContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetachDeviceFromContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceToDetachId': json['deviceToDetachId'],
        'contractorToDetachId': json['contractorToDetachId'],
    };
}

export function DetachDeviceFromContractorCommandToJSON(value?: DetachDeviceFromContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceToDetachId': value.deviceToDetachId,
        'contractorToDetachId': value.contractorToDetachId,
    };
}


