/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    ContactPersonDto,
    ContactPersonDtoFromJSON,
    ContactPersonDtoFromJSONTyped,
    ContactPersonDtoToJSON,
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
    UpdateSlaCommand,
    UpdateSlaCommandFromJSON,
    UpdateSlaCommandFromJSONTyped,
    UpdateSlaCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateTownCommand
 */
export interface UpdateTownCommand {
    /**
     * 
     * @type {Array<ContactPersonDto>}
     * @memberof UpdateTownCommand
     */
    contactPersons?: Array<ContactPersonDto> | null;
    /**
     * 
     * @type {Array<UpdateSlaCommand>}
     * @memberof UpdateTownCommand
     */
    slas?: Array<UpdateSlaCommand> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    municipalityName?: string | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof UpdateTownCommand
     */
    municipalityAddress?: AddressDto;
    /**
     * 
     * @type {PhoneDto}
     * @memberof UpdateTownCommand
     */
    phone?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    sectionActionId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTownCommand
     */
    isActivated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTownCommand
     */
    sectionFailureEmailTo?: string | null;
}

export function UpdateTownCommandFromJSON(json: any): UpdateTownCommand {
    return UpdateTownCommandFromJSONTyped(json, false);
}

export function UpdateTownCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTownCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(ContactPersonDtoFromJSON)),
        'slas': !exists(json, 'slas') ? undefined : (json['slas'] === null ? null : (json['slas'] as Array<any>).map(UpdateSlaCommandFromJSON)),
        'id': json['id'],
        'name': json['name'],
        'municipalityName': !exists(json, 'municipalityName') ? undefined : json['municipalityName'],
        'municipalityAddress': !exists(json, 'municipalityAddress') ? undefined : AddressDtoFromJSON(json['municipalityAddress']),
        'phone': !exists(json, 'phone') ? undefined : PhoneDtoFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'sectionActionId': !exists(json, 'sectionActionId') ? undefined : json['sectionActionId'],
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
        'sectionFailureEmailTo': !exists(json, 'sectionFailureEmailTo') ? undefined : json['sectionFailureEmailTo'],
    };
}

export function UpdateTownCommandToJSON(value?: UpdateTownCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(ContactPersonDtoToJSON)),
        'slas': value.slas === undefined ? undefined : (value.slas === null ? null : (value.slas as Array<any>).map(UpdateSlaCommandToJSON)),
        'id': value.id,
        'name': value.name,
        'municipalityName': value.municipalityName,
        'municipalityAddress': AddressDtoToJSON(value.municipalityAddress),
        'phone': PhoneDtoToJSON(value.phone),
        'email': value.email,
        'sectionActionId': value.sectionActionId,
        'isActivated': value.isActivated,
        'sectionFailureEmailTo': value.sectionFailureEmailTo,
    };
}


