import { createModel } from "@rematch/core";
import { RootModel } from "app/store";
import {
  ListRequestParams,
  mergeListParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { api } from "api";
import { FailureTaskVm } from "api/generated/lumen";
import { mapToGetAllQuery } from "utils";

interface FailureTaskListState {
  listParams: ListRequestParams;
  list: FailureTaskVm[];
}

const initialState: FailureTaskListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "name",
        orderByType: OrderByType.Ascending,
      },
    ],
  },
  list: [],
};

export const failureTaskList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: FailureTaskVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureTaskList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const mergedParams = mergeListParams(
          state.failureTaskList.listParams,
          params
        );

        const data = await api.lumen.failureTask.apiFailureTasksPost({
          getAllFailureTasksQuery: mapToGetAllQuery(mergedParams),
        });

        const { items, ...pagination } = data;

        dispatch(
          failureTaskList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },
    };
  },
});
