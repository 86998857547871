import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SupportedLanguage } from "../models/common";

import { Locale } from "antd/lib/locale-provider";
import huHU from "antd/es/locale/hu_HU";
import enGB from "antd/es/locale/en_GB";

import "dayjs/locale/hu";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

const antDLocales: { [language in SupportedLanguage]: Locale } = {
  hu: huHU,
  en: enGB,
};

const dayJsLocales: { [language in SupportedLanguage]: string } = {
  hu: "hu",
  en: "en-gb",
};

interface UseLanguageConfigurator {
  antDLocale?: Locale;
}

const useLanguageConfigurator = (): UseLanguageConfigurator => {
  const { i18n } = useTranslation();
  const [antDLocale, setAntDLocale] = useState<Locale>();

  useEffect(() => {
    const currentLanguage = i18n.language as SupportedLanguage;

    dayjs.locale(dayJsLocales[currentLanguage]);

    setAntDLocale(antDLocales[currentLanguage]);
  }, [i18n.language]);

  return {
    antDLocale,
  };
};

export default useLanguageConfigurator;
