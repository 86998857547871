import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { OrderByType } from "api/generated/lumen";
import { queryKeys } from ".";

const useExamTypesQuery = (isActive?: boolean) => {
  const query = useQuery(queryKeys.examTypes.all, () =>
    api.lumen.examTypes.apiExamTypesGetAllPost({
      getAllExamTypesQuery: {
        pageSize: -1,
        sortings: [{ orderBy: "name", orderByType: OrderByType.Ascend }],
        filterKeys: {
          isActive: typeof isActive === "boolean" ? isActive : undefined,
        },
      },
    })
  );

  return query;
};

export default useExamTypesQuery;
