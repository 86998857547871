import { useState } from "react";
import { Form, FormInstance } from "antd";
import { getAllRequired, SchemaKey } from "../utils/schema";

export interface FormUtils<Values = any> {
  schema?: SchemaKey;
  form: FormInstance<Values>;
  submittable: boolean;
  required: string[];
  handleFieldsChanged: () => void;
}

export type FormUtilsProps = {
  schema?: SchemaKey;
  requiredFields?: string[];
};

function useGrapeAntdForm<T = any>(
  schema?: SchemaKey,
  requiredFields?: string[]
): FormUtils<T> {
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const schemaRequired = (schema && getAllRequired(schema)) || [];
  const required = [...(requiredFields || []), ...schemaRequired];

  const handleFieldsChanged = (): void => {
    const isAllRequiredFilled = required.every((fieldName: string) => {
      const namePath = fieldName?.split(".");

      if (form.getFieldInstance(namePath)) {
        const value = form.getFieldValue(namePath);
        return typeof value === "string" ? value.trim() : value;
      }

      // optional or conditionally rendered fields
      return true;
    });

    const hasErrors = form
      .getFieldsError()
      .filter(({ errors }) => errors.length).length;
    setSubmittable(isAllRequiredFilled && !hasErrors);
  };

  return {
    form,
    schema,
    required,
    submittable,
    handleFieldsChanged,
  };
}

export default useGrapeAntdForm;
