/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    ContactPersonDto,
    ContactPersonDtoFromJSON,
    ContactPersonDtoFromJSONTyped,
    ContactPersonDtoToJSON,
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateContractorCommand
 */
export interface CreateContractorCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    name: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CreateContractorCommand
     */
    headquarterAddress: AddressDto;
    /**
     * 
     * @type {AddressDto}
     * @memberof CreateContractorCommand
     */
    postalAddress?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    email: string;
    /**
     * 
     * @type {PhoneDto}
     * @memberof CreateContractorCommand
     */
    phone?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    taxnumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    companyregisterNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractorCommand
     */
    statusId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractorCommand
     */
    isInvited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractorCommand
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractorCommand
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<ContactPersonDto>}
     * @memberof CreateContractorCommand
     */
    contactPersons?: Array<ContactPersonDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateContractorCommand
     */
    townIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateContractorCommand
     */
    documentIds?: Array<string> | null;
}

export function CreateContractorCommandFromJSON(json: any): CreateContractorCommand {
    return CreateContractorCommandFromJSONTyped(json, false);
}

export function CreateContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'headquarterAddress': AddressDtoFromJSON(json['headquarterAddress']),
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressDtoFromJSON(json['postalAddress']),
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : PhoneDtoFromJSON(json['phone']),
        'taxnumber': !exists(json, 'taxnumber') ? undefined : json['taxnumber'],
        'companyregisterNumber': !exists(json, 'companyregisterNumber') ? undefined : json['companyregisterNumber'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'isInvited': !exists(json, 'isInvited') ? undefined : json['isInvited'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(ContactPersonDtoFromJSON)),
        'townIds': !exists(json, 'townIds') ? undefined : json['townIds'],
        'documentIds': !exists(json, 'documentIds') ? undefined : json['documentIds'],
    };
}

export function CreateContractorCommandToJSON(value?: CreateContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'headquarterAddress': AddressDtoToJSON(value.headquarterAddress),
        'postalAddress': AddressDtoToJSON(value.postalAddress),
        'email': value.email,
        'phone': PhoneDtoToJSON(value.phone),
        'taxnumber': value.taxnumber,
        'companyregisterNumber': value.companyregisterNumber,
        'statusId': value.statusId,
        'isInvited': value.isInvited,
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(ContactPersonDtoToJSON)),
        'townIds': value.townIds,
        'documentIds': value.documentIds,
    };
}


