/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateWorkbookCommand,
    CreateWorkbookCommandFromJSON,
    CreateWorkbookCommandToJSON,
    CreateWorkbookTaskCommand,
    CreateWorkbookTaskCommandFromJSON,
    CreateWorkbookTaskCommandToJSON,
    CreateWorkbookTaskItemCommand,
    CreateWorkbookTaskItemCommandFromJSON,
    CreateWorkbookTaskItemCommandToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    UpdateWorkbookTaskCommand,
    UpdateWorkbookTaskCommandFromJSON,
    UpdateWorkbookTaskCommandToJSON,
    UpdateWorkbookTaskItemCommand,
    UpdateWorkbookTaskItemCommandFromJSON,
    UpdateWorkbookTaskItemCommandToJSON,
    WorkbookVm,
    WorkbookVmFromJSON,
    WorkbookVmToJSON,
} from '../models';

export interface ApiWorkbooksFailureFailureIdGetRequest {
    failureId: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiWorkbooksIdGetRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiWorkbooksPostRequest {
    xLUMENTransactionGuid?: string;
    createWorkbookCommand?: CreateWorkbookCommand;
}

export interface ApiWorkbooksTaskIdDeleteRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiWorkbooksTaskIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateWorkbookTaskCommand?: UpdateWorkbookTaskCommand;
}

export interface ApiWorkbooksTaskItemIdDeleteRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiWorkbooksTaskItemIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateWorkbookTaskItemCommand?: UpdateWorkbookTaskItemCommand;
}

export interface ApiWorkbooksTaskItemPostRequest {
    xLUMENTransactionGuid?: string;
    createWorkbookTaskItemCommand?: CreateWorkbookTaskItemCommand;
}

export interface ApiWorkbooksTaskPostRequest {
    xLUMENTransactionGuid?: string;
    createWorkbookTaskCommand?: CreateWorkbookTaskCommand;
}

/**
 * 
 */
export class WorkbooksApi extends runtime.BaseAPI {

    /**
     * Gets the requested instance, identified by failureId.
     */
    async apiWorkbooksFailureFailureIdGetRaw(requestParameters: ApiWorkbooksFailureFailureIdGetRequest): Promise<runtime.ApiResponse<WorkbookVm>> {
        if (requestParameters.failureId === null || requestParameters.failureId === undefined) {
            throw new runtime.RequiredError('failureId','Required parameter requestParameters.failureId was null or undefined when calling apiWorkbooksFailureFailureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/failure/{failureId}`.replace(`{${"failureId"}}`, encodeURIComponent(String(requestParameters.failureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkbookVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by failureId.
     */
    async apiWorkbooksFailureFailureIdGet(requestParameters: ApiWorkbooksFailureFailureIdGetRequest): Promise<WorkbookVm> {
        const response = await this.apiWorkbooksFailureFailureIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiWorkbooksIdGetRaw(requestParameters: ApiWorkbooksIdGetRequest): Promise<runtime.ApiResponse<WorkbookVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWorkbooksIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkbookVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiWorkbooksIdGet(requestParameters: ApiWorkbooksIdGetRequest): Promise<WorkbookVm> {
        const response = await this.apiWorkbooksIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a workbook
     */
    async apiWorkbooksPostRaw(requestParameters: ApiWorkbooksPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkbookCommandToJSON(requestParameters.createWorkbookCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a workbook
     */
    async apiWorkbooksPost(requestParameters: ApiWorkbooksPostRequest): Promise<string> {
        const response = await this.apiWorkbooksPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete a WorkbookTask with the specified id
     */
    async apiWorkbooksTaskIdDeleteRaw(requestParameters: ApiWorkbooksTaskIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWorkbooksTaskIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete a WorkbookTask with the specified id
     */
    async apiWorkbooksTaskIdDelete(requestParameters: ApiWorkbooksTaskIdDeleteRequest): Promise<void> {
        await this.apiWorkbooksTaskIdDeleteRaw(requestParameters);
    }

    /**
     * Update a task (a.k.a \"munkaügy\")
     */
    async apiWorkbooksTaskIdPutRaw(requestParameters: ApiWorkbooksTaskIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWorkbooksTaskIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkbookTaskCommandToJSON(requestParameters.updateWorkbookTaskCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a task (a.k.a \"munkaügy\")
     */
    async apiWorkbooksTaskIdPut(requestParameters: ApiWorkbooksTaskIdPutRequest): Promise<void> {
        await this.apiWorkbooksTaskIdPutRaw(requestParameters);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete a WorkbookTaskItem with the specified id
     */
    async apiWorkbooksTaskItemIdDeleteRaw(requestParameters: ApiWorkbooksTaskItemIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWorkbooksTaskItemIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/taskItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete a WorkbookTaskItem with the specified id
     */
    async apiWorkbooksTaskItemIdDelete(requestParameters: ApiWorkbooksTaskItemIdDeleteRequest): Promise<void> {
        await this.apiWorkbooksTaskItemIdDeleteRaw(requestParameters);
    }

    /**
     * Update a workbook task item (aka: Munkaögyhöz rendelt eszköz
     */
    async apiWorkbooksTaskItemIdPutRaw(requestParameters: ApiWorkbooksTaskItemIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWorkbooksTaskItemIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/taskItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkbookTaskItemCommandToJSON(requestParameters.updateWorkbookTaskItemCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a workbook task item (aka: Munkaögyhöz rendelt eszköz
     */
    async apiWorkbooksTaskItemIdPut(requestParameters: ApiWorkbooksTaskItemIdPutRequest): Promise<void> {
        await this.apiWorkbooksTaskItemIdPutRaw(requestParameters);
    }

    /**
     * Associates an inventory item to a workbooktask
     */
    async apiWorkbooksTaskItemPostRaw(requestParameters: ApiWorkbooksTaskItemPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/taskItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkbookTaskItemCommandToJSON(requestParameters.createWorkbookTaskItemCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Associates an inventory item to a workbooktask
     */
    async apiWorkbooksTaskItemPost(requestParameters: ApiWorkbooksTaskItemPostRequest): Promise<string> {
        const response = await this.apiWorkbooksTaskItemPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new task (\"munkaügy\") for an existing workbook
     */
    async apiWorkbooksTaskPostRaw(requestParameters: ApiWorkbooksTaskPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Workbooks/task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkbookTaskCommandToJSON(requestParameters.createWorkbookTaskCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new task (\"munkaügy\") for an existing workbook
     */
    async apiWorkbooksTaskPost(requestParameters: ApiWorkbooksTaskPostRequest): Promise<string> {
        const response = await this.apiWorkbooksTaskPostRaw(requestParameters);
        return await response.value();
    }

}
