/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TranslationCodesVm
 */
export interface TranslationCodesVm {
    /**
     * 
     * @type {Array<Error>}
     * @memberof TranslationCodesVm
     */
    errors?: Array<Error> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof TranslationCodesVm
     */
    enums?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslationCodesVm
     */
    general?: Array<string> | null;
}

export function TranslationCodesVmFromJSON(json: any): TranslationCodesVm {
    return TranslationCodesVmFromJSONTyped(json, false);
}

export function TranslationCodesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationCodesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'enums': !exists(json, 'enums') ? undefined : json['enums'],
        'general': !exists(json, 'general') ? undefined : json['general'],
    };
}

export function TranslationCodesVmToJSON(value?: TranslationCodesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': value.errors,
        'enums': value.enums,
        'general': value.general,
    };
}


