import { mdiDelete, mdiEye, mdiPlus } from "@mdi/js";
import { Button, Modal, Space } from "antd";
import { DeadlineStatusType, ExamTypeVm, ExamVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import IconButton from "components/ui/IconButton";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { DateFormat } from "widgets/table/DateDisplay";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ColumnConfigParams, DataType } from "widgets/table/useTableUtils";
import ExamCreateModal from "./ExamCreateModal";
import styles from "./ExamsTab.module.scss";

const { confirm } = Modal;

interface ExamsTabProps {
  afterDelete: () => void;
}

const ExamsTab: React.FC<ExamsTabProps> = ({ afterDelete }) => {
  const { t } = useTranslation();
  const { repairman } = useSelector((state) => state.repairmenView);
  const { list } = useSelector((state) => state.repairmenExamTab);
  const { fetchList } = useSelector(
    (state) => state.loading.effects.repairmenExamTab
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchTableData = useCallback(() => {
    if (repairman?.id) {
      dispatch.repairmenExamTab.fetchList(repairman?.id);
    }
  }, [dispatch.repairmenExamTab, repairman?.id]);

  const columnParams = useMemo<ColumnConfigParams<ExamVm>[]>(
    () => [
      {
        title: t("exam.examType"),
        key: "examType",
        sort: false,
        render(value: ExamTypeVm, record: ExamVm, index) {
          return (
            value.name +
            (!value.isActive ? ` (${t("common.deactivated")})` : "")
          );
        },
      },
      {
        title: t("exam.expirationDate"),
        key: "expirationDate",
        sort: false,
        dataType: DataType.DATE,
        dateFormat: DateFormat.Date,
      },
    ],
    [t]
  );

  const handleDeleteButtonClick = useCallback(
    async (id?: string) => {
      if (typeof id !== "string") {
        return;
      }

      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.repairmenExamTab.delete(id);

          fetchTableData();
          afterDelete();
        },
      });
    },
    [dispatch.repairmenExamTab, fetchTableData, afterDelete, t]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      dispatch.repairmenExamTab.setEditingItemId(id);

      setCreateModalOpen(true);
    },
    [dispatch.repairmenExamTab]
  );

  const handleModalOk = useCallback(() => {
    fetchTableData();

    setCreateModalOpen(false);
  }, [fetchTableData]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<ExamVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const customTableProps = useDumbTable({
    columnParams,
    list,
    actionColumnParams,
    headerOptions: (
      <Button
        icon={<MaterialIcon path={mdiPlus} />}
        type="primary"
        onClick={handleNewButtonClick}
      >
        {t("exam.new")}
      </Button>
    ),
  });

  const getRowClassName = useCallback((record: ExamVm, index: number) => {
    switch (record.deadlineStatus) {
      case DeadlineStatusType.Expired:
        return styles.expiredDeadlineRow;

      case DeadlineStatusType.NearDeadline:
        return styles.nearDeadlineRow;

      default:
        return "";
    }
  }, []);

  useEffect(() => {
    fetchTableData();

    return () => {
      dispatch.repairmenExamTab.reset();
    };
  }, [dispatch.repairmenExamTab, fetchTableData]);

  return (
    <div>
      <CustomTable<ExamVm>
        {...customTableProps}
        selectable={false}
        loading={fetchList.loading}
        rowClassName={getRowClassName}
        scroll={{ x: 1000 }}
      />

      <ExamCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => handleModalOk()}
      />
    </div>
  );
};

export default ExamsTab;
