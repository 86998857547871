import {
  ContractorVm,
  CreateContractorCommand,
  DictionaryVm,
  RegistrationStates,
  UpdateContractorCommand,
} from "api/generated/lumen";
import { ExtendedSelectValue } from "models/common";

export interface ContractorCreateAddressValues {
  zip?: string | null;
  city: ExtendedSelectValue;
  street: string;
  number: string;
}

export interface ContactsFieldsetValues {
  id?: string;
  familyname: string;
  forename: string;
  email: string;
  phone: {
    number: string;
  };
  shouldInvite?: boolean;
  userId?: string | null;
  registrationState?: RegistrationStates;
}

export interface ContractorCreateFormValues {
  id?: string;
  name: string;
  headquarterAddress: ContractorCreateAddressValues;
  postalAddress: ContractorCreateAddressValues;
  taxNumber?: string;
  companyRegisterNumber?: string;
  email: string;
  phone: {
    number: string;
  };
  townIds?: string[];
  contactPersons: ContactsFieldsetValues[];
  isActive?: boolean;
  status?: DictionaryVm;
}

export const mapToCreateContractorCommand = ({
  name,
  contactPersons,
  email,
  headquarterAddress,
  phone,
  postalAddress,
  townIds,
  companyRegisterNumber,
  taxNumber,
}: ContractorCreateFormValues): CreateContractorCommand => ({
  name,
  contactPersons,
  email,
  headquarterAddress: {
    ...headquarterAddress,
    city: headquarterAddress.city.label,
  },
  phone,
  postalAddress: {
    ...postalAddress,
    city: postalAddress.city.label,
  },
  townIds,
  companyregisterNumber: companyRegisterNumber,
  taxnumber: taxNumber,
});

export const mapToUpdateContractorCommand = ({
  id,
  name,
  contactPersons,
  email,
  headquarterAddress,
  phone,
  postalAddress,
  townIds,
  companyRegisterNumber,
  taxNumber,
  isActive,
  status,
}: ContractorCreateFormValues): UpdateContractorCommand => ({
  id: id || "",
  name,
  contactPersons,
  email,
  headquarterAddress: {
    ...headquarterAddress,
    city: headquarterAddress.city.label,
  },
  phone,
  postalAddress: {
    ...postalAddress,
    city: postalAddress.city.label,
  },
  townIds,
  companyregisterNumber: companyRegisterNumber,
  taxnumber: taxNumber,
  isActive,
  statusId: status?.id,
});

export const mapContractorVmToUpdateContractorCommand = (
  value: ContractorVm
): UpdateContractorCommand => ({
  ...value,
  id: value.id || "",
  name: value.name || "",
  headquarterAddress: {
    ...value.headquarterAddress,
    zip: value.headquarterAddress?.zip,
    street: value.headquarterAddress?.street || "",
    city: value.headquarterAddress?.city || "",
    number: value.headquarterAddress?.number || "",
  },
  postalAddress: {
    ...value.postalAddress,
    zip: value.postalAddress?.zip,
    street: value.postalAddress?.street || "",
    city: value.postalAddress?.city || "",
    number: value.postalAddress?.number || "",
  },
  email: value.email || "",
  townIds: value.towns?.map((town) => town.id || ""),
  taxnumber: value.taxNumber,
  companyregisterNumber: value.companyRegisterNumber,
  statusId: value.status?.id,
  contactPersons: value.contacts,
  documentIds: value.documents?.map((document) => document.id || ""),
});

export interface AssignContractorToSpecialPlaceFormValues {
  specialPlaceId: string;
}
