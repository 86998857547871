import { FC } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { TableDropdownFooter } from "./TableDropdownFooter";
import { mdiCalendar } from "@mdi/js";

import styles from "./TableDropdown.module.scss";
import { MaterialIcon } from "components/MaterialIcon";
import CustomDatePicker from "components/CustomDatePicker";

export const DateRangePickerTableDropdown: FC<FilterDropdownProps> = (
  props
) => {
  const { setSelectedKeys, selectedKeys } = props;

  return (
    <div>
      <div className={styles.inputContainer}>
        <CustomDatePicker.RangePicker
          suffixIcon={
            <MaterialIcon path={mdiCalendar} className={styles.suffixIcon} />
          }
          onChange={(e: any) => setSelectedKeys([e || undefined])}
          value={(selectedKeys[0] as any) || null}
        />
      </div>

      <TableDropdownFooter {...props} />
    </div>
  );
};
