import { Modal } from "antd";
import { useDispatch } from "app/store";
import useActivatedPublicTownsQuery from "hooks/queries/useActivatedPublicTownsQuery";
import { useCallback, useEffect } from "react";
import { FailureReportFormValues, mapToReportFailureCommand } from "../models";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import FailureReportContent from "./FailureReportContent";

const FailureReport: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const activatedTownsQuery = useActivatedPublicTownsQuery();

  const handleFinish = useCallback(
    async (values: FailureReportFormValues, recaptchaToken: string) => {
      const mappedFailure = mapToReportFailureCommand(
        values,
        activatedTownsQuery.data?.items || []
      );

      await dispatch.failureReport.report({
        values: mappedFailure,
        recaptchaToken: recaptchaToken,
      });

      Modal.success({
        content: t("failure.report.successModal.description", {
          city: mappedFailure.address?.city,
          street: mappedFailure.address?.street,
          number: mappedFailure.address?.number,
        }),
        title: t("failure.report.successModal.title"),
        okText: t("common.ok"),
      });
    },
    [activatedTownsQuery.data?.items, dispatch.failureReport, t]
  );

  useEffect(() => {
    dispatch.failureReport.fetchPublicFailureTypes();
  }, [dispatch.dictionaries, dispatch.failureReport]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
      language="hu"
    >
      <FailureReportContent handleFinish={handleFinish} />
    </GoogleReCaptchaProvider>
  );
};

export default FailureReport;
