import { AddressVm } from "api/generated/lumen";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface AddressDisplayProps {
  value?: AddressVm;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ value }) => {
  const { displayAddress } = useLanguageSensitiveUtils();

  if (!value) {
    return <EmptyContent />;
  }

  return <>{displayAddress(value)}</>;
};

export default AddressDisplay;
