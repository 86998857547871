/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserFilterType {
    None = 'None',
    Contractor = 'Contractor',
    Repairman = 'Repairman',
    Council = 'Council',
    CallCenter = 'CallCenter'
}

export function UserFilterTypeFromJSON(json: any): UserFilterType {
    return UserFilterTypeFromJSONTyped(json, false);
}

export function UserFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFilterType {
    return json as UserFilterType;
}

export function UserFilterTypeToJSON(value?: UserFilterType | null): any {
    return value as any;
}

