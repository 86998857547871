import {
  CreateRepairmanCommand,
  RegistrationStates,
  RepairmanDto,
  RepairmanVm,
  UpdateRepairmanCommand,
} from "api/generated/lumen";
import dayjs, { Dayjs } from "dayjs";
import { ExtendedSelectValue } from "models/common";

export interface RepairmenCreateFormValues {
  id?: string;
  familyname: string;
  forename: string;
  birthPlace?: string;
  birthDate?: dayjs.Dayjs;
  address?: {
    zip?: string | null;
    city?: ExtendedSelectValue;
    street?: string;
    number?: string;
  };
  email: string;
  phone: {
    number?: string;
  };
  inviteRepairman?: boolean;
  contractorId: string;
  isActive?: boolean;
  registrationState?: RegistrationStates;
}

export const mapToCreateRepairmanCommand = ({
  inviteRepairman,
  ...repairmanData
}: RepairmenCreateFormValues): CreateRepairmanCommand => ({
  inviteRepairman,
  repairman: {
    ...repairmanData,
    birthDate: repairmanData.birthDate
      ? repairmanData.birthDate.toDate()
      : undefined,
    address:
      repairmanData.address?.city?.label ||
      repairmanData.address?.number ||
      repairmanData.address?.street
        ? {
            ...repairmanData.address,
            city: repairmanData.address?.city?.label || "", // Tech debt: Due to low BE resource availability, these fields stayed non-nullable in Swagger docs, but they need to be nullable on the FE
            number: repairmanData.address?.number || "", // Tech debt: Due to low BE resource availability, these fields stayed non-nullable in Swagger docs, but they need to be nullable on the FE
            street: repairmanData.address?.street || "", // Tech debt: Due to low BE resource availability, these fields stayed non-nullable in Swagger docs, but they need to be nullable on the FE
          }
        : undefined,
  },
});

export const mapToUpdateRepairmanCommand = ({
  inviteRepairman,
  ...repairmanData
}: RepairmenCreateFormValues): UpdateRepairmanCommand => ({
  id: repairmanData.id || "",
  inviteRepairman,
  repairman: {
    ...repairmanData,
    birthDate: repairmanData.birthDate
      ? repairmanData.birthDate.toDate()
      : undefined,
    address: {
      ...repairmanData.address,
      city: repairmanData.address?.city?.label || "", // Tech debt: PUT request values are interpreted as following: If no data is sent for a property, or NULL or undefined, BE doesn't change the property's value in DB, so if user deletes value, we need to send empty string to "null" it from DB
      number: repairmanData.address?.number || "", // Tech debt: PUT request values are interpreted as following: If no data is sent for a property, or NULL or undefined, BE doesn't change the property's value in DB, so if user deletes value, we need to send empty string to "null" it from DB
      street: repairmanData.address?.street || "", // Tech debt: PUT request values are interpreted as following: If no data is sent for a property, or NULL or undefined, BE doesn't change the property's value in DB, so if user deletes value, we need to send empty string to "null" it from DB
    },
  },
});

export const mapRepairVmToUpdateRepairmanCommand = (
  value: RepairmanVm
): UpdateRepairmanCommand => ({
  ...value,
  id: value.id || "",
});

export const mapRepairmanVmToRepairmanDto = (
  value: RepairmanVm
): RepairmanDto => ({
  ...value,
  familyname: value.familyname || "",
  forename: value.forename || "",
  address: {
    ...value.address,
    zip: value.address?.zip,
    street: value.address?.street || "",
    city: value.address?.city || "",
    number: value.address?.number || "",
  },
  email: value.email || "",
  documentIds: (value.documents?.map((doc) => doc.id).filter((x) => x) ||
    []) as string[],
});

export interface ExamCreateFormValues {
  id?: string;
  examTypeId: string;
  expirationDate?: Dayjs;
}
