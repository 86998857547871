/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateFailureCommand
 */
export interface UpdateFailureCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    identifier?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateFailureCommand
     */
    reportedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateFailureCommand
     */
    closedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateFailureCommand
     */
    deadline?: Date;
    /**
     * 
     * @type {AddressDto}
     * @memberof UpdateFailureCommand
     */
    address?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    typeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    expanseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    description1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    natureId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    description2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    optimalDeadlineDaysId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    statusId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    stateChangeReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    contractorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    creatorTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    notifierFamilyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    notifierMiddlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    notifierForename?: string | null;
    /**
     * 
     * @type {PhoneDto}
     * @memberof UpdateFailureCommand
     */
    notifierPhone?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    notifierEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailureCommand
     */
    repairmanIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailureCommand
     */
    townId?: string | null;
}

export function UpdateFailureCommandFromJSON(json: any): UpdateFailureCommand {
    return UpdateFailureCommandFromJSONTyped(json, false);
}

export function UpdateFailureCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFailureCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'reportedAt': !exists(json, 'reportedAt') ? undefined : (new Date(json['reportedAt'])),
        'closedAt': !exists(json, 'closedAt') ? undefined : (json['closedAt'] === null ? null : new Date(json['closedAt'])),
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'address': !exists(json, 'address') ? undefined : AddressDtoFromJSON(json['address']),
        'typeId': json['typeId'],
        'expanseId': !exists(json, 'expanseId') ? undefined : json['expanseId'],
        'description1': !exists(json, 'description1') ? undefined : json['description1'],
        'natureId': !exists(json, 'natureId') ? undefined : json['natureId'],
        'description2': !exists(json, 'description2') ? undefined : json['description2'],
        'optimalDeadlineDaysId': !exists(json, 'optimalDeadlineDaysId') ? undefined : json['optimalDeadlineDaysId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'stateChangeReason': !exists(json, 'stateChangeReason') ? undefined : json['stateChangeReason'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'contractorId': !exists(json, 'contractorId') ? undefined : json['contractorId'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'creatorTypeId': !exists(json, 'creatorTypeId') ? undefined : json['creatorTypeId'],
        'notifierFamilyname': !exists(json, 'notifierFamilyname') ? undefined : json['notifierFamilyname'],
        'notifierMiddlename': !exists(json, 'notifierMiddlename') ? undefined : json['notifierMiddlename'],
        'notifierForename': !exists(json, 'notifierForename') ? undefined : json['notifierForename'],
        'notifierPhone': !exists(json, 'notifierPhone') ? undefined : PhoneDtoFromJSON(json['notifierPhone']),
        'notifierEmail': !exists(json, 'notifierEmail') ? undefined : json['notifierEmail'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'repairmanIds': !exists(json, 'repairmanIds') ? undefined : json['repairmanIds'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
    };
}

export function UpdateFailureCommandToJSON(value?: UpdateFailureCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'reportedAt': value.reportedAt === undefined ? undefined : (value.reportedAt.toISOString()),
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt === null ? null : value.closedAt.toISOString()),
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'address': AddressDtoToJSON(value.address),
        'typeId': value.typeId,
        'expanseId': value.expanseId,
        'description1': value.description1,
        'natureId': value.natureId,
        'description2': value.description2,
        'optimalDeadlineDaysId': value.optimalDeadlineDaysId,
        'statusId': value.statusId,
        'stateChangeReason': value.stateChangeReason,
        'parentId': value.parentId,
        'contractorId': value.contractorId,
        'deviceId': value.deviceId,
        'creatorTypeId': value.creatorTypeId,
        'notifierFamilyname': value.notifierFamilyname,
        'notifierMiddlename': value.notifierMiddlename,
        'notifierForename': value.notifierForename,
        'notifierPhone': PhoneDtoToJSON(value.notifierPhone),
        'notifierEmail': value.notifierEmail,
        'comment': value.comment,
        'repairmanIds': value.repairmanIds,
        'townId': value.townId,
    };
}


