import { createModel } from "@rematch/core";
import { api } from "api";
import {
  ContractorVm,
  FailureVm,
  RepairmanVm,
  UpdateContractorCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";
import { getTownNameFiltersForContractor } from "../utils";

interface FailureAssignState {
  failure?: FailureVm;
  editingItemId?: string;
  contractors: ContractorVm[];
  repairmen: RepairmanVm[];
}

const initialState: FailureAssignState = {
  failure: undefined,
  editingItemId: undefined,
  contractors: [],
  repairmen: [],
};

export const failureAssign = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFailure(state, data: FailureVm) {
      state.failure = data;
    },
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    setContractors(state, data: ContractorVm[]) {
      state.contractors = data;
    },
    setRepairmen(state, data: RepairmanVm[]) {
      state.repairmen = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureAssign } = dispatch;

    return {
      async fetchFailure(id: string): Promise<void> {
        const data = await api.lumen.failures.apiFailuresIdGet({ id });

        dispatch(failureAssign.setFailure(data));
      },

      async fetchContractorsForTowns(townNames: string[]): Promise<void> {
        const townNameFilters = getTownNameFiltersForContractor(townNames);

        const data = await api.lumen.contractors.apiContractorsGetAllPost({
          getAllContractorsQuery: {
            pageSize: -1,
            filterKeys: {
              ...townNameFilters,
            },
          },
        });

        const { items } = data;

        dispatch(failureAssign.setContractors(items || []));
      },

      async fetchContractorsForDevices(deviceIds: string[]): Promise<void> {
        const data =
          await api.lumen.contractors.apiContractorsGetContractorsBasedOnDeviceIdsPost(
            {
              getContractorsBasedOnDeviceIdsQuery: {
                deviceIds,
              },
            }
          );

        dispatch(failureAssign.setContractors(data));
      },

      async fetchRepairmen(contractorId: string): Promise<void> {
        const data = await api.lumen.repairmen.apiRepairmenGetAllPost({
          getAllRepairmenQuery: {
            pageSize: -1,
            filterKeys: {
              isActive: "true",
              "contractor.id": contractorId,
            },
          },
        });

        const { items } = data;

        dispatch(failureAssign.setRepairmen(items || []));
      },

      async assignToContractor({
        failureId,
        contractor,
      }: {
        failureId?: string;
        contractor: UpdateContractorCommand;
      }): Promise<void> {
        if (contractor.id && failureId) {
          return api.lumen.failures.apiFailuresAssignMultipleToContractorPut({
            assignFailuresToContractorCommand: {
              contractorId: contractor.id,
              failureIds: [failureId],
            },
          });
        }
      },

      async assignToRepairmen({
        id,
        repairmanIds,
      }: {
        id: string;
        repairmanIds: string[];
      }): Promise<void> {
        return api.lumen.failures.apiFailuresIdAssignRepairmenPut({
          id,
          failureRepairmenDto: {
            repairmanIds,
          },
        });
      },
    };
  },
});
