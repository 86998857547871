/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface DictionaryGroup
 */
export interface DictionaryGroup {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroup
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroup
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroup
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DictionaryGroup
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroup
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DictionaryGroup
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Array<Dictionary>}
     * @memberof DictionaryGroup
     */
    dictionaryItems?: Array<Dictionary> | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroup
     */
    id?: string;
}

export function DictionaryGroupFromJSON(json: any): DictionaryGroup {
    return DictionaryGroupFromJSONTyped(json, false);
}

export function DictionaryGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'dictionaryItems': !exists(json, 'dictionaryItems') ? undefined : (json['dictionaryItems'] === null ? null : (json['dictionaryItems'] as Array<any>).map(DictionaryFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DictionaryGroupToJSON(value?: DictionaryGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'dictionaryItems': value.dictionaryItems === undefined ? undefined : (value.dictionaryItems === null ? null : (value.dictionaryItems as Array<any>).map(DictionaryToJSON)),
        'id': value.id,
    };
}


