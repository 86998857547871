import { mdiEye, mdiLinkVariantOff } from "@mdi/js";
import { Button, Modal, Space } from "antd";
import { DeviceVm, SpecialPlaceVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import IconButton from "components/ui/IconButton";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ColumnConfigParams } from "widgets/table/useTableUtils";

const { confirm } = Modal;

const MaintainedDevicesTab: React.FC = () => {
  const { t } = useTranslation();
  const { contractor, assignedMaintainedDevices } = useSelector(
    (state) => state.contractorView
  );
  const { fetchAssignedMaintainedDevices } = useSelector(
    (state) => state.loading.effects.contractorView
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columnParams = useMemo<ColumnConfigParams<DeviceVm>[]>(
    () => [
      {
        key: "name",
        sort: false,
        title: t("maintainedDevice.name"),
      },
    ],
    [t]
  );

  const handleDetachButtonClick = useCallback(
    async (id?: string) => {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("contractor.confirmDetachMaintainedDeviceModal.title"),
        onOk: async () => {
          if (typeof contractor?.id !== "string" || typeof id !== "string") {
            return;
          }

          await dispatch.contractorView.detachMaintainedDevice({
            contractorToDetachId: contractor.id,
            deviceToDetachId: id,
          });

          dispatch.contractorView.fetchAssignedMaintainedDevices(contractor.id);
        },
      });
    },
    [contractor?.id, dispatch.contractorView, t]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/maintained-devices/${id}`);
    },
    [navigate]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<DeviceVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiLinkVariantOff}
              onClick={() => handleDetachButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDetachButtonClick, handleViewButtonClick, t]
  );

  const handleDetachAllMaintainedDeviceClick = useCallback(() => {
    confirm({
      okText: t("common.confirmDeleteModal.yes"),
      okType: "danger",
      cancelText: t("common.confirmDeleteModal.no"),
      title: t("contractor.confirmDetachAllMaintainedDeviceModal.title"),
      onOk: async () => {
        if (typeof contractor?.id !== "string") {
          return;
        }

        await dispatch.contractorView.detachAllMaintainedDevice(contractor.id);

        dispatch.contractorView.fetchAssignedMaintainedDevices(contractor.id);
      },
    });
  }, [contractor?.id, dispatch.contractorView, t]);

  const customTableProps = useDumbTable({
    columnParams,
    list: assignedMaintainedDevices,
    actionColumnParams,
    loading: fetchAssignedMaintainedDevices.loading,
    headerOptions: (
      <Space>
        <Button
          danger
          htmlType="submit"
          block
          icon={<MaterialIcon path={mdiLinkVariantOff} />}
          onClick={() => handleDetachAllMaintainedDeviceClick()}
          disabled={!assignedMaintainedDevices.length}
        >
          {t("contractor.detachAllMaintainedDevice")}
        </Button>
      </Space>
    ),
  });

  useEffect(() => {
    if (typeof contractor?.id !== "string") {
      return;
    }

    dispatch.contractorView.fetchAssignedMaintainedDevices(contractor?.id);

    return () => {
      dispatch.contractorView.setAssignedMaintainedDevices([]);
    };
  }, [contractor?.id, dispatch.contractorView]);

  return (
    <CustomTable<SpecialPlaceVm>
      {...customTableProps}
      selectable={false}
      scroll={{ x: 1000 }}
    />
  );
};

export default MaintainedDevicesTab;
