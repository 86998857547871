import { mdiLinkOff } from "@mdi/js";
import { Tag } from "antd";
import { FailureVm } from "api/generated/lumen";
import AddressDisplay from "components/ui/AddressDisplay";
import IconButton from "components/ui/IconButton";
import styles from "./AttachedFailureCard.module.scss";
import { useTranslation } from "react-i18next";

interface AttachedFailureCardProps {
  failure: FailureVm;
  detachFailure: (failureToDetachId?: string) => void;
}

const AttachedFailureCard: React.FC<AttachedFailureCardProps> = ({
  failure,
  detachFailure,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <span className={styles.identifier}>
        {failure.identifier} - {failure.type?.name}
      </span>
      <span className={styles.address}>
        <AddressDisplay value={failure.address} />
      </span>
      <span>
        <Tag color={failure.status?.isSealed ? "processing" : "success"}>
          {failure.status?.isSealed
            ? t("failure.attachedFailure.status.closed")
            : t("failure.attachedFailure.status.active")}
        </Tag>
      </span>
      <span>
        <IconButton
          path={mdiLinkOff}
          onClick={() => detachFailure(failure.id)}
        />
      </span>
    </div>
  );
};

export default AttachedFailureCard;
