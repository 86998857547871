import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "api";
import {
  OrderByType,
  TownVmPaginatedSearchResponse,
} from "api/generated/lumen";
import { queryKeys } from ".";

const useActivatedPublicTownsQuery = (): UseQueryResult<
  TownVmPaginatedSearchResponse,
  unknown
> => {
  const query = useQuery(
    queryKeys.towns.allPublicActivated,
    () =>
      api.lumen.towns.apiTownsGetAllPublicPost({
        getAllTownsPubliclyQuery: {
          pageSize: -1,
          sortings: [{ orderBy: "name", orderByType: OrderByType.Ascend }],
        },
      }),

    {}
  );

  return query;
};

export default useActivatedPublicTownsQuery;
