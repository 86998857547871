import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { FilterLogicalOperator, OrderByType } from "api/generated/lumen";
import { queryKeys } from ".";

const useDevicesQuery = () => {
  const query = useQuery(queryKeys.devices.all, () =>
    api.lumen.devices.apiDevicesGetAllPost({
      getAllDevicesQuery: {
        pageSize: -1,
        filterLogicalOperator: FilterLogicalOperator.And,
        filterKeys: { isActive: true },
        sortings: [{ orderBy: "name", orderByType: OrderByType.Ascend }],
      },
    })
  );

  return query;
};

export default useDevicesQuery;
