/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamTypeVm
 */
export interface ExamTypeVm {
    /**
     * 
     * @type {string}
     * @memberof ExamTypeVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamTypeVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamTypeVm
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExamTypeVm
     */
    warningDaysBeforeExpiration?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamTypeVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamTypeVm
     */
    isActive?: boolean;
}

export function ExamTypeVmFromJSON(json: any): ExamTypeVm {
    return ExamTypeVmFromJSONTyped(json, false);
}

export function ExamTypeVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamTypeVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'warningDaysBeforeExpiration': !exists(json, 'warningDaysBeforeExpiration') ? undefined : json['warningDaysBeforeExpiration'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function ExamTypeVmToJSON(value?: ExamTypeVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'warningDaysBeforeExpiration': value.warningDaysBeforeExpiration,
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
    };
}


