import {
  Button,
  Descriptions,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "app/store";
import StretchToCardEdge from "components/StretchToCardEdge";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import styles from "./MaintainedDevicesView.module.scss";
import ReactJson from "react-json-view";
import { MaterialIcon } from "components/MaterialIcon";
import { mdiAlertPlus, mdiOpenInNew } from "@mdi/js";
import ContractorsTab from "./components/ContractorsTab";
import StringDisplay from "components/ui/StringDisplay";
import TabsWrapper from "components/ui/TabsWrapper";
import CardTitle from "components/ui/CardTitle";
import CardContent from "components/ui/CardContent";

declare module "react-json-view" {
  export interface ReactJsonViewProps {
    displayArrayKey: boolean;
  }
}

const collapseDepth =
  process.env.REACT_APP_JSON_VIEW_COLLAPSE_DEPTH !== ""
    ? process.env.REACT_APP_JSON_VIEW_COLLAPSE_DEPTH
    : undefined;

const externalLink1Label =
  process.env.REACT_APP_DEVICE_EXTERNAL_LINK_1_LABEL !== ""
    ? process.env.REACT_APP_DEVICE_EXTERNAL_LINK_1_LABEL
    : undefined;

const externalLink1UrlPrefix =
  process.env.REACT_APP_DEVICE_EXTERNAL_LINK_1_URL_PREFIX !== ""
    ? process.env.REACT_APP_DEVICE_EXTERNAL_LINK_1_URL_PREFIX
    : undefined;

const externalLink2Label =
  process.env.REACT_APP_DEVICE_EXTERNAL_LINK_2_LABEL !== ""
    ? process.env.REACT_APP_DEVICE_EXTERNAL_LINK_2_LABEL
    : undefined;

const externalLink2UrlPrefix =
  process.env.REACT_APP_DEVICE_EXTERNAL_LINK_2_URL_PREFIX !== ""
    ? process.env.REACT_APP_DEVICE_EXTERNAL_LINK_2_URL_PREFIX
    : undefined;

const MaintainedDevicesView = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { device } = useSelector((state) => state.maintainedDevicesView);
  const { fetchDevice } = useSelector(
    (state) => state.loading.effects.maintainedDevicesView
  );
  const { isVisible: isFailureCreateModalVisible } = useSelector(
    (state) => state.failureCreate
  );

  const deviceDetailsObj =
    device && typeof device.detailJson === "string"
      ? JSON.parse(device.detailJson)
      : undefined;

  const handleAddNewFailureClick = useCallback(() => {
    if (typeof id === "string") {
      dispatch.failureCreate.setPrePopulatedValues({
        deviceId: id,
      });
    }
    dispatch.failureCreate.setIsVisible(true);
  }, [dispatch.failureCreate, id]);

  useEffect(() => {
    if (!isFailureCreateModalVisible) {
      dispatch.failureCreate.setPrePopulatedValues({
        deviceId: undefined,
      });
    }
  }, [dispatch.failureCreate, isFailureCreateModalVisible]);

  useEffect(() => {
    dispatch.common.setBreadcrumbs([
      {
        title: t("menu.maintainedDevices"),
        to: "/maintained-devices",
      },
      {
        title: device?.name || "",
      },
    ]);

    return () => {
      dispatch.common.setBreadcrumbs([]);
    };
  }, [device?.name, dispatch.common, t]);

  useEffect(() => {
    if (typeof id === "string") {
      dispatch.maintainedDevicesView.fetchDevice(id);
    }

    return () => {
      dispatch.maintainedDevicesView.reset();
    };
  }, [dispatch.contractorView, dispatch.maintainedDevicesView, id]);

  return (
    <>
      <CardTitle className={styles.headerDescriptionsRow}>
        <VerticalDataDescriptions column={1}>
          <Descriptions.Item label={t("maintainedDevice.name")}>
            {device?.name ? (
              <Row justify="space-between">
                <Typography.Title level={2}>{device?.name}</Typography.Title>
              </Row>
            ) : (
              <EmptyContent />
            )}
          </Descriptions.Item>
        </VerticalDataDescriptions>

        <Space>
          <div className={styles.tagWrapper}>
            {device?.isActive ? (
              <Tag color="success">{t("common.active")}</Tag>
            ) : (
              <Tag color="error">{t("common.inactive")}</Tag>
            )}
          </div>

          {device?.isActive && (
            <Button
              icon={<MaterialIcon path={mdiAlertPlus} />}
              type="primary"
              onClick={handleAddNewFailureClick}
            >
              {t("maintainedDevice.addNewFailure")}
            </Button>
          )}
        </Space>
      </CardTitle>

      <CardContent>
        <Spin spinning={fetchDevice.loading}>
          <Space direction="vertical">
            {(externalLink1Label || externalLink2Label) && (
              <Row justify="end">
                <Space>
                  {externalLink1Label && (
                    <Button
                      icon={<MaterialIcon path={mdiOpenInNew} />}
                      href={`${externalLink1UrlPrefix}${device?.redirectPathPostfix1}`}
                      target="_blank"
                    >
                      {externalLink1Label}
                    </Button>
                  )}

                  {externalLink2Label && (
                    <Button
                      icon={<MaterialIcon path={mdiOpenInNew} />}
                      href={`${externalLink2UrlPrefix}${device?.redirectPathPostfix2}`}
                      target="_blank"
                    >
                      {externalLink2Label}
                    </Button>
                  )}
                </Space>
              </Row>
            )}

            <VerticalDataDescriptions>
              <Descriptions.Item
                label={t("maintainedDevice.assignedSpecialPlace")}
              >
                <StringDisplay value={device?.specialPlace?.name} />
              </Descriptions.Item>
            </VerticalDataDescriptions>
          </Space>
          <Typography.Title level={5}>
            {t("maintainedDevice.details")}
          </Typography.Title>
          {deviceDetailsObj && (
            <ReactJson
              src={deviceDetailsObj}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              name={null}
              collapsed={
                collapseDepth
                  ? parseInt(collapseDepth, 10) || undefined
                  : undefined
              }
              quotesOnKeys={false}
              displayArrayKey={false}
            />
          )}
          <TabsWrapper extraMargin>
            <StretchToCardEdge>
              <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane
                type="card"
                size="small"
                className={styles.tabs}
              >
                <Tabs.TabPane
                  tab={t("maintainedDevice.tabs.contractors")}
                  key="1"
                >
                  <ContractorsTab />
                </Tabs.TabPane>
              </Tabs>
            </StretchToCardEdge>
          </TabsWrapper>
        </Spin>
      </CardContent>
    </>
  );
};

export default MaintainedDevicesView;
