import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { OrderByType } from "api/generated/lumen";
import { DictionaryGroupType } from "models/common";
import { MILISECONDS_24H } from "models/constants";
import { queryKeys } from ".";

interface UseDictionariesQueryProps {
  dictionaryGroupType: DictionaryGroupType;
  dictionaryIsEditableInApp?: boolean;
  onlyActive?: boolean;
}

const useDictionariesQuery = ({
  dictionaryGroupType,
  dictionaryIsEditableInApp,
  onlyActive,
}: UseDictionariesQueryProps) => {
  const query = useQuery(
    queryKeys.dictionaries.byDictionaryGroupType(dictionaryGroupType),
    async () => {
      const data = await api.lumen.dictionaryItems.apiDictionaryItemsPost({
        getAllDictionaryItemsQuery: {
          filterKeys: {
            "group.value": dictionaryGroupType,
            ...(onlyActive ? { isActive: true } : {}),
          },
          sortings: [
            {
              orderByType: OrderByType.Ascend,
              orderBy: "name",
            },
          ],
          pageSize: -1,
        },
      });

      return data.items || [];
    },
    {
      cacheTime: !dictionaryIsEditableInApp ? MILISECONDS_24H : undefined,
      staleTime: !dictionaryIsEditableInApp ? MILISECONDS_24H : undefined,
    }
  );

  return query;
};

export default useDictionariesQuery;
