/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleVm,
    RoleVmFromJSON,
    RoleVmFromJSONTyped,
    RoleVmToJSON,
    UserFilterType,
    UserFilterTypeFromJSON,
    UserFilterTypeFromJSONTyped,
    UserFilterTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserInfoVm
 */
export interface UserInfoVm {
    /**
     * 
     * @type {string}
     * @memberof UserInfoVm
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoVm
     */
    userName?: string | null;
    /**
     * 
     * @type {UserFilterType}
     * @memberof UserInfoVm
     */
    userFilterType?: UserFilterType;
    /**
     * 
     * @type {string}
     * @memberof UserInfoVm
     */
    userFilterId?: string | null;
    /**
     * 
     * @type {RoleVm}
     * @memberof UserInfoVm
     */
    role?: RoleVm;
}

export function UserInfoVmFromJSON(json: any): UserInfoVm {
    return UserInfoVmFromJSONTyped(json, false);
}

export function UserInfoVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'userFilterType': !exists(json, 'userFilterType') ? undefined : UserFilterTypeFromJSON(json['userFilterType']),
        'userFilterId': !exists(json, 'userFilterId') ? undefined : json['userFilterId'],
        'role': !exists(json, 'role') ? undefined : RoleVmFromJSON(json['role']),
    };
}

export function UserInfoVmToJSON(value?: UserInfoVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userName': value.userName,
        'userFilterType': UserFilterTypeToJSON(value.userFilterType),
        'userFilterId': value.userFilterId,
        'role': RoleVmToJSON(value.role),
    };
}


