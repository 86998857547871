/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignFailuresToRepairmanCommand
 */
export interface AssignFailuresToRepairmanCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignFailuresToRepairmanCommand
     */
    failureIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignFailuresToRepairmanCommand
     */
    repairmanIds: Array<string>;
}

export function AssignFailuresToRepairmanCommandFromJSON(json: any): AssignFailuresToRepairmanCommand {
    return AssignFailuresToRepairmanCommandFromJSONTyped(json, false);
}

export function AssignFailuresToRepairmanCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignFailuresToRepairmanCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureIds': json['failureIds'],
        'repairmanIds': json['repairmanIds'],
    };
}

export function AssignFailuresToRepairmanCommandToJSON(value?: AssignFailuresToRepairmanCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureIds': value.failureIds,
        'repairmanIds': value.repairmanIds,
    };
}


