import { createModel } from "@rematch/core";
import { api } from "api";
import { RepairmanVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { downloadFile } from "utils/file";
import {
  mapRepairmanVmToRepairmanDto,
  mapRepairVmToUpdateRepairmanCommand,
} from "../models";

interface RepairmenViewState {
  repairman?: RepairmanVm;
}

const initialState: RepairmenViewState = {
  repairman: undefined,
};

export const repairmenView = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setRepairman(state, data: RepairmanVm) {
      state.repairman = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { repairmenView } = dispatch;

    return {
      async fetchRepairman(id: string): Promise<void> {
        const data = await api.lumen.repairmen.apiRepairmenIdGet({ id });

        dispatch(repairmenView.setRepairman(data));
      },

      async addDocument(
        {
          repairmanData,
          file,
          fileName,
        }: {
          repairmanData: RepairmanVm;
          file: Blob;
          fileName: string;
        },
        state
      ): Promise<void> {
        const uploadedFileId = await api.lumen.documents.apiDocumentsUploadPost(
          {
            documentName: fileName,
            documentFileData: file,
          }
        );

        const repairmanDto = mapRepairmanVmToRepairmanDto(repairmanData);
        return api.lumen.repairmen.apiRepairmenIdPut({
          id: state.repairmenView.repairman?.id || "",
          updateRepairmanCommand: {
            ...mapRepairVmToUpdateRepairmanCommand(repairmanData),
            repairman: {
              ...repairmanDto,
              documentIds: [
                ...(repairmanDto.documentIds || []),
                JSON.parse(uploadedFileId), // BE gives back string with "" and TextApiResponse makes double "" from it. This removes double """".
              ],
            },
          },
        });
      },

      async downloadDocument(id: string): Promise<void> {
        const data = await api.lumen.documents.apiDocumentsIdGetRaw({
          id,
        });

        downloadFile(data.raw);
      },

      async deleteDocument(
        {
          repairmanData,
          documentId,
        }: {
          repairmanData: RepairmanVm;
          documentId: string;
        },
        state
      ): Promise<void> {
        const repairmanDto = mapRepairmanVmToRepairmanDto(repairmanData);
        await api.lumen.repairmen.apiRepairmenIdPut({
          id: state.repairmenView.repairman?.id || "",
          updateRepairmanCommand: {
            ...mapRepairVmToUpdateRepairmanCommand(repairmanData),
            repairman: {
              ...repairmanDto,
              documentIds:
                repairmanDto?.documentIds?.filter((id) => id !== documentId) ||
                [],
            },
          },
        });

        return api.lumen.documents.apiDocumentsIdDelete({ id: documentId });
      },
    };
  },
});
