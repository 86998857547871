/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DeadlineStatusType {
    Normal = 'Normal',
    NearDeadline = 'NearDeadline',
    Expired = 'Expired'
}

export function DeadlineStatusTypeFromJSON(json: any): DeadlineStatusType {
    return DeadlineStatusTypeFromJSONTyped(json, false);
}

export function DeadlineStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeadlineStatusType {
    return json as DeadlineStatusType;
}

export function DeadlineStatusTypeToJSON(value?: DeadlineStatusType | null): any {
    return value as any;
}

