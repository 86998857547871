import { createModel } from "@rematch/core";
import { api } from "api";
import { RepairmanListItemVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { mapToGetAllQuery } from "utils";
import { downloadFile } from "utils/file";
import {
  ListRequestParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { getFilterParamsForRepairmenGetRequests } from "../utils";

interface RepairmenListState {
  listParams: ListRequestParams;
  list: RepairmanListItemVm[];
}

const initialState: RepairmenListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "deadlineStatus",
        orderByType: OrderByType.Descending,
      },
      {
        orderBy: "familyname",
        orderByType: OrderByType.Ascending,
      },
    ],
  },
  list: [],
};

export const repairmenList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: RepairmanListItemVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { repairmenList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const { payload, mergedParams } =
          getFilterParamsForRepairmenGetRequests(
            params,
            state.repairmenList.listParams
          );

        const data = await api.lumen.repairmen.apiRepairmenGetAllPost({
          getAllRepairmenQuery: mapToGetAllQuery(payload),
        });

        const { items, ...pagination } = data;

        dispatch(
          repairmenList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async generateExcel(columnTitles: string[], state): Promise<void> {
        const { payload } = getFilterParamsForRepairmenGetRequests(
          state.repairmenList.listParams,
          state.repairmenList.listParams
        );

        payload.order = payload.order?.filter(
          (order) => order.orderBy !== "deadlineStatus"
        );

        const data = await api.lumen.repairmen.apiRepairmenExportPostRaw({
          getAllRepairmenExcelQuery: {
            ...mapToGetAllQuery(payload),
            columnNames: columnTitles,
            pageSize: -1,
          },
        });

        downloadFile(data.raw);
      },

      async deleteItem(id?: string): Promise<void> {
        if (typeof id === "string") {
          return api.lumen.repairmen.apiRepairmenIdDelete({ id });
        }
      },
    };
  },
});
