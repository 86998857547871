import RepairmenList from "features/repairmen/list/RepairmenList";
import { useParams } from "react-router-dom";

const RepairmenByContractorTab = () => {
  const { id: contractorId } = useParams<{ id: string }>();

  return <RepairmenList contractorId={contractorId} />;
};

export default RepairmenByContractorTab;
