/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageThumbnailVm,
    ImageThumbnailVmFromJSON,
    ImageThumbnailVmFromJSONTyped,
    ImageThumbnailVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImageVm
 */
export interface ImageVm {
    /**
     * 
     * @type {string}
     * @memberof ImageVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVm
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVm
     */
    imageData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVm
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageVm
     */
    size?: number;
    /**
     * 
     * @type {Array<ImageThumbnailVm>}
     * @memberof ImageVm
     */
    imageThumbnails?: Array<ImageThumbnailVm> | null;
}

export function ImageVmFromJSON(json: any): ImageVm {
    return ImageVmFromJSONTyped(json, false);
}

export function ImageVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'imageData': !exists(json, 'imageData') ? undefined : json['imageData'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'imageThumbnails': !exists(json, 'imageThumbnails') ? undefined : (json['imageThumbnails'] === null ? null : (json['imageThumbnails'] as Array<any>).map(ImageThumbnailVmFromJSON)),
    };
}

export function ImageVmToJSON(value?: ImageVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fileName': value.fileName,
        'imageData': value.imageData,
        'contentType': value.contentType,
        'size': value.size,
        'imageThumbnails': value.imageThumbnails === undefined ? undefined : (value.imageThumbnails === null ? null : (value.imageThumbnails as Array<any>).map(ImageThumbnailVmToJSON)),
    };
}


