/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FilterLogicalOperator,
    FilterLogicalOperatorFromJSON,
    FilterLogicalOperatorFromJSONTyped,
    FilterLogicalOperatorToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFailureCountQuery
 */
export interface GetFailureCountQuery {
    /**
     * 
     * @type {FilterLogicalOperator}
     * @memberof GetFailureCountQuery
     */
    filterLogicalOperator?: FilterLogicalOperator;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetFailureCountQuery
     */
    filterKeys?: { [key: string]: any; } | null;
}

export function GetFailureCountQueryFromJSON(json: any): GetFailureCountQuery {
    return GetFailureCountQueryFromJSONTyped(json, false);
}

export function GetFailureCountQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFailureCountQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterLogicalOperator': !exists(json, 'filterLogicalOperator') ? undefined : FilterLogicalOperatorFromJSON(json['filterLogicalOperator']),
        'filterKeys': !exists(json, 'filterKeys') ? undefined : json['filterKeys'],
    };
}

export function GetFailureCountQueryToJSON(value?: GetFailureCountQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filterLogicalOperator': FilterLogicalOperatorToJSON(value.filterLogicalOperator),
        'filterKeys': value.filterKeys,
    };
}


