/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmFromJSONTyped,
    ContractorVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    RegistrationStates,
    RegistrationStatesFromJSON,
    RegistrationStatesFromJSONTyped,
    RegistrationStatesToJSON,
    TownVm,
    TownVmFromJSON,
    TownVmFromJSONTyped,
    TownVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactPersonVm
 */
export interface ContactPersonVm {
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    forename?: string | null;
    /**
     * 
     * @type {PhoneVm}
     * @memberof ContactPersonVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonVm
     */
    userId?: string | null;
    /**
     * 
     * @type {Array<TownVm>}
     * @memberof ContactPersonVm
     */
    towns?: Array<TownVm> | null;
    /**
     * 
     * @type {ContractorVm}
     * @memberof ContactPersonVm
     */
    contractor?: ContractorVm;
    /**
     * 
     * @type {RegistrationStates}
     * @memberof ContactPersonVm
     */
    registrationState?: RegistrationStates;
}

export function ContactPersonVmFromJSON(json: any): ContactPersonVm {
    return ContactPersonVmFromJSONTyped(json, false);
}

export function ContactPersonVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPersonVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'towns': !exists(json, 'towns') ? undefined : (json['towns'] === null ? null : (json['towns'] as Array<any>).map(TownVmFromJSON)),
        'contractor': !exists(json, 'contractor') ? undefined : ContractorVmFromJSON(json['contractor']),
        'registrationState': !exists(json, 'registrationState') ? undefined : RegistrationStatesFromJSON(json['registrationState']),
    };
}

export function ContactPersonVmToJSON(value?: ContactPersonVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'phone': PhoneVmToJSON(value.phone),
        'email': value.email,
        'userId': value.userId,
        'towns': value.towns === undefined ? undefined : (value.towns === null ? null : (value.towns as Array<any>).map(TownVmToJSON)),
        'contractor': ContractorVmToJSON(value.contractor),
        'registrationState': RegistrationStatesToJSON(value.registrationState),
    };
}


