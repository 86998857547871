/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FilterLogicalOperator,
    FilterLogicalOperatorFromJSON,
    FilterLogicalOperatorFromJSONTyped,
    FilterLogicalOperatorToJSON,
    SortingDto,
    SortingDtoFromJSON,
    SortingDtoFromJSONTyped,
    SortingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAllTownsSlenderQuery
 */
export interface GetAllTownsSlenderQuery {
    /**
     * 
     * @type {number}
     * @memberof GetAllTownsSlenderQuery
     */
    page?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllTownsSlenderQuery
     */
    pageSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllTownsSlenderQuery
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllTownsSlenderQuery
     */
    take?: number;
    /**
     * 
     * @type {FilterLogicalOperator}
     * @memberof GetAllTownsSlenderQuery
     */
    filterLogicalOperator?: FilterLogicalOperator;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetAllTownsSlenderQuery
     */
    filterKeys?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Array<SortingDto>}
     * @memberof GetAllTownsSlenderQuery
     */
    sortings?: Array<SortingDto> | null;
}

export function GetAllTownsSlenderQueryFromJSON(json: any): GetAllTownsSlenderQuery {
    return GetAllTownsSlenderQueryFromJSONTyped(json, false);
}

export function GetAllTownsSlenderQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllTownsSlenderQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'skip': !exists(json, 'skip') ? undefined : json['skip'],
        'take': !exists(json, 'take') ? undefined : json['take'],
        'filterLogicalOperator': !exists(json, 'filterLogicalOperator') ? undefined : FilterLogicalOperatorFromJSON(json['filterLogicalOperator']),
        'filterKeys': !exists(json, 'filterKeys') ? undefined : json['filterKeys'],
        'sortings': !exists(json, 'sortings') ? undefined : (json['sortings'] === null ? null : (json['sortings'] as Array<any>).map(SortingDtoFromJSON)),
    };
}

export function GetAllTownsSlenderQueryToJSON(value?: GetAllTownsSlenderQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'skip': value.skip,
        'take': value.take,
        'filterLogicalOperator': FilterLogicalOperatorToJSON(value.filterLogicalOperator),
        'filterKeys': value.filterKeys,
        'sortings': value.sortings === undefined ? undefined : (value.sortings === null ? null : (value.sortings as Array<any>).map(SortingDtoToJSON)),
    };
}


