import { Layout } from "antd";
import { FC, ReactNode } from "react";
import styles from "./Header.module.scss";

interface HeaderProps {
  children?: ReactNode;
}

const Header: FC<HeaderProps> = ({ children }) => {
  return (
    <Layout.Header>
      <div className={styles.topMenu}>{children}</div>
    </Layout.Header>
  );
};

export default Header;
