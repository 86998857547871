/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddAttachedFailuresCommand
 */
export interface AddAttachedFailuresCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddAttachedFailuresCommand
     */
    attachedFailureIds?: Array<string> | null;
}

export function AddAttachedFailuresCommandFromJSON(json: any): AddAttachedFailuresCommand {
    return AddAttachedFailuresCommandFromJSONTyped(json, false);
}

export function AddAttachedFailuresCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAttachedFailuresCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachedFailureIds': !exists(json, 'attachedFailureIds') ? undefined : json['attachedFailureIds'],
    };
}

export function AddAttachedFailuresCommandToJSON(value?: AddAttachedFailuresCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachedFailureIds': value.attachedFailureIds,
    };
}


