/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImageThumbnail
 */
export interface ImageThumbnail {
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnail
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnail
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnail
     */
    thumbnailData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnail
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnail
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ImageThumbnail
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnail
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ImageThumbnail
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {User}
     * @memberof ImageThumbnail
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof ImageThumbnail
     */
    modifier?: User;
    /**
     * 
     * @type {Image}
     * @memberof ImageThumbnail
     */
    image?: Image;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnail
     */
    id?: string;
}

export function ImageThumbnailFromJSON(json: any): ImageThumbnail {
    return ImageThumbnailFromJSONTyped(json, false);
}

export function ImageThumbnailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageThumbnail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'thumbnailData': !exists(json, 'thumbnailData') ? undefined : json['thumbnailData'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'image': !exists(json, 'image') ? undefined : ImageFromJSON(json['image']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ImageThumbnailToJSON(value?: ImageThumbnail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'thumbnailData': value.thumbnailData,
        'imageId': value.imageId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'image': ImageToJSON(value.image),
        'id': value.id,
    };
}


