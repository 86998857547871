import { mdiLogoutVariant } from "@mdi/js";
import { useDispatch } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import BrandImage from "components/ui/BrandImage";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./PrivateMobileHeader.module.scss";

interface PrivateMobileHeaderProps {
  userName?: string | null;
}

const PrivateMobileHeader: React.FC<PrivateMobileHeaderProps> = ({
  userName,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <Link to="/">
        <BrandImage subPath="logo.svg" className={styles.logo} />
      </Link>
      <div className={styles.names}>
        <span className={styles.userName}>{userName}</span>
      </div>
      <div
        className={styles.logoutWrapper}
        onClick={() => dispatch.auth.logout()}
      >
        <MaterialIcon path={mdiLogoutVariant} />
      </div>
    </div>
  );
};

export default PrivateMobileHeader;
