import { FC, useMemo } from "react";
import { TableDropdownFooter } from "./TableDropdownFooter";
import { Select } from "antd";
import { ExtendedFilterDropdownProps } from "./models";
import styles from "./TableDropdown.module.scss";

const filterOptionsLabelFromBeginning = (
  inputValue: string,
  option: any | undefined
) => {
  return inputValue && option
    ? option?.label.toUpperCase().startsWith(inputValue.toUpperCase())
    : true;
};

export const SelectTableDropdown: FC<ExtendedFilterDropdownProps> = (props) => {
  const { setSelectedKeys, selectedKeys, filters, loading } = props;

  const options = useMemo(
    () =>
      filters?.map((filter) => ({
        label: filter.text,
        value: filter.value,
      })),
    [filters]
  );

  return (
    <div>
      <div className={styles.inputContainer}>
        <Select
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          loading={loading}
          options={options}
          className={styles.input}
          showSearch
          optionFilterProp="label"
          filterOption={filterOptionsLabelFromBeginning}
        />
      </div>
      <TableDropdownFooter {...props} />
    </div>
  );
};
