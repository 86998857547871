import React, { useCallback, useEffect, useMemo } from "react";
import { CustomTableProps } from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { useTableSettingsUtils } from "widgets/table/table-settings/useTableSettingsUtils";
import {
  ColumnConfigParams,
  ListRequestParams,
  useTableUtils,
} from "widgets/table/useTableUtils";

export interface UseTableProps<T> {
  fetchTableData: (params: any) => Promise<any>;
  fetchAdditionalData?: () => void;
  resetListParams: () => void;
  resetStore: () => void;
  columnParams: ColumnConfigParams[];
  actionColumnParams?: Partial<ExtendedColumnType<T>>;
  listParams: ListRequestParams;
  list: any;
  columnStorageName: ColumnStorageName;
  headerOptions: React.ReactNode;
  loading?: boolean;
  disableTableSettings?: boolean;
}

function useTable<T extends { [key: string]: any }>({
  fetchTableData,
  fetchAdditionalData,
  columnParams,
  actionColumnParams,
  listParams,
  list,
  columnStorageName,
  headerOptions,
  resetListParams,
  resetStore,
  loading,
  disableTableSettings = false,
}: UseTableProps<T>): CustomTableProps {
  useEffect(() => {
    fetchTableData(undefined);
  }, [fetchTableData]);

  useEffect(() => {
    fetchAdditionalData?.();
  }, [fetchAdditionalData]);

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);

  const tableUtils = useTableUtils<T>({
    listParamsState: listParams,
    getDataFunc: fetchTableData,
    columnParams,
    actionColumnParams,
    allowMultiSort: true,
  });

  const dataSource = useMemo(
    () => tableUtils.addKeyProp(list),
    [tableUtils, list]
  );

  // Comment out this, if don't need tableSettingsUtils
  const tableSettingsUtils = useTableSettingsUtils(
    tableUtils.columnsConfig,
    columnStorageName
  );

  const resetFilters = useCallback(() => {
    resetListParams();
    fetchTableData(undefined);
  }, [fetchTableData, resetListParams]);

  const customTableProps = useMemo<CustomTableProps>(
    () => ({
      loading,
      hasHeaderOffset: true,
      hasFixedColumn: true,
      columns: !disableTableSettings
        ? tableSettingsUtils.currentColumns
        : tableUtils.columnsConfig,
      dataSource: dataSource,
      pagination: tableUtils.paginationConfig,
      onChange: tableUtils.handleTableChange,
      tableSettingsUtils: !disableTableSettings
        ? tableSettingsUtils
        : undefined,
      extraContent: headerOptions,
      resetFilters: resetFilters,
    }),
    [
      loading,
      disableTableSettings,
      tableSettingsUtils,
      tableUtils.columnsConfig,
      tableUtils.paginationConfig,
      tableUtils.handleTableChange,
      dataSource,
      headerOptions,
      resetFilters,
    ]
  );

  return customTableProps;
}

export default useTable;
