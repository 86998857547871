import { Select, SelectProps } from "antd";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";
import { DictionaryGroupType } from "models/common";
import { forwardRef, Ref, useMemo } from "react";
import { dictionaryOptions, filterOptionsLabelFromBeginning } from "utils";

interface DictionarySelectProps
  extends Omit<SelectProps, "options" | "loading" | "filterOption"> {
  dictionaryGroupType: DictionaryGroupType;
  dictionaryIsEditableInApp?: boolean;
}

const DictionarySelect = forwardRef(
  (
    {
      dictionaryGroupType,
      dictionaryIsEditableInApp = false,
      ...rest
    }: DictionarySelectProps,
    ref?: Ref<any>
  ) => {
    const query = useDictionariesQuery({
      dictionaryGroupType,
      dictionaryIsEditableInApp,
    });

    const options = useMemo(
      () => dictionaryOptions(query.data || []),
      [query.data]
    );

    return (
      <Select
        ref={ref}
        size="large"
        showSearch
        optionFilterProp="label"
        options={options}
        loading={query.isFetching}
        filterOption={filterOptionsLabelFromBeginning}
        {...rest}
      />
    );
  }
);

export default DictionarySelect;
