import { Col, Input, Row } from "antd";
import { useWatch } from "antd/lib/form/Form";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import StreetInput from "components/form/StreetInput";
import TownInput from "components/form/TownInput";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useConfigurationValidationRules from "hooks/useConfigurationValidationRules";
import { ExtendedSelectValue } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { extendedSelectValueRequired } from "utils/form";
import { GrapeAntdForm, phoneNumber, requiredField, min } from "widgets/form";

const MunicipalityFieldset: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const cityFormValue = useWatch<ExtendedSelectValue | undefined>(
    ["municipalityAddress", "city"],
    form
  );

  const zipPatternRules = useConfigurationValidationRules("zipPattern");

  return (
    <>
      <fieldset>
        <legend>{t("town.municipalityFieldset")}</legend>

        <FieldsetFieldsWrapper>
          <Row justify="center" gutter={[24, 8]}>
            <Col span={24}>
              <GrapeAntdForm.Item
                name="municipalityName"
                label={t("town.municipalityName")}
                rules={[requiredField(t)]}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={4}>
              <GrapeAntdForm.Item
                name={["municipalityAddress", "zip"]}
                label={t("town.zip")}
                rules={zipPatternRules}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={8}>
              <GrapeAntdForm.Item
                name={["municipalityAddress", "city"]}
                label={t("town.city")}
                rules={[extendedSelectValueRequired(t)]}
              >
                <TownInput size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={8}>
              <GrapeAntdForm.Item
                name={["municipalityAddress", "street"]}
                label={t("town.street")}
                rules={[requiredField(t)]}
              >
                <StreetInput size="large" townId={cityFormValue?.value} />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={4}>
              <GrapeAntdForm.Item
                name={["municipalityAddress", "number"]}
                label={t("town.houseNumber")}
                rules={[requiredField(t)]}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name="email"
                label={t("town.email")}
                rules={[requiredField(t)]}
              >
                <Input size="large" type="email" />
              </GrapeAntdForm.Item>
            </Col>
            <Col span={12}>
              <GrapeAntdForm.Item
                name={["phone", "number"]}
                label={t("town.phone")}
                rules={[phoneNumber(t), min(t, 6, "string")]}
              >
                <Input size="large" type="phone" />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </FieldsetFieldsWrapper>
      </fieldset>
    </>
  );
};

export default MunicipalityFieldset;
