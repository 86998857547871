import { FC, useRef, useEffect } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { TableDropdownFooter } from "./TableDropdownFooter";

import styles from "./TableDropdown.module.scss";

export const SearchTableDropdown: FC<FilterDropdownProps> = (props) => {
  const { setSelectedKeys, selectedKeys, confirm, visible } = props;
  const { t } = useTranslation();

  const inputRef = useRef<any>();

  useEffect(() => {
    if (visible) {
      setTimeout(() => inputRef.current.focus(), 0);
    }
  }, [visible]);

  return (
    <div>
      <div className={styles.inputContainer}>
        <Input
          className={styles.input}
          ref={inputRef}
          autoFocus={visible}
          placeholder={t("common.search")}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm({ closeDropdown: true })}
        />
      </div>
      <TableDropdownFooter {...props} />
    </div>
  );
};
