import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateExamTypeCommand,
  UpdateExamTypeCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface ExamCategoryCreateState {
  editingItemId?: string;
}

const initialState: ExamCategoryCreateState = {
  editingItemId: undefined,
};

export const examTypeCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    resetEditingItemData(state) {
      state.editingItemId = undefined;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      async create(values: CreateExamTypeCommand): Promise<string> {
        return api.lumen.examTypes.apiExamTypesPost({
          createExamTypeCommand: values,
        });
      },

      async edit(values: UpdateExamTypeCommand): Promise<void> {
        return api.lumen.examTypes.apiExamTypesIdPut({
          id: values.id,
          updateExamTypeCommand: values,
        });
      },
    };
  },
});
