import { createModel } from "@rematch/core";
import { RootModel } from "app/store";
import { Breadcrumb, DeviceLayoutType } from "models/common";

interface CommonState {
  currentPageTitle?: string;
  deviceLayoutType?: DeviceLayoutType;
  breadCrumbs: Breadcrumb[];
}

const initialState: CommonState = {
  currentPageTitle: undefined,
  deviceLayoutType: undefined,
  breadCrumbs: [],
};

export const common = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrentPageTitle(state, data: string | undefined) {
      state.currentPageTitle = data;
    },
    setDeviceLayoutType(state, data: DeviceLayoutType) {
      state.deviceLayoutType = data;
    },
    setBreadcrumbs(state, data: Breadcrumb[]) {
      state.breadCrumbs = data;
    },
    reset: () => initialState,
  },
});
