import { Col, Input, Radio, Row, Select, Space, Spin, Tag } from "antd";
import { useWatch } from "antd/lib/form/Form";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import { TownCreateFormValues } from "features/towns/models";
import { FormMode, ItemProps } from "models/common";
import React, { useEffect, useMemo } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { dictionaryOptions, filterOptionsLabelFromBeginning } from "utils";
import { email, FormUtils, GrapeAntdForm, requiredField } from "widgets/form";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import styles from "./BaseDataFieldset.module.scss";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";

interface BaseDataFieldsetProps {
  formMode: FormMode;
  formUtils: FormUtils<TownCreateFormValues>;
  notActivatedTownsOptions: ItemProps[];
  notActivatedTownsOptionsLoading: boolean;
  handleTownChange: (value: any) => void;
  visible?: boolean;
}

const emailsSelectTagRender =
  (t: TFunction): any =>
  ({ label, value, closable, onClose }: CustomTagProps) => {
    const validEmailAddress = email(t).pattern?.test(value);

    return (
      <Tag
        color={validEmailAddress ? styles.primaryColor : styles.errorColor}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

const BaseDataFieldset: React.FC<BaseDataFieldsetProps> = ({
  formMode,
  formUtils,
  notActivatedTownsOptions,
  notActivatedTownsOptionsLoading,
  handleTownChange,
  visible,
}) => {
  const sectionActionsQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenSectionActions",
  });

  const emailSectionActionId = useMemo(
    () => sectionActionsQuery.data?.find((item) => item.value === "Email")?.id,
    [sectionActionsQuery.data]
  );

  const sectionActionOptions = useMemo(
    () => dictionaryOptions(sectionActionsQuery.data || []),
    [sectionActionsQuery.data]
  );

  const { t } = useTranslation();
  const sectionActionIdValue = useWatch<string | undefined>(
    ["sectionActionId"],
    formUtils.form
  );

  useEffect(() => {
    if (visible && formMode === "Create" && emailSectionActionId) {
      formUtils.form.setFieldsValue({
        sectionActionId: emailSectionActionId,
      });
    }
  }, [emailSectionActionId, formMode, formUtils.form, visible]);

  return (
    <fieldset>
      <legend>{t("town.baseDataFieldset")}</legend>

      <FieldsetFieldsWrapper>
        <Row gutter={[24, 8]}>
          <Col span={24}>
            {formMode === "Create" ? (
              <>
                <GrapeAntdForm.Item
                  name="townId"
                  rules={[requiredField(t)]}
                  label={t("town.city")}
                >
                  <Select
                    placeholder={t("town.cityPlaceholder")}
                    size="large"
                    options={notActivatedTownsOptions}
                    onChange={handleTownChange}
                    loading={notActivatedTownsOptionsLoading}
                    showSearch
                    optionFilterProp="label"
                    filterOption={filterOptionsLabelFromBeginning}
                  />
                </GrapeAntdForm.Item>
                <GrapeAntdForm.Item name="name" hidden>
                  <Input hidden />
                </GrapeAntdForm.Item>
              </>
            ) : (
              <GrapeAntdForm.Item name="name">
                <Input disabled size="large" />
              </GrapeAntdForm.Item>
            )}
          </Col>

          <Col span={24}>
            <GrapeAntdForm.Item
              name="sectionActionId"
              label={t("town.createModal.forwardTo")}
              required
              rules={[
                requiredField(
                  t,
                  undefined,
                  undefined,
                  t("town.createModal.sectionActionIdRequired")
                ),
              ]}
            >
              {sectionActionOptions.length ? (
                <Radio.Group>
                  <Space direction="vertical">
                    {sectionActionOptions.map((sectionAction: ItemProps) => (
                      <Radio
                        value={sectionAction.value}
                        key={`${sectionAction.value}`}
                      >
                        {sectionAction.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              ) : (
                <Spin />
              )}
            </GrapeAntdForm.Item>
          </Col>

          {sectionActionIdValue === emailSectionActionId && (
            <Col span={24}>
              <div translate="no">
                <GrapeAntdForm.Item
                  name="sectionFailureEmailTo"
                  label={t("town.createModal.sectionFailureEmailTo")}
                  required
                  rules={[
                    requiredField(t, undefined, {
                      type: "array",
                      defaultField: email(t),
                    }),
                  ]}
                >
                  <Select
                    mode="tags"
                    size="large"
                    open={false}
                    tokenSeparators={[",", ";"]}
                    tagRender={emailsSelectTagRender(t)}
                  />
                </GrapeAntdForm.Item>
              </div>
            </Col>
          )}
        </Row>
      </FieldsetFieldsWrapper>
    </fieldset>
  );
};

export default BaseDataFieldset;
