/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageThumbnailVm
 */
export interface ImageThumbnailVm {
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnailVm
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnailVm
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnailVm
     */
    thumbnailData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnailVm
     */
    contentType?: string | null;
}

export function ImageThumbnailVmFromJSON(json: any): ImageThumbnailVm {
    return ImageThumbnailVmFromJSONTyped(json, false);
}

export function ImageThumbnailVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageThumbnailVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'thumbnailData': !exists(json, 'thumbnailData') ? undefined : json['thumbnailData'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
    };
}

export function ImageThumbnailVmToJSON(value?: ImageThumbnailVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'thumbnailData': value.thumbnailData,
        'contentType': value.contentType,
    };
}


