import { Descriptions, Space, Typography } from "antd";
import { FailureVm } from "api/generated/lumen";
import NameDisplay from "components/ui/NameDisplay";
import PhoneDisplay from "components/ui/PhoneDisplay";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import styles from "../FailureViewDefault.module.scss";

interface FailureReporterDataProps {
  failure?: FailureVm;
}

const FailureReporterData: React.FC<FailureReporterDataProps> = ({
  failure,
}) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="large">
      <Typography.Title level={5} className={styles.headerTitle}>
        {t("failure.reporter")}
      </Typography.Title>

      <VerticalDataDescriptions column={3}>
        <Descriptions.Item label={t("common.name")}>
          <NameDisplay
            value={{
              familyname: failure?.notifierFamilyname,
              forename: failure?.notifierForename,
              middlename: failure?.notifierMiddlename,
            }}
          />
        </Descriptions.Item>

        <Descriptions.Item label={t("common.phone")}>
          <PhoneDisplay value={failure?.notifierPhone} />
        </Descriptions.Item>

        <Descriptions.Item label={t("common.email")}>
          {failure?.notifierEmail ? (
            <a href={`mailto:${failure?.notifierEmail}`}>
              {failure?.notifierEmail}
            </a>
          ) : (
            <EmptyContent />
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.creatorType")}>
          {failure?.creatorType?.displayName ?? <EmptyContent />}
        </Descriptions.Item>
      </VerticalDataDescriptions>
    </Space>
  );
};

export default FailureReporterData;
