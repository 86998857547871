import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "api";
import {
  OrderByType,
  SlenderTownVmPaginatedSearchResponse,
} from "api/generated/lumen";
import { MILISECONDS_24H } from "models/constants";
import { queryKeys } from ".";

const useAllSlenderTownsQuery = (): UseQueryResult<
  SlenderTownVmPaginatedSearchResponse,
  unknown
> => {
  const query = useQuery(
    queryKeys.towns.allSlender,
    () =>
      api.lumen.towns.apiTownsGetAllSlenderPost({
        getAllTownsSlenderQuery: {
          pageSize: -1,
          sortings: [{ orderBy: "name", orderByType: OrderByType.Ascend }],
        },
      }),
    {
      cacheTime: MILISECONDS_24H,
      staleTime: MILISECONDS_24H,
    }
  );

  return query;
};

export default useAllSlenderTownsQuery;
