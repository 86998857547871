/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateExamTypeCommand
 */
export interface UpdateExamTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateExamTypeCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExamTypeCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExamTypeCommand
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExamTypeCommand
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateExamTypeCommand
     */
    warningDaysBeforeExpiration?: number | null;
}

export function UpdateExamTypeCommandFromJSON(json: any): UpdateExamTypeCommand {
    return UpdateExamTypeCommandFromJSONTyped(json, false);
}

export function UpdateExamTypeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateExamTypeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'warningDaysBeforeExpiration': !exists(json, 'warningDaysBeforeExpiration') ? undefined : json['warningDaysBeforeExpiration'],
    };
}

export function UpdateExamTypeCommandToJSON(value?: UpdateExamTypeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'warningDaysBeforeExpiration': value.warningDaysBeforeExpiration,
    };
}


