import { Select, SelectProps } from "antd";
import useAllSlenderTownsQuery from "hooks/queries/useAllSlenderTownsQuery";
import { ExtendedSelectValue, ItemProps } from "models/common";
import React, { useMemo } from "react";
import { filterOptionsLabelFromBeginning } from "utils";

type TownInputProps = Omit<SelectProps, "options">;

const TownInput: React.FC<TownInputProps> = (props) => {
  const query = useAllSlenderTownsQuery();

  const options = useMemo<ItemProps[]>(
    () =>
      query.data?.items?.map((item) => ({
        label: item.name || "",
        value: item.id || "",
      })) || [],
    [query.data]
  );

  return (
    <Select<ExtendedSelectValue, ItemProps>
      showSearch
      optionFilterProp="label"
      filterOption={filterOptionsLabelFromBeginning}
      {...props}
      options={options}
      loading={query.isLoading}
      labelInValue
    />
  );
};

export default TownInput;
