import { RuleObject } from "antd/lib/form";
import { TFunction } from "react-i18next";
import { ExtendedSelectValue } from "models/common";
import { requiredField } from "widgets/form";

export const extendedSelectValueRequired = (t: TFunction): RuleObject =>
  requiredField(t, undefined, {
    type: "object",
    validator: (rule, value: ExtendedSelectValue) =>
      value.label !== "" && value.value !== ""
        ? Promise.resolve()
        : Promise.reject(new Error()), // In case of old invalid data in DB
  });
