import { ModalProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CustomModal from "components/ui/CustomModal";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface RepairerCommentModalProps extends ModalProps {
  loading?: boolean;
  comment?: string | null;
  setComment?: React.Dispatch<React.SetStateAction<any>>;
  required: boolean;
}

const RepairerCommentModal: React.FC<RepairerCommentModalProps> = ({
  onCancel,
  onOk,
  visible,
  comment,
  setComment,
  loading,
  required,
  ...rest
}) => {
  const { t } = useTranslation();

  const canSubmit = useMemo(
    () => !required || (comment && comment.length > 0),
    [comment, required]
  );

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={t("failure.stateChangeReason")}
      size="large"
      destroyOnClose
      okButtonProps={{ loading: loading, disabled: !canSubmit ?? true }}
      cancelButtonProps={{ disabled: loading }}
    >
      <TextArea
        onChange={(e) => {
          setComment?.(e.target.value);
        }}
        value={comment || ""}
        rows={6}
      />
    </CustomModal>
  );
};

export default RepairerCommentModal;
