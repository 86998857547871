import { mdiPencil } from "@mdi/js";
import { Descriptions, Row, Spin, Tabs, Tag, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import StretchToCardEdge from "components/StretchToCardEdge";
import AddressDisplay from "components/ui/AddressDisplay";
import CardContent from "components/ui/CardContent";
import CardTitle from "components/ui/CardTitle";
import IconButton from "components/ui/IconButton";
import TabsWrapper from "components/ui/TabsWrapper";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import useFeature from "hooks/useFeature";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import ContractorCreateModal from "../create/ContractorCreateModal";
import ContactsTab from "./components/ContactsTab";
import DocumentsTab from "./components/DocumentsTab";
import MaintainedDevicesTab from "./components/MaintainedDevicesTab";
import RepairmenByContractorTab from "./components/RepairmenByContractorTab";
import SpecialPlacesTab from "./components/SpecialPlacesTab";
import styles from "./ContractorView.module.scss";

const ContractorView: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { contractor } = useSelector((state) => state.contractorView);
  const { fetchContractor } = useSelector(
    (state) => state.loading.effects.contractorView
  );
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const handleEditButtonClick = useCallback(() => {
    setEditModalOpen(true);
  }, []);

  const fetchContractorById = useCallback(
    (id?: string) => {
      if (typeof id === "string") {
        dispatch.contractorView.fetchContractor(id);
      }
    },
    [dispatch.contractorView]
  );

  const handleAfterDocumentUpload = useCallback(() => {
    fetchContractorById(params.id);

    setEditModalOpen(false);
  }, [fetchContractorById, params.id]);

  const handleAfterDocumentDelete = useCallback(() => {
    fetchContractorById(params.id);
  }, [fetchContractorById, params.id]);

  useEffect(() => {
    fetchContractorById(params.id);
  }, [fetchContractorById, params.id]);

  useEffect(() => {
    dispatch.common.setBreadcrumbs([
      {
        title: t("menu.contractors"),
        to: "/contractors",
      },
      {
        title: contractor?.name || "",
      },
    ]);

    return () => {
      dispatch.common.setBreadcrumbs([]);
    };
  }, [contractor?.name, dispatch.common, t]);

  useEffect(() => {
    return () => {
      dispatch.contractorView.reset();
    };
  }, [dispatch.contractorView]);

  return (
    <>
      <CardTitle className={styles.headerDescriptionsRow}>
        <VerticalDataDescriptions column={1}>
          <Descriptions.Item label={t("contractor.partner")}>
            {contractor?.name ? (
              <Row justify="space-between">
                <Typography.Title level={2}>
                  {contractor?.name}
                </Typography.Title>
              </Row>
            ) : (
              <EmptyContent />
            )}
          </Descriptions.Item>
        </VerticalDataDescriptions>

        <div className={styles.tagWrapper}>
          {contractor?.isActive ? (
            <Tag color="success">{t("common.active")}</Tag>
          ) : (
            <Tag color="error">{t("common.inactive")}</Tag>
          )}
        </div>
        <IconButton path={mdiPencil} onClick={() => handleEditButtonClick()} />
      </CardTitle>

      <CardContent>
        <Spin spinning={fetchContractor.loading}>
          <VerticalDataDescriptions>
            <Descriptions.Item label={t("contractor.headquarterAddress")}>
              <AddressDisplay value={contractor?.headquarterAddress} />
            </Descriptions.Item>
            <Descriptions.Item label={t("contractor.postalAddress")}>
              <AddressDisplay value={contractor?.postalAddress} />
            </Descriptions.Item>
            <Descriptions.Item label={t("contractor.companyregisterNumber")}>
              {contractor?.companyRegisterNumber || <EmptyContent />}
            </Descriptions.Item>
            <Descriptions.Item label={t("contractor.taxnumber")}>
              {contractor?.taxNumber || <EmptyContent />}
            </Descriptions.Item>
            <Descriptions.Item label={t("contractor.email")}>
              {contractor?.email || <EmptyContent />}
            </Descriptions.Item>
            <Descriptions.Item label={t("contractor.phone")}>
              {contractor?.phone?.number || <EmptyContent />}
            </Descriptions.Item>
            {!isMaintainedDevicesFeatureEnabled && (
              <Descriptions.Item label={t("contractor.towns")}>
                {contractor?.towns?.map((town) => town.name).join(", ") || (
                  <EmptyContent />
                )}
              </Descriptions.Item>
            )}
          </VerticalDataDescriptions>

          <TabsWrapper extraMargin>
            <StretchToCardEdge>
              <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane
                type="card"
                size="small"
              >
                <Tabs.TabPane tab={t("contractor.tabs.contacts")} key="1">
                  <ContactsTab />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("contractor.tabs.repairmen")} key="2">
                  <RepairmenByContractorTab />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("contractor.tabs.documents")} key="3">
                  <DocumentsTab
                    afterDocumentUpload={handleAfterDocumentUpload}
                    afterDocumentDelete={handleAfterDocumentDelete}
                  />
                </Tabs.TabPane>

                {isMaintainedDevicesFeatureEnabled && (
                  <>
                    <Tabs.TabPane
                      tab={t("contractor.tabs.specialPlaces")}
                      key="4"
                    >
                      <SpecialPlacesTab />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={t("contractor.tabs.maintainedDevices")}
                      key="5"
                    >
                      <MaintainedDevicesTab />
                    </Tabs.TabPane>
                  </>
                )}
              </Tabs>
            </StretchToCardEdge>
          </TabsWrapper>
        </Spin>
      </CardContent>

      <ContractorCreateModal
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
      />
    </>
  );
};

export default ContractorView;
