/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleFailureTypeVm
 */
export interface SimpleFailureTypeVm {
    /**
     * 
     * @type {string}
     * @memberof SimpleFailureTypeVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleFailureTypeVm
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleFailureTypeVm
     */
    specialCase?: boolean | null;
}

export function SimpleFailureTypeVmFromJSON(json: any): SimpleFailureTypeVm {
    return SimpleFailureTypeVmFromJSONTyped(json, false);
}

export function SimpleFailureTypeVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleFailureTypeVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'specialCase': !exists(json, 'specialCase') ? undefined : json['specialCase'],
    };
}

export function SimpleFailureTypeVmToJSON(value?: SimpleFailureTypeVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'specialCase': value.specialCase,
    };
}


