/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldRequireTypes,
    FieldRequireTypesFromJSON,
    FieldRequireTypesFromJSONTyped,
    FieldRequireTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailureStatusVm
 */
export interface FailureStatusVm {
    /**
     * 
     * @type {string}
     * @memberof FailureStatusVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FailureStatusVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureStatusVm
     */
    alternativeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureStatusVm
     */
    value?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureStatusVm
     */
    isSpecialCase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FailureStatusVm
     */
    isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FailureStatusVm
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FailureStatusVm
     */
    requiresWorkbookTask?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FailureStatusVm
     */
    requiresComment?: boolean;
    /**
     * 
     * @type {FieldRequireTypes}
     * @memberof FailureStatusVm
     */
    requiresStateChangeReason?: FieldRequireTypes;
}

export function FailureStatusVmFromJSON(json: any): FailureStatusVm {
    return FailureStatusVmFromJSONTyped(json, false);
}

export function FailureStatusVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureStatusVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alternativeName': !exists(json, 'alternativeName') ? undefined : json['alternativeName'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'isSpecialCase': !exists(json, 'isSpecialCase') ? undefined : json['isSpecialCase'],
        'isSealed': !exists(json, 'isSealed') ? undefined : json['isSealed'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'requiresWorkbookTask': !exists(json, 'requiresWorkbookTask') ? undefined : json['requiresWorkbookTask'],
        'requiresComment': !exists(json, 'requiresComment') ? undefined : json['requiresComment'],
        'requiresStateChangeReason': !exists(json, 'requiresStateChangeReason') ? undefined : FieldRequireTypesFromJSON(json['requiresStateChangeReason']),
    };
}

export function FailureStatusVmToJSON(value?: FailureStatusVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'alternativeName': value.alternativeName,
        'value': value.value,
        'isSpecialCase': value.isSpecialCase,
        'isSealed': value.isSealed,
        'isDefault': value.isDefault,
        'requiresWorkbookTask': value.requiresWorkbookTask,
        'requiresComment': value.requiresComment,
        'requiresStateChangeReason': FieldRequireTypesToJSON(value.requiresStateChangeReason),
    };
}


