import { Avatar, Space } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import CustomText from "components/typography/CustomText";
import styles from "./ProfileMenu.module.scss";
import { mdiAccount } from "@mdi/js";

interface ProfileMenuProps {
  userName?: string | null;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ userName }) => {
  return (
    <Space>
      <Avatar
        size={32}
        icon={<MaterialIcon path={mdiAccount} className={styles.icon} />}
        className={styles.avatar}
      />
      <div className={styles.textsWrapper}>
        <div>
          <CustomText size="lg" strong>
            {userName}
          </CustomText>
        </div>
      </div>
    </Space>
  );
};

export default ProfileMenu;
