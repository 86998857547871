import { mdiLogoutVariant } from "@mdi/js";
import { useDispatch } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import useAuth from "features/auth/useAuth";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarDivider,
  SidebarMenu,
  SideMenuOptionProps,
} from "widgets/layout/sidebar-header-layout";
import styles from "./SidebarFooterMenu.module.scss";

const SidebarFooterMenu: React.FC = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuth();
  const dispatch = useDispatch();

  const footerOptions = useMemo<SideMenuOptionProps[]>(
    () => [
      {
        label: t("menu.logout"),
        icon: <MaterialIcon path={mdiLogoutVariant} />,
        onClick: () => dispatch.auth.logout(),
      },
    ],
    [dispatch.auth, t]
  );

  return (
    <>
      {!!process.env.REACT_APP_SHOW_BUILD_NUMBER && (
        <span className={styles.versionNumber}>
          v{process.env.REACT_APP_BUILD_NUMBER}
        </span>
      )}
      <SidebarDivider />

      <SidebarMenu
        options={footerOptions}
        menuItemClassName={styles.footerMenuItem}
        hasRole={hasRole}
      />
    </>
  );
};

export default SidebarFooterMenu;
