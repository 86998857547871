/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachContactPersonToTownCommand,
    AttachContactPersonToTownCommandFromJSON,
    AttachContactPersonToTownCommandToJSON,
    ContactPersonVmPaginatedSearchResponse,
    ContactPersonVmPaginatedSearchResponseFromJSON,
    ContactPersonVmPaginatedSearchResponseToJSON,
    CreateContactPersonCommand,
    CreateContactPersonCommandFromJSON,
    CreateContactPersonCommandToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllContactPersonsQuery,
    GetAllContactPersonsQueryFromJSON,
    GetAllContactPersonsQueryToJSON,
} from '../models';

export interface ApiContactPersonsAttachContactPersonToTownPutRequest {
    xLUMENTransactionGuid?: string;
    attachContactPersonToTownCommand?: AttachContactPersonToTownCommand;
}

export interface ApiContactPersonsGetAllPostRequest {
    xLUMENTransactionGuid?: string;
    getAllContactPersonsQuery?: GetAllContactPersonsQuery;
}

export interface ApiContactPersonsPostRequest {
    xLUMENTransactionGuid?: string;
    createContactPersonCommand?: CreateContactPersonCommand;
}

/**
 * 
 */
export class ContactPersonsApi extends runtime.BaseAPI {

    /**
     * Attaches an existing contact person to a town if both exist and not attached yet
     */
    async apiContactPersonsAttachContactPersonToTownPutRaw(requestParameters: ApiContactPersonsAttachContactPersonToTownPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ContactPersons/AttachContactPersonToTown`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttachContactPersonToTownCommandToJSON(requestParameters.attachContactPersonToTownCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attaches an existing contact person to a town if both exist and not attached yet
     */
    async apiContactPersonsAttachContactPersonToTownPut(requestParameters: ApiContactPersonsAttachContactPersonToTownPutRequest): Promise<void> {
        await this.apiContactPersonsAttachContactPersonToTownPutRaw(requestParameters);
    }

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiContactPersonsGetAllPostRaw(requestParameters: ApiContactPersonsGetAllPostRequest): Promise<runtime.ApiResponse<ContactPersonVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ContactPersons/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllContactPersonsQueryToJSON(requestParameters.getAllContactPersonsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactPersonVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiContactPersonsGetAllPost(requestParameters: ApiContactPersonsGetAllPostRequest): Promise<ContactPersonVmPaginatedSearchResponse> {
        const response = await this.apiContactPersonsGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a contact person
     */
    async apiContactPersonsPostRaw(requestParameters: ApiContactPersonsPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/ContactPersons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactPersonCommandToJSON(requestParameters.createContactPersonCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a contact person
     */
    async apiContactPersonsPost(requestParameters: ApiContactPersonsPostRequest): Promise<string> {
        const response = await this.apiContactPersonsPostRaw(requestParameters);
        return await response.value();
    }

}
