import { Col, Input, ModalProps, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FailureVm, FieldRequireTypes } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomDatePicker from "components/CustomDatePicker";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import dayjs from "dayjs";
import useActivatedPublicTownsQuery from "hooks/queries/useActivatedPublicTownsQuery";
import useFailureStatusesQuery from "hooks/queries/useFailureStatusesQuery";
import useEditFormModal from "hooks/useEditModal";
import useFeature from "hooks/useFeature";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { DateFormat } from "widgets/table/DateDisplay";
import {
  FailureCreateFormValues,
  mapToCreateFailureCommand,
  mapToUpdateFailureCommand,
} from "../models";
import FailureAttributesFieldset from "./components/FailureAttributesFieldset";
import FailureDeviceFieldset from "./components/FailureDeviceFieldset";
import FailureLocationFieldset from "./components/FailureLocationFieldset";
import FailureNotifierFieldset from "./components/FailureNotifierFieldset";
import useAuth from "features/auth/useAuth";

const FailureCreateModal: React.FC<ModalProps> = ({
  onCancel,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<FailureCreateFormValues>(
    "CreateFailureCommand"
  );
  const dispatch = useDispatch();
  const failureStatusesQuery = useFailureStatusesQuery();
  const { hasRole } = useAuth();

  const { create, edit } = useSelector(
    (state) => state.loading.effects.failureCreate
  );

  const { editingItemId, closeDateBackwardDays, prePopulatedValues } =
    useSelector((state) => state.failureCreate);

  const { failure } = useSelector((state) => state.failureView);
  const activatedTownsQuery = useActivatedPublicTownsQuery();

  const [selectedStatusId, setSelectedStatusId] = useState<
    string | undefined
  >();
  const selectedStatus = useMemo(
    () =>
      failureStatusesQuery.data?.find((item) => item.id === selectedStatusId),
    [failureStatusesQuery.data, selectedStatusId]
  );

  const { isEnabled: isFailureListParam1Enabled } =
    useFeature("failureListParam1");
  const { isEnabled: isFailureListParam2Enabled } =
    useFeature("failureListParam2");
  const { isEnabled: isFailureDescription1Enabled } = useFeature(
    "failureDescription1"
  );
  const { isEnabled: isFailureDescription2Enabled } = useFeature(
    "failureDescription2"
  );
  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  const getInitialValuesForEditMode = useCallback(
    (itemData: FailureVm): FailureCreateFormValues => {
      return {
        id: itemData.id,
        townId:
          activatedTownsQuery.data?.items?.find(
            (item) => item.name === itemData.address?.city
          )?.id || "",
        address: {
          zip: itemData.address?.zip,
          street: itemData.address?.street || "",
          number: itemData.address?.number || "",
        },
        typeId: itemData.type?.id || "",
        expanseId: isFailureListParam1Enabled
          ? itemData.expanse?.id
          : undefined,
        natureId: isFailureListParam2Enabled ? itemData.nature?.id : undefined,
        comment: itemData.comment || "",
        statusId: itemData.status?.id,
        stateChangeReason: itemData.stateChangeReason || "",
        description1: isFailureDescription1Enabled
          ? itemData.description1 || undefined
          : undefined,
        description2: isFailureDescription2Enabled
          ? itemData.description2 || undefined
          : undefined,
        deviceId: itemData.deviceId || undefined,
        contractorId: itemData.contractor?.id,
        notifierFamilyname: itemData.notifierFamilyname || undefined,
        notifierForename: itemData.notifierForename || undefined,
        notifierEmail: itemData.notifierEmail || undefined,
        notifierPhone: itemData.notifierPhone,
      };
    },
    [
      activatedTownsQuery.data?.items,
      isFailureDescription1Enabled,
      isFailureDescription2Enabled,
      isFailureListParam1Enabled,
      isFailureListParam2Enabled,
    ]
  );

  const closedDateDisabledDates = useCallback(
    (current: dayjs.Dayjs): boolean => {
      return typeof closeDateBackwardDays !== "number"
        ? true
        : current.isBefore(
            dayjs().subtract(closeDateBackwardDays + 1, "days")
          ) || current.isAfter(dayjs());
    },
    [closeDateBackwardDays]
  );

  const { formMode } = useEditFormModal<FailureVm, FailureCreateFormValues>({
    item: failure,
    formUtils,
    getInitialValues: getInitialValuesForEditMode,
    modalIsVisible: visible,
    resetItemId: dispatch.failureCreate.setEditingItemId,
    itemId: editingItemId,
    hasViewMode: false,
  });

  const handleFinish = useCallback(
    async (values: FailureCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.failureCreate.create(
          mapToCreateFailureCommand(
            values,
            activatedTownsQuery.data?.items || []
          )
        );
      }

      if (formMode === "Edit") {
        await dispatch.failureCreate.edit(
          mapToUpdateFailureCommand(
            values,
            activatedTownsQuery.data?.items || []
          )
        );
      }

      dispatch.failureCreate.setIsSaved(true);

      // Add a slight delay to let the property change propagte through the app
      await new Promise((resolve) => setTimeout(resolve, 10));

      onCancel?.("" as any);
      formUtils.form.resetFields();
      dispatch.failureCreate.setIsSaved(false);
    },
    [
      activatedTownsQuery.data?.items,
      dispatch.failureCreate,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const failureStatusOptions = useMemo(
    () =>
      failureStatusesQuery.data?.map((item) => ({
        label: item.name || "",
        value: item.id || "",
        requiresStateChangeReason: item.requiresStateChangeReason,
      })),
    [failureStatusesQuery.data]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  // The Notifier data must be filled if a user with CallCenter role creates a new failure, or modifies a failure which were created with notifier data
  const showNotifierFieldset = useCallback(
    (failure?: FailureVm) =>
      hasRole(["CallCenter"]) &&
      (formMode === "Create" ||
        (failure?.notifierEmail &&
          failure?.notifierForename &&
          failure.notifierFamilyname)),
    [formMode, hasRole]
  );

  useEffect(() => {
    if (prePopulatedValues) {
      formUtils.form.setFieldsValue(prePopulatedValues);
    }
  }, [formUtils.form, prePopulatedValues]);

  useEffect(() => {
    if (visible) {
      dispatch.failureCreate.fetchFailureTypes();
      dispatch.failureCreate.fetchCloseDateBackwardDays();
    }
  }, [dispatch.dictionaries, dispatch.failureCreate, visible]);

  useEffect(() => {
    // clear out state change reason if while editing, an active status is selected (due to active status options are not disabled when an inactive status is selected)
    // the edit button is not visible when viewing an inactive failure
    const requiresStateChangeReason = failureStatusOptions?.find(
      (status) => status.value === selectedStatus?.value
    )?.requiresStateChangeReason;
    if (requiresStateChangeReason === FieldRequireTypes.NotAllowed) {
      formUtils.form.setFieldsValue({ stateChangeReason: undefined });
    }

    // Clear any errors to make sure that the rules did not leave any irrelevant error message
    formUtils.form.setFields([{ name: "stateChangeReason", errors: [] }]);
  }, [failureStatusOptions, formUtils.form, selectedStatus]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("failure.createModal.title")
          : t("failure.editModal.title")
      }
      size="xl"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: create.loading || edit.loading }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      longModal
    >
      <p className="text-center">
        {formMode === "Create"
          ? t("failure.createModal.description")
          : t("failure.editModal.description")}
      </p>

      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>
        <GrapeAntdForm.Item name="contractorId" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        {formMode === "Edit" && (
          <Row gutter={24} justify="center">
            <Col span={12}>
              <GrapeAntdForm.Item
                label={t("failure.status")}
                name="statusId"
                rules={[requiredField(t)]}
              >
                <Select
                  size="large"
                  options={failureStatusOptions}
                  loading={failureStatusesQuery.isLoading}
                  onChange={(value) => setSelectedStatusId(value)}
                />
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                label={t("failure.stateChangeReason")}
                name="stateChangeReason"
                rules={
                  selectedStatus?.requiresStateChangeReason ===
                  FieldRequireTypes.Required
                    ? [requiredField(t)]
                    : undefined
                }
              >
                <TextArea
                  maxLength={300}
                  rows={6}
                  disabled={
                    selectedStatus?.requiresStateChangeReason ===
                    FieldRequireTypes.NotAllowed
                  }
                />
              </GrapeAntdForm.Item>
              {selectedStatus?.isSealed && (
                <GrapeAntdForm.Item
                  label={t("failure.closedDate")}
                  name="closedDate"
                  initialValue={dayjs()}
                  rules={[requiredField(t, undefined, { type: "object" })]}
                >
                  <CustomDatePicker
                    size="large"
                    format={DateFormat.Date}
                    disabledDate={closedDateDisabledDates}
                    allowClear={false}
                  />
                </GrapeAntdForm.Item>
              )}
            </Col>
          </Row>
        )}

        {isMaintainedDevicesEnabled ? (
          <FailureDeviceFieldset />
        ) : (
          <FailureLocationFieldset />
        )}
        <FailureAttributesFieldset editingFailuresType={failure?.type} />

        {showNotifierFieldset(failure) && (
          <FailureNotifierFieldset isEmailRequired={false} />
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default FailureCreateModal;
