import { ContractorVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { GetFilterParamsForGetRequests } from "models/common";
import { calculateFilterRequestParams } from "utils";
import {
  ListRequestParams,
  mergeListParams,
} from "widgets/table/useTableUtils";

export const getFilterParamsForContractorsGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  const newParams: ListRequestParams = {
    ...params,
    filter: {
      ...params.filter,
    },
  };

  const mergedParams = mergeListParams(existingParams, newParams);

  const filter = calculateFilterRequestParams<ContractorVm>(mergedParams);

  const payload: ListRequestParams = {
    ...mergedParams,
    filter,
  };

  return { payload, mergedParams };
};
