import { Space, Typography } from "antd";
import StretchToCardEdge from "components/StretchToCardEdge";
import { FC, ReactNode } from "react";
import styles from "./ListPageLayout.module.scss";
import CardContent from "components/ui/CardContent";

interface ListPageLayoutProps {
  title: string;
  children?: ReactNode;
}

const ListPageLayout: FC<ListPageLayoutProps> = ({ children, title }) => {
  return (
    <CardContent className={styles.card}>
      <Space direction="vertical" size="large" className={styles.space}>
        <Typography.Title level={2}>{title}</Typography.Title>
        <StretchToCardEdge>{children}</StretchToCardEdge>
      </Space>
    </CardContent>
  );
};

export default ListPageLayout;
