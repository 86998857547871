import {
  mdiAccountHardHat,
  mdiAlert,
  mdiBookAlphabet,
  mdiChartBar,
  mdiDatabaseArrowUp,
  mdiMapMarker,
  mdiOfficeBuilding,
  mdiShape,
  mdiStore,
  mdiWrench,
} from "@mdi/js";
import { MaterialIcon } from "components/MaterialIcon";
import { Permission } from "components/Permission";
import useAuth from "features/auth/useAuth";
import useFeature from "hooks/useFeature";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarDivider,
  SidebarMenu,
  SideMenuOptionProps,
} from "widgets/layout/sidebar-header-layout";

const SidebarMainMenu: React.FC = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuth();

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const group1Options = useMemo<SideMenuOptionProps[]>(
    () => [
      {
        label: t("menu.failures"),
        link: "/failures",
        icon: <MaterialIcon path={mdiAlert} />,
      },
      {
        label: t("menu.contractors"),
        icon: <MaterialIcon path={mdiOfficeBuilding} />,
        link: "/contractors",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      {
        label: t("menu.repairmen"),
        icon: <MaterialIcon path={mdiAccountHardHat} />,
        link: "/repairmen",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"],
      },
      {
        label: t("menu.inventory"),
        icon: <MaterialIcon path={mdiStore} />,
        link: "/inventory",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      {
        label: t("menu.report"),
        icon: <MaterialIcon path={mdiChartBar} />,
        link: "/report",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"],
      },
      ...(isMaintainedDevicesFeatureEnabled
        ? [
            {
              label: t("menu.maintainedDevices"),
              icon: <MaterialIcon path={mdiWrench} />,
              link: "/maintained-devices",
              roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
            } as SideMenuOptionProps,
          ]
        : []),
    ],
    [isMaintainedDevicesFeatureEnabled, t]
  );

  const group2Options = useMemo<SideMenuOptionProps[]>(
    () => [
      ...((isMaintainedDevicesFeatureEnabled
        ? [
            {
              label: t("menu.specialPlaces"),
              icon: <MaterialIcon path={mdiMapMarker} />,
              link: "/special-places",
              roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
            },
          ]
        : [
            {
              label: t("menu.towns"),
              link: "/towns",
              icon: <MaterialIcon path={mdiMapMarker} />,
              roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
            },
          ]) as SideMenuOptionProps[]),
      {
        label: t("menu.failureTypes"),
        icon: <MaterialIcon path={mdiShape} />,
        link: "/failure-types",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      {
        label: t("menu.dictionaries"),
        icon: <MaterialIcon path={mdiBookAlphabet} />,
        link: "/dictionaries",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      ...(!isMaintainedDevicesFeatureEnabled
        ? [
            {
              label: t("menu.dataImport"),
              icon: <MaterialIcon path={mdiDatabaseArrowUp} />,
              link: "/data-import",
              roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
            } as SideMenuOptionProps,
          ]
        : []),
    ],
    [isMaintainedDevicesFeatureEnabled, t]
  );

  return (
    <>
      <SidebarMenu
        options={group1Options}
        title={t("menu.sections.failures")}
        hasRole={hasRole}
      />

      <Permission acceptableRoles={["LumenAdmin", "Root_SUPER_ADMIN"]}>
        <SidebarDivider />
        <SidebarMenu
          options={group2Options}
          title={t("menu.sections.settings")}
          hasRole={hasRole}
        />
      </Permission>
    </>
  );
};

export default SidebarMainMenu;
