import { mdiMapMarker } from "@mdi/js";
import { AddressVm } from "api/generated/lumen";
import { MaterialIcon } from "components/MaterialIcon";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import AddressDisplay from "./AddressDisplay";

interface GoogleMapsAddressLinkProps {
  address?: AddressVm;
}

const GoogleMapsAddressLink: React.FC<GoogleMapsAddressLinkProps> = ({
  address,
}) => {
  const { displayAddress } = useLanguageSensitiveUtils();

  return address ? (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
        displayAddress(address)
      )}`}
      target="_blank"
      rel="noreferrer"
    >
      <MaterialIcon path={mdiMapMarker} /> <AddressDisplay value={address} />
    </a>
  ) : (
    <EmptyContent />
  );
};

export default GoogleMapsAddressLink;
