import { createModel } from "@rematch/core";
import { api } from "api";
import {
  ContractorVm,
  DeviceVm,
  SpecialPlaceVm,
  UpdateContractorCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";
import { downloadFile } from "utils/file";

interface ContractorViewState {
  contractor?: ContractorVm;
  assignedSpecialPlaces: SpecialPlaceVm[];
  assignedMaintainedDevices: DeviceVm[];
}

const initialState: ContractorViewState = {
  contractor: undefined,
  assignedSpecialPlaces: [],
  assignedMaintainedDevices: [],
};

export const contractorView = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setContractor(state, data: ContractorVm) {
      state.contractor = data;
    },
    setAssignedSpecialPlaces(state, data: SpecialPlaceVm[]) {
      state.assignedSpecialPlaces = data;
    },
    setAssignedMaintainedDevices(state, data: DeviceVm[]) {
      state.assignedMaintainedDevices = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { contractorView } = dispatch;

    return {
      async fetchContractor(id: string): Promise<void> {
        const data = await api.lumen.contractors.apiContractorsIdGet({ id });

        dispatch(contractorView.setContractor(data));
      },

      async addDocument({
        contractorData,
        file,
        fileName,
      }: {
        contractorData: UpdateContractorCommand;
        file: Blob;
        fileName: string;
      }): Promise<void> {
        const uploadedFileId = await api.lumen.documents.apiDocumentsUploadPost(
          {
            documentName: fileName,
            documentFileData: file,
          }
        );

        return api.lumen.contractors.apiContractorsIdPut({
          id: contractorData.id,
          updateContractorCommand: {
            id: contractorData.id,
            documentIds: [
              ...(contractorData.documentIds || []),
              JSON.parse(uploadedFileId), // BE gives back string with "" and TextApiResponse makes double "" from it. This removes double """".
            ],
          },
        });
      },

      async downloadDocument(id: string): Promise<void> {
        const data = await api.lumen.documents.apiDocumentsIdGetRaw({
          id,
        });

        downloadFile(data.raw);
      },

      async deleteDocument({
        contractorData,
        documentId,
      }: {
        contractorData: UpdateContractorCommand;
        documentId: string;
      }): Promise<void> {
        await api.lumen.contractors.apiContractorsIdPut({
          id: contractorData.id,
          updateContractorCommand: {
            ...contractorData,
            documentIds: contractorData.documentIds?.filter(
              (id) => id !== documentId
            ),
          },
        });

        return api.lumen.documents.apiDocumentsIdDelete({ id: documentId });
      },

      async fetchAssignedSpecialPlaces(id: string): Promise<void> {
        const data =
          await api.lumen.contractors.apiContractorsGetContractorSpecialPlacesidGet(
            { id }
          );

        dispatch(contractorView.setAssignedSpecialPlaces(data));
      },

      async detachSpecialPlace({
        specialPlaceToDetachId,
        contractorToDetachId,
      }: {
        specialPlaceToDetachId: string;
        contractorToDetachId: string;
      }): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesDetachSpecialPlaceFromContractorDelete(
          {
            detachSpecialPlaceFromContractorCommand: {
              contractorToDetachId,
              specialPlaceToDetachId,
            },
          }
        );
      },

      async fetchAssignedMaintainedDevices(id: string): Promise<void> {
        const data =
          await api.lumen.contractors.apiContractorsGetContractorDevicesidGet({
            id,
          });

        dispatch(contractorView.setAssignedMaintainedDevices(data));
      },

      async detachMaintainedDevice({
        deviceToDetachId,
        contractorToDetachId,
      }: {
        deviceToDetachId: string;
        contractorToDetachId: string;
      }): Promise<void> {
        return api.lumen.devices.apiDevicesDetachDeviceFromContractorDelete({
          detachDeviceFromContractorCommand: {
            contractorToDetachId,
            deviceToDetachId,
          },
        });
      },

      async detachAllMaintainedDevice(
        contractorToDetachId: string
      ): Promise<void> {
        return api.lumen.devices.apiDevicesDetachAllDevicesFromContractorDelete(
          { detachAllDevicesFromContractorCommand: { contractorToDetachId } }
        );
      },
    };
  },
});
