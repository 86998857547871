import { Button, Input, Space, Typography } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CardWithBrandLogo from "components/ui/CardWithBrandLogo";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hashSHA256 } from "utils/crypto";
import { GrapeAntdForm, useGrapeAntdForm } from "widgets/form";
import ConfirmPasswordFormItem from "../components/ConfirmPasswordFormItem";
import PasswordFormItem from "../components/PasswordFormItem";
import { ForgottenPasswordSetNewPasswordFormValues } from "../models";
import useRegister from "../Register/useRegister";
import styles from "./ForgottenPasswordSetNewPassword.module.scss";

const ForgottenPasswordSetNewPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const formUtils =
    useGrapeAntdForm<ForgottenPasswordSetNewPasswordFormValues>();
  const { forgottenPasswordSetNewPassword, checkInvitation } = useSelector(
    (state) => state.loading.effects.auth
  );
  const { checkInvitationData, passwordComplexity, passwordComplexityLoading } =
    useRegister({ userId });

  const navigate = useNavigate();

  const handleFinish = useCallback(
    async (
      values: ForgottenPasswordSetNewPasswordFormValues
    ): Promise<void> => {
      await dispatch.auth.forgottenPasswordSetNewPassword({
        userName: values.userName,
        passwordHash: await hashSHA256(values.password),
      });

      if (checkInvitation.error) {
        navigate("failed", { replace: true });
      }

      if (forgottenPasswordSetNewPassword.success) {
        navigate("success", { replace: true });
      }
    },
    [
      checkInvitation.error,
      dispatch.auth,
      forgottenPasswordSetNewPassword.success,
      navigate,
    ]
  );

  useEffect(() => {
    if (checkInvitationData?.userName) {
      formUtils.form.setFieldsValue({
        userName: checkInvitationData.userName,
      });
    }
  }, [checkInvitationData?.userName, formUtils.form]);

  useEffect(() => {
    dispatch.auth.logout();
  }, [dispatch.auth]);

  return (
    <CardWithBrandLogo
      footerContent={
        <Link to="/login">
          <Button type="link" className={styles.button}>
            {t("forgottenPassword.backToLogin")}
          </Button>
        </Link>
      }
      size="small"
    >
      <div>
        <Typography.Title>
          {t("forgottenPassword.setNewPasswordPage.title")}
        </Typography.Title>

        <CustomForm formUtils={formUtils} onFinish={handleFinish}>
          <GrapeAntdForm.Item name="userName" hidden>
            <Input hidden />
          </GrapeAntdForm.Item>

          <Space direction="vertical" size="large" className={styles.space}>
            <div>
              <PasswordFormItem
                passwordComplexity={passwordComplexity}
                passwordComplexityLoading={passwordComplexityLoading}
              />

              <ConfirmPasswordFormItem />
            </div>

            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              loading={forgottenPasswordSetNewPassword.loading}
            >
              {t("common.save")}
            </Button>
          </Space>
        </CustomForm>
      </div>
    </CardWithBrandLogo>
  );
};

export default ForgottenPasswordSetNewPassword;
