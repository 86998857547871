/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigSettingRulesVm,
    ConfigSettingRulesVmFromJSON,
    ConfigSettingRulesVmFromJSONTyped,
    ConfigSettingRulesVmToJSON,
    FeatureSwitchVm,
    FeatureSwitchVmFromJSON,
    FeatureSwitchVmFromJSONTyped,
    FeatureSwitchVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigSettingVm
 */
export interface ConfigSettingVm {
    /**
     * 
     * @type {FeatureSwitchVm}
     * @memberof ConfigSettingVm
     */
    featureSwitch?: FeatureSwitchVm;
    /**
     * 
     * @type {ConfigSettingRulesVm}
     * @memberof ConfigSettingVm
     */
    rules?: ConfigSettingRulesVm;
}

export function ConfigSettingVmFromJSON(json: any): ConfigSettingVm {
    return ConfigSettingVmFromJSONTyped(json, false);
}

export function ConfigSettingVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigSettingVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featureSwitch': !exists(json, 'featureSwitch') ? undefined : FeatureSwitchVmFromJSON(json['featureSwitch']),
        'rules': !exists(json, 'rules') ? undefined : ConfigSettingRulesVmFromJSON(json['rules']),
    };
}

export function ConfigSettingVmToJSON(value?: ConfigSettingVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featureSwitch': FeatureSwitchVmToJSON(value.featureSwitch),
        'rules': ConfigSettingRulesVmToJSON(value.rules),
    };
}


