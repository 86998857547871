import { Middleware, RequestContext } from "api/generated/lumen";
import { getLocalStorageItem } from "utils/storage";

export const getAuthorizationHeader = (): string => {
  const accessToken = getLocalStorageItem("loginData")?.accessToken;

  return accessToken ? `Bearer ${accessToken}` : "";
};

export const requestHeadersMiddleware: Middleware[] = [
  {
    pre: async (ctx: RequestContext): Promise<void> => {
      ctx.init.headers = {
        ...ctx.init.headers,
        Authorization: getAuthorizationHeader(),
        "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone, // BE needs to know the clients timezone for dates in Excel export generation
      };

      return Promise.resolve();
    },
  },
];

export const unauthenticatedRequestHeadersMiddleware: Middleware[] = [
  {
    pre: async (ctx: RequestContext): Promise<void> => {
      ctx.init.headers = {
        ...ctx.init.headers,
        "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone, // BE needs to know the clients timezone for dates in Excel export generation
      };

      return Promise.resolve();
    },
  },
];
