/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TownExcelVm
 */
export interface TownExcelVm {
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    municipalityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TownExcelVm
     */
    email?: string | null;
}

export function TownExcelVmFromJSON(json: any): TownExcelVm {
    return TownExcelVmFromJSONTyped(json, false);
}

export function TownExcelVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): TownExcelVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'municipalityName': !exists(json, 'municipalityName') ? undefined : json['municipalityName'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function TownExcelVmToJSON(value?: TownExcelVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'municipalityName': value.municipalityName,
        'zip': value.zip,
        'city': value.city,
        'address': value.address,
        'number': value.number,
        'email': value.email,
    };
}


