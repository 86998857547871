import { FC, ReactNode } from "react";
import styles from "./MobilePageLayout.module.scss";

interface MobilePageLayoutProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  children?: ReactNode;
}

const MobilePageLayout: FC<MobilePageLayoutProps> = ({
  children,
  leftContent,
  rightContent,
}) => {
  return (
    <div className={styles.wrapper}>
      {(leftContent || rightContent) && (
        <header className={styles.header}>
          <div>{leftContent}</div>
          <div>{rightContent}</div>
        </header>
      )}

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default MobilePageLayout;
