/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface RepairmanDto
 */
export interface RepairmanDto {
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    familyname: string;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    forename: string;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    birthPlace?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RepairmanDto
     */
    birthDate?: Date;
    /**
     * 
     * @type {AddressDto}
     * @memberof RepairmanDto
     */
    address?: AddressDto;
    /**
     * 
     * @type {PhoneDto}
     * @memberof RepairmanDto
     */
    phone?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    statusId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairmanDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairmanDto
     */
    contractorId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairmanDto
     */
    documentIds?: Array<string> | null;
}

export function RepairmanDtoFromJSON(json: any): RepairmanDto {
    return RepairmanDtoFromJSONTyped(json, false);
}

export function RepairmanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairmanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'familyname': json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': json['forename'],
        'birthPlace': !exists(json, 'birthPlace') ? undefined : json['birthPlace'],
        'birthDate': !exists(json, 'birthDate') ? undefined : (new Date(json['birthDate'])),
        'address': !exists(json, 'address') ? undefined : AddressDtoFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : PhoneDtoFromJSON(json['phone']),
        'email': json['email'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'contractorId': !exists(json, 'contractorId') ? undefined : json['contractorId'],
        'documentIds': !exists(json, 'documentIds') ? undefined : json['documentIds'],
    };
}

export function RepairmanDtoToJSON(value?: RepairmanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'birthPlace': value.birthPlace,
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate.toISOString()),
        'address': AddressDtoToJSON(value.address),
        'phone': PhoneDtoToJSON(value.phone),
        'email': value.email,
        'statusId': value.statusId,
        'isActive': value.isActive,
        'contractorId': value.contractorId,
        'documentIds': value.documentIds,
    };
}


