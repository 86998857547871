import { useCallback, useMemo } from "react";
import { useWindowSize } from "react-use";

interface TableHeightCalculatorProps {
  headerSelector?: string;
  titleHeight?: number;
  tabsSelector?: string;
  tableActionsHeight?: any;
  tableHeaderSelector?: string;
  titleHeaderSelector?: string;
}

const FULL_PAGE_CARD_CONTENT_WRAPPER = 40;
const CARD_CONTENT_PADDING = 32;
const TABLE_HEADER_HEIGHT = 39.6;
const SCROLLBAR_WIDTH = 16;
const PAGINATION_HEIGHT = 64;

export const useTableHeightCalculator = ({
  headerSelector = ".ant-layout-header",
  tabsSelector = ".ant-tabs-nav",
  tableActionsHeight,
  titleHeaderSelector = "h2.ant-typography",
}: TableHeightCalculatorProps) => {
  const { height: windowHeight } = useWindowSize();

  const convertPxToNumber = useCallback((pixel: string) => {
    const number = +pixel.split("px")?.[0];
    return isNaN(number) ? 0 : number;
  }, []);

  const getComputedHeightBySelector = useCallback(
    (selector: string) => {
      const element = document.querySelector(selector);
      const { marginBottom, marginTop, paddingBottom, paddingTop, height } =
        element
          ? getComputedStyle(element)
          : {
              marginBottom: "0",
              marginTop: "0",
              paddingBottom: "0",
              paddingTop: "0",
              height: "0",
            };

      return Object.values({
        marginBottom,
        marginTop,
        paddingBottom,
        paddingTop,
        height,
      }).reduce((prev, cur) => prev + convertPxToNumber(cur), 0);
    },
    [convertPxToNumber]
  );

  const tableHeight = useMemo(() => {
    const headerHeight = getComputedHeightBySelector(headerSelector);
    const tabsHeight = getComputedHeightBySelector(tabsSelector);
    const titleHeight = getComputedHeightBySelector(titleHeaderSelector);

    const heights: number[] = [
      FULL_PAGE_CARD_CONTENT_WRAPPER,
      CARD_CONTENT_PADDING,
      TABLE_HEADER_HEIGHT,
      SCROLLBAR_WIDTH,
      PAGINATION_HEIGHT,
      headerHeight,
      tabsHeight,
      titleHeight,
      tableActionsHeight,
    ];

    return heights.reduce((prev, cur) => prev - cur, windowHeight);
  }, [
    windowHeight,
    getComputedHeightBySelector,
    headerSelector,
    tabsSelector,
    tableActionsHeight,
    titleHeaderSelector,
  ]);

  return tableHeight;
};
