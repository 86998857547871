import { FC, ReactNode } from "react";
import styles from "./Container.module.scss";
import clsx from "clsx";

interface ContainerProps {
  className?: string;
  fluid?: boolean;
  children?: ReactNode;
}

export const Container: FC<ContainerProps> = ({
  children,
  className,
  fluid,
}) => {
  return (
    <div
      className={clsx(styles.container, { [styles.fluid]: fluid }, className)}
    >
      {children}
    </div>
  );
};
