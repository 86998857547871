import { mdiPencil } from "@mdi/js";
import { Button, Col, Input, ModalProps, Row, Select } from "antd";
import { RepairmanVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useAuth from "features/auth/useAuth";
import useAllSlenderTownsQuery from "hooks/queries/useAllSlenderTownsQuery";
import useEditFormModal from "hooks/useEditModal";
import { ItemProps, mapPublicTownVmToExtendedSelectValue } from "models/common";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { backendDateToDayJs } from "utils/date";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import {
  mapToCreateRepairmanCommand,
  mapToUpdateRepairmanCommand,
  RepairmenCreateFormValues,
} from "../models";
import AddressFieldset from "./components/AddressFieldset";
import ContactFieldset from "./components/ContactFieldset";
import PersonalDataFieldset from "./components/PersonalDataFieldset";
import styles from "./RepairmenCreateModal.module.scss";

interface RepairmenCreateModalProps extends ModalProps {
  contractorId?: string;
}

const RepairmenCreateModal: React.FC<RepairmenCreateModalProps> = ({
  onCancel,
  visible,
  contractorId,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<RepairmenCreateFormValues>(
    "CreateRepairmanCommand"
  );
  const dispatch = useDispatch();
  const { editingItemId, contractors } = useSelector(
    (state) => state.repairmenCreate
  );
  const { repairman } = useSelector((state) => state.repairmenView);
  const { create, edit, fetchContractors } = useSelector(
    (state) => state.loading.effects.repairmenCreate
  );
  const { userIsAdmin } = useAuth();
  const townsQuery = useAllSlenderTownsQuery();

  const getInitialValues = useCallback(
    (itemData: RepairmanVm): RepairmenCreateFormValues => {
      const townVm = townsQuery.data?.items?.find(
        (item) => item.name === itemData.address?.city
      );

      return {
        familyname: itemData.familyname || "",
        forename: itemData.forename || "",
        email: itemData.email || "",
        birthDate: backendDateToDayJs(itemData.birthDate),
        birthPlace: itemData.birthPlace || undefined,
        id: itemData.id,
        phone: {
          number: itemData.phone?.number || "",
        },
        address: {
          zip: itemData.address?.zip,
          city: mapPublicTownVmToExtendedSelectValue(townVm),
          street: itemData.address?.street || "",
          number: itemData.address?.number || "",
        },
        contractorId: itemData.contractor?.id || "",
        isActive: itemData.isActive,
        registrationState: itemData.registrationState,
      };
    },
    [townsQuery.data?.items]
  );

  const { formMode, setFormMode } = useEditFormModal<
    RepairmanVm,
    RepairmenCreateFormValues
  >({
    item: repairman,
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.repairmenCreate.setEditingItemId,
    itemId: editingItemId,
  });

  const handleFinish = useCallback(
    async (values: RepairmenCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.repairmenCreate.create(
          mapToCreateRepairmanCommand(values)
        );

        dispatch.repairmenList.fetchList(undefined);
      }

      if (formMode === "Edit") {
        await dispatch.repairmenCreate.edit(
          mapToUpdateRepairmanCommand(values)
        );

        if (typeof values.id === "string") {
          dispatch.repairmenView.fetchRepairman(values.id);
        }
      }

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.repairmenCreate,
      dispatch.repairmenList,
      dispatch.repairmenView,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const contractorOptions = useMemo<ItemProps[]>(
    () =>
      contractors.map((type) => ({
        label: type.name || "",
        value: type.id || "",
      })),
    [contractors]
  );

  useEffect(() => {
    if (repairman && visible) {
      setFormMode("Edit");
    } else {
      setFormMode("Create");
    }
  }, [repairman, setFormMode, visible]);

  useEffect(() => {
    if (visible && userIsAdmin) {
      dispatch.repairmenCreate.fetchContractors();
    }
  }, [dispatch.repairmenCreate, userIsAdmin, visible]);

  useEffect(() => {
    if (contractorId) {
      formUtils.form.setFieldsValue({ contractorId });
    }
  }, [contractorId, formUtils]);

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("repairmen.createModal.title")
          : t("repairmen.editModal.title")
      }
      size="xl"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: create.loading || edit.loading }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
      visible={visible}
      longModal
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        {formMode === "View" && (
          <Row justify="end">
            <Button
              icon={<MaterialIcon path={mdiPencil} />}
              onClick={() => setFormMode("Edit")}
            >
              {t("common.edit")}
            </Button>
          </Row>
        )}

        {userIsAdmin && (
          <Row
            gutter={[24, 0]}
            justify="center"
            className={styles.contractorRow}
          >
            <Col span={12}>
              <GrapeAntdForm.Item
                name="contractorId"
                rules={[requiredField(t)]}
                label={t("repairmen.contractor")}
              >
                <Select
                  placeholder={t("repairmen.contractorPlaceholder")}
                  size="large"
                  options={contractorOptions}
                  loading={fetchContractors.loading}
                  showSearch
                  optionFilterProp="label"
                  disabled={!!contractorId}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        )}

        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        <Row gutter={[0, 12]}>
          <PersonalDataFieldset formMode={formMode} />
          <AddressFieldset />
          <ContactFieldset formUtils={formUtils} />
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default RepairmenCreateModal;
