import { ModalProps, Select } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { AssignDevicesToSpecialPlaceFormValues } from "../models";

const AssignMaintainedDevicesToSpecialPlaceModal: React.FC<ModalProps> = ({
  onCancel,
  onOk,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { assignMaintainedDevicesToSpecialPlace, fetchSpecialPlaces } =
    useSelector((state) => state.loading.effects.assignMaintainedDevices);
  const { specialPlaces } = useSelector(
    (state) => state.assignMaintainedDevices
  );
  const { selectedIds } = useSelector((state) => state.maintainedDevicesList);

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const specialPlaceOptions = useMemo(
    () =>
      specialPlaces.map((specialPlace) => ({
        label: specialPlace.name || "",
        value: specialPlace.id || "",
      })),
    [specialPlaces]
  );

  const handleCancel = useCallback(() => {
    onCancel?.("" as any);

    if (!visible) {
      setTimeout(() => {
        formUtils.form.resetFields();

        dispatch.assignMaintainedDevices.setSpecialPlaces([]);
      }, 300); // modal closing animation's length
    }
  }, [dispatch.assignMaintainedDevices, formUtils.form, onCancel, visible]);

  const handleFinish = useCallback(
    async (values: AssignDevicesToSpecialPlaceFormValues): Promise<void> => {
      await dispatch.assignMaintainedDevices.assignMaintainedDevicesToSpecialPlace(
        { deviceIds: selectedIds, specialPlaceId: values.specialPlaceId }
      );

      onOk?.("" as any);
      formUtils.form.resetFields();
    },
    [dispatch.assignMaintainedDevices, formUtils.form, onOk, selectedIds]
  );

  useEffect(() => {
    if (visible) {
      dispatch.assignMaintainedDevices.fetchSpecialPlaces();
    }
  }, [dispatch.assignMaintainedDevices, visible]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={handleCancel}
      title={t("maintainedDevice.assignSpecialPlaceModal.title")}
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: assignMaintainedDevicesToSpecialPlace.loading,
      }}
      cancelButtonProps={{
        disabled: assignMaintainedDevicesToSpecialPlace.loading,
      }}
      okText={t("maintainedDevice.assignSpecialPlaceModal.okText")}
      formInstance={formUtils.form}
    >
      <p>{t("maintainedDevice.assignSpecialPlaceModal.description")}</p>

      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item
          name="specialPlaceId"
          label={t("maintainedDevice.assignSpecialPlaceModal.specialPlace")}
          rules={[requiredField(t)]}
        >
          <Select
            size="large"
            options={specialPlaceOptions}
            loading={fetchSpecialPlaces.loading}
            showSearch
            optionFilterProp="label"
            filterOption={filterOptionsLabelFromBeginning}
          />
        </GrapeAntdForm.Item>
      </CustomForm>
    </CustomModal>
  );
};

export default AssignMaintainedDevicesToSpecialPlaceModal;
