import { RepairmanListItemVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { GetFilterParamsForGetRequests } from "models/common";
import { calculateFilterRequestParams } from "utils";
import {
  ListRequestParams,
  mergeListParams,
} from "widgets/table/useTableUtils";

export const getFilterParamsForRepairmenGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  const mergedParams = mergeListParams(existingParams, params);

  const filter =
    calculateFilterRequestParams<RepairmanListItemVm>(mergedParams);

  const payload: ListRequestParams = {
    ...mergedParams,
    filter,
  };

  return { payload, mergedParams };
};
