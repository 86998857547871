import { DictionaryVm } from "api/generated/lumen";
import React from "react";
import { getDictionaryItemLabel } from "utils";
import { EmptyContent } from "../../widgets/table/table-content/EmptyContent";

interface DictionaryDisplayProps {
  dictionary?: DictionaryVm | null;
}

const DictionaryDisplay: React.FC<DictionaryDisplayProps> = ({
  dictionary,
}) => {
  return (
    <>{dictionary ? getDictionaryItemLabel(dictionary) : <EmptyContent />}</>
  );
};

export default DictionaryDisplay;
