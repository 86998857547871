import { createModel } from "@rematch/core";
import { api } from "api";
import { TownVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { mapToGetAllQuery } from "utils";
import { downloadFile } from "utils/file";
import {
  ListRequestParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { getFilterParamsForTownsGetRequests } from "../utils";

interface FailuresListState {
  listParams: ListRequestParams;
  list: TownVm[];
}

const initialState: FailuresListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "name",
        orderByType: OrderByType.Ascending,
      },
    ],
  },
  list: [],
};

export const townList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      { data, listParams }: { data: TownVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { townList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const { payload, mergedParams } = getFilterParamsForTownsGetRequests(
          params,
          state.townList.listParams
        );

        const data = await api.lumen.towns.apiTownsGetAllPost({
          getAllTownsQuery: mapToGetAllQuery(payload),
        });

        const { items, ...pagination } = data;

        dispatch(
          townList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async inactivate(
        id: string | undefined = undefined,
        state
      ): Promise<void> {
        const { list } = state.townList;

        if (typeof id !== "string") {
          return;
        }

        const townToDelete = list.find((item) => item.id === id);

        if (!townToDelete || typeof townToDelete.name !== "string") {
          return;
        }

        return api.lumen.towns.apiTownsIdPut({
          id,
          updateTownCommand: {
            ...townToDelete,
            id,
            isActivated: false,
            name: townToDelete.name,
            municipalityAddress: {
              city: townToDelete.municipalityAddress?.city || "",
              zip: townToDelete.municipalityAddress?.zip,
              street: townToDelete.municipalityAddress?.street || "",
              number: townToDelete.municipalityAddress?.number || "",
            },
            slas: townToDelete.slas?.map((sla) => ({
              ...sla,
              id: sla.id || "",
              failureTypes:
                sla.failureTypes?.map((failureType) => ({
                  id: failureType.id || "",
                  name: failureType.name || "",
                })) || [],
              slaCategoryId: sla.slaCategoryId || "",
              townId: sla.townId || "",
            })),
          },
        });
      },

      async generateExcel(columnTitles: string[], state): Promise<void> {
        const { payload } = getFilterParamsForTownsGetRequests(
          state.townList.listParams,
          state.townList.listParams
        );

        const data = await api.lumen.towns.apiTownsExportPostRaw({
          getAllTownsExcelQuery: {
            ...mapToGetAllQuery(payload),
            columnNames: columnTitles,
            pageSize: -1,
          },
        });

        downloadFile(data.raw);
      },
    };
  },
});
