/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RepairmanDto,
    RepairmanDtoFromJSON,
    RepairmanDtoFromJSONTyped,
    RepairmanDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateRepairmanCommand
 */
export interface UpdateRepairmanCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairmanCommand
     */
    id: string;
    /**
     * 
     * @type {RepairmanDto}
     * @memberof UpdateRepairmanCommand
     */
    repairman?: RepairmanDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRepairmanCommand
     */
    inviteRepairman?: boolean;
}

export function UpdateRepairmanCommandFromJSON(json: any): UpdateRepairmanCommand {
    return UpdateRepairmanCommandFromJSONTyped(json, false);
}

export function UpdateRepairmanCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRepairmanCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'repairman': !exists(json, 'repairman') ? undefined : RepairmanDtoFromJSON(json['repairman']),
        'inviteRepairman': !exists(json, 'inviteRepairman') ? undefined : json['inviteRepairman'],
    };
}

export function UpdateRepairmanCommandToJSON(value?: UpdateRepairmanCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'repairman': RepairmanDtoToJSON(value.repairman),
        'inviteRepairman': value.inviteRepairman,
    };
}


