import { mdiDelete, mdiEye } from "@mdi/js";
import { Modal, ModalFuncProps, Space } from "antd";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

import { DictionaryVm, InventoryVm } from "api/generated/lumen";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import ListPageLayout from "components/layout/ListPageLayout";
import { dictionaryFilters } from "utils";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import InventoryCreateModal from "../create/InventoryCreateModal";
import useInventoryListColumnTitles from "./useInventoryListColumnTitles";
import useInventoryCategoriesQuery from "hooks/queries/useInventoryCategoriesQuery";

const { confirm } = Modal;

// We only display inventory items that has active inventory category
const InventoryList: React.FC = () => {
  const { listParams, list } = useSelector((state) => state.inventoryList);
  const inventoryCategoriesQuery = useInventoryCategoriesQuery(true);
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.inventoryList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { columnTitles } = useInventoryListColumnTitles();

  const columnParams = useMemo<ColumnConfigParams<InventoryVm>[]>(
    () =>
      [
        {
          key: "partNumber",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "name",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
          width: "400px",
        },
        {
          key: "manufacturer",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "type",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
        },
        {
          key: "category.value",
          dataIndex: "category",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.FILTER,
          filters: dictionaryFilters(
            inventoryCategoriesQuery.data?.items || []
          ),
          filterOptionsLoading: inventoryCategoriesQuery.isFetching,
          render: (value: DictionaryVm | null) => {
            return <DictionaryDisplay dictionary={value} />;
          },
        },
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [
      columnTitles,
      inventoryCategoriesQuery.data?.items,
      inventoryCategoriesQuery.isFetching,
    ]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      dispatch.inventoryCreate.setEditingItemId(id);

      setCreateModalOpen(true);
    },
    [dispatch.inventoryCreate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.inventoryList.deleteItem({ id });

          return dispatch.inventoryList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.inventoryList, t, listParams]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleExportButtonClick = useCallback(() => {
    dispatch.inventoryList.generateExcel(columnTitles);
  }, [columnTitles, dispatch.inventoryList]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<InventoryVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.inventoryList.fetchList(requestParams),
    [dispatch.inventoryList]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.INVENTORY,
    headerOptions: (
      <TableHeaderOptions
        onNewButtonClick={handleNewButtonClick}
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.inventoryList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.inventoryList.reset,
  });

  return (
    <ListPageLayout title={t("inventory.list.title")}>
      <CustomTable<InventoryVm>
        {...customTableProps}
        scrollX={1048}
        selectable={false}
      />

      <InventoryCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />
    </ListPageLayout>
  );
};

export default InventoryList;
