/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    Workbook,
    WorkbookFromJSON,
    WorkbookFromJSONTyped,
    WorkbookToJSON,
    WorkbookTaskItem,
    WorkbookTaskItemFromJSON,
    WorkbookTaskItemFromJSONTyped,
    WorkbookTaskItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkbookTask
 */
export interface WorkbookTask {
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    reasonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    statusId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    taskId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    workbookId?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTask
     */
    statechangedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    statechangedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTask
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTask
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Dictionary}
     * @memberof WorkbookTask
     */
    reason?: Dictionary;
    /**
     * 
     * @type {User}
     * @memberof WorkbookTask
     */
    statechanger?: User;
    /**
     * 
     * @type {Dictionary}
     * @memberof WorkbookTask
     */
    status?: Dictionary;
    /**
     * 
     * @type {Dictionary}
     * @memberof WorkbookTask
     */
    task?: Dictionary;
    /**
     * 
     * @type {Workbook}
     * @memberof WorkbookTask
     */
    workbook?: Workbook;
    /**
     * 
     * @type {Array<WorkbookTaskItem>}
     * @memberof WorkbookTask
     */
    items?: Array<WorkbookTaskItem> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTask
     */
    id?: string;
}

export function WorkbookTaskFromJSON(json: any): WorkbookTask {
    return WorkbookTaskFromJSONTyped(json, false);
}

export function WorkbookTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbookTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonId': !exists(json, 'reasonId') ? undefined : json['reasonId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'workbookId': !exists(json, 'workbookId') ? undefined : json['workbookId'],
        'statechangedDate': !exists(json, 'statechangedDate') ? undefined : (json['statechangedDate'] === null ? null : new Date(json['statechangedDate'])),
        'statechangedBy': !exists(json, 'statechangedBy') ? undefined : json['statechangedBy'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'reason': !exists(json, 'reason') ? undefined : DictionaryFromJSON(json['reason']),
        'statechanger': !exists(json, 'statechanger') ? undefined : UserFromJSON(json['statechanger']),
        'status': !exists(json, 'status') ? undefined : DictionaryFromJSON(json['status']),
        'task': !exists(json, 'task') ? undefined : DictionaryFromJSON(json['task']),
        'workbook': !exists(json, 'workbook') ? undefined : WorkbookFromJSON(json['workbook']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(WorkbookTaskItemFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function WorkbookTaskToJSON(value?: WorkbookTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonId': value.reasonId,
        'statusId': value.statusId,
        'taskId': value.taskId,
        'workbookId': value.workbookId,
        'statechangedDate': value.statechangedDate === undefined ? undefined : (value.statechangedDate === null ? null : value.statechangedDate.toISOString()),
        'statechangedBy': value.statechangedBy,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'reason': DictionaryToJSON(value.reason),
        'statechanger': UserToJSON(value.statechanger),
        'status': DictionaryToJSON(value.status),
        'task': DictionaryToJSON(value.task),
        'workbook': WorkbookToJSON(value.workbook),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(WorkbookTaskItemToJSON)),
        'id': value.id,
    };
}


