import { mdiAlertCircleOutline, mdiCheck, mdiPause, mdiPlay } from "@mdi/js";
import { Tag } from "antd";
import { FailureStatusVm } from "api/generated/lumen";
import { MaterialIcon } from "components/MaterialIcon";
import { FailureStatus } from "models/common";
import React, { useMemo } from "react";

interface StatusTagProps {
  status: FailureStatusVm;
  useAlternativeName?: boolean;
}

const tagColors: { [key in FailureStatus]: string } = {
  Noticed: "error",
  Fixed: "success",
  DidNotFix: "processing",
  SectionFailure: "warning",
  Suspended: "warning",
};

const tagIconPaths: { [key in FailureStatus]: string } = {
  Noticed: mdiAlertCircleOutline,
  Fixed: mdiPlay,
  DidNotFix: mdiCheck,
  SectionFailure: mdiPause,
  Suspended: mdiPause,
};

const StatusTag: React.FC<StatusTagProps> = ({
  status,
  useAlternativeName,
}) => {
  const iconPath = useMemo(
    () => tagIconPaths[(status.value as FailureStatus) || "Noticed"],
    [status.value]
  );

  const tagColor = useMemo(
    () => tagColors[(status.value as FailureStatus) || "Noticed"],
    [status.value]
  );

  return (
    <Tag
      icon={
        iconPath ? (
          <MaterialIcon
            path={tagIconPaths[(status.value as FailureStatus) || "Noticed"]}
          />
        ) : undefined
      }
      color={tagColor || undefined}
    >
      {useAlternativeName ? status.alternativeName : status.name}
    </Tag>
  );
};

export default StatusTag;
