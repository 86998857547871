import { createModel } from "@rematch/core";
import { api } from "api";
import { FailureVm, InventoryVm, WorkbookVm } from "api/generated/lumen";
import { RootModel } from "app/store";

interface FailureViewState {
  failure?: FailureVm;
  workbook?: WorkbookVm;
  attachedFailures: FailureVm[];
  inventoryItems: InventoryVm[];
}

const initialState: FailureViewState = {
  failure: undefined,
  workbook: undefined,
  attachedFailures: [],
  inventoryItems: [],
};

export const failureView = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFailure(state, data: FailureVm) {
      state.failure = data;
    },
    setWorkbook(state, data: WorkbookVm) {
      state.workbook = data;
    },
    setAttachedFailures(state, data: FailureVm[]) {
      state.attachedFailures = data;
    },
    resetAttachedFailures(state) {
      state.attachedFailures = [];
    },
    setInventoryItems(state, data: InventoryVm[]) {
      state.inventoryItems = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureView } = dispatch;

    return {
      async fetchFailure(id: string): Promise<void> {
        const data = await api.lumen.failures.apiFailuresIdGet({ id });

        dispatch(failureView.setFailure(data));
      },

      // Need to fetch attached failures, because FailureVm.attachedFailures doesn't contain all necessary data
      async fetchAttachedFailures(id: string): Promise<void> {
        const data = await api.lumen.failures.apiFailuresGetAllPost({
          getAllFailuresQuery: {
            filterKeys: {
              parentId: id,
            },
            pageSize: -1,
          },
        });

        const { items } = data;

        dispatch(failureView.setAttachedFailures(items || []));
      },

      async detach({
        failureId,
        failureToDetachId,
      }: {
        failureId: string;
        failureToDetachId: string;
      }): Promise<string> {
        return api.lumen.failures.apiFailuresDetachPost({
          detachFailureCommand: { failureId, failureToDetachId },
        });
      },

      async fetchWorkbook(failureId: string | undefined): Promise<void> {
        if (failureId) {
          const data =
            await api.lumen.workbooks.apiWorkbooksFailureFailureIdGet({
              failureId,
            });

          dispatch(failureView.setWorkbook(data));
        }
      },

      async setWorkbookTaskStatus({
        id,
        statusId,
        taskId,
        workbookId,
        reasonId,
      }: {
        id: string;
        statusId: string;
        taskId: string;
        workbookId: string;
        reasonId?: string;
      }): Promise<void> {
        return api.lumen.workbooks.apiWorkbooksTaskIdPut({
          id,
          updateWorkbookTaskCommand: {
            id,
            statusId,
            taskId,
            workbookId,
            reasonId,
          },
        });
      },

      async addTaskToWorkbook({
        taskId,
        workbookId,
      }: {
        taskId: string;
        workbookId: string;
      }): Promise<string> {
        return api.lumen.workbooks.apiWorkbooksTaskPost({
          createWorkbookTaskCommand: {
            taskId,
            workbookId,
          },
        });
      },

      async fetchInventoryItems(): Promise<void> {
        const data = await api.lumen.inventory.apiInventoryGetAllPost({
          getAllInventoryItemsQuery: {
            pageSize: -1,
          },
        });

        const { items } = data;

        dispatch(failureView.setInventoryItems(items || []));
      },

      async addTaskItemToWorkbookTask({
        inventoryId,
        workbookTaskId,
        itemCount,
      }: {
        inventoryId: string;
        workbookTaskId: string;
        itemCount: number;
      }): Promise<string> {
        return api.lumen.workbooks.apiWorkbooksTaskItemPost({
          createWorkbookTaskItemCommand: {
            inventoryId,
            workbookTaskId,
            itemCount,
          },
        });
      },

      async deleteWorkbookTaskItem(
        {
          id,
        }: {
          id?: string;
        },
        state
      ): Promise<void> {
        if (typeof id === "string") {
          return api.lumen.workbooks.apiWorkbooksTaskItemIdDelete({ id });
        }
      },

      async editTaskItemCount({
        inventoryId,
        workbookTaskId,
        itemCount,
        id,
      }: {
        inventoryId: string;
        workbookTaskId: string;
        itemCount: number;
        id: string;
      }): Promise<void> {
        return api.lumen.workbooks.apiWorkbooksTaskItemIdPut({
          id,
          updateWorkbookTaskItemCommand: {
            id,
            inventoryId,
            workbookTaskId,
            itemCount,
          },
        });
      },
    };
  },
});
