import { Layout } from "antd";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import styles from "./Sidebar.module.scss";
import config from "../config";

interface SidebarContextState {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SidebarContext = createContext<SidebarContextState>({
  open: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpen: () => {},
});

interface SidebarProps {
  collapser?: ReactNode;
  logo?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

const Sidebar: FC<SidebarProps> = ({ collapser, logo, footer, children }) => {
  const [open, setOpen] = useState(true);

  return (
    <SidebarContext.Provider value={{ open, setOpen }}>
      <Layout.Sider
        width={config.siderWidth}
        collapsedWidth={config.siderCollapsedWidth}
        trigger={null}
        collapsible
        collapsed={!open}
        onCollapse={(collapsed) => setOpen(collapsed)}
        className={styles.sider}
      >
        {collapser}

        <div className={styles.logoWrapper}>{logo}</div>

        {children}

        {footer && <div className={styles.footerMenuGroup}>{footer}</div>}
      </Layout.Sider>
    </SidebarContext.Provider>
  );
};

export default Sidebar;
