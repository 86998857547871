import { FC, ReactNode } from "react";
import styles from "./FailureViewRepairerTabContent.module.scss";

interface FailureViewRepairerTabContentProps {
  children?: ReactNode;
}

const FailureViewRepairerTabContent: FC<FailureViewRepairerTabContentProps> = ({
  children,
}) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default FailureViewRepairerTabContent;
