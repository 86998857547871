/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigSettingVm,
    ConfigSettingVmFromJSON,
    ConfigSettingVmToJSON,
    TranslationCodesVm,
    TranslationCodesVmFromJSON,
    TranslationCodesVmToJSON,
} from '../models';

export interface ApiInformationConfigurationGetRequest {
    xLUMENTransactionGuid?: string;
}

export interface ApiInformationTranslationCodesGetRequest {
    xLUMENTransactionGuid?: string;
}

/**
 * 
 */
export class InformationApi extends runtime.BaseAPI {

    /**
     * Returns a part of the Configuration from Backend appSettings.json
     */
    async apiInformationConfigurationGetRaw(requestParameters: ApiInformationConfigurationGetRequest): Promise<runtime.ApiResponse<ConfigSettingVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/Configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigSettingVmFromJSON(jsonValue));
    }

    /**
     * Returns a part of the Configuration from Backend appSettings.json
     */
    async apiInformationConfigurationGet(requestParameters: ApiInformationConfigurationGetRequest): Promise<ConfigSettingVm> {
        const response = await this.apiInformationConfigurationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the Translation codes
     */
    async apiInformationTranslationCodesGetRaw(requestParameters: ApiInformationTranslationCodesGetRequest): Promise<runtime.ApiResponse<TranslationCodesVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/TranslationCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationCodesVmFromJSON(jsonValue));
    }

    /**
     * Returns the Translation codes
     */
    async apiInformationTranslationCodesGet(requestParameters: ApiInformationTranslationCodesGetRequest): Promise<TranslationCodesVm> {
        const response = await this.apiInformationTranslationCodesGetRaw(requestParameters);
        return await response.value();
    }

}
