import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import immerPlugin from "@rematch/immer";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import { failureList } from "features/failures/list/failuresList.store";
import { auth } from "features/auth/auth.store";
import { failureCreate } from "features/failures/create/failureCreate.store";
import { townList } from "features/towns/list/townList.store";
import { townCreate } from "features/towns/create/townCreate.store";
import { failureTypesList } from "features/failure-types/list/failureTypesList.store";
import { failureTypesCreate } from "features/failure-types/create/failureTypesCreate.store";
import { common } from "../../features/common/common.store";
import { inventoryList } from "features/inventory/list/inventoryList.store";
import { contractorsList } from "features/contractors/list/contractorsList.store";
import { inventoryCreate } from "features/inventory/create/inventoryCreate.store";
import { contractorCreate } from "features/contractors/create/contractorCreate.store";
import { contractorView } from "features/contractors/view/contractorView.store";
import { repairmenList } from "features/repairmen/list/repairmenList.store";
import { repairmenCreate } from "features/repairmen/create/repairmenCreate.store";
import { repairmenView } from "features/repairmen/view/repairmenView.store";
import { failureAssign } from "features/failures/assign/failureAssign.store";
import { failureView } from "features/failures/view/failureView.store";
import { failureBulkAssign } from "features/failures/list/bulk-actions/failureBulkAssign.store";
import { failureReport } from "features/failures/report/failureReport.store";
import { inventoryCategoryList } from "features/manage-dictionaries/tabs/inventory-category-tab/list/inventoryCategoryList.store";
import { inventoryCategoryCreate } from "features/manage-dictionaries/tabs/inventory-category-tab/create/inventoryCategoryCreate.store";
import { failureTaskList } from "features/manage-dictionaries/tabs/failure-task-tab/list/failureTaskList.store";
import { failureTaskCreate } from "features/manage-dictionaries/tabs/failure-task-tab/create/failureTaskCreate.store";
import { maintainedDevicesList } from "features/maintained-devices/list/maintainedDevicesList.store";
import { maintainedDevicesView } from "features/maintained-devices/view/maintainedDevicesView.store";
import { specialPlacesList } from "features/special-places/list/specialPlacesList.store";
import { specialPlacesCreate } from "features/special-places/create/specialPlacesCreate.store";
import { specialPlacesView } from "features/special-places/view/specialPlacesView.store";
import { assignContractor } from "features/contractors/assign/assignContractor.store";
import { assignMaintainedDevices } from "features/maintained-devices/assign/assignMaintainedDevices.store";
import { examTypeList } from "features/manage-dictionaries/tabs/exam-type-tab/list/examTypeList.store";
import { examTypeCreate } from "features/manage-dictionaries/tabs/exam-type-tab/create/examTypeCreate.store";
import { repairmenExamTab } from "features/repairmen/view/components/tabs/exams/repairmenExamTab.store";
import { taskItemList } from "features/report/tabs/task-items-tab/taskItemList.store";

// Models
export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  failureList: typeof failureList;
  failureCreate: typeof failureCreate;
  failureTypesList: typeof failureTypesList;
  failureTypesCreate: typeof failureTypesCreate;
  townList: typeof townList;
  townCreate: typeof townCreate;
  common: typeof common;
  inventoryList: typeof inventoryList;
  contractorsList: typeof contractorsList;
  inventoryCreate: typeof inventoryCreate;
  contractorCreate: typeof contractorCreate;
  contractorView: typeof contractorView;
  repairmenList: typeof repairmenList;
  repairmenCreate: typeof repairmenCreate;
  repairmenView: typeof repairmenView;
  failureAssign: typeof failureAssign;
  failureView: typeof failureView;
  failureBulkAssign: typeof failureBulkAssign;
  failureReport: typeof failureReport;
  inventoryCategoryList: typeof inventoryCategoryList;
  inventoryCategoryCreate: typeof inventoryCategoryCreate;
  failureTaskList: typeof failureTaskList;
  failureTaskCreate: typeof failureTaskCreate;
  maintainedDevicesList: typeof maintainedDevicesList;
  maintainedDevicesView: typeof maintainedDevicesView;
  specialPlacesList: typeof specialPlacesList;
  specialPlacesCreate: typeof specialPlacesCreate;
  specialPlacesView: typeof specialPlacesView;
  assignContractor: typeof assignContractor;
  assignMaintainedDevices: typeof assignMaintainedDevices;
  examTypeList: typeof examTypeList;
  examTypeCreate: typeof examTypeCreate;
  repairmenExamTab: typeof repairmenExamTab;
  taskItemList: typeof taskItemList;
}

const models: RootModel = {
  auth,
  failureList,
  failureCreate,
  failureTypesList,
  failureTypesCreate,
  townList,
  townCreate,
  common,
  inventoryList,
  contractorsList,
  inventoryCreate,
  contractorCreate,
  contractorView,
  repairmenList,
  repairmenCreate,
  repairmenView,
  failureAssign,
  failureView,
  failureBulkAssign,
  failureReport,
  inventoryCategoryList,
  inventoryCategoryCreate,
  failureTaskList,
  failureTaskCreate,
  maintainedDevicesList,
  maintainedDevicesView,
  specialPlacesList,
  specialPlacesCreate,
  specialPlacesView,
  assignContractor,
  assignMaintainedDevices,
  examTypeList,
  examTypeCreate,
  repairmenExamTab,
  taskItemList,
};

// Setup

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }>;

export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    immerPlugin({ blacklist: ["loading"] }),
    loadingPlugin({ type: "full" }),
  ],
  redux: {
    rootReducers: {
      RESET: () => undefined,
    },
  },
});
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = (): Dispatch => useReduxDispatch<Dispatch>();
