import { createModel } from "@rematch/core";
import { api } from "api";
import { ContractorVm, OrderByType, SpecialPlaceVm } from "api/generated/lumen";
import { RootModel } from "app/store";

interface AssignContractorState {
  contractor?: ContractorVm;
  specialPlaces: SpecialPlaceVm[];
}

const initialState: AssignContractorState = {
  contractor: undefined,
  specialPlaces: [],
};

export const assignContractor = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setContractor(state, data?: ContractorVm) {
      state.contractor = data;
    },
    setSpecialPlaces(state, data: SpecialPlaceVm[]) {
      state.specialPlaces = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { assignContractor } = dispatch;

    return {
      async fetchSpecialPlaces(): Promise<void> {
        // API only returns non deleted special places by default
        const data = await api.lumen.specialPlaces.apiSpecialPlacesGetAllPost({
          getAllSpecialPlacesQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
          },
        });

        const { items } = data;

        dispatch(assignContractor.setSpecialPlaces(items || []));
      },

      async assignContractorToSpecialPlace({
        contractorId,
        specialPlaceId,
      }: {
        contractorId: string;
        specialPlaceId: string;
      }): Promise<void> {
        return api.lumen.specialPlaces.apiSpecialPlacesAssignSpecialPlaceToContractorPost(
          {
            assignSpecialPlaceToContractorCommand: {
              contractorId,
              specialPlaceId,
            },
          }
        );
      },
    };
  },
});
