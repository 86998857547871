import { ModalProps, Select } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { AssignDevicesToContractorFormValues } from "../models";

const AssignMaintainedDevicesToContractorModal: React.FC<ModalProps> = ({
  onCancel,
  onOk,
  visible,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { assignMaintainedDevicesToContractor, fetchContractors } = useSelector(
    (state) => state.loading.effects.assignMaintainedDevices
  );
  const { contractors } = useSelector((state) => state.assignMaintainedDevices);
  const { selectedIds } = useSelector((state) => state.maintainedDevicesList);

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const contractorOptions = useMemo(
    () =>
      contractors.map((contractor) => ({
        label: contractor.name || "",
        value: contractor.id || "",
      })),
    [contractors]
  );

  const handleCancel = useCallback(() => {
    onCancel?.("" as any);

    if (!visible) {
      setTimeout(() => {
        formUtils.form.resetFields();

        dispatch.assignMaintainedDevices.setContractors([]);
      }, 300); // modal closing animation's length
    }
  }, [dispatch.assignMaintainedDevices, formUtils.form, onCancel, visible]);

  const handleFinish = useCallback(
    async (values: AssignDevicesToContractorFormValues): Promise<void> => {
      await dispatch.assignMaintainedDevices.assignMaintainedDevicesToContractor(
        { contractorId: values.contractorId, deviceIds: selectedIds }
      );

      onOk?.("" as any);
      formUtils.form.resetFields();
    },
    [dispatch.assignMaintainedDevices, formUtils.form, onOk, selectedIds]
  );

  useEffect(() => {
    if (visible) {
      dispatch.assignMaintainedDevices.fetchContractors();
    }
  }, [dispatch.assignMaintainedDevices, visible]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={handleCancel}
      title={t("maintainedDevice.assignContractorModal.title")}
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: assignMaintainedDevicesToContractor.loading,
      }}
      cancelButtonProps={{
        disabled: assignMaintainedDevicesToContractor.loading,
      }}
      okText={t("maintainedDevice.assignContractorModal.okText")}
      formInstance={formUtils.form}
    >
      <p>{t("maintainedDevice.assignContractorModal.description")}</p>

      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item
          name="contractorId"
          label={t("maintainedDevice.assignContractorModal.contractor")}
          rules={[requiredField(t)]}
        >
          <Select
            size="large"
            options={contractorOptions}
            loading={fetchContractors.loading}
            showSearch
            optionFilterProp="label"
            filterOption={filterOptionsLabelFromBeginning}
          />
        </GrapeAntdForm.Item>
      </CustomForm>
    </CustomModal>
  );
};

export default AssignMaintainedDevicesToContractorModal;
