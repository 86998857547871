import { FC, ReactNode } from "react";
import styles from "./DarkContainerCard.module.scss";

interface DarkContainerCardProps {
  icon?: ReactNode;
  title: ReactNode;
  slim?: boolean;
  children?: ReactNode;
}

const DarkContainerCard: FC<DarkContainerCardProps> = ({
  children,
  icon,
  title,
  slim,
}) => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {icon && <div className={styles.icon}>{icon}</div>}

        <div className={styles.title}>{title}</div>
      </header>
      {children}
    </div>
  );
};

export default DarkContainerCard;
