/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Repairman,
    RepairmanFromJSON,
    RepairmanFromJSONTyped,
    RepairmanToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    fileData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {User}
     * @memberof Document
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Document
     */
    modifier?: User;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Document
     */
    contractors?: Array<Contractor> | null;
    /**
     * 
     * @type {Array<Repairman>}
     * @memberof Document
     */
    repairmen?: Array<Repairman> | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id?: string;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'fileData': !exists(json, 'fileData') ? undefined : json['fileData'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorFromJSON)),
        'repairmen': !exists(json, 'repairmen') ? undefined : (json['repairmen'] === null ? null : (json['repairmen'] as Array<any>).map(RepairmanFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fileName': value.fileName,
        'contentType': value.contentType,
        'fileData': value.fileData,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorToJSON)),
        'repairmen': value.repairmen === undefined ? undefined : (value.repairmen === null ? null : (value.repairmen as Array<any>).map(RepairmanToJSON)),
        'id': value.id,
    };
}


