import { DictionaryVm, UpdateTownCommand } from "api/generated/lumen";
import { ExtendedSelectValue } from "models/common";

export interface SLAFieldsetValues {
  id?: string;
  slaCategoryId: string;
  deadlineDays: string;
  optimalDeadlineDaysId?: string;
  failureTypes: string[];
}

export interface ContactsFieldsetValues {
  id?: string;
  familyname: string;
  forename: string;
  email: string;
  phone: {
    number: string;
  };
  shouldInvite?: boolean;
  existingContact?: string; // only used to select an existing contact from all existing contacts; keeps the DTO aligned with the form values
}

export interface TownCreateFormValues {
  townId?: string;
  name: string;
  isActivated?: boolean;
  id: string;
  municipalityName: string;
  municipalityAddress: {
    zip?: string | null;
    city: ExtendedSelectValue;
    street: string;
    number: string;
  };
  email: string;
  phone: {
    number?: string;
  };
  sectionActionId: string;
  contactPersons: ContactsFieldsetValues[];
  slas: SLAFieldsetValues[];
  sectionFailureEmailTo?: string[];
}

export const mapToUpdateTownCommand = (
  values: TownCreateFormValues,
  failureTypes: DictionaryVm[]
): UpdateTownCommand => ({
  ...values,
  id: values.id,
  slas:
    values.slas?.map((item) => {
      return {
        ...item,
        optimalDeadlineDaysId: item.optimalDeadlineDaysId || "",
        id: item.id || "00000000-0000-0000-0000-000000000000", // BE handles 000.. uuid as null...
        deadlineDays: +item.deadlineDays,
        failureTypes: item.failureTypes.map((failureType) => {
          const failureTypeDictionaryItem = failureTypes.find(
            (failureTypeItem) => failureTypeItem.id === failureType
          );

          return {
            id: failureTypeDictionaryItem?.id || "",
            name: failureTypeDictionaryItem?.name || "",
          };
        }),
        townId: values.id || values.townId || "",
      };
    }) ?? [],
  contactPersons: values.contactPersons.map((person) => ({
    ...person,
    phone: {
      number: person.phone?.number,
    },
  })),
  sectionActionId: values.sectionActionId,
  municipalityAddress: {
    ...values.municipalityAddress,
    city: values.municipalityAddress.city.label,
  },
  sectionFailureEmailTo: values.sectionFailureEmailTo?.join(";"),
});
