import {
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import { DictionaryGroupType } from "models/common";

const townKeys = createQueryKeys("towns", {
  allSlender: null,
  filterableSlender: (filterKeys?: { [key: string]: any }) => ({ filterKeys }),
  allPublicActivated: null,
});

const streetKeys = createQueryKeys("streets", {
  byTownId: (townId: string | undefined) => [townId],
});

const informationKeys = createQueryKeys("information", {
  features: null,
});

const dictionaryKeys = createQueryKeys("dictionaries", {
  byDictionaryGroupType: (dictionaryGroupType: DictionaryGroupType) => [
    dictionaryGroupType,
  ],
});

const inventoryCategoryKeys = createQueryKeys("inventoryCategories", {
  isActive: (active?: boolean) => [active],
});

const deviceKeys = createQueryKeys("devices", {
  properties: null,
  all: null,
});

const failureKeys = createQueryKeys("failures", {
  deadlineInfoCount: null,
  isSealed: (isSealed?: boolean) => [isSealed],
});

const examTypeKeys = createQueryKeys("examTypes", {
  all: null,
});

export const queryKeys = mergeQueryKeys(
  townKeys,
  streetKeys,
  informationKeys,
  dictionaryKeys,
  inventoryCategoryKeys,
  deviceKeys,
  failureKeys,
  examTypeKeys
);
