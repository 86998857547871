/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigAllValidationRulesVm
 */
export interface ConfigAllValidationRulesVm {
    /**
     * 
     * @type {string}
     * @memberof ConfigAllValidationRulesVm
     */
    zipPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigAllValidationRulesVm
     */
    taxNumberPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigAllValidationRulesVm
     */
    addressPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigAllValidationRulesVm
     */
    multipleEmailAddressPattern?: string | null;
}

export function ConfigAllValidationRulesVmFromJSON(json: any): ConfigAllValidationRulesVm {
    return ConfigAllValidationRulesVmFromJSONTyped(json, false);
}

export function ConfigAllValidationRulesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigAllValidationRulesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zipPattern': !exists(json, 'zipPattern') ? undefined : json['zipPattern'],
        'taxNumberPattern': !exists(json, 'taxNumberPattern') ? undefined : json['taxNumberPattern'],
        'addressPattern': !exists(json, 'addressPattern') ? undefined : json['addressPattern'],
        'multipleEmailAddressPattern': !exists(json, 'multipleEmailAddressPattern') ? undefined : json['multipleEmailAddressPattern'],
    };
}

export function ConfigAllValidationRulesVmToJSON(value?: ConfigAllValidationRulesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zipPattern': value.zipPattern,
        'taxNumberPattern': value.taxNumberPattern,
        'addressPattern': value.addressPattern,
        'multipleEmailAddressPattern': value.multipleEmailAddressPattern,
    };
}


