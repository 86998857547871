/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublicTownVm,
    PublicTownVmFromJSON,
    PublicTownVmFromJSONTyped,
    PublicTownVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface PublicTownVmPaginatedSearchResponse
 */
export interface PublicTownVmPaginatedSearchResponse {
    /**
     * 
     * @type {Array<PublicTownVm>}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    items?: Array<PublicTownVm> | null;
    /**
     * 
     * @type {number}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    to?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicTownVmPaginatedSearchResponse
     */
    size?: number;
}

export function PublicTownVmPaginatedSearchResponseFromJSON(json: any): PublicTownVmPaginatedSearchResponse {
    return PublicTownVmPaginatedSearchResponseFromJSONTyped(json, false);
}

export function PublicTownVmPaginatedSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicTownVmPaginatedSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(PublicTownVmFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'size': !exists(json, 'size') ? undefined : json['size'],
    };
}

export function PublicTownVmPaginatedSearchResponseToJSON(value?: PublicTownVmPaginatedSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(PublicTownVmToJSON)),
        'totalCount': value.totalCount,
        'from': value.from,
        'to': value.to,
        'page': value.page,
        'size': value.size,
    };
}


