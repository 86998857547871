/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DevicePropertyVm
 */
export interface DevicePropertyVm {
    /**
     * 
     * @type {string}
     * @memberof DevicePropertyVm
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevicePropertyVm
     */
    value?: string | null;
}

export function DevicePropertyVmFromJSON(json: any): DevicePropertyVm {
    return DevicePropertyVmFromJSONTyped(json, false);
}

export function DevicePropertyVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevicePropertyVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function DevicePropertyVmToJSON(value?: DevicePropertyVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}


