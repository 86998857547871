/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryGroupVm,
    DictionaryGroupVmFromJSON,
    DictionaryGroupVmFromJSONTyped,
    DictionaryGroupVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface DictionaryVm
 */
export interface DictionaryVm {
    /**
     * 
     * @type {string}
     * @memberof DictionaryVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryVm
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryVm
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryVm
     */
    groupId?: string;
    /**
     * 
     * @type {DictionaryGroupVm}
     * @memberof DictionaryVm
     */
    group?: DictionaryGroupVm;
}

export function DictionaryVmFromJSON(json: any): DictionaryVm {
    return DictionaryVmFromJSONTyped(json, false);
}

export function DictionaryVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'group': !exists(json, 'group') ? undefined : DictionaryGroupVmFromJSON(json['group']),
    };
}

export function DictionaryVmToJSON(value?: DictionaryVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'description': value.description,
        'groupId': value.groupId,
        'group': DictionaryGroupVmToJSON(value.group),
    };
}


