import { CheckInvitationVm, PasswordComplexityVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { useEffect } from "react";

interface UseRegisterProps {
  userId: string | undefined;
}

interface UseRegister {
  checkInvitationData: CheckInvitationVm | undefined;
  passwordComplexity: PasswordComplexityVm | undefined;
  passwordComplexityLoading: boolean | undefined;
}

const useRegister = ({ userId }: UseRegisterProps): UseRegister => {
  const dispatch = useDispatch();
  const { fetchPasswordComplexity } = useSelector(
    (state) => state.loading.effects.auth
  );
  const { passwordComplexity, checkInvitationData } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (typeof userId === "string") {
      dispatch.auth.checkInvitation(userId);
    }
  }, [dispatch.auth, userId]);

  useEffect(() => {
    dispatch.auth.fetchPasswordComplexity();
  }, [dispatch.auth]);

  return {
    checkInvitationData,
    passwordComplexity,
    passwordComplexityLoading: fetchPasswordComplexity.loading,
  };
};

export default useRegister;
