import { TabPaneProps, Tabs } from "antd";
import { useDispatch } from "app/store";
import ListPageLayout from "components/layout/ListPageLayout";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TabsWrapper from "components/ui/TabsWrapper";
import TaskItemList from "./task-items-tab/TaskItemList";

type TabKey = "taskItems";

interface CustomTabPaneProps extends Omit<TabPaneProps, "key"> {
  key: TabKey;
}

const ReportTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams("");
  const activeTabParam = searchParams.get("tab");

  const tabs = useMemo<CustomTabPaneProps[]>(
    () => [
      {
        tab: t("report.tabs.taskItems"),
        key: "taskItems",
        children: <TaskItemList />,
      },
    ],
    [t]
  );

  const defaultActiveKey = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.key === activeTabParam);

    return activeTab ? activeTab.key : tabs[0].key;
  }, [activeTabParam, tabs]);

  const handleTabsChange = useCallback(
    (activeKey: string) => {
      setSearchParams(new URLSearchParams(`tab=${activeKey}`));

      dispatch.taskItemList.reset();
    },
    [dispatch.taskItemList, setSearchParams]
  );

  return (
    <ListPageLayout title={t("report.title")}>
      <TabsWrapper>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          destroyInactiveTabPane
          onChange={handleTabsChange}
          type="card"
          size="small"
        >
          {tabs.map(({ key, ...rest }) => (
            <Tabs.TabPane {...rest} key={key} />
          ))}
        </Tabs>
      </TabsWrapper>
    </ListPageLayout>
  );
};

export default ReportTabs;
