import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseInventoryListColumnTitles {
  columnTitles: string[];
}

const useInventoryListColumnTitles = (): UseInventoryListColumnTitles => {
  const { t } = useTranslation();

  const columnTitles = useMemo(
    () => [
      t("inventory.partNumber"),
      t("inventory.name"),
      t("inventory.manufacturer"),
      t("inventory.type"),
      t("inventory.category"),
    ],
    [t]
  );

  return {
    columnTitles,
  };
};

export default useInventoryListColumnTitles;
