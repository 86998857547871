/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Inventory
 */
export interface Inventory {
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    partNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Inventory
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    imageId?: string | null;
    /**
     * 
     * @type {Dictionary}
     * @memberof Inventory
     */
    category?: Dictionary;
    /**
     * 
     * @type {Image}
     * @memberof Inventory
     */
    image?: Image;
    /**
     * 
     * @type {boolean}
     * @memberof Inventory
     */
    isActivated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Inventory
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Inventory
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {User}
     * @memberof Inventory
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Inventory
     */
    modifier?: User;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    id?: string;
}

export function InventoryFromJSON(json: any): Inventory {
    return InventoryFromJSONTyped(json, false);
}

export function InventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Inventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'category': !exists(json, 'category') ? undefined : DictionaryFromJSON(json['category']),
        'image': !exists(json, 'image') ? undefined : ImageFromJSON(json['image']),
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function InventoryToJSON(value?: Inventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partNumber': value.partNumber,
        'name': value.name,
        'manufacturer': value.manufacturer,
        'type': value.type,
        'isDeleted': value.isDeleted,
        'categoryId': value.categoryId,
        'imageId': value.imageId,
        'category': DictionaryToJSON(value.category),
        'image': ImageToJSON(value.image),
        'isActivated': value.isActivated,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'id': value.id,
    };
}


