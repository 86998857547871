import { TownVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { GetFilterParamsForGetRequests } from "models/common";
import { calculateFilterRequestParams } from "utils";
import {
  ListRequestParams,
  mergeListParams,
} from "widgets/table/useTableUtils";

export const getFilterParamsForTownsGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  // there is no isActivated column, but we get data based on this default filtering
  const newParams: ListRequestParams = {
    ...params,
    filter: {
      ...params.filter,
      isActivated: true,
    },
  };

  const mergedParams = mergeListParams(existingParams, newParams);

  const filter = calculateFilterRequestParams<TownVm>(mergedParams);

  const payload: ListRequestParams = {
    ...mergedParams,
    filter,
  };

  return { payload, mergedParams };
};
