import { FC } from "react";
import { mdiCalendar, mdiFilter, mdiFilterOutline } from "@mdi/js";
import styles from "./FilterIcon.module.scss";
import { MaterialIcon } from "components/MaterialIcon";

export interface FilterIconProps {
  type: "date" | "filter";
  isFiltered: boolean;
}

export const FilterIcon: FC<FilterIconProps> = ({ type, isFiltered }) => {
  const icon =
    type === "date" ? mdiCalendar : isFiltered ? mdiFilter : mdiFilterOutline;

  return (
    <div className={styles.container}>
      {isFiltered && <span className={styles.indicator} />}
      <MaterialIcon path={icon} className={styles.icon} />
    </div>
  );
};
