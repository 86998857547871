import { Space } from "antd";
import React from "react";
import FailureViewAttachedSection from "./FailureViewAttachedSection";
import FailureViewDetailsSection from "./FailureViewDetailsSection";
import FailureViewWorkbookTasksSection from "./FailureViewWorkbookTasksSection";

const FailureViewDetailsTab: React.FC = () => {
  return (
    <div>
      <Space direction="vertical" size="large">
        <FailureViewDetailsSection />
        <FailureViewWorkbookTasksSection />
        <FailureViewAttachedSection />
      </Space>
    </div>
  );
};

export default FailureViewDetailsTab;
