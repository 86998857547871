/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    Sla,
    SlaFromJSON,
    SlaFromJSONTyped,
    SlaToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailureType
 */
export interface FailureType {
    /**
     * 
     * @type {string}
     * @memberof FailureType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureType
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureType
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailureType
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof FailureType
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FailureType
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FailureType
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureType
     */
    specialCase?: boolean | null;
    /**
     * 
     * @type {User}
     * @memberof FailureType
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof FailureType
     */
    modifier?: User;
    /**
     * 
     * @type {Array<Dictionary>}
     * @memberof FailureType
     */
    tasks?: Array<Dictionary> | null;
    /**
     * 
     * @type {Array<Sla>}
     * @memberof FailureType
     */
    slas?: Array<Sla> | null;
    /**
     * 
     * @type {string}
     * @memberof FailureType
     */
    id?: string;
}

export function FailureTypeFromJSON(json: any): FailureType {
    return FailureTypeFromJSONTyped(json, false);
}

export function FailureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'specialCase': !exists(json, 'specialCase') ? undefined : json['specialCase'],
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(DictionaryFromJSON)),
        'slas': !exists(json, 'slas') ? undefined : (json['slas'] === null ? null : (json['slas'] as Array<any>).map(SlaFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function FailureTypeToJSON(value?: FailureType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'isDeleted': value.isDeleted,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'specialCase': value.specialCase,
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(DictionaryToJSON)),
        'slas': value.slas === undefined ? undefined : (value.slas === null ? null : (value.slas as Array<any>).map(SlaToJSON)),
        'id': value.id,
    };
}


