/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddAttachedFailuresCommand,
    AddAttachedFailuresCommandFromJSON,
    AddAttachedFailuresCommandToJSON,
    AssignFailuresToContractorCommand,
    AssignFailuresToContractorCommandFromJSON,
    AssignFailuresToContractorCommandToJSON,
    AssignFailuresToRepairmanCommand,
    AssignFailuresToRepairmanCommandFromJSON,
    AssignFailuresToRepairmanCommandToJSON,
    CreateFailureCommand,
    CreateFailureCommandFromJSON,
    CreateFailureCommandToJSON,
    DetachFailureCommand,
    DetachFailureCommandFromJSON,
    DetachFailureCommandToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    FailureCountVm,
    FailureCountVmFromJSON,
    FailureCountVmToJSON,
    FailureListItemVmPaginatedSearchResponse,
    FailureListItemVmPaginatedSearchResponseFromJSON,
    FailureListItemVmPaginatedSearchResponseToJSON,
    FailureRepairmenDto,
    FailureRepairmenDtoFromJSON,
    FailureRepairmenDtoToJSON,
    FailureStatusVmPaginatedSearchResponse,
    FailureStatusVmPaginatedSearchResponseFromJSON,
    FailureStatusVmPaginatedSearchResponseToJSON,
    FailureVm,
    FailureVmFromJSON,
    FailureVmToJSON,
    GetAllFailureCreatorTypesQuery,
    GetAllFailureCreatorTypesQueryFromJSON,
    GetAllFailureCreatorTypesQueryToJSON,
    GetAllFailuresExcelQuery,
    GetAllFailuresExcelQueryFromJSON,
    GetAllFailuresExcelQueryToJSON,
    GetAllFailuresQuery,
    GetAllFailuresQueryFromJSON,
    GetAllFailuresQueryToJSON,
    GetFailureCountQuery,
    GetFailureCountQueryFromJSON,
    GetFailureCountQueryToJSON,
    GetFailureStatuesesQuery,
    GetFailureStatuesesQueryFromJSON,
    GetFailureStatuesesQueryToJSON,
    ReportFailureCommand,
    ReportFailureCommandFromJSON,
    ReportFailureCommandToJSON,
    UpdateFailureCommand,
    UpdateFailureCommandFromJSON,
    UpdateFailureCommandToJSON,
} from '../models';

export interface ApiFailuresAssignManyToRepairmanPutRequest {
    xLUMENTransactionGuid?: string;
    assignFailuresToRepairmanCommand?: AssignFailuresToRepairmanCommand;
}

export interface ApiFailuresAssignMultipleToContractorPutRequest {
    xLUMENTransactionGuid?: string;
    assignFailuresToContractorCommand?: AssignFailuresToContractorCommand;
}

export interface ApiFailuresAttachPostRequest {
    xLUMENTransactionGuid?: string;
    addAttachedFailuresCommand?: AddAttachedFailuresCommand;
}

export interface ApiFailuresDetachPostRequest {
    xLUMENTransactionGuid?: string;
    detachFailureCommand?: DetachFailureCommand;
}

export interface ApiFailuresExportPostRequest {
    xLUMENTransactionGuid?: string;
    getAllFailuresExcelQuery?: GetAllFailuresExcelQuery;
}

export interface ApiFailuresGetAllPostRequest {
    xLUMENTransactionGuid?: string;
    getAllFailuresQuery?: GetAllFailuresQuery;
}

export interface ApiFailuresGetCloseDateBackwardDaysGetRequest {
    xLUMENTransactionGuid?: string;
}

export interface ApiFailuresGetCountPostRequest {
    xLUMENTransactionGuid?: string;
    getFailureCountQuery?: GetFailureCountQuery;
}

export interface ApiFailuresGetFailureCreatorTypesPostRequest {
    xLUMENTransactionGuid?: string;
    getAllFailureCreatorTypesQuery?: GetAllFailureCreatorTypesQuery;
}

export interface ApiFailuresGetFailureStatuesesPostRequest {
    xLUMENTransactionGuid?: string;
    getFailureStatuesesQuery?: GetFailureStatuesesQuery;
}

export interface ApiFailuresIdAssignRepairmenPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    failureRepairmenDto?: FailureRepairmenDto;
}

export interface ApiFailuresIdGetRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiFailuresIdPutRequest {
    id: string;
    xLUMENTransactionGuid?: string;
    updateFailureCommand?: UpdateFailureCommand;
}

export interface ApiFailuresPostRequest {
    xLUMENTransactionGuid?: string;
    createFailureCommand?: CreateFailureCommand;
}

export interface ApiFailuresReportPostRequest {
    xLUMENTransactionGuid?: string;
    captcha?: string;
    reportFailureCommand?: ReportFailureCommand;
}

/**
 * 
 */
export class FailuresApi extends runtime.BaseAPI {

    /**
     * Assign failures to a repairman
     */
    async apiFailuresAssignManyToRepairmanPutRaw(requestParameters: ApiFailuresAssignManyToRepairmanPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/AssignManyToRepairman`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignFailuresToRepairmanCommandToJSON(requestParameters.assignFailuresToRepairmanCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign failures to a repairman
     */
    async apiFailuresAssignManyToRepairmanPut(requestParameters: ApiFailuresAssignManyToRepairmanPutRequest): Promise<void> {
        await this.apiFailuresAssignManyToRepairmanPutRaw(requestParameters);
    }

    /**
     * Assign multiple failures to a contractor
     */
    async apiFailuresAssignMultipleToContractorPutRaw(requestParameters: ApiFailuresAssignMultipleToContractorPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/AssignMultipleToContractor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignFailuresToContractorCommandToJSON(requestParameters.assignFailuresToContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign multiple failures to a contractor
     */
    async apiFailuresAssignMultipleToContractorPut(requestParameters: ApiFailuresAssignMultipleToContractorPutRequest): Promise<void> {
        await this.apiFailuresAssignMultipleToContractorPutRaw(requestParameters);
    }

    /**
     * Attaches some failures to a parent
     */
    async apiFailuresAttachPostRaw(requestParameters: ApiFailuresAttachPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/Attach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAttachedFailuresCommandToJSON(requestParameters.addAttachedFailuresCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Attaches some failures to a parent
     */
    async apiFailuresAttachPost(requestParameters: ApiFailuresAttachPostRequest): Promise<string> {
        const response = await this.apiFailuresAttachPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Detached a failure from its parent
     */
    async apiFailuresDetachPostRaw(requestParameters: ApiFailuresDetachPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/Detach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DetachFailureCommandToJSON(requestParameters.detachFailureCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Detached a failure from its parent
     */
    async apiFailuresDetachPost(requestParameters: ApiFailuresDetachPostRequest): Promise<string> {
        const response = await this.apiFailuresDetachPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure list as Excel file
     */
    async apiFailuresExportPostRaw(requestParameters: ApiFailuresExportPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailuresExcelQueryToJSON(requestParameters.getAllFailuresExcelQuery),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure list as Excel file
     */
    async apiFailuresExportPost(requestParameters: ApiFailuresExportPostRequest): Promise<string> {
        const response = await this.apiFailuresExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure list
     */
    async apiFailuresGetAllPostRaw(requestParameters: ApiFailuresGetAllPostRequest): Promise<runtime.ApiResponse<FailureListItemVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailuresQueryToJSON(requestParameters.getAllFailuresQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureListItemVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure list
     */
    async apiFailuresGetAllPost(requestParameters: ApiFailuresGetAllPostRequest): Promise<FailureListItemVmPaginatedSearchResponse> {
        const response = await this.apiFailuresGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Number of days Failure.ClosedAt can be dated backward
     */
    async apiFailuresGetCloseDateBackwardDaysGetRaw(requestParameters: ApiFailuresGetCloseDateBackwardDaysGetRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/GetCloseDateBackwardDays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Number of days Failure.ClosedAt can be dated backward
     */
    async apiFailuresGetCloseDateBackwardDaysGet(requestParameters: ApiFailuresGetCloseDateBackwardDaysGetRequest): Promise<number> {
        const response = await this.apiFailuresGetCloseDateBackwardDaysGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get failure count.
     */
    async apiFailuresGetCountPostRaw(requestParameters: ApiFailuresGetCountPostRequest): Promise<runtime.ApiResponse<FailureCountVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/GetCount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetFailureCountQueryToJSON(requestParameters.getFailureCountQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureCountVmFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get failure count.
     */
    async apiFailuresGetCountPost(requestParameters: ApiFailuresGetCountPostRequest): Promise<FailureCountVm> {
        const response = await this.apiFailuresGetCountPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of possible failure creator types
     */
    async apiFailuresGetFailureCreatorTypesPostRaw(requestParameters: ApiFailuresGetFailureCreatorTypesPostRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/GetFailureCreatorTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailureCreatorTypesQueryToJSON(requestParameters.getAllFailureCreatorTypesQuery),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get the list of possible failure creator types
     */
    async apiFailuresGetFailureCreatorTypesPost(requestParameters: ApiFailuresGetFailureCreatorTypesPostRequest): Promise<number> {
        const response = await this.apiFailuresGetFailureCreatorTypesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of failure statuses
     */
    async apiFailuresGetFailureStatuesesPostRaw(requestParameters: ApiFailuresGetFailureStatuesesPostRequest): Promise<runtime.ApiResponse<FailureStatusVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/GetFailureStatueses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetFailureStatuesesQueryToJSON(requestParameters.getFailureStatuesesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureStatusVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of failure statuses
     */
    async apiFailuresGetFailureStatuesesPost(requestParameters: ApiFailuresGetFailureStatuesesPostRequest): Promise<FailureStatusVmPaginatedSearchResponse> {
        const response = await this.apiFailuresGetFailureStatuesesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Assign failure to repairmen
     */
    async apiFailuresIdAssignRepairmenPutRaw(requestParameters: ApiFailuresIdAssignRepairmenPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailuresIdAssignRepairmenPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/{id}/AssignRepairmen`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FailureRepairmenDtoToJSON(requestParameters.failureRepairmenDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign failure to repairmen
     */
    async apiFailuresIdAssignRepairmenPut(requestParameters: ApiFailuresIdAssignRepairmenPutRequest): Promise<void> {
        await this.apiFailuresIdAssignRepairmenPutRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiFailuresIdGetRaw(requestParameters: ApiFailuresIdGetRequest): Promise<runtime.ApiResponse<FailureVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailuresIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiFailuresIdGet(requestParameters: ApiFailuresIdGetRequest): Promise<FailureVm> {
        const response = await this.apiFailuresIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current Failure with the fulfilled model
     */
    async apiFailuresIdPutRaw(requestParameters: ApiFailuresIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailuresIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFailureCommandToJSON(requestParameters.updateFailureCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current Failure with the fulfilled model
     */
    async apiFailuresIdPut(requestParameters: ApiFailuresIdPutRequest): Promise<void> {
        await this.apiFailuresIdPutRaw(requestParameters);
    }

    /**
     * Creates a new failure
     */
    async apiFailuresPostRaw(requestParameters: ApiFailuresPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFailureCommandToJSON(requestParameters.createFailureCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new failure
     */
    async apiFailuresPost(requestParameters: ApiFailuresPostRequest): Promise<string> {
        const response = await this.apiFailuresPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new failure (public reporting)
     */
    async apiFailuresReportPostRaw(requestParameters: ApiFailuresReportPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (requestParameters.captcha !== undefined && requestParameters.captcha !== null) {
            headerParameters['captcha'] = String(requestParameters.captcha);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Failures/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportFailureCommandToJSON(requestParameters.reportFailureCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new failure (public reporting)
     */
    async apiFailuresReportPost(requestParameters: ApiFailuresReportPostRequest): Promise<string> {
        const response = await this.apiFailuresReportPostRaw(requestParameters);
        return await response.value();
    }

}
