import { mdiDelete, mdiPlus } from "@mdi/js";
import {
  Button,
  CheckboxOptionType,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { useWatch } from "antd/lib/form/Form";
import { ContactPerson } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import IconButton from "components/ui/IconButton";
import { TownCreateFormValues } from "features/towns/models";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import { FormMode, ItemProps } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { enumTranslate } from "utils/enumTranslate";
import {
  email,
  FormUtils,
  GrapeAntdForm,
  min,
  phoneNumber,
  requiredField,
} from "widgets/form";
import styles from "./ContactsFieldset.module.scss";
import { NewContactType } from "./NewContactType";

const { confirm } = Modal;
interface ContactsFieldsetProps {
  formMode: FormMode;
  formUtils: FormUtils<TownCreateFormValues>;
}

const ContactsFieldset: React.FC<ContactsFieldsetProps> = ({
  formMode,
  formUtils,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { existingContacts } = useSelector((state) => state.townCreate);
  const { loading } = useSelector(
    (state) => state.loading.effects.townCreate.fetchExistingContacts
  );
  const [selectedContactTypes, setSelectedContactTypes] = useState<{
    [fieldKey: number]: NewContactType;
  }>();
  const [existingContactIdSelection, setExistingContactIdSelection] = useState<{
    [id: string]: boolean;
  }>({});
  const { displayName } = useLanguageSensitiveUtils();

  const removeExistingContactSelection = useCallback((id: string) => {
    // toggle off the selection of this existing contact to make available in select
    setExistingContactIdSelection((prev) => ({
      ...prev,
      [id]: false,
    }));
  }, []);

  const contactPersonsValues = useWatch("contactPersons");

  const handleRemove = (
    index: number,
    removeFn: (index: number | number[]) => void
  ) => {
    // If user deletes already saved contactPerson
    if (contactPersonsValues[index]?.id) {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          removeExistingContactSelection(contactPersonsValues[index].id);
          removeFn(index);
        },
      });

      return;
    }

    removeFn(index);
  };

  const contactTypeOptions = useMemo<CheckboxOptionType[]>(
    () => [
      {
        value: NewContactType.NEW,
        label: t("contact.new"),
      },
      {
        value: NewContactType.EXISTING,
        label: t("contact.existing"),
      },
    ],
    [t]
  );

  const handleContactTypeChange = useCallback(
    (key: number, index: number, value: NewContactType) => {
      const contactValues = formUtils.form.getFieldValue("contactPersons");

      // if moved away from the existing type, remove the selection of the contact id
      const selectedExistingContactId = contactValues[index]?.id;
      if (value !== NewContactType.EXISTING && selectedExistingContactId) {
        removeExistingContactSelection(selectedExistingContactId);
      }

      // clear contact form item
      contactValues[index] = {};

      // set new form values
      formUtils.form.setFieldsValue({
        contactPersons: contactValues,
      });

      // update state of specific radio group
      setSelectedContactTypes((prev) => ({ ...prev, [key]: value }));
    },
    [formUtils.form, removeExistingContactSelection]
  );

  const existingContactOptions = useMemo<ItemProps[]>(() => {
    const notSelectables = Object.keys(existingContactIdSelection);
    return (existingContacts || [])
      .filter(
        (contact: ContactPerson) => !notSelectables.includes(contact.id ?? "")
      )
      .map(
        ({ id, familyname, forename, middlename, email }: ContactPerson) => ({
          value: id || "",
          label: `${displayName({
            familyname,
            forename,
            middlename,
          })} (${email})`,
        })
      );
  }, [displayName, existingContactIdSelection, existingContacts]);

  const handleExistingContactSelection = useCallback(
    (index: number, contactId: string) => {
      // get all the contact form values
      const contactValues = formUtils.form.getFieldValue("contactPersons");

      // filter based on selected value
      const selectedContact = existingContacts?.find(
        (contact) => contact.id === contactId
      );

      // extract previously selected id to update selection map
      const prevId = contactValues[index]?.id;

      // override the appropriate contact form values locally
      contactValues[index] = selectedContact;

      // set the updated values to the form items
      formUtils.form.setFieldsValue({
        contactPersons: contactValues,
      });

      // add contact id to list to filter it out from further selections
      setExistingContactIdSelection((prev) => ({
        ...prev,
        [contactId]: true,
        ...(prevId !== undefined ? { [prevId]: false } : {}),
      }));
    },
    [existingContacts, formUtils.form]
  );

  const isExistingContactPerson = (index: number) =>
    !!contactPersonsValues?.[index]?.id;

  useEffect(() => {
    if (formMode !== "View") {
      dispatch.townCreate.fetchExistingContacts();
    }
  }, [formMode, dispatch.townCreate]);

  useEffect(() => {
    if (existingContacts?.length) {
      contactPersonsValues?.forEach((el: ContactPerson) => {
        if (el?.id) {
          removeExistingContactSelection(el.id);
        }
      });
    }
  }, [contactPersonsValues, existingContacts, removeExistingContactSelection]);

  return (
    <>
      <fieldset>
        <Form.List name="contactPersons" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              <legend>
                <Row justify="space-between">
                  <Col>{t("town.contactsFieldset")}</Col>
                  <Col>
                    <Button
                      icon={<MaterialIcon path={mdiPlus} />}
                      onClick={add}
                      disabled={formMode === "View"}
                    >
                      {t("town.newContact")}
                    </Button>
                  </Col>
                </Row>
              </legend>

              <Space direction="vertical" className={styles.space}>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <FieldsetFieldsWrapper key={key}>
                      {formMode !== "View" && (
                        <Row
                          justify="space-between"
                          align="middle"
                          className={styles.actionButtons}
                        >
                          <Col>
                            <Radio.Group
                              defaultValue={
                                formMode === "Create"
                                  ? NewContactType.NEW
                                  : isExistingContactPerson(name)
                                  ? NewContactType.EXISTING
                                  : NewContactType.NEW
                              }
                              onChange={(e: RadioChangeEvent) =>
                                handleContactTypeChange(
                                  key,
                                  name,
                                  e.target.value as NewContactType
                                )
                              }
                            >
                              {contactTypeOptions.map((option) => (
                                <Radio
                                  {...option}
                                  key={option.value + ""}
                                  disabled={
                                    (option.value as NewContactType) ===
                                      NewContactType.EXISTING &&
                                    existingContacts !== undefined &&
                                    existingContactOptions.length < 1
                                  }
                                >
                                  {option.label}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Col>
                          {fields.length > 1 && (
                            <Col>
                              <IconButton
                                path={mdiDelete}
                                onClick={() => handleRemove(name, remove)}
                                className={styles.deleteButton}
                                lightIcon
                              />
                            </Col>
                          )}
                        </Row>
                      )}
                      {selectedContactTypes?.[key] ===
                        NewContactType.EXISTING && (
                        <Row>
                          <Col span={24}>
                            <GrapeAntdForm.Item
                              {...restField}
                              name={[name, "existingContact"]}
                              label={t("town.selectExistingContact")}
                              className={styles.existingContactSelector}
                            >
                              <Select
                                placeholder={t(
                                  "contact.existingContactPlaceholder"
                                )}
                                size="large"
                                options={existingContactOptions}
                                onChange={(value) =>
                                  handleExistingContactSelection(name, value)
                                }
                                loading={loading}
                                showSearch
                                optionFilterProp="label"
                                filterOption={filterOptionsLabelFromBeginning}
                              />
                            </GrapeAntdForm.Item>
                          </Col>
                        </Row>
                      )}
                      <Row justify="center" gutter={[24, 8]}>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "id"]}
                            hidden
                          >
                            <Input hidden />
                          </GrapeAntdForm.Item>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "familyname"]}
                            label={t("contact.familyName")}
                            rules={[requiredField(t)]}
                          >
                            <Input
                              size="large"
                              disabled={
                                selectedContactTypes?.[key] ===
                                  NewContactType.EXISTING ||
                                isExistingContactPerson(name)
                              }
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "forename"]}
                            label={t("contact.forename")}
                            rules={[requiredField(t)]}
                          >
                            <Input
                              size="large"
                              disabled={
                                selectedContactTypes?.[key] ===
                                  NewContactType.EXISTING ||
                                isExistingContactPerson(name)
                              }
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "email"]}
                            label={t("contact.email")}
                            rules={[requiredField(t), email(t)]}
                          >
                            <Input
                              size="large"
                              type="email"
                              disabled={
                                selectedContactTypes?.[key] ===
                                  NewContactType.EXISTING ||
                                isExistingContactPerson(name)
                              }
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "phone", "number"]}
                            label={t("contact.phone")}
                            rules={[phoneNumber(t), min(t, 6, "string")]}
                          >
                            <Input
                              size="large"
                              type="phone"
                              disabled={
                                selectedContactTypes?.[key] ===
                                  NewContactType.EXISTING ||
                                isExistingContactPerson(name)
                              }
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                      </Row>

                      <Row justify="center" gutter={[24, 8]}>
                        <Col span={12}>
                          <Space
                            align="center"
                            size="small"
                            className={styles.registrationState}
                          >
                            {t("contact.registrationState") + ": "}
                            {enumTranslate(
                              contactPersonsValues?.[key]?.registrationState,
                              "registrationState"
                            )}
                          </Space>
                        </Col>

                        <Col span={12}>
                          {formMode !== "View" &&
                            (selectedContactTypes?.[key] ??
                              NewContactType.NEW) === NewContactType.NEW && (
                              <div className={styles.invite}>
                                <Space align="center" size="middle">
                                  <span>{t("contact.toBeInvited")}</span>
                                  <GrapeAntdForm.Item
                                    name={[name, "shouldInvite"]}
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Switch
                                      disabled={
                                        contactPersonsValues?.[key]?.userId
                                      }
                                    />
                                  </GrapeAntdForm.Item>
                                </Space>
                              </div>
                            )}
                        </Col>
                      </Row>
                    </FieldsetFieldsWrapper>
                  );
                })}
              </Space>
            </>
          )}
        </Form.List>
      </fieldset>
    </>
  );
};

export default ContactsFieldset;
