/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkbookTaskDto,
    WorkbookTaskDtoFromJSON,
    WorkbookTaskDtoFromJSONTyped,
    WorkbookTaskDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateWorkbookCommand
 */
export interface CreateWorkbookCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkbookCommand
     */
    failureId: string;
    /**
     * 
     * @type {Array<WorkbookTaskDto>}
     * @memberof CreateWorkbookCommand
     */
    tasks?: Array<WorkbookTaskDto> | null;
}

export function CreateWorkbookCommandFromJSON(json: any): CreateWorkbookCommand {
    return CreateWorkbookCommandFromJSONTyped(json, false);
}

export function CreateWorkbookCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkbookCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureId': json['failureId'],
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(WorkbookTaskDtoFromJSON)),
    };
}

export function CreateWorkbookCommandToJSON(value?: CreateWorkbookCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureId': value.failureId,
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(WorkbookTaskDtoToJSON)),
    };
}


