/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryGroup,
    DictionaryGroupFromJSON,
    DictionaryGroupFromJSONTyped,
    DictionaryGroupToJSON,
    FailureType,
    FailureTypeFromJSON,
    FailureTypeFromJSONTyped,
    FailureTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Dictionary
 */
export interface Dictionary {
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Dictionary
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Dictionary
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Dictionary
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {DictionaryGroup}
     * @memberof Dictionary
     */
    group?: DictionaryGroup;
    /**
     * 
     * @type {Array<FailureType>}
     * @memberof Dictionary
     */
    failureTypes?: Array<FailureType> | null;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    id?: string;
}

export function DictionaryFromJSON(json: any): Dictionary {
    return DictionaryFromJSONTyped(json, false);
}

export function DictionaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dictionary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'group': !exists(json, 'group') ? undefined : DictionaryGroupFromJSON(json['group']),
        'failureTypes': !exists(json, 'failureTypes') ? undefined : (json['failureTypes'] === null ? null : (json['failureTypes'] as Array<any>).map(FailureTypeFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DictionaryToJSON(value?: Dictionary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'description': value.description,
        'isActive': value.isActive,
        'groupId': value.groupId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'group': DictionaryGroupToJSON(value.group),
        'failureTypes': value.failureTypes === undefined ? undefined : (value.failureTypes === null ? null : (value.failureTypes as Array<any>).map(FailureTypeToJSON)),
        'id': value.id,
    };
}


