import { PhoneDto } from "api/generated/lumen";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface PhoneDisplayProps {
  value?: PhoneDto;
}

// Temporarily  we only display the number property
const PhoneDisplay: React.FC<PhoneDisplayProps> = ({ value }) => {
  return value ? <>{value.number}</> : <EmptyContent />;
};

export default PhoneDisplay;
