import { mdiPlusThick } from "@mdi/js";
import { Space, Spin } from "antd";
import { useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import AddNewWorkbookTaskModal from "./components/AddNewWorkbookTaskModal";
import FailureViewWorbookTaskCard from "../../../components/FailureViewWorbookTaskCard";
import styles from "./FailureViewWorkbookTab.module.scss";
import useFailureViewWorkbookTaskCard from "features/failures/view/components/useFailureViewWorkbookTaskCard";

const FailureViewWorkbookTab: React.FC = () => {
  const {
    handleTaskCompleted,
    selectedTask,
    setWorkbookTaskStatusIsLoading,
    fetchWorkbookTaskReasonsIsLoading,
    fetchWorkbookTaskStatusesIsLoading,
    addNewTaskModalIsOpen,
    setAddNewTaskModalIsOpen,
  } = useFailureViewWorkbookTaskCard();

  const { t } = useTranslation();
  const { workbook } = useSelector((state) => state.failureView);
  const { fetchWorkbook } = useSelector(
    (state) => state.loading.effects.failureView
  );

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.addNewTask}
        onClick={() => setAddNewTaskModalIsOpen(true)}
      >
        <MaterialIcon path={mdiPlusThick} className={styles.addNewTaskIcon} />
        {t("workbook.addNewTask")}
      </button>
      <Spin
        spinning={
          fetchWorkbook.loading ||
          fetchWorkbookTaskReasonsIsLoading ||
          fetchWorkbookTaskStatusesIsLoading
        }
      >
        <Space direction="vertical" className={styles.space}>
          {workbook?.tasks?.map((task) => (
            <FailureViewWorbookTaskCard
              task={task}
              onTaskCompleted={handleTaskCompleted}
              completeButtonIsLoading={
                selectedTask === task.id && setWorkbookTaskStatusIsLoading
              }
              key={task.id}
            />
          ))}
        </Space>
      </Spin>

      <AddNewWorkbookTaskModal
        visible={addNewTaskModalIsOpen}
        onCancel={() => setAddNewTaskModalIsOpen(false)}
      />
    </div>
  );
};

export default FailureViewWorkbookTab;
