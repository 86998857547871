import { mdiPencil, mdiPlus } from "@mdi/js";
import {
  Button,
  Col,
  Input,
  ModalProps,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import {
  ApiInventoryPostRequest,
  InventoryDetailsVm,
} from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useFileInput from "hooks/useFileInput";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dictionaryOptions, filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import styles from "./InventoryCreateModal.module.scss";
import CustomForm from "components/form/CustomForm";
import useEditFormModal from "hooks/useEditModal";
import useInventoryCategoriesQuery from "hooks/queries/useInventoryCategoriesQuery";
import { ListRequestParams } from "widgets/table/useTableUtils";

const maxFileSize = 10;

export interface InventoryCreate
  extends Omit<ApiInventoryPostRequest, "imageData"> {
  imageData: UploadFile[];
}

export interface InventoryCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

const InventoryCreateModal: React.FC<InventoryCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<InventoryCreate>();
  const dispatch = useDispatch();
  const { editingItemId, editingItem } = useSelector(
    (state) => state.inventoryCreate
  );
  const inventoryCategoriesQuery = useInventoryCategoriesQuery(true);
  const { create, edit, fetchItem } = useSelector(
    (state) => state.loading.effects.inventoryCreate
  );
  const { dummyRequest, normFile, fileSizeCheck, fileExtensionCheck } =
    useFileInput();

  const categoryOptions = useMemo(
    () => dictionaryOptions(inventoryCategoriesQuery.data?.items || []),
    [inventoryCategoriesQuery.data?.items]
  );

  const acceptedFileTypes = useMemo(() => {
    const types = ["image/png", "image/x-png", "image/jpeg"];
    return types.join(", ");
  }, []);

  const getInitialValues = useCallback(
    (itemData: InventoryDetailsVm) => ({
      partNumber: itemData.partNumber || undefined,
      name: itemData.name || undefined,
      manufacturer: itemData.manufacturer || undefined,
      type: itemData.type || undefined,
      categoryId: itemData.category?.id,
      imageData: itemData.image
        ? [
            {
              uid: "-1",
              name: itemData.image.fileName || undefined,
              status: "done" as UploadFileStatus,
              url: `data:${itemData.image?.imageThumbnails?.[0].contentType};base64,${itemData.image?.imageThumbnails?.[0].thumbnailData}`,
              size: 0,
              type:
                itemData.image?.imageThumbnails?.[0].contentType || undefined,
            },
          ]
        : undefined,
    }),
    []
  );

  const { formMode, setFormMode } = useEditFormModal<
    InventoryDetailsVm,
    InventoryCreate
  >({
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.inventoryCreate.resetEditingItemData,
    item: editingItem,
  });

  const handleFinish = useCallback(
    async (values: InventoryCreate): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.inventoryCreate.create({
          ...values,
          imageData: values.imageData?.[0].originFileObj,
        });
      }

      if (formMode === "Edit" && typeof editingItemId === "string") {
        await dispatch.inventoryCreate.edit({
          ...values,
          id: editingItemId,
          imageData: values.imageData?.[0].originFileObj,
        });
      }

      dispatch.inventoryList.fetchList(fetchDataParams);

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.inventoryCreate,
      dispatch.inventoryList,
      editingItemId,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  useEffect(() => {
    if (visible && typeof editingItemId === "string") {
      dispatch.inventoryCreate.fetchItem(editingItemId);
    }
  }, [dispatch.inventoryCreate, editingItemId, visible]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("inventory.createModal.title")
          : t("inventory.editModal.title")
      }
      size="large"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: create.loading || edit.loading,
        disabled: formMode === "View",
      }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
    >
      {/* TODO: THIS HAS BEEN SOLVED IN ChooseRepairMenToAssign AT LINE 60 */}
      {/* Only show form if initial data is already loaded in View or Edit mode, because otherwise useEditFormModal's formUtils.form.setFieldsValue function call would make all fields touched, but we only want fields to be touched if user changes someting in them. Known issue in AntD: https://github.com/ant-design/ant-design/issues/33991  */}
      {formMode === "Create" && fetchItem.loading ? (
        <div className={styles.spinWrapper}>
          <Spin size="large" />
        </div>
      ) : (
        <CustomForm
          formUtils={formUtils}
          onFinish={handleFinish}
          viewMode={formMode === "View"}
        >
          <Row justify="center" gutter={40}>
            <Col span={5}>
              <GrapeAntdForm.Item
                name="imageData"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  accept={acceptedFileTypes}
                  beforeUpload={(file) =>
                    fileSizeCheck(file, maxFileSize) &&
                    fileExtensionCheck(file, acceptedFileTypes)
                  }
                  customRequest={dummyRequest}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                    showPreviewIcon: false,
                  }}
                  listType="picture-card"
                >
                  <div>
                    <MaterialIcon
                      path={mdiPlus}
                      className={styles.uploadIcon}
                    />
                    <div className={styles.uploadText}>
                      {t("inventory.addNewImage")}
                    </div>
                  </div>
                </Upload>
              </GrapeAntdForm.Item>
            </Col>
            <Col span={19}>
              {formMode === "View" && (
                <Row justify="end" className={styles.editWrapper}>
                  <Button
                    icon={<MaterialIcon path={mdiPencil} />}
                    className={styles.editButton}
                    onClick={() => setFormMode("Edit")}
                  >
                    {t("common.edit")}
                  </Button>
                </Row>
              )}

              <GrapeAntdForm.Item name="isActivated" hidden initialValue={true}>
                <Switch />
              </GrapeAntdForm.Item>

              <GrapeAntdForm.Item
                name="partNumber"
                label={t("inventory.partNumber")}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                name="name"
                label={t("inventory.name")}
                rules={[requiredField(t)]}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>
              <GrapeAntdForm.Item
                name="manufacturer"
                label={t("inventory.manufacturer")}
              >
                <Input size="large" />
              </GrapeAntdForm.Item>

              <GrapeAntdForm.Item name="type" label={t("inventory.type")}>
                <Input size="large" />
              </GrapeAntdForm.Item>

              <GrapeAntdForm.Item
                name="categoryId"
                label={t("inventory.category")}
                rules={[requiredField(t)]}
              >
                <Select
                  size="large"
                  options={categoryOptions}
                  loading={inventoryCategoriesQuery.isFetching}
                  showSearch
                  optionFilterProp="label"
                  filterOption={filterOptionsLabelFromBeginning}
                />
              </GrapeAntdForm.Item>
            </Col>
          </Row>
        </CustomForm>
      )}
    </CustomModal>
  );
};

export default InventoryCreateModal;
