/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageThumbnailMetadataVm,
    ImageThumbnailMetadataVmFromJSON,
    ImageThumbnailMetadataVmFromJSONTyped,
    ImageThumbnailMetadataVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImageMetadataVm
 */
export interface ImageMetadataVm {
    /**
     * 
     * @type {string}
     * @memberof ImageMetadataVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageMetadataVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageMetadataVm
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageMetadataVm
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageMetadataVm
     */
    size?: number;
    /**
     * 
     * @type {Array<ImageThumbnailMetadataVm>}
     * @memberof ImageMetadataVm
     */
    imageThumbnails?: Array<ImageThumbnailMetadataVm> | null;
}

export function ImageMetadataVmFromJSON(json: any): ImageMetadataVm {
    return ImageMetadataVmFromJSONTyped(json, false);
}

export function ImageMetadataVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageMetadataVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'imageThumbnails': !exists(json, 'imageThumbnails') ? undefined : (json['imageThumbnails'] === null ? null : (json['imageThumbnails'] as Array<any>).map(ImageThumbnailMetadataVmFromJSON)),
    };
}

export function ImageMetadataVmToJSON(value?: ImageMetadataVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fileName': value.fileName,
        'contentType': value.contentType,
        'size': value.size,
        'imageThumbnails': value.imageThumbnails === undefined ? undefined : (value.imageThumbnails === null ? null : (value.imageThumbnails as Array<any>).map(ImageThumbnailMetadataVmToJSON)),
    };
}


