import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import i18n from "app/i18n";

const getEnumTranslation = (key: string, context: string) => {
  const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
  const i18nKey = `enums.${context}.${modifiedKey}`;
  return i18n.t([i18nKey, key]);
};

export const enumTranslateText = (key?: string, context?: string) => {
  if (!key || !context) return;

  return getEnumTranslation(key, context);
};

export const enumTranslate = (key?: string | null, context?: string) => {
  if (!key || !context) return <EmptyContent />;

  return getEnumTranslation(key, context);
};
