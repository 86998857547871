import { DevicePropertyType } from "api/generated/lumen";
import { SupportedLanguage } from "models/common";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface DevicePropertyDisplayProps {
  type: DevicePropertyType | undefined;
  value: string | null | undefined;
}

const localeMap: Record<SupportedLanguage, string> = {
  en: "en-GB",
  hu: "hu-HU",
};

const DevicePropertyDisplay: React.FC<DevicePropertyDisplayProps> = ({
  type,
  value,
}) => {
  const { i18n } = useTranslation();

  const formattedValue = useMemo(() => {
    if (!value || !type) {
      return null;
    }

    switch (type) {
      case DevicePropertyType.Text:
        return value;

      case DevicePropertyType.Number:
        return new Intl.NumberFormat(
          localeMap[i18n.language as SupportedLanguage]
        ).format(+value);

      default:
        return value;
    }
  }, [i18n.language, type, value]);

  return <>{formattedValue}</>;
};

export default DevicePropertyDisplay;
