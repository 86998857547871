import { createModel } from "@rematch/core";
import { api } from "api";
import {
  CreateInventoryCategoryCommand,
  UpdateInventoryCategoryCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface InventoryCategoryCreateState {
  editingItemId?: string;
}

const initialState: InventoryCategoryCreateState = {
  editingItemId: undefined,
};

export const inventoryCategoryCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    resetEditingItemData(state) {
      state.editingItemId = undefined;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    return {
      async create(values: CreateInventoryCategoryCommand): Promise<string> {
        return api.lumen.inventoryCategory.apiInventoryCategoryInventorycategoryPost(
          { createInventoryCategoryCommand: values }
        );
      },

      async edit(values: UpdateInventoryCategoryCommand): Promise<void> {
        return api.lumen.inventoryCategory.apiInventoryCategoryInventorycategoryIdPut(
          { id: values.id, updateInventoryCategoryCommand: values }
        );
      },
    };
  },
});
