import { EnvTranslationKey } from "app/i18n/generated/env-translation-keys";
import { useTranslation } from "react-i18next";
import themeConfig from "themeConfig";

const useEnvTranslation = (key: EnvTranslationKey) => {
  const { t } = useTranslation();

  return t(`env.${key}.${themeConfig.currentBrand}`);
};

export default useEnvTranslation;
