/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignDevicesToContractorCommand
 */
export interface AssignDevicesToContractorCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignDevicesToContractorCommand
     */
    deviceIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignDevicesToContractorCommand
     */
    contractorId: string;
}

export function AssignDevicesToContractorCommandFromJSON(json: any): AssignDevicesToContractorCommand {
    return AssignDevicesToContractorCommandFromJSONTyped(json, false);
}

export function AssignDevicesToContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignDevicesToContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceIds': json['deviceIds'],
        'contractorId': json['contractorId'],
    };
}

export function AssignDevicesToContractorCommandToJSON(value?: AssignDevicesToContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceIds': value.deviceIds,
        'contractorId': value.contractorId,
    };
}


