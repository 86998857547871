import { Checkbox, Input } from "antd";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import {
  email,
  GrapeAntdForm,
  min,
  phoneNumber,
  requiredField,
} from "widgets/form";
import { useTranslation } from "react-i18next";
import { FieldsetProps } from "models/common";

interface GdprCheckboxData {
  isGDPRAccepted: boolean;
  setGDPRAccepted: (isAccepted: boolean) => void;
}

interface FailureNotifierFieldsetProps extends FieldsetProps {
  isEmailRequired: boolean;
  gdprCheckboxData?: GdprCheckboxData;
}

const FailureNotifierFieldset: React.FC<FailureNotifierFieldsetProps> = ({
  isEmailRequired,
  gdprCheckboxData,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <fieldset disabled={disabled ?? false}>
      <legend>{t("failure.report.notifierTitle")}</legend>

      <FieldsetFieldsWrapper>
        <GrapeAntdForm.Item
          label={t("failure.report.notifierFamilyname")}
          name="notifierFamilyname"
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          label={t("failure.report.notifierForename")}
          name="notifierForename"
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          label={t("failure.report.notifierPhone")}
          name={["notifierPhone", "number"]}
          rules={[phoneNumber(t), min(t, 6, "string")]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item
          label={t("failure.report.notifierEmail")}
          name="notifierEmail"
          rules={[isEmailRequired ? requiredField(t) : {}, email(t)]}
        >
          <Input size="large" type="email" />
        </GrapeAntdForm.Item>

        {gdprCheckboxData && (
          <Checkbox
            checked={gdprCheckboxData.isGDPRAccepted}
            onChange={() =>
              gdprCheckboxData.setGDPRAccepted(!gdprCheckboxData.isGDPRAccepted)
            }
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t("failure.report.gdpr", {
                  link: process.env.REACT_APP_GDPR_TERMS_LINK,
                }),
              }}
            />
          </Checkbox>
        )}
      </FieldsetFieldsWrapper>
    </fieldset>
  );
};

export default FailureNotifierFieldset;
