/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContractorListItemVm
 */
export interface ContractorListItemVm {
    /**
     * 
     * @type {string}
     * @memberof ContractorListItemVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorListItemVm
     */
    name?: string | null;
    /**
     * 
     * @type {AddressVm}
     * @memberof ContractorListItemVm
     */
    headquarterAddress?: AddressVm;
    /**
     * 
     * @type {AddressVm}
     * @memberof ContractorListItemVm
     */
    postalAddress?: AddressVm;
    /**
     * 
     * @type {string}
     * @memberof ContractorListItemVm
     */
    email?: string | null;
    /**
     * 
     * @type {PhoneVm}
     * @memberof ContractorListItemVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof ContractorListItemVm
     */
    taxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorListItemVm
     */
    companyRegisterNumber?: string | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof ContractorListItemVm
     */
    status?: DictionaryVm;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorListItemVm
     */
    isInvited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorListItemVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorListItemVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ContractorListItemVm
     */
    createdDate?: Date;
}

export function ContractorListItemVmFromJSON(json: any): ContractorListItemVm {
    return ContractorListItemVmFromJSONTyped(json, false);
}

export function ContractorListItemVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractorListItemVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'headquarterAddress': !exists(json, 'headquarterAddress') ? undefined : AddressVmFromJSON(json['headquarterAddress']),
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressVmFromJSON(json['postalAddress']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'companyRegisterNumber': !exists(json, 'companyRegisterNumber') ? undefined : json['companyRegisterNumber'],
        'status': !exists(json, 'status') ? undefined : DictionaryVmFromJSON(json['status']),
        'isInvited': !exists(json, 'isInvited') ? undefined : json['isInvited'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
    };
}

export function ContractorListItemVmToJSON(value?: ContractorListItemVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'headquarterAddress': AddressVmToJSON(value.headquarterAddress),
        'postalAddress': AddressVmToJSON(value.postalAddress),
        'email': value.email,
        'phone': PhoneVmToJSON(value.phone),
        'taxNumber': value.taxNumber,
        'companyRegisterNumber': value.companyRegisterNumber,
        'status': DictionaryVmToJSON(value.status),
        'isInvited': value.isInvited,
        'isActive': value.isActive,
        'isDeleted': value.isDeleted,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
    };
}


