import { FC, ReactNode } from "react";
import styles from "./FieldsetFieldsWrapper.module.scss";

interface FieldsetFieldsWrapperProps {
  children?: ReactNode;
}

const FieldsetFieldsWrapper: FC<FieldsetFieldsWrapperProps> = ({
  children,
}) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default FieldsetFieldsWrapper;
