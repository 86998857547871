import { mdiLink } from "@mdi/js";
import { Divider, Empty, Modal } from "antd";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import { useCallback, useEffect } from "react";
import DarkContainerCard from "../DarkContainerCard";
import AttachedFailureCard from "./AttachedFailureCard";
import styles from "./FailureViewAttachedSection.module.scss";
import { useTranslation } from "react-i18next";

const FailureViewAttachedSection: React.FC = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { failure, attachedFailures } = useSelector(
    (state) => state.failureView
  );
  const { t } = useTranslation();

  const detachFailure = useCallback(
    (failureToDetachId?: string) => {
      confirm({
        okText: t("failure.confirmDetachModal.yes"),
        okType: "danger",
        cancelText: t("failure.confirmDetachModal.no"),
        title: t("failure.confirmDetachModal.title"),
        onOk: async () => {
          if (failureToDetachId && failure?.id) {
            await dispatch.failureView.detach({
              failureId: failure?.id,
              failureToDetachId: failureToDetachId,
            });
            await dispatch.failureView.fetchAttachedFailures(failure?.id);
          }
        },
      });
    },
    [t, confirm, dispatch.failureView, failure?.id]
  );

  useEffect(() => {
    if (failure?.id) {
      dispatch.failureView.fetchAttachedFailures(failure?.id);
    }
  }, [dispatch.failureView, failure?.id]);

  return (
    <DarkContainerCard
      title={`${t("failure.attachedFailures")} ${
        attachedFailures?.length ? `(${attachedFailures.length})` : ""
      }`}
      icon={<MaterialIcon path={mdiLink} />}
    >
      <div className={styles.wrapper}>
        {attachedFailures?.length ? (
          attachedFailures.map((attachedFailure) => (
            <div key={`attached-${attachedFailure.id}`}>
              <AttachedFailureCard
                failure={attachedFailure}
                detachFailure={detachFailure}
              />
              <Divider className={styles.divider} />
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>
    </DarkContainerCard>
  );
};

export default FailureViewAttachedSection;
