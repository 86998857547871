/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordComplexityVm
 */
export interface PasswordComplexityVm {
    /**
     * 
     * @type {number}
     * @memberof PasswordComplexityVm
     */
    minLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordComplexityVm
     */
    mixedCase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordComplexityVm
     */
    specialCharacters?: boolean;
}

export function PasswordComplexityVmFromJSON(json: any): PasswordComplexityVm {
    return PasswordComplexityVmFromJSONTyped(json, false);
}

export function PasswordComplexityVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordComplexityVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minLength': !exists(json, 'minLength') ? undefined : json['minLength'],
        'mixedCase': !exists(json, 'mixedCase') ? undefined : json['mixedCase'],
        'specialCharacters': !exists(json, 'specialCharacters') ? undefined : json['specialCharacters'],
    };
}

export function PasswordComplexityVmToJSON(value?: PasswordComplexityVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minLength': value.minLength,
        'mixedCase': value.mixedCase,
        'specialCharacters': value.specialCharacters,
    };
}


