/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FailureStatusFilter {
    OnlySealed = 'OnlySealed',
    OnlyOpened = 'OnlyOpened'
}

export function FailureStatusFilterFromJSON(json: any): FailureStatusFilter {
    return FailureStatusFilterFromJSONTyped(json, false);
}

export function FailureStatusFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureStatusFilter {
    return json as FailureStatusFilter;
}

export function FailureStatusFilterToJSON(value?: FailureStatusFilter | null): any {
    return value as any;
}

