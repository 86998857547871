/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExamType,
    ExamTypeFromJSON,
    ExamTypeFromJSONTyped,
    ExamTypeToJSON,
    Repairman,
    RepairmanFromJSON,
    RepairmanFromJSONTyped,
    RepairmanToJSON,
} from './';

/**
 * 
 * @export
 * @interface Exam
 */
export interface Exam {
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    repairmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    examTypeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Exam
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {ExamType}
     * @memberof Exam
     */
    examType?: ExamType;
    /**
     * 
     * @type {Repairman}
     * @memberof Exam
     */
    repairman?: Repairman;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Exam
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Exam
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    id?: string;
}

export function ExamFromJSON(json: any): Exam {
    return ExamFromJSONTyped(json, false);
}

export function ExamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Exam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairmanId': !exists(json, 'repairmanId') ? undefined : json['repairmanId'],
        'examTypeId': !exists(json, 'examTypeId') ? undefined : json['examTypeId'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'examType': !exists(json, 'examType') ? undefined : ExamTypeFromJSON(json['examType']),
        'repairman': !exists(json, 'repairman') ? undefined : RepairmanFromJSON(json['repairman']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ExamToJSON(value?: Exam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairmanId': value.repairmanId,
        'examTypeId': value.examTypeId,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'isDeleted': value.isDeleted,
        'examType': ExamTypeToJSON(value.examType),
        'repairman': RepairmanToJSON(value.repairman),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


