/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
} from '../models';

export interface ApiDocumentsIdDeleteRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiDocumentsIdGetRequest {
    id: string;
    xLUMENTransactionGuid?: string;
}

export interface ApiDocumentsUploadPostRequest {
    xLUMENTransactionGuid?: string;
    documentName?: string;
    documentFileData?: Blob;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the document having the given id.
     */
    async apiDocumentsIdDeleteRaw(requestParameters: ApiDocumentsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDocumentsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the document having the given id.
     */
    async apiDocumentsIdDelete(requestParameters: ApiDocumentsIdDeleteRequest): Promise<void> {
        await this.apiDocumentsIdDeleteRaw(requestParameters);
    }

    /**
     * Download document
     */
    async apiDocumentsIdGetRaw(requestParameters: ApiDocumentsIdGetRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDocumentsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download document
     */
    async apiDocumentsIdGet(requestParameters: ApiDocumentsIdGetRequest): Promise<Blob> {
        const response = await this.apiDocumentsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload a document.
     */
    async apiDocumentsUploadPostRaw(requestParameters: ApiDocumentsUploadPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.documentName !== undefined) {
            formParams.append('Document.Name', requestParameters.documentName as any);
        }

        if (requestParameters.documentFileData !== undefined) {
            formParams.append('Document.FileData', requestParameters.documentFileData as any);
        }

        const response = await this.request({
            path: `/api/Documents/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload a document.
     */
    async apiDocumentsUploadPost(requestParameters: ApiDocumentsUploadPostRequest): Promise<string> {
        const response = await this.apiDocumentsUploadPostRaw(requestParameters);
        return await response.value();
    }

}
