import { FC, ReactNode } from "react";
import styles from "./StretchToMobileLayoutEdge.module.scss";

interface StretchToMobileLayoutEdgeProps {
  children?: ReactNode;
}

const StretchToMobileLayoutEdge: FC<StretchToMobileLayoutEdgeProps> = ({
  children,
}) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default StretchToMobileLayoutEdge;
