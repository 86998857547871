import { Col, Input, Row } from "antd";
import { useWatch } from "antd/lib/form/Form";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import StreetInput from "components/form/StreetInput";
import TownInput from "components/form/TownInput";
import useConfigurationValidationRules from "hooks/useConfigurationValidationRules";
import { ExtendedSelectValue } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm } from "widgets/form";
import styles from "./AddressFieldset.module.scss";

const AddressFieldset: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const cityFormValue = useWatch<ExtendedSelectValue | undefined>(
    ["address", "city"],
    form
  );

  const zipPatternRules = useConfigurationValidationRules("zipPattern");

  return (
    <Row gutter={[24, 16]} className={styles.row}>
      <Col span={24}>
        <div className={styles.sectionTitle}>
          {t("repairmen.addressSection")}
        </div>
      </Col>
      <Col span={4}>
        <GrapeAntdForm.Item
          name={["address", "zip"]}
          label={t("repairmen.address.zip")}
          rules={zipPatternRules}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={8}>
        <GrapeAntdForm.Item
          name={["address", "city"]}
          label={t("repairmen.address.city")}
        >
          <TownInput size="large" allowClear />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={8}>
        <GrapeAntdForm.Item
          name={["address", "street"]}
          label={t("repairmen.address.street")}
        >
          <StreetInput size="large" townId={cityFormValue?.value} />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={4}>
        <GrapeAntdForm.Item
          name={["address", "number"]}
          label={t("repairmen.address.houseNumber")}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>
      </Col>
    </Row>
  );
};

export default AddressFieldset;
