import {
  mdiCheck,
  mdiCheckCircleOutline,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircleOutline,
  mdiDelete,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";
import { Button, Col, Descriptions, Modal, ModalFuncProps, Row } from "antd";
import { WorkbookTaskItem, WorkbookTaskVm } from "api/generated/lumen";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import NameDisplay from "components/ui/NameDisplay";
import { DeviceLayoutType, LumenWorkbookTaskStatuses } from "models/common";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import AddTaskItemModal from "../repairer/components/FailureViewWorkbookTab/components/AddTaskItemToWorkbookTask/AddTaskItemModal";
import styles from "./FailureViewWorbookTaskCard.module.scss";
import FailureViewWorbookTaskRejectModal from "../repairer/components/FailureViewWorkbookTab/components/FailureViewWorbookTaskRejectModal";
import { useDispatch, useSelector } from "app/store";

interface FailureViewWorbookTaskCardProps {
  task: WorkbookTaskVm;
  onTaskCompleted: (id: string | undefined, taskId: string | undefined) => void;
  completeButtonIsLoading?: boolean;
  deviceLayoutType?: DeviceLayoutType;
}

const FailureViewWorbookTaskCard: React.FC<FailureViewWorbookTaskCardProps> = ({
  task,
  onTaskCompleted,
  completeButtonIsLoading,
  deviceLayoutType = "mobile",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
  const [addTaskItemModalIsOpen, setAddTaskItemModalIsOpen] = useState(false);
  const [workbookTaskItemToEdit, setWorkbookTaskItemToEdit] = useState<
    WorkbookTaskItem | undefined
  >();
  const { failure } = useSelector((state) => state.failureView);

  const handleAddTaskItemModalCancel = useCallback(() => {
    setAddTaskItemModalIsOpen(false);

    setWorkbookTaskItemToEdit(undefined);
  }, []);

  const onEditButtonClick = useCallback((item: WorkbookTaskItem) => {
    setWorkbookTaskItemToEdit(item);

    setAddTaskItemModalIsOpen(true);
  }, []);

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.failureView.deleteWorkbookTaskItem({ id });
          await dispatch.failureView.fetchWorkbook(failure?.id);
        },
      };

      Modal.confirm(confirmProps);
    },
    [dispatch.failureView, failure?.id, t]
  );

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.done]: task.status?.value === LumenWorkbookTaskStatuses.Done,
          [styles.rejected]:
            task.status?.value === LumenWorkbookTaskStatuses.Rejected,
          [styles.desktop]: deviceLayoutType === "default",
        })}
      >
        {task.status?.value === LumenWorkbookTaskStatuses.ToDo && (
          <div className={styles.title}>
            {t("workbook.isTheTaskAlreadyDone")}
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.taskTitle}>
            {task.status?.value !== LumenWorkbookTaskStatuses.ToDo && (
              <MaterialIcon
                path={
                  task.status?.value === LumenWorkbookTaskStatuses.Done
                    ? mdiCheckCircleOutline
                    : mdiCloseCircleOutline
                }
                className={clsx(styles.icon, {
                  [styles.doneIcon]:
                    task.status?.value === LumenWorkbookTaskStatuses.Done,
                  [styles.rejectedIcon]:
                    task.status?.value === LumenWorkbookTaskStatuses.Rejected,
                })}
              />
            )}

            <DictionaryDisplay dictionary={task.task} />

            {task.status?.value !== LumenWorkbookTaskStatuses.ToDo && (
              <div className={styles.statusChangedDateWrapper}>
                <MaterialIcon
                  path={mdiClockOutline}
                  className={styles.statusChangedDateIcon}
                />
                <DateDisplay
                  value={task.statechangedDate}
                  format={DateFormat.DateTime}
                />
              </div>
            )}
          </div>

          {task.status?.value !== LumenWorkbookTaskStatuses.ToDo && (
            <div className={styles.changedByWrapper}>
              {task.status?.value === LumenWorkbookTaskStatuses.Rejected && (
                <Col span={12}>
                  <Descriptions column={1} layout="vertical" colon={false}>
                    <Descriptions.Item label={t("workbook.reason")}>
                      <DictionaryDisplay dictionary={task.reason} />
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              )}

              {task.status?.value === LumenWorkbookTaskStatuses.Done && (
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    icon={<MaterialIcon path={mdiPlus} />}
                    onClick={() => setAddTaskItemModalIsOpen(true)}
                  >
                    {t("workbook.addNewItem")}
                  </Button>
                </Col>
              )}

              <Col flex="auto" className={styles.changedBy}>
                <NameDisplay value={task.statechanger} />
              </Col>
            </div>
          )}

          {task.status?.value === LumenWorkbookTaskStatuses.ToDo && (
            <Row gutter={[26, 26]} justify="center">
              <Col>
                <div className={styles.buttonWrapper}>
                  <Button
                    className={clsx(styles.button, styles.dangerButton)}
                    block
                    danger
                    icon={<MaterialIcon path={mdiClose} />}
                    onClick={() => setRejectModalIsOpen(true)}
                  >
                    {t("workbook.taskDidntDone")}
                  </Button>
                </div>
              </Col>

              <Col>
                <div className={styles.buttonWrapper}>
                  <Button
                    className={clsx(styles.button, styles.successButton)}
                    block
                    icon={<MaterialIcon path={mdiCheck} />}
                    onClick={() => onTaskCompleted(task.id, task.taskId)}
                    loading={completeButtonIsLoading}
                  >
                    {t("workbook.taskDone")}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>

        {!!task.workbookTaskItems?.length && (
          <div className={styles.items}>
            {task.workbookTaskItems?.map((item) => (
              <div className={styles.item} key={item.id}>
                <div className={styles.itemDetails}>
                  <div>
                    {item.inventory?.manufacturer && (
                      <div className={styles.itemManufacturer}>
                        {item.inventory?.manufacturer}
                      </div>
                    )}
                    <div className={styles.itemName}>
                      {item.inventory?.name}
                    </div>
                  </div>
                  <div className={styles.itemCount}>
                    {item.itemCount} {t("common.units.pieces")}
                  </div>
                </div>

                <div className={styles.itemActions}>
                  <button
                    className={styles.editButton}
                    onClick={() => onEditButtonClick(item)}
                  >
                    <MaterialIcon
                      path={mdiPencil}
                      className={styles.buttonIcon}
                    />
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDeleteButtonClick(item.id)}
                  >
                    <MaterialIcon
                      path={mdiDelete}
                      className={styles.buttonIcon}
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <FailureViewWorbookTaskRejectModal
        visible={rejectModalIsOpen}
        onCancel={() => setRejectModalIsOpen(false)}
        task={task}
        deviceLayoutType={deviceLayoutType}
      />

      <AddTaskItemModal
        visible={addTaskItemModalIsOpen}
        onCancel={handleAddTaskItemModalCancel}
        task={task}
        workbookTaskItemToEdit={workbookTaskItemToEdit}
        deviceLayoutType={deviceLayoutType}
      />
    </>
  );
};

export default FailureViewWorbookTaskCard;
