import { mdiEye, mdiLinkOff } from "@mdi/js";
import { Modal, Space } from "antd";
import { DocumentMetadataVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useFailureTableColumns from "features/failures/list/default/useFailureTableColumns";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";

const { confirm } = Modal;

const FailureAttachedTab: React.FC = () => {
  const { t } = useTranslation();
  const { failure, attachedFailures } = useSelector(
    (state) => state.failureView
  );
  const { fetchAttachedFailures } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const dispatch = useDispatch();

  const { columnParams } = useFailureTableColumns();
  const navigate = useNavigate();

  const columnParamsWithoutSortNFilter = useMemo(
    () =>
      columnParams.map((column) => ({
        ...column,
        sort: false,
        filterMode: undefined,
      })),
    [columnParams]
  );

  const fetchAttachedFailuresData = useCallback(() => {
    if (typeof failure?.id === "string") {
      dispatch.failureView.fetchAttachedFailures(failure.id);
    }
  }, [dispatch.failureView, failure?.id]);

  const handleDetachButtonClick = useCallback(
    (id?: string) => {
      if (typeof id === "string") {
        confirm({
          okText: t("failure.confirmDetachModal.yes"),
          okType: "danger",
          cancelText: t("failure.confirmDetachModal.no"),
          title: t("failure.confirmDetachModal.title"),
          onOk: async () => {
            await dispatch.failureView.detach({
              failureId: failure?.id || "",
              failureToDetachId: id,
            });

            fetchAttachedFailuresData();
          },
        });
      }
    },
    [fetchAttachedFailuresData, dispatch.failureView, failure?.id, t]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/failures/${id}`);
    },
    [navigate]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<DocumentMetadataVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiLinkOff}
              onClick={() => handleDetachButtonClick(record.id)}
            />
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDetachButtonClick, handleViewButtonClick, t]
  );

  const customTableProps = useDumbTable({
    columnParams: columnParamsWithoutSortNFilter,
    list: attachedFailures,
    actionColumnParams,
    loading: fetchAttachedFailures.loading,
    resetStore: dispatch.failureView.resetAttachedFailures,
  });

  useEffect(() => {
    fetchAttachedFailuresData();
  }, [fetchAttachedFailuresData]);

  return (
    <CustomTable<DocumentMetadataVm>
      {...customTableProps}
      selectable={false}
      scroll={{ x: 3000 }}
    />
  );
};

export default FailureAttachedTab;
