import React from "react";
import clsx from "clsx";
import styles from "./CustomText.module.scss";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

const { Text } = Typography;

type CustomTextSize = "xs" | "sm" | "md" | "lg";

interface CustomTextProps extends TextProps {
  size?: CustomTextSize;
}

const classNames: { [size in CustomTextSize]: string } = {
  xs: styles.xs,
  sm: styles.sm,
  md: "",
  lg: styles.lg,
};

const CustomText: React.FC<CustomTextProps> = ({
  className,
  size = "md",
  ...rest
}) => {
  return <Text {...rest} className={clsx(classNames[size], className)} />;
};

export default CustomText;
