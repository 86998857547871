import {
  ButtonProps,
  FormInstance,
  Modal,
  ModalFuncProps,
  ModalProps,
} from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CustomModal.module.scss";
import clsx from "clsx";

type CustomModalSize = "default" | "large" | "xl";

type CancelConfirmProps = Omit<ModalFuncProps, "onOk">;

interface CustomModalProps extends ModalProps {
  size?: CustomModalSize;
  formInstance?: FormInstance<any>;
  cancelConfirmProps?: CancelConfirmProps;
  className?: string;
  longModal?: boolean;
}

const sizes: { [key in CustomModalSize]: number } = {
  default: 520,
  large: 900,
  xl: 1000,
};

const { confirm } = Modal;

const CustomModal: React.FC<CustomModalProps> = ({
  size = "default",
  okButtonProps,
  cancelButtonProps,
  formInstance,
  onCancel,
  cancelConfirmProps = {},
  className,
  longModal,
  ...rest
}) => {
  const { t } = useTranslation();

  const computedOkButtonProps = useMemo<ButtonProps>(
    () => ({
      ...(okButtonProps || {}),
      size: "large",
    }),
    [okButtonProps]
  );

  const computedCancelButtonProps = useMemo<ButtonProps>(
    () => ({
      ...(cancelButtonProps || {}),
      size: "large",
    }),
    [cancelButtonProps]
  );

  const handleCancel = useCallback(() => {
    if (!formInstance || !formInstance.isFieldsTouched()) {
      onCancel?.("" as any);

      return;
    }

    const confirmProps: ModalFuncProps = {
      okText: t("common.confirmClosingFormModal.yes"),
      okType: "danger",
      cancelText: t("common.confirmClosingFormModal.no"),
      title: t("common.confirmClosingFormModal.title"),
      ...cancelConfirmProps,
      onOk() {
        onCancel?.("" as any);

        formInstance.resetFields();
      },
    };

    confirm(confirmProps);
  }, [cancelConfirmProps, formInstance, onCancel, t]);

  return (
    <Modal
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      {...rest}
      width={sizes[size]}
      okButtonProps={computedOkButtonProps}
      cancelButtonProps={computedCancelButtonProps}
      onCancel={handleCancel}
      className={clsx(className, { [styles.longModal]: longModal })}
    />
  );
};

export default CustomModal;
