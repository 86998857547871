/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateWorkbookTaskItemCommand
 */
export interface UpdateWorkbookTaskItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskItemCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskItemCommand
     */
    workbookTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskItemCommand
     */
    inventoryId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkbookTaskItemCommand
     */
    itemCount?: number;
}

export function UpdateWorkbookTaskItemCommandFromJSON(json: any): UpdateWorkbookTaskItemCommand {
    return UpdateWorkbookTaskItemCommandFromJSONTyped(json, false);
}

export function UpdateWorkbookTaskItemCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkbookTaskItemCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'workbookTaskId': json['workbookTaskId'],
        'inventoryId': json['inventoryId'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
    };
}

export function UpdateWorkbookTaskItemCommandToJSON(value?: UpdateWorkbookTaskItemCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'workbookTaskId': value.workbookTaskId,
        'inventoryId': value.inventoryId,
        'itemCount': value.itemCount,
    };
}


