import { mdiPencil } from "@mdi/js";
import { Button, Col, Input, InputNumber, ModalProps, Row, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ExamTypeVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import { ExamTypeCreateFormValues } from "features/manage-dictionaries/models";
import useEditFormModal from "hooks/useEditModal";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { ListRequestParams } from "widgets/table/useTableUtils";

export interface ExamTypeCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

const ExamTypeCreateModal: React.FC<ExamTypeCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<ExamTypeCreateFormValues>();
  const dispatch = useDispatch();
  const { editingItemId } = useSelector((state) => state.examTypeCreate);
  const { list } = useSelector((state) => state.examTypeList);
  const { create, edit } = useSelector(
    (state) => state.loading.effects.examTypeCreate
  );

  const getInitialValues = useCallback(
    (itemData: ExamTypeVm): ExamTypeCreateFormValues => {
      return {
        name: itemData.name || "",
        isActive: itemData.isActive ?? true,
        description: itemData.description || undefined,
        id: itemData.id,
        warningDaysBeforeExpiration:
          itemData.warningDaysBeforeExpiration || undefined,
      };
    },
    []
  );

  const { formMode, setFormMode } = useEditFormModal<
    ExamTypeVm,
    ExamTypeCreateFormValues
  >({
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.examTypeCreate.resetEditingItemData,
    itemId: editingItemId,
    list,
  });

  const handleFinish = useCallback(
    async (values: ExamTypeCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.examTypeCreate.create(values);
      }

      if (formMode === "Edit" && typeof values.id === "string") {
        await dispatch.examTypeCreate.edit({
          id: values.id,
          ...values,
        });
      }

      dispatch.examTypeList.fetchList(fetchDataParams);

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.examTypeCreate,
      dispatch.examTypeList,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("examType.createModal.title")
          : t("examType.editModal.title")
      }
      size="large"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: create.loading || edit.loading,
        disabled: formMode === "View",
      }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        {formMode === "View" && (
          <Row justify="end">
            <Button
              icon={<MaterialIcon path={mdiPencil} />}
              onClick={() => setFormMode("Edit")}
            >
              {t("common.edit")}
            </Button>
          </Row>
        )}

        <Row justify="center" gutter={[24, 0]}>
          {(formMode === "Edit" || formMode === "View") && (
            <>
              <Col span={24}>
                <GrapeAntdForm.Item
                  name="isActive"
                  valuePropName="checked"
                  label={t("examType.status")}
                >
                  <Switch
                    checkedChildren={t("common.active")}
                    unCheckedChildren={t("common.inactive")}
                  />
                </GrapeAntdForm.Item>
              </Col>

              <GrapeAntdForm.Item name="id" hidden>
                <Input hidden />
              </GrapeAntdForm.Item>
            </>
          )}
          <Col span={14}>
            <GrapeAntdForm.Item
              name="name"
              label={t("examType.name")}
              rules={[requiredField(t)]}
            >
              <Input size="large" />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={10}>
            <GrapeAntdForm.Item
              name="warningDaysBeforeExpiration"
              label={t("examType.warningDaysBeforeExpiration")}
            >
              <InputNumber size="large" />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={24}>
            <GrapeAntdForm.Item
              name="description"
              label={t("examType.description")}
            >
              <TextArea size="large" maxLength={200} rows={6} />
            </GrapeAntdForm.Item>
          </Col>
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default ExamTypeCreateModal;
