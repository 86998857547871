/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    ImageVm,
    ImageVmFromJSON,
    ImageVmFromJSONTyped,
    ImageVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface InventoryDetailsVm
 */
export interface InventoryDetailsVm {
    /**
     * 
     * @type {string}
     * @memberof InventoryDetailsVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryDetailsVm
     */
    partNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDetailsVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDetailsVm
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDetailsVm
     */
    type?: string | null;
    /**
     * 
     * @type {ImageVm}
     * @memberof InventoryDetailsVm
     */
    image?: ImageVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof InventoryDetailsVm
     */
    category?: DictionaryVm;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryDetailsVm
     */
    isActivated?: boolean;
}

export function InventoryDetailsVmFromJSON(json: any): InventoryDetailsVm {
    return InventoryDetailsVmFromJSONTyped(json, false);
}

export function InventoryDetailsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryDetailsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'image': !exists(json, 'image') ? undefined : ImageVmFromJSON(json['image']),
        'category': !exists(json, 'category') ? undefined : DictionaryVmFromJSON(json['category']),
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
    };
}

export function InventoryDetailsVmToJSON(value?: InventoryDetailsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'partNumber': value.partNumber,
        'name': value.name,
        'manufacturer': value.manufacturer,
        'type': value.type,
        'image': ImageVmToJSON(value.image),
        'category': DictionaryVmToJSON(value.category),
        'isActivated': value.isActivated,
    };
}


