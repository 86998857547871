/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Failure,
    FailureFromJSON,
    FailureFromJSONTyped,
    FailureToJSON,
    WorkbookTask,
    WorkbookTaskFromJSON,
    WorkbookTaskFromJSONTyped,
    WorkbookTaskToJSON,
} from './';

/**
 * 
 * @export
 * @interface Workbook
 */
export interface Workbook {
    /**
     * 
     * @type {string}
     * @memberof Workbook
     */
    failureId?: string;
    /**
     * 
     * @type {string}
     * @memberof Workbook
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Workbook
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Workbook
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Workbook
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Failure}
     * @memberof Workbook
     */
    failure?: Failure;
    /**
     * 
     * @type {Array<WorkbookTask>}
     * @memberof Workbook
     */
    tasks?: Array<WorkbookTask> | null;
    /**
     * 
     * @type {string}
     * @memberof Workbook
     */
    id?: string;
}

export function WorkbookFromJSON(json: any): Workbook {
    return WorkbookFromJSONTyped(json, false);
}

export function WorkbookFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workbook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureId': !exists(json, 'failureId') ? undefined : json['failureId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'failure': !exists(json, 'failure') ? undefined : FailureFromJSON(json['failure']),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(WorkbookTaskFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function WorkbookToJSON(value?: Workbook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureId': value.failureId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'failure': FailureToJSON(value.failure),
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(WorkbookTaskToJSON)),
        'id': value.id,
    };
}


