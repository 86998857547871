import { FC, ReactNode } from "react";
import styles from "./TabsWrapper.module.scss";
import clsx from "clsx";

interface TabsWrapperProps {
  children?: ReactNode;
  extraMargin?: boolean;
}

const TabsWrapper: FC<TabsWrapperProps> = ({ children, extraMargin }) => {
  return (
    <div className={clsx(styles.tabs, { [styles.extraMargin]: extraMargin })}>
      {children}
    </div>
  );
};

export default TabsWrapper;
