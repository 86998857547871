import useFeature from "hooks/useFeature";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseFailureTypesListColumnTitles {
  columnTitles: string[];
}

const useFailureTypesListColumnTitles = (): UseFailureTypesListColumnTitles => {
  const { t } = useTranslation();

  const {
    isEnabled: isFailureTypeSpecialCaseEnabled,
    label: failureTypeSpecialCaseLabel,
  } = useFeature("failureSpecialCase");

  const columnTitles = useMemo(
    () => [
      t("failureType.name"),
      t("failureType.tasks"),
      ...(isFailureTypeSpecialCaseEnabled && failureTypeSpecialCaseLabel
        ? [failureTypeSpecialCaseLabel]
        : []),
    ],
    [failureTypeSpecialCaseLabel, isFailureTypeSpecialCaseEnabled, t]
  );

  return {
    columnTitles,
  };
};

export default useFailureTypesListColumnTitles;
