import { mdiDelete, mdiPlus } from "@mdi/js";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import IconButton from "components/ui/IconButton";
import { ContractorCreateFormValues } from "features/contractors/models";
import { FormMode } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  email,
  FormUtils,
  GrapeAntdForm,
  min,
  phoneNumber,
  requiredField,
} from "widgets/form";
import styles from "./ContactsFieldset.module.scss";
import { enumTranslate } from "utils/enumTranslate";
import { RegistrationStates } from "api/generated/lumen";

const { confirm } = Modal;
interface ContactsFieldsetProps {
  formMode: FormMode;
  formUtils: FormUtils<ContractorCreateFormValues>;
}

const ContactsFieldset: React.FC<ContactsFieldsetProps> = ({
  formMode,
  formUtils,
}) => {
  const { t } = useTranslation();

  const handleRemove = (
    index: number,
    removeFn: (index: number | number[]) => void
  ) => {
    const contactPersonsValues = formUtils.form.getFieldValue("contactPersons");

    // If user deletes already saved contactPerson
    if (contactPersonsValues[index]?.id) {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          removeFn(index);
        },
      });

      return;
    }

    removeFn(index);
  };

  return (
    <>
      <fieldset>
        <Form.List name="contactPersons" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              <legend>
                <Row justify="space-between">
                  <Col>{t("contractor.contactsFieldset")}</Col>
                  <Col>
                    <Button
                      icon={<MaterialIcon path={mdiPlus} />}
                      onClick={add}
                      disabled={formMode === "View"}
                    >
                      {t("contractor.newContact")}
                    </Button>
                  </Col>
                </Row>
              </legend>

              <Space direction="vertical" className={styles.space}>
                {fields.map(({ key, name, ...restField }, index) => {
                  const contactPerson =
                    formUtils.form.getFieldValue("contactPersons")[index];
                  const contactPersonHasUserId = !!contactPerson.userId;

                  return (
                    <FieldsetFieldsWrapper key={key}>
                      {fields.length > 1 && (
                        <IconButton
                          path={mdiDelete}
                          onClick={() => handleRemove(name, remove)}
                          className={styles.deleteButton}
                          lightIcon
                          disabled={formMode === "View"}
                        />
                      )}

                      <Row justify="center" gutter={[24, 8]}>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "id"]}
                            hidden
                          >
                            <Input hidden />
                          </GrapeAntdForm.Item>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "familyname"]}
                            label={t("contact.familyName")}
                            rules={[requiredField(t)]}
                          >
                            <Input
                              size="large"
                              disabled={contactPersonHasUserId}
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "forename"]}
                            label={t("contact.forename")}
                            rules={[requiredField(t)]}
                          >
                            <Input
                              size="large"
                              disabled={contactPersonHasUserId}
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "email"]}
                            label={t("contact.email")}
                            rules={[requiredField(t), email(t)]}
                          >
                            <Input
                              size="large"
                              type="email"
                              disabled={contactPersonHasUserId}
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <GrapeAntdForm.Item
                            {...restField}
                            name={[name, "phone", "number"]}
                            label={t("contact.phone")}
                            rules={[phoneNumber(t), min(t, 6, "string")]}
                          >
                            <Input
                              size="large"
                              type="phone"
                              disabled={contactPersonHasUserId}
                            />
                          </GrapeAntdForm.Item>
                        </Col>
                      </Row>

                      <Row justify="end">
                        <Col span={12}>
                          <Space
                            align="center"
                            size="small"
                            className={styles.registrationState}
                          >
                            {t("contact.registrationState") + ": "}
                            {enumTranslate(
                              contactPerson.registrationState,
                              "registrationState"
                            )}
                          </Space>
                        </Col>

                        <Col span={12}>
                          {contactPerson?.registrationState !==
                            RegistrationStates.Registered && (
                            <div className={styles.invite}>
                              <Space align="center" size="middle">
                                <span>{t("contact.toBeInvited")}</span>
                                <GrapeAntdForm.Item
                                  name={[name, "shouldInvite"]}
                                  valuePropName="checked"
                                  noStyle
                                >
                                  <Switch disabled={contactPersonHasUserId} />
                                </GrapeAntdForm.Item>
                              </Space>
                            </div>
                          )}
                        </Col>
                      </Row>

                      {contactPersonHasUserId && (
                        <Typography.Text italic>
                          {t("contact.contactPersonNotEditable")}
                        </Typography.Text>
                      )}
                    </FieldsetFieldsWrapper>
                  );
                })}
              </Space>
            </>
          )}
        </Form.List>
      </fieldset>
    </>
  );
};

export default ContactsFieldset;
