import { Col, Input, Row, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import StreetInput from "components/form/StreetInput";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useActivatedPublicTownsQuery from "hooks/queries/useActivatedPublicTownsQuery";
import useConfigurationValidationRules from "hooks/useConfigurationValidationRules";
import { FieldsetProps, ItemProps } from "models/common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, max, requiredField } from "widgets/form";

const FailureLocationFieldset: React.FC<FieldsetProps> = (props) => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const townIdFormValue = useWatch<string | undefined>("townId", form);
  const activatedTownsQuery = useActivatedPublicTownsQuery();

  const townOptions = useMemo<ItemProps[]>(
    () =>
      (activatedTownsQuery.data?.items || []).map((type) => ({
        label: type.name || "",
        value: type.id || "",
      })),
    [activatedTownsQuery.data?.items]
  );

  const zipPatternRules = useConfigurationValidationRules("zipPattern");

  return (
    <fieldset {...props}>
      <legend>{t("failure.location")}</legend>
      <FieldsetFieldsWrapper>
        <Row gutter={24}>
          <Col span={4}>
            <GrapeAntdForm.Item
              label={t("failure.zip")}
              name={["address", "zip"]}
              rules={zipPatternRules}
            >
              <Input placeholder={t("failure.zipPlaceholder")} size="large" />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={8}>
            <GrapeAntdForm.Item
              label={t("failure.town")}
              name="townId"
              rules={[requiredField(t)]}
            >
              <Select
                placeholder={t("failure.townPlaceholder")}
                size="large"
                options={townOptions}
                loading={activatedTownsQuery.isLoading}
                showSearch
                optionFilterProp="label"
                filterOption={filterOptionsLabelFromBeginning}
              />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={8}>
            <GrapeAntdForm.Item
              label={t("failure.street")}
              name={["address", "street"]}
              rules={[requiredField(t), max(t, 150, "string")]}
            >
              <StreetInput
                placeholder={t("failure.streetPlaceholder")}
                size="large"
                maxLength={150}
                townId={townIdFormValue}
              />
            </GrapeAntdForm.Item>
          </Col>
          <Col span={4}>
            <GrapeAntdForm.Item
              label={t("failure.houseNumber")}
              name={["address", "number"]}
              rules={[requiredField(t), max(t, 10, "string")]}
            >
              <Input
                placeholder={t("failure.houseNumberPlaceholder")}
                size="large"
                maxLength={10}
              />
            </GrapeAntdForm.Item>
          </Col>
        </Row>
      </FieldsetFieldsWrapper>
    </fieldset>
  );
};

export default FailureLocationFieldset;
