/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmFromJSONTyped,
    ContractorVmToJSON,
    FailureVm,
    FailureVmFromJSON,
    FailureVmFromJSONTyped,
    FailureVmToJSON,
    SpecialPlaceVm,
    SpecialPlaceVmFromJSON,
    SpecialPlaceVmFromJSONTyped,
    SpecialPlaceVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeviceVm
 */
export interface DeviceVm {
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    text1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    text2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    text3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    text4?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    text5?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceVm
     */
    number1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceVm
     */
    number2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceVm
     */
    number3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceVm
     */
    number4?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceVm
     */
    number5?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    detailJson?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<FailureVm>}
     * @memberof DeviceVm
     */
    failures?: Array<FailureVm> | null;
    /**
     * 
     * @type {SpecialPlaceVm}
     * @memberof DeviceVm
     */
    specialPlace?: SpecialPlaceVm;
    /**
     * 
     * @type {Array<ContractorVm>}
     * @memberof DeviceVm
     */
    contractors?: Array<ContractorVm> | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    redirectPathPostfix1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    redirectPathPostfix2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    deviceExternalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeviceVm
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeviceVm
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeviceVm
     */
    modifiedDate?: Date | null;
}

export function DeviceVmFromJSON(json: any): DeviceVm {
    return DeviceVmFromJSONTyped(json, false);
}

export function DeviceVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'text1': !exists(json, 'text1') ? undefined : json['text1'],
        'text2': !exists(json, 'text2') ? undefined : json['text2'],
        'text3': !exists(json, 'text3') ? undefined : json['text3'],
        'text4': !exists(json, 'text4') ? undefined : json['text4'],
        'text5': !exists(json, 'text5') ? undefined : json['text5'],
        'number1': !exists(json, 'number1') ? undefined : json['number1'],
        'number2': !exists(json, 'number2') ? undefined : json['number2'],
        'number3': !exists(json, 'number3') ? undefined : json['number3'],
        'number4': !exists(json, 'number4') ? undefined : json['number4'],
        'number5': !exists(json, 'number5') ? undefined : json['number5'],
        'detailJson': !exists(json, 'detailJson') ? undefined : json['detailJson'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'failures': !exists(json, 'failures') ? undefined : (json['failures'] === null ? null : (json['failures'] as Array<any>).map(FailureVmFromJSON)),
        'specialPlace': !exists(json, 'specialPlace') ? undefined : SpecialPlaceVmFromJSON(json['specialPlace']),
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorVmFromJSON)),
        'redirectPathPostfix1': !exists(json, 'redirectPathPostfix1') ? undefined : json['redirectPathPostfix1'],
        'redirectPathPostfix2': !exists(json, 'redirectPathPostfix2') ? undefined : json['redirectPathPostfix2'],
        'deviceExternalId': !exists(json, 'deviceExternalId') ? undefined : json['deviceExternalId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
    };
}

export function DeviceVmToJSON(value?: DeviceVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'text1': value.text1,
        'text2': value.text2,
        'text3': value.text3,
        'text4': value.text4,
        'text5': value.text5,
        'number1': value.number1,
        'number2': value.number2,
        'number3': value.number3,
        'number4': value.number4,
        'number5': value.number5,
        'detailJson': value.detailJson,
        'isActive': value.isActive,
        'failures': value.failures === undefined ? undefined : (value.failures === null ? null : (value.failures as Array<any>).map(FailureVmToJSON)),
        'specialPlace': SpecialPlaceVmToJSON(value.specialPlace),
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorVmToJSON)),
        'redirectPathPostfix1': value.redirectPathPostfix1,
        'redirectPathPostfix2': value.redirectPathPostfix2,
        'deviceExternalId': value.deviceExternalId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
    };
}


