/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Inventory,
    InventoryFromJSON,
    InventoryFromJSONTyped,
    InventoryToJSON,
    WorkbookTask,
    WorkbookTaskFromJSON,
    WorkbookTaskFromJSONTyped,
    WorkbookTaskToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkbookTaskItem
 */
export interface WorkbookTaskItem {
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItem
     */
    workbookTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItem
     */
    inventoryId?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkbookTaskItem
     */
    itemCount?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItem
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTaskItem
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItem
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTaskItem
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Inventory}
     * @memberof WorkbookTaskItem
     */
    inventory?: Inventory;
    /**
     * 
     * @type {WorkbookTask}
     * @memberof WorkbookTaskItem
     */
    workbookTask?: WorkbookTask;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskItem
     */
    id?: string;
}

export function WorkbookTaskItemFromJSON(json: any): WorkbookTaskItem {
    return WorkbookTaskItemFromJSONTyped(json, false);
}

export function WorkbookTaskItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbookTaskItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workbookTaskId': !exists(json, 'workbookTaskId') ? undefined : json['workbookTaskId'],
        'inventoryId': !exists(json, 'inventoryId') ? undefined : json['inventoryId'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'inventory': !exists(json, 'inventory') ? undefined : InventoryFromJSON(json['inventory']),
        'workbookTask': !exists(json, 'workbookTask') ? undefined : WorkbookTaskFromJSON(json['workbookTask']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function WorkbookTaskItemToJSON(value?: WorkbookTaskItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workbookTaskId': value.workbookTaskId,
        'inventoryId': value.inventoryId,
        'itemCount': value.itemCount,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'inventory': InventoryToJSON(value.inventory),
        'workbookTask': WorkbookTaskToJSON(value.workbookTask),
        'id': value.id,
    };
}


