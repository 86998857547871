import { FC, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import DocumentTitle from "components/DocumentTitle";
import { useDispatch } from "app/store";

export type PrivateRouteProps = {
  roles?: Role[];
  title?: string;
  children?: ReactNode;
};

export const PrivateRoute: FC<PrivateRouteProps> = ({
  roles,
  title,
  children,
}) => {
  const { userIsLoggedIn, hasRole } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const renderLoggedInContent = () =>
    hasRole(roles) ? (
      <>
        <DocumentTitle title={title} />
        {children}
      </>
    ) : (
      <Navigate to={{ pathname: "/" }} replace />
    );

  const renderNotLoggedInContent = () => (
    <Navigate to={{ pathname: "/login" }} state={{ from: location }} replace />
  );

  useEffect(() => {
    dispatch.common.setCurrentPageTitle(title);
  }, [dispatch.common, title]);

  return userIsLoggedIn ? renderLoggedInContent() : renderNotLoggedInContent();
};
