/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RefreshTokenVm,
    RefreshTokenVmFromJSON,
    RefreshTokenVmFromJSONTyped,
    RefreshTokenVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthenticationVm
 */
export interface AuthenticationVm {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVm
     */
    accessToken?: string | null;
    /**
     * 
     * @type {RefreshTokenVm}
     * @memberof AuthenticationVm
     */
    refreshToken?: RefreshTokenVm;
}

export function AuthenticationVmFromJSON(json: any): AuthenticationVm {
    return AuthenticationVmFromJSONTyped(json, false);
}

export function AuthenticationVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : RefreshTokenVmFromJSON(json['refreshToken']),
    };
}

export function AuthenticationVmToJSON(value?: AuthenticationVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': RefreshTokenVmToJSON(value.refreshToken),
    };
}


