import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseRepairmanListColumnTitles {
  columnTitles: string[];
  columnTitlesForExcel: string[];
}

const useRepairmanListColumnTitles = (
  contractorId?: string
): UseRepairmanListColumnTitles => {
  const { t } = useTranslation();

  const columnTitles = useMemo(
    () =>
      [
        t("repairmen.familyname"),
        t("repairmen.forename"),
        t("repairmen.email"),
        t("repairmen.phone"),
        t("repairmen.contractor"),
        t("repairmen.status"),
      ].filter((el) => (contractorId ? el !== t("repairmen.contractor") : el)),
    [t, contractorId]
  );

  const columnTitlesForExcel = useMemo(
    () => [
      t("repairmen.familyname"),
      t("repairmen.forename"),
      t("repairmen.email"),
      t("repairmen.phone"),
      t("repairmen.contractor"),
      t("repairmen.status"),
    ],
    [t]
  );

  return {
    columnTitles,
    columnTitlesForExcel,
  };
};

export default useRepairmanListColumnTitles;
