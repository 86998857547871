import { FormInstance, Input } from "antd";
import { Rule } from "antd/lib/form";
import { PasswordComplexityVm } from "api/generated/lumen";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField } from "widgets/form";

interface PasswordFormItemProps {
  passwordComplexity: PasswordComplexityVm | undefined;
  passwordComplexityLoading: boolean | undefined;
}

const PasswordFormItem: React.FC<PasswordFormItemProps> = ({
  passwordComplexity,
  passwordComplexityLoading,
}) => {
  const { t } = useTranslation();

  return (
    <GrapeAntdForm.Item
      name="password"
      label={t("register.password")}
      rules={[
        requiredField(t),
        (formInstance: FormInstance) => ({
          validator(_: Rule, value: string) {
            if (!value) {
              return Promise.resolve();
            }

            if (
              passwordComplexity?.minLength &&
              value.length < passwordComplexity.minLength
            ) {
              return Promise.reject(
                new Error(
                  t("error.password.minLength", {
                    minLength: passwordComplexity?.minLength,
                  })
                )
              );
            }

            if (
              passwordComplexity?.mixedCase &&
              !/(?=.*[a-z])(?=.*[A-Z])/.test(value)
            ) {
              return Promise.reject(new Error(t("error.password.mixedCase")));
            }

            if (
              passwordComplexity?.specialCharacters &&
              !/(?=.*[^A-Za-z0-9])/.test(value)
            ) {
              return Promise.reject(
                new Error(t("error.password.specialCharacters"))
              );
            }

            return Promise.resolve();
          },
        }),
      ]}
      extra={
        passwordComplexityLoading ||
        !(
          passwordComplexity?.minLength ||
          passwordComplexity?.mixedCase ||
          passwordComplexity?.specialCharacters
        ) ? (
          ""
        ) : (
          <>
            {t("register.passwordComplexity.intro")}
            <ul>
              <li>
                {t("register.passwordComplexity.minLength", {
                  minLength: passwordComplexity?.minLength,
                })}
              </li>
              {passwordComplexity?.mixedCase && (
                <li>{t("register.passwordComplexity.mixedCase")}</li>
              )}
              {passwordComplexity?.specialCharacters && (
                <li>{t("register.passwordComplexity.specialCharacters")}</li>
              )}
            </ul>
          </>
        )
      }
    >
      <Input.Password size="large" />
    </GrapeAntdForm.Item>
  );
};

export default PasswordFormItem;
