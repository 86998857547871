/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DevicePropertyVm,
    DevicePropertyVmFromJSON,
    DevicePropertyVmFromJSONTyped,
    DevicePropertyVmToJSON,
    SpecialPlaceVm,
    SpecialPlaceVmFromJSON,
    SpecialPlaceVmFromJSONTyped,
    SpecialPlaceVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedDynamicDeviceVm
 */
export interface DetailedDynamicDeviceVm {
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    detailJson?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedDynamicDeviceVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<DevicePropertyVm>}
     * @memberof DetailedDynamicDeviceVm
     */
    deviceProperties?: Array<DevicePropertyVm> | null;
    /**
     * 
     * @type {SpecialPlaceVm}
     * @memberof DetailedDynamicDeviceVm
     */
    specialPlace?: SpecialPlaceVm;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    redirectPathPostfix1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    redirectPathPostfix2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    deviceExternalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDynamicDeviceVm
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DetailedDynamicDeviceVm
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDynamicDeviceVm
     */
    modifiedDate?: Date | null;
}

export function DetailedDynamicDeviceVmFromJSON(json: any): DetailedDynamicDeviceVm {
    return DetailedDynamicDeviceVmFromJSONTyped(json, false);
}

export function DetailedDynamicDeviceVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedDynamicDeviceVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detailJson': !exists(json, 'detailJson') ? undefined : json['detailJson'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'deviceProperties': !exists(json, 'deviceProperties') ? undefined : (json['deviceProperties'] === null ? null : (json['deviceProperties'] as Array<any>).map(DevicePropertyVmFromJSON)),
        'specialPlace': !exists(json, 'specialPlace') ? undefined : SpecialPlaceVmFromJSON(json['specialPlace']),
        'redirectPathPostfix1': !exists(json, 'redirectPathPostfix1') ? undefined : json['redirectPathPostfix1'],
        'redirectPathPostfix2': !exists(json, 'redirectPathPostfix2') ? undefined : json['redirectPathPostfix2'],
        'deviceExternalId': !exists(json, 'deviceExternalId') ? undefined : json['deviceExternalId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
    };
}

export function DetailedDynamicDeviceVmToJSON(value?: DetailedDynamicDeviceVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detailJson': value.detailJson,
        'id': value.id,
        'name': value.name,
        'isActive': value.isActive,
        'deviceProperties': value.deviceProperties === undefined ? undefined : (value.deviceProperties === null ? null : (value.deviceProperties as Array<any>).map(DevicePropertyVmToJSON)),
        'specialPlace': SpecialPlaceVmToJSON(value.specialPlace),
        'redirectPathPostfix1': value.redirectPathPostfix1,
        'redirectPathPostfix2': value.redirectPathPostfix2,
        'deviceExternalId': value.deviceExternalId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
    };
}


