import { Descriptions } from "antd";
import { useSelector } from "app/store";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import GoogleMapsAddressLink from "components/ui/GoogleMapsAddressLink";
import StringDisplay from "components/ui/StringDisplay";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import ReporterDataDisplay from "./ReporterDataDisplay";
import styles from "./FailureViewDetailsSection.module.scss";
import CustomText from "components/typography/CustomText";
import useFeature from "hooks/useFeature";

const FailureViewDetailsSection: React.FC = () => {
  const { t } = useTranslation();
  const { failure } = useSelector((state) => state.failureView);
  const { displayName } = useLanguageSensitiveUtils();

  const {
    isEnabled: isFailureListParam1Enabled,
    label: failureListParam1Label,
  } = useFeature("failureListParam1");
  const {
    isEnabled: isFailureListParam2Enabled,
    label: failureListParam2Label,
  } = useFeature("failureListParam2");
  const {
    isEnabled: isFailureDescription1Enabled,
    label: failureDescription1Label,
  } = useFeature("failureDescription1");
  const {
    isEnabled: isFailureDescription2Enabled,
    label: failureDescription2Label,
  } = useFeature("failureDescription2");
  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  return (
    <Descriptions layout="vertical" column={3} colon={false}>
      <Descriptions.Item label={t("failure.reportingDate")}>
        <DateDisplay value={failure?.reportedAt} format={DateFormat.DateTime} />
      </Descriptions.Item>

      <Descriptions.Item label={t("failure.deadline")}>
        <DateDisplay value={failure?.deadline} format={DateFormat.Date} />
      </Descriptions.Item>

      <Descriptions.Item
        label={t("failure.recommendedFixingDays")}
        className={styles.recommendedFixingDaysItem}
      >
        {failure?.optimalDeadlineDays && <>~ </>}

        <DictionaryDisplay dictionary={failure?.optimalDeadlineDays} />
      </Descriptions.Item>

      <Descriptions.Item label={t("failure.type")}>
        <StringDisplay value={failure?.type?.name} />
      </Descriptions.Item>

      {isFailureListParam1Enabled && (
        <Descriptions.Item label={failureListParam1Label}>
          <DictionaryDisplay dictionary={failure?.expanse} />
        </Descriptions.Item>
      )}

      {isFailureListParam2Enabled && (
        <Descriptions.Item label={failureListParam2Label}>
          <DictionaryDisplay dictionary={failure?.nature} />
        </Descriptions.Item>
      )}

      {isFailureDescription1Enabled && (
        <Descriptions.Item label={failureDescription1Label}>
          <StringDisplay value={failure?.description1} />
        </Descriptions.Item>
      )}

      {isFailureDescription2Enabled && (
        <Descriptions.Item label={failureDescription2Label}>
          <StringDisplay value={failure?.description2} />
        </Descriptions.Item>
      )}

      <Descriptions.Item label={t("failure.repairmens")}>
        {failure?.repairmen?.length ? (
          failure?.repairmen.map((repairer) => displayName(repairer)).join(", ")
        ) : (
          <EmptyContent />
        )}
      </Descriptions.Item>

      <Descriptions.Item label={t("failure.reporter")}>
        <ReporterDataDisplay
          notifierPhone={failure?.notifierPhone}
          notifierFamilyname={failure?.notifierFamilyname}
          notifierForename={failure?.notifierForename}
          notifierMiddlename={failure?.notifierMiddlename}
        />
      </Descriptions.Item>

      {isMaintainedDevicesEnabled ? (
        <Descriptions.Item label={t("failure.device")}>
          <StringDisplay value={failure?.deviceName} />
        </Descriptions.Item>
      ) : (
        <Descriptions.Item label={t("failure.address")}>
          <GoogleMapsAddressLink address={failure?.address} />
        </Descriptions.Item>
      )}

      <Descriptions.Item label={t("failure.stateChangeReason")}>
        {failure?.stateChangeReason ? (
          <CustomText>{failure.stateChangeReason}</CustomText>
        ) : (
          <EmptyContent />
        )}
      </Descriptions.Item>

      <Descriptions.Item label={t("failure.comment")}>
        {failure?.comment ? `"${failure?.comment}"` : <EmptyContent />}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default FailureViewDetailsSection;
