import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { queryKeys } from ".";

const useInventoryCategoriesQuery = (isActive?: boolean) => {
  const query = useQuery(queryKeys.inventoryCategories.isActive(isActive), () =>
    api.lumen.inventoryCategory.apiInventoryCategoryPost({
      getAllInventoryCategoriesQuery: {
        filterKeys:
          typeof isActive === "boolean"
            ? {
                isActive,
              }
            : {},
        pageSize: -1,
      },
    })
  );

  return query;
};

export default useInventoryCategoriesQuery;
