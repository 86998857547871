/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicTownVm
 */
export interface PublicTownVm {
    /**
     * 
     * @type {string}
     * @memberof PublicTownVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicTownVm
     */
    name?: string | null;
}

export function PublicTownVmFromJSON(json: any): PublicTownVm {
    return PublicTownVmFromJSONTyped(json, false);
}

export function PublicTownVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicTownVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function PublicTownVmToJSON(value?: PublicTownVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


