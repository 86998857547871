interface ThemeConfig {
  imagesFolder: string;
  currentBrand?: string;
}

const currentBrand = process.env.REACT_APP_CURRENT_BRAND;

const themeConfig: ThemeConfig = {
  imagesFolder: `${process.env.PUBLIC_URL}/images/themes/${currentBrand}`,
  currentBrand,
};

export default themeConfig;
