import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseContractorsListColumnTitles {
  columnTitles: string[];
}

const useContractorsListColumnTitles = (): UseContractorsListColumnTitles => {
  const { t } = useTranslation();

  const columnTitles = useMemo(
    () => [
      t("contractor.name"),
      t("contractor.address.zip"),
      t("contractor.address.city"),
      t("contractor.address.street"),
      t("contractor.address.houseNumber"),
      t("contractor.status"),
    ],
    [t]
  );

  return {
    columnTitles,
  };
};

export default useContractorsListColumnTitles;
