/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetachAllDevicesFromContractorCommand
 */
export interface DetachAllDevicesFromContractorCommand {
    /**
     * 
     * @type {string}
     * @memberof DetachAllDevicesFromContractorCommand
     */
    contractorToDetachId: string;
}

export function DetachAllDevicesFromContractorCommandFromJSON(json: any): DetachAllDevicesFromContractorCommand {
    return DetachAllDevicesFromContractorCommandFromJSONTyped(json, false);
}

export function DetachAllDevicesFromContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetachAllDevicesFromContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractorToDetachId': json['contractorToDetachId'],
    };
}

export function DetachAllDevicesFromContractorCommandToJSON(value?: DetachAllDevicesFromContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractorToDetachId': value.contractorToDetachId,
    };
}


