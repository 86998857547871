import { FC } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { ExtendedFilterDropdownProps } from "./models";
import styles from "./TableDropdown.module.scss";

export const TableDropdownFooter: FC<ExtendedFilterDropdownProps> = ({
  confirm,
  clearFilters,
  selectedKeys,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ant-table-filter-dropdown-btns">
      <Button
        type="link"
        onClick={clearFilters}
        disabled={!selectedKeys[0] || loading}
      >
        {t("common.reset")}
      </Button>

      <Button
        type="primary"
        onClick={() => confirm({ closeDropdown: true })}
        disabled={loading}
        className={styles.searchBtn}
      >
        {t("common.search")}
      </Button>
    </div>
  );
};
