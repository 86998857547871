/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureSwitchVm
 */
export interface FeatureSwitchVm {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    maintainedDevices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    failureListParam1?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    failureListParam2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    failureDescription1?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    failureDescription2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    failureSpecialCase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSwitchVm
     */
    exam?: boolean;
}

export function FeatureSwitchVmFromJSON(json: any): FeatureSwitchVm {
    return FeatureSwitchVmFromJSONTyped(json, false);
}

export function FeatureSwitchVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureSwitchVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maintainedDevices': !exists(json, 'maintainedDevices') ? undefined : json['maintainedDevices'],
        'failureListParam1': !exists(json, 'failureListParam1') ? undefined : json['failureListParam1'],
        'failureListParam2': !exists(json, 'failureListParam2') ? undefined : json['failureListParam2'],
        'failureDescription1': !exists(json, 'failureDescription1') ? undefined : json['failureDescription1'],
        'failureDescription2': !exists(json, 'failureDescription2') ? undefined : json['failureDescription2'],
        'failureSpecialCase': !exists(json, 'failureSpecialCase') ? undefined : json['failureSpecialCase'],
        'exam': !exists(json, 'exam') ? undefined : json['exam'],
    };
}

export function FeatureSwitchVmToJSON(value?: FeatureSwitchVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maintainedDevices': value.maintainedDevices,
        'failureListParam1': value.failureListParam1,
        'failureListParam2': value.failureListParam2,
        'failureDescription1': value.failureDescription1,
        'failureDescription2': value.failureDescription2,
        'failureSpecialCase': value.failureSpecialCase,
        'exam': value.exam,
    };
}


