/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface DictionaryGroupVm
 */
export interface DictionaryGroupVm {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroupVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroupVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGroupVm
     */
    value?: string | null;
    /**
     * 
     * @type {Array<DictionaryVm>}
     * @memberof DictionaryGroupVm
     */
    dictionaryItems?: Array<DictionaryVm> | null;
}

export function DictionaryGroupVmFromJSON(json: any): DictionaryGroupVm {
    return DictionaryGroupVmFromJSONTyped(json, false);
}

export function DictionaryGroupVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryGroupVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'dictionaryItems': !exists(json, 'dictionaryItems') ? undefined : (json['dictionaryItems'] === null ? null : (json['dictionaryItems'] as Array<any>).map(DictionaryVmFromJSON)),
    };
}

export function DictionaryGroupVmToJSON(value?: DictionaryGroupVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'dictionaryItems': value.dictionaryItems === undefined ? undefined : (value.dictionaryItems === null ? null : (value.dictionaryItems as Array<any>).map(DictionaryVmToJSON)),
    };
}


