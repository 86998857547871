import { Input, InputProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ItemChooserSearch.module.scss";

const ItemChooserSearch: React.FC<InputProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.inputWrapper}>
      <Input
        {...props}
        placeholder={t("common.searchPlaceholder")}
        className={styles.input}
      />
    </div>
  );
};

export default ItemChooserSearch;
