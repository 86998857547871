import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface BooleanDisplayProps {
  value?: boolean;
  trueDisplay: React.ReactNode;
  falseDisplay: React.ReactNode;
}

const BooleanDisplay: React.FC<BooleanDisplayProps> = ({
  value,
  trueDisplay,
  falseDisplay,
}) => {
  return (
    <>
      {typeof value === "boolean" ? (
        value ? (
          trueDisplay
        ) : (
          falseDisplay
        )
      ) : (
        <EmptyContent />
      )}
    </>
  );
};

export default BooleanDisplay;
