import { LegacyRef, useEffect, useMemo, useState } from "react";
import { ConfigProvider, Table, TablePaginationConfig, TableProps } from "antd";
import { CustomTableHeader } from "./CustomTableHeader";
import { v4 } from "uuid";
import { useSelectableRowUtils } from "./useSelectableRowUtils";
import { TableSettingsUtils } from "./table-settings/useTableSettingsUtils";
import differenceBy from "lodash.differenceby";
import { useMeasure } from "react-use";
import { useTableHeightCalculator } from "./table-settings/useTableHeightCalculator";

export interface CustomTableProps extends TableProps<any> {
  hideHeader?: boolean;
  inlineTitle?: string;
  hasHeaderOffset?: boolean;
  hasFixedColumn?: boolean;
  selectable?: boolean;
  isBackendPagination?: boolean;
  resetSwitch?: boolean;
  extraContent?: React.ReactNode;
  tableSettingsUtils?: TableSettingsUtils<any>;
  resetFilters?: () => void;
  onSelectedChange?: (selected: any[]) => void;
  pageHasTabs?: boolean;
  scroll?: {
    x?: string | number | true | undefined;
    y?: string | number | undefined;
  } & { scrollToFirstRowOnChange?: boolean | undefined };
  scrollX?: string | number | true;
}

function CustomTable<RecordType extends object = any>({
  hideHeader,
  hasHeaderOffset,
  hasFixedColumn,
  selectable = true,
  isBackendPagination = true,
  onSelectedChange = () => ({}),
  columns,
  dataSource,
  onChange,
  pagination,
  resetSwitch,
  extraContent,
  tableSettingsUtils,
  resetFilters,
  inlineTitle,
  pageHasTabs,
  scroll,
  scrollX,
  ...tableProps
}: CustomTableProps): JSX.Element {
  const className =
    `responsive-table ` +
    `${tableProps.className || ""} ` +
    `${hasHeaderOffset ? "has-header-offset " : ""}` +
    `${hasFixedColumn ? "has-fixed-column " : ""}`;

  const [storedSorter, setStoredSorter] = useState<any[]>([]);

  const {
    selectColumnConfig,
    setCurrentPageIds,
    selectedItems,
    resetSelection,
  } = useSelectableRowUtils({
    isBackendPagination,
    defaultPageSize: (pagination as any)?.pageSize,
  });

  useEffect(() => {
    setCurrentPageIds(
      (dataSource as any) || [],
      (pagination as any)?.current,
      (pagination as any)?.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    onSelectedChange(selectedItems);
  }, [selectedItems, onSelectedChange]);

  useEffect(() => {
    resetSelection();
    setCurrentPageIds(
      (dataSource as any) || [],
      (pagination as any)?.current,
      (pagination as any)?.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSwitch]);

  const extendedColumns = useMemo(() => {
    const columnsWithClassName = (columns || []).map((x) => {
      if (!x.sorter && !x.filters && !x.filterIcon) {
        return {
          ...x,
          className: `${x.className || ""} text-only-table-cell`,
        };
      } else {
        return x;
      }
    });

    if (dataSource && dataSource.length && selectable) {
      return [selectColumnConfig, ...columnsWithClassName];
    } else {
      return columnsWithClassName;
    }
  }, [dataSource, columns, selectable, selectColumnConfig]);

  const extendedTableProps = {
    ...tableProps,
    dataSource,
    columns: extendedColumns,
    pagination,
    onChange: (
      paginationChange: any,
      filters: any,
      sorter: any,
      extra: any
    ) => {
      let newSorter: any;
      if (Array.isArray(sorter)) {
        newSorter = [];

        if (storedSorter?.length === 0) {
          newSorter = sorter;
        } else {
          // pick sorter items in the order of the previously stored
          storedSorter?.forEach((storedEl: any) => {
            const findEl = sorter.find(
              (sorterEL: any) => sorterEL.field === storedEl.field
            );

            if (findEl) {
              newSorter.push(findEl);
            }
          });

          // append the rest to the end that are new
          newSorter = [
            ...newSorter,
            ...differenceBy(sorter, storedSorter, "columnKey"),
          ];
        }

        // store new sorter
        setStoredSorter(newSorter);
      } else {
        newSorter = sorter;
        // reset stored state
        setStoredSorter([]);
      }
      if (isBackendPagination) {
        setCurrentPageIds(
          (dataSource as any) || [],
          paginationChange.current,
          paginationChange.pageSize
        );
      } else {
        setCurrentPageIds(
          (extra.currentDataSource as any) || [],
          paginationChange.current,
          paginationChange.pageSize
        );
      }
      onChange?.(paginationChange, filters, newSorter, extra);
    },
  };

  const isFiltered = useMemo(
    () =>
      columns?.some(
        (column) =>
          column.filteredValue?.some(
            (filter) => typeof filter !== "undefined"
          ) || column.sortOrder
      ),
    [columns]
  );

  const [tableActionsRef, { height: tableActionsHeight }] = useMeasure();

  const header = useMemo(
    () => (
      <div ref={tableActionsRef as LegacyRef<HTMLDivElement>}>
        <CustomTableHeader
          title={inlineTitle}
          resultCount={
            (pagination as TablePaginationConfig)?.total ?? dataSource?.length
          }
          selectedCount={selectedItems?.length}
          extraContent={extraContent}
          tableSettingsUtils={tableSettingsUtils}
          isFiltered={isFiltered}
          resetFilters={() => {
            if (resetFilters) {
              resetFilters();
              setStoredSorter([]);
            }
          }}
        />
      </div>
    ),
    [
      inlineTitle,
      pagination,
      dataSource?.length,
      selectedItems?.length,
      extraContent,
      tableSettingsUtils,
      isFiltered,
      resetFilters,
      tableActionsRef,
    ]
  );

  const id = v4();

  const tableHeight = useTableHeightCalculator({
    tableActionsHeight,
  });

  return (
    <ConfigProvider
      getPopupContainer={() => document.getElementById(id) as HTMLElement}
    >
      {!hideHeader && header}
      <Table<RecordType>
        size="small"
        {...extendedTableProps}
        className={className}
        id={id}
        scroll={scroll ?? { x: scrollX ?? 3000, y: tableHeight }}
      />
    </ConfigProvider>
  );
}

export default CustomTable;
