import FailuresTable from "../../default/FailuresTable";
import { FailureStatusFilter } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";

const fetchDataParams: FailureListRequestParams = {
  statusFilter: FailureStatusFilter.OnlyOpened,
};

const TableOpen: React.FC = () => {
  return <FailuresTable fetchDataParams={fetchDataParams} />;
};

export default TableOpen;
