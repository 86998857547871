import { Button, Typography } from "antd";
import CardWithBrandLogo from "components/ui/CardWithBrandLogo";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./ForgottenPasswordSetNewPasswordFailed.module.scss";

const ForgottenPasswordSetNewPasswordFailed: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CardWithBrandLogo
      footerContent={
        <Link to="/login">
          <Button type="link" className={styles.button}>
            {t("forgottenPassword.backToLogin")}
          </Button>
        </Link>
      }
    >
      <div>
        <Typography.Title>
          {t("forgottenPassword.setNewPasswordPage.failed.title")}
        </Typography.Title>

        <div className={styles.description}>
          {t("forgottenPassword.setNewPasswordPage.failed.description")}
        </div>
      </div>
    </CardWithBrandLogo>
  );
};

export default ForgottenPasswordSetNewPasswordFailed;
