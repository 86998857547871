/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExamTypeCommand
 */
export interface CreateExamTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateExamTypeCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamTypeCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamTypeCommand
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExamTypeCommand
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateExamTypeCommand
     */
    warningDaysBeforeExpiration?: number | null;
}

export function CreateExamTypeCommandFromJSON(json: any): CreateExamTypeCommand {
    return CreateExamTypeCommandFromJSONTyped(json, false);
}

export function CreateExamTypeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExamTypeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'warningDaysBeforeExpiration': !exists(json, 'warningDaysBeforeExpiration') ? undefined : json['warningDaysBeforeExpiration'],
    };
}

export function CreateExamTypeCommandToJSON(value?: CreateExamTypeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'warningDaysBeforeExpiration': value.warningDaysBeforeExpiration,
    };
}


