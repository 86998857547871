import { Col, Row, Typography } from "antd";
import { Container } from "components/layout/Container";
import BrandImage from "components/ui/BrandImage";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Error404.module.scss";

const { Paragraph } = Typography;

const Error404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row justify="center">
        <Col lg={11}>
          <div className={styles.content}>
            <BrandImage subPath="404.svg" />
            <h1 className={styles.title}>{t("404.title")}</h1>
            <Paragraph className={styles.paragraph}>
              {t("404.paragraph")}
            </Paragraph>
            <Link to="/" replace>
              <strong>{t("404.backToHome")}</strong>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Error404;
