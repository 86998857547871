import { Descriptions, Space, Typography } from "antd";
import { FailureVm } from "api/generated/lumen";
import CustomText from "components/typography/CustomText";
import NameDisplay from "components/ui/NameDisplay";
import VerticalDataDescriptions from "components/ui/VerticalDataDescriptions";
import React from "react";
import { useTranslation } from "react-i18next";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import styles from "../FailureViewDefault.module.scss";

interface FailureViewHistoryDataProps {
  failure?: FailureVm;
}

const FailureViewHistoryData: React.FC<FailureViewHistoryDataProps> = ({
  failure,
}) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="large">
      <Typography.Title level={5} className={styles.headerTitle}>
        {t("failure.history")}
      </Typography.Title>

      <VerticalDataDescriptions column={3}>
        <Descriptions.Item label={t("failure.creator")}>
          <NameDisplay value={failure?.creator} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.modifiedDate")}>
          <DateDisplay value={failure?.modifiedDate} format={DateFormat.Date} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.modifier")}>
          <NameDisplay value={failure?.modifier} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.closedDate")}>
          <DateDisplay value={failure?.closedAt} format={DateFormat.Date} />
        </Descriptions.Item>

        <Descriptions.Item label={t("failure.stateChangeReason")}>
          {failure?.stateChangeReason ? (
            <CustomText>{failure.stateChangeReason}</CustomText>
          ) : (
            <EmptyContent />
          )}
        </Descriptions.Item>
      </VerticalDataDescriptions>
    </Space>
  );
};

export default FailureViewHistoryData;
