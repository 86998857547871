/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Failure,
    FailureFromJSON,
    FailureFromJSONTyped,
    FailureToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailureCreatorType
 */
export interface FailureCreatorType {
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorType
     */
    displayName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureCreatorType
     */
    isAnonymous?: boolean;
    /**
     * 
     * @type {Array<Role>}
     * @memberof FailureCreatorType
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Array<Failure>}
     * @memberof FailureCreatorType
     */
    failures?: Array<Failure> | null;
    /**
     * 
     * @type {User}
     * @memberof FailureCreatorType
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof FailureCreatorType
     */
    modifier?: User;
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorType
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FailureCreatorType
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorType
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FailureCreatorType
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FailureCreatorType
     */
    id?: string;
}

export function FailureCreatorTypeFromJSON(json: any): FailureCreatorType {
    return FailureCreatorTypeFromJSONTyped(json, false);
}

export function FailureCreatorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureCreatorType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'isAnonymous': !exists(json, 'isAnonymous') ? undefined : json['isAnonymous'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'failures': !exists(json, 'failures') ? undefined : (json['failures'] === null ? null : (json['failures'] as Array<any>).map(FailureFromJSON)),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function FailureCreatorTypeToJSON(value?: FailureCreatorType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'isAnonymous': value.isAnonymous,
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'failures': value.failures === undefined ? undefined : (value.failures === null ? null : (value.failures as Array<any>).map(FailureToJSON)),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


