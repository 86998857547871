import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./DefaultLayout.module.scss";

const DefaultLayout: React.FC = () => {
  return (
    <Layout className={styles.layout}>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default DefaultLayout;
