import { FC, ReactNode } from "react";
import styles from "./StretchToCardEdge.module.scss";

interface StretchToCardEdgeProps {
  children?: ReactNode;
}

const StretchToCardEdge: FC<StretchToCardEdgeProps> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default StretchToCardEdge;
