import Claims from "features/auth/claims";
import useAuth from "features/auth/useAuth";
import useFeature from "hooks/useFeature";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseFailuresListColumnTitles {
  columnTitles: string[];
  exportColumnTitles: string[];
}

const useFailuresListColumnTitles = (): UseFailuresListColumnTitles => {
  const { t } = useTranslation();
  const { hasClaim } = useAuth();

  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  const columnTitles = useMemo(
    () => [
      t("failure.id"),
      ...(isMaintainedDevicesEnabled
        ? [t("failure.device")]
        : [
            t("failure.zip"),
            t("failure.town"),
            t("failure.street"),
            t("failure.houseNumber"),
          ]),
      t("failure.type"),
      t("failure.commentColumnTitle"),
      t("failure.reportingDate"),
      t("failure.deadline"),
      t("failure.recommendedFixingDays"),
      t("failure.status"),
      ...(hasClaim([Claims.Failures.CONTRACTORVIEW])
        ? [t("failure.contractor"), t("failure.repairmens")]
        : []),
      t("failure.closedDate"),
      t("failure.createdByFamilyname"),
      t("failure.createdByForename"),
      t("failure.creatorType"),
    ],
    [hasClaim, isMaintainedDevicesEnabled, t]
  );

  const exportColumnTitles = useMemo(
    () => [
      t("failure.id"),
      ...(isMaintainedDevicesEnabled
        ? [t("failure.device")]
        : [
            t("failure.zip"),
            t("failure.town"),
            t("failure.street"),
            t("failure.houseNumber"),
          ]),
      t("failure.type"),
      t("failure.reportingDate"),
      t("failure.deadline"),
      t("failure.recommendedFixingDays"),
      t("failure.status"),
      ...(hasClaim([Claims.Failures.CONTRACTORVIEW])
        ? [t("failure.contractor"), t("failure.repairmens")]
        : []),
      t("failure.closedDate"),
      t("failure.createdByFamilyname"),
      t("failure.createdByForename"),
      t("failure.comment"),
      t("failure.creatorType"),
      ...(hasClaim([Claims.Failures.NOTIFIERVIEW])
        ? [
            t("failure.notifierFamilyname"),
            t("failure.notifierForename"),
            t("failure.notifierPhone"),
            t("failure.notifierEmail"),
          ]
        : []),
    ],
    [hasClaim, isMaintainedDevicesEnabled, t]
  );

  return {
    columnTitles,
    exportColumnTitles,
  };
};

export default useFailuresListColumnTitles;
