import clsx from "clsx";
import React from "react";
import styles from "./ItemChooserTask.module.scss";

type ItemChooserTaskProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ItemChooserTask: React.FC<ItemChooserTaskProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div {...rest} className={clsx(styles.task, className)}>
      {children}
    </div>
  );
};

export default ItemChooserTask;
