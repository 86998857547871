import { createModel } from "@rematch/core";
import { api } from "api";
import { WorkBookTaskItemReportListItemVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { mapToGetAllQuery } from "utils";
import { downloadFile } from "utils/file";
import {
  ListRequestParams,
  mergeListParamsWithPagination,
} from "widgets/table/useTableUtils";
import { getFilterParamsForTaskItemReportGetRequests } from "./utils";

interface WorkBookTaskItemReportListState {
  listParams: ListRequestParams;
  list: WorkBookTaskItemReportListItemVm[];
}

const initialState: WorkBookTaskItemReportListState = {
  listParams: {
    pageSize: 10,
  },
  list: [],
};

export const taskItemList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: {
        data: WorkBookTaskItemReportListItemVm[];
        listParams: ListRequestParams;
      }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { taskItemList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const { payload, mergedParams } =
          getFilterParamsForTaskItemReportGetRequests(
            params,
            state.taskItemList.listParams
          );

        const data = await api.lumen.report.apiReportGetWorkBookTaskItemsPost({
          workBookTaskItemReportQuery: mapToGetAllQuery(payload),
        });

        const { items, ...pagination } = data;

        dispatch(
          taskItemList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async generateExcel(columnTitles: string[], state): Promise<void> {
        const { payload } = getFilterParamsForTaskItemReportGetRequests(
          state.taskItemList.listParams,
          state.taskItemList.listParams
        );

        const data =
          await api.lumen.report.apiReportGetWorkBookTaskItemsExportPostRaw({
            workBookTaskItemReportExcelQuery: {
              ...mapToGetAllQuery(payload),
              columnNames: columnTitles,
              pageSize: -1,
            },
          });

        downloadFile(data.raw);
      },
    };
  },
});
