import { Button, ButtonProps } from "antd";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import React from "react";
import styles from "./IconButton.module.scss";

interface IconButtonProps extends Omit<ButtonProps, "shape" | "size"> {
  path: string;
  iconClassname?: string;
  variant?: "alternative" | "warning";
  xxl?: boolean;
  lightIcon?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  path,
  iconClassname,
  className,
  lightIcon,
  variant,
  xxl,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      icon={
        <MaterialIcon
          path={path}
          className={clsx(styles.icon, iconClassname, {
            [styles.lightIcon]: lightIcon,
          })}
        />
      }
      className={clsx(
        styles.button,
        { [styles.alternative]: variant === "alternative" },
        { [styles.xxl]: xxl },
        { [styles.warning]: variant === "warning" },
        className
      )}
      shape="circle"
    />
  );
};

export default IconButton;
