import { TabPaneProps, Tabs } from "antd";
import { useDispatch } from "app/store";
import ListPageLayout from "components/layout/ListPageLayout";
import useAuth from "features/auth/useAuth";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TableAll from "./components/TableAll";
import TableClosed from "./components/TableClosed";
import TableOpen from "./components/TableOpen";
import TableToAttend from "./components/TableToAttend";
import TabsWrapper from "components/ui/TabsWrapper";

type TabKey = "toAttend" | "all" | "open" | "closed";

interface CustomTabPaneProps extends Omit<TabPaneProps, "key"> {
  key: TabKey;
}

const FailureTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams("");
  const activeTabParam = searchParams.get("tab");
  const { hasRole } = useAuth();

  const tabs = useMemo<CustomTabPaneProps[]>(
    () => [
      ...((hasRole(["Council"])
        ? []
        : [
            {
              tab: t("failures.list.tab.toAttend"),
              key: "toAttend",
              children: <TableToAttend />,
            },
            {
              tab: t("failures.list.tab.open"),
              key: "open",
              children: <TableOpen />,
            },
            {
              tab: t("failures.list.tab.closed"),
              key: "closed",
              children: <TableClosed />,
            },
          ]) as CustomTabPaneProps[]),
      {
        tab: t("failures.list.tab.all"),
        key: "all",
        children: <TableAll />,
      },
    ],
    [hasRole, t]
  );

  const defaultActiveKey = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.key === activeTabParam);

    return activeTab ? activeTab.key : tabs[0].key;
  }, [activeTabParam, tabs]);

  const handleTabsChange = useCallback(
    (activeKey: string) => {
      setSearchParams(new URLSearchParams(`tab=${activeKey}`));

      dispatch.failureList.reset();
    },
    [dispatch.failureList, setSearchParams]
  );

  return (
    <ListPageLayout title={t("failures.list.title")}>
      <TabsWrapper>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          destroyInactiveTabPane
          onChange={handleTabsChange}
          type="card"
          size="small"
        >
          {tabs.map(({ key, ...rest }) => (
            <Tabs.TabPane {...rest} key={key} />
          ))}
        </Tabs>
      </TabsWrapper>
    </ListPageLayout>
  );
};

export default FailureTabs;
