import { FormInstance, Input } from "antd";
import { Rule } from "antd/lib/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField } from "widgets/form";

const ConfirmPasswordFormItem: React.FC = () => {
  const { t } = useTranslation();

  return (
    <GrapeAntdForm.Item
      name="confirmPassword"
      dependencies={["password"]}
      label={t("register.confirmPassword")}
      rules={[
        requiredField(t),
        ({ getFieldValue }: FormInstance) => ({
          validator(_: Rule, value: string) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }

            return Promise.reject(
              new Error(t("error.common.passwordsMismatch"))
            );
          },
        }),
      ]}
    >
      <Input.Password size="large" />
    </GrapeAntdForm.Item>
  );
};

export default ConfirmPasswordFormItem;
