import { Spin } from "antd";
import React from "react";
import styles from "./FullPageSpin.module.scss";

const FullPageSpin: React.FC = () => {
  return (
    <div className={styles.container}>
      <Spin size="large" />
    </div>
  );
};

export default FullPageSpin;
