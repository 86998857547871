import { createModel } from "@rematch/core";
import { api } from "api";
import {
  ApiInventoryIdPutRequest,
  ApiInventoryPostRequest,
  InventoryDetailsVm,
  InventoryVm,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface InventoryCreateState {
  editingItemId?: string;
  editingItem?: InventoryDetailsVm;
}

const initialState: InventoryCreateState = {
  editingItemId: undefined,
  editingItem: undefined,
};

export const inventoryCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    setEditingItem(state, data: InventoryVm) {
      state.editingItem = data;
    },
    resetEditingItemData(state) {
      state.editingItem = undefined;
      state.editingItemId = undefined;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { inventoryCreate } = dispatch;

    return {
      async fetchItem(id: string): Promise<void> {
        const item = await api.lumen.inventory.apiInventoryIdGet({ id });

        dispatch(inventoryCreate.setEditingItem(item));
      },

      async create(values: ApiInventoryPostRequest): Promise<string> {
        return api.lumen.inventory.apiInventoryPost(values);
      },

      async edit(values: ApiInventoryIdPutRequest): Promise<void> {
        return api.lumen.inventory.apiInventoryIdPut(values);
      },
    };
  },
});
