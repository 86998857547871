import { ConfigProvider, Empty, Radio, Spin, Typography } from "antd";
import { ContractorVm, FailureVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { mapContractorVmToUpdateContractorCommand } from "features/contractors/models";
import {
  FailureAssignToContractorFormValues,
  FailureListRequestParams,
} from "features/failures/models";
import useFeature from "hooks/useFeature";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormUtils, GrapeAntdForm, requiredField } from "widgets/form";
import styles from "./ChooseContractorToAssign.module.scss";

interface ChooseContractorToAssignProps {
  formUtils: FormUtils;
  failure?: FailureVm;
  onCancel:
    | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
  contractors: ContractorVm[];
  contractorsLoading?: boolean;
  fetchDataParams?: FailureListRequestParams;
  finishHandler?: (contractorId: string) => Promise<void>;
}

const ChooseContractorToAssign: React.FC<ChooseContractorToAssignProps> = ({
  formUtils,
  failure,
  onCancel,
  contractors,
  fetchDataParams,
  finishHandler,
  contractorsLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const { assignFailuresToContractor: assignToContractorBulkAssign } =
    useSelector((state) => state.loading.effects.failureBulkAssign);
  const { assignToContractor } = useSelector(
    (state) => state.loading.effects.failureAssign
  );

  const handleFinish = useCallback(
    async (values: FailureAssignToContractorFormValues): Promise<void> => {
      const selectedContractorId = values.contractorId;

      const selectedContractor = contractors.find(
        (contractor) => contractor.id === selectedContractorId
      );

      if (!selectedContractor || !failure) {
        return;
      }

      if (finishHandler) {
        await finishHandler(selectedContractor.id || "");
      } else {
        await dispatch.failureAssign.assignToContractor({
          contractor:
            mapContractorVmToUpdateContractorCommand(selectedContractor),
          failureId: failure?.id,
        });
      }

      dispatch.failureList.fetchList(fetchDataParams);

      onCancel?.("" as any);
    },
    [
      contractors,
      dispatch.failureAssign,
      dispatch.failureList,
      failure,
      fetchDataParams,
      onCancel,
      finishHandler,
    ]
  );

  /**
   * This is for setting the initialValues of the form. The initialValues prop doesn't work
   * because the `failure` prop needs to be loaded first
   */
  useEffect(() => {
    if (assignToContractor.loading || assignToContractorBulkAssign.loading) {
      return;
    }

    formUtils.form.setFieldsValue({
      contractorId: failure?.contractor?.id,
    });

    /**
     * After setting the initial values with `form.setFieldsValue`, the form becomes dirty
     * setting the touched prop with setFields rollbacks the dirtiness
     */
    formUtils.form.setFields([{ name: "contractorId", touched: false }]);
  }, [
    assignToContractor.loading,
    assignToContractorBulkAssign.loading,
    failure?.contractor?.id,
    formUtils.form,
  ]);

  if (contractorsLoading) {
    return (
      <div className={styles.spinWrapper}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <CustomForm
      formUtils={formUtils}
      onFinish={handleFinish}
      className={styles.form}
    >
      <GrapeAntdForm.Item
        name="contractorId"
        required
        rules={[
          requiredField(
            t,
            undefined,
            undefined,
            t("failure.assignModal.contractorIdRequired")
          ),
        ]}
      >
        {contractors.length ? (
          <Radio.Group className={styles.radiosWrapper}>
            {contractors.map((contractor) => (
              <Radio value={contractor.id} key={contractor.id}>
                <Typography.Text
                  className={styles.contractorName}
                  title={contractor.name || ""}
                  ellipsis
                >
                  {contractor.name}
                </Typography.Text>

                {!isMaintainedDevicesFeatureEnabled && (
                  <Typography.Text
                    className={styles.contractorTown}
                    title={contractor.towns
                      ?.map((town) => town.name)
                      .join(", ")}
                    ellipsis
                  >
                    {contractor.towns?.map((town) => town.name).join(", ")}
                  </Typography.Text>
                )}
              </Radio>
            ))}
          </Radio.Group>
        ) : (
          <ConfigProvider
            locale={{
              locale: "hu",
              Empty: {
                description: t("common.noDataForSearchValue"),
              },
            }}
          >
            <Empty />
          </ConfigProvider>
        )}
      </GrapeAntdForm.Item>
    </CustomForm>
  );
};

export default ChooseContractorToAssign;
