import {
  CreateFailureCommand,
  FailureStatusFilter,
  PhoneVm,
  PublicTownVm,
  ReportFailureCommand,
  UpdateFailureCommand,
} from "api/generated/lumen";
import dayjs from "dayjs";
import { ListRequestParams } from "widgets/table/useTableUtils";

export interface FailureCreateFormValues {
  id?: string;
  townId?: string;
  address?: {
    zip?: string | null;
    street: string;
    number: string;
  };
  typeId: string;
  expanseId?: string;
  natureId?: string;
  comment?: string;
  statusId?: string;
  stateChangeReason?: string;
  closedDate?: dayjs.Dayjs;
  description1?: string;
  description2?: string;
  deviceId?: string;
  contractorId?: string;
  notifierFamilyname?: string;
  notifierForename?: string;
  notifierEmail?: string;
  notifierPhone?: PhoneVm;
}
export interface FailureReportFormValues
  extends Omit<
    FailureCreateFormValues,
    | "notifierFamilyname"
    | "notifierForename"
    | "notifierEmail"
    | "notifierPhone"
  > {
  notifierFamilyname: string;
  notifierForename: string;
  notifierEmail: string;
  notifierPhone: PhoneVm;
}

export const mapToCreateFailureCommand = (
  value: FailureCreateFormValues,
  towns: PublicTownVm[]
): CreateFailureCommand => ({
  ...value,
  address: value.address
    ? {
        ...value.address,
        city: towns.find((item) => item.id === value.townId)?.name || "",
      }
    : undefined,
});

export const mapToReportFailureCommand = (
  value: FailureReportFormValues,
  towns: PublicTownVm[]
): ReportFailureCommand => ({
  ...value,
  address: value.address
    ? {
        ...value.address,
        city: towns.find((item) => item.id === value.townId)?.name || "",
      }
    : undefined,
});

export const mapToUpdateFailureCommand = (
  value: FailureCreateFormValues,
  towns: PublicTownVm[]
): UpdateFailureCommand => ({
  ...value,
  id: value.id || "",
  address: value.address
    ? {
        ...value.address,
        city: towns.find((item) => item.id === value.townId)?.name || "",
      }
    : undefined,
  closedAt: value.closedDate?.toDate(),
});

export interface FailureAssignToContractorFormValues {
  contractorId: string;
}

export interface FailureAssignToRepairmenFormValues {
  repairmanIds: string[];
}

export interface FailureListRequestParams extends ListRequestParams {
  statusFilter?: FailureStatusFilter;
  onlyNotAssignedToRepairman?: boolean;
}
