/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Failure,
    FailureFromJSON,
    FailureFromJSONTyped,
    FailureToJSON,
    SpecialPlace,
    SpecialPlaceFromJSON,
    SpecialPlaceFromJSONTyped,
    SpecialPlaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    detailJson?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    text1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    text2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    text3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    text4?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    text5?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    number1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    number2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    number3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    number4?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    number5?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceExternalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    redirectPathPostfix1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    redirectPathPostfix2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    specialPlaceId?: string | null;
    /**
     * 
     * @type {SpecialPlace}
     * @memberof Device
     */
    specialPlace?: SpecialPlace;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Array<Failure>}
     * @memberof Device
     */
    failures?: Array<Failure> | null;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Device
     */
    contractors?: Array<Contractor> | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id?: string;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'detailJson': !exists(json, 'detailJson') ? undefined : json['detailJson'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'text1': !exists(json, 'text1') ? undefined : json['text1'],
        'text2': !exists(json, 'text2') ? undefined : json['text2'],
        'text3': !exists(json, 'text3') ? undefined : json['text3'],
        'text4': !exists(json, 'text4') ? undefined : json['text4'],
        'text5': !exists(json, 'text5') ? undefined : json['text5'],
        'number1': !exists(json, 'number1') ? undefined : json['number1'],
        'number2': !exists(json, 'number2') ? undefined : json['number2'],
        'number3': !exists(json, 'number3') ? undefined : json['number3'],
        'number4': !exists(json, 'number4') ? undefined : json['number4'],
        'number5': !exists(json, 'number5') ? undefined : json['number5'],
        'deviceExternalId': !exists(json, 'deviceExternalId') ? undefined : json['deviceExternalId'],
        'redirectPathPostfix1': !exists(json, 'redirectPathPostfix1') ? undefined : json['redirectPathPostfix1'],
        'redirectPathPostfix2': !exists(json, 'redirectPathPostfix2') ? undefined : json['redirectPathPostfix2'],
        'specialPlaceId': !exists(json, 'specialPlaceId') ? undefined : json['specialPlaceId'],
        'specialPlace': !exists(json, 'specialPlace') ? undefined : SpecialPlaceFromJSON(json['specialPlace']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'failures': !exists(json, 'failures') ? undefined : (json['failures'] === null ? null : (json['failures'] as Array<any>).map(FailureFromJSON)),
        'contractors': !exists(json, 'contractors') ? undefined : (json['contractors'] === null ? null : (json['contractors'] as Array<any>).map(ContractorFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'detailJson': value.detailJson,
        'isActive': value.isActive,
        'text1': value.text1,
        'text2': value.text2,
        'text3': value.text3,
        'text4': value.text4,
        'text5': value.text5,
        'number1': value.number1,
        'number2': value.number2,
        'number3': value.number3,
        'number4': value.number4,
        'number5': value.number5,
        'deviceExternalId': value.deviceExternalId,
        'redirectPathPostfix1': value.redirectPathPostfix1,
        'redirectPathPostfix2': value.redirectPathPostfix2,
        'specialPlaceId': value.specialPlaceId,
        'specialPlace': SpecialPlaceToJSON(value.specialPlace),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'failures': value.failures === undefined ? undefined : (value.failures === null ? null : (value.failures as Array<any>).map(FailureToJSON)),
        'contractors': value.contractors === undefined ? undefined : (value.contractors === null ? null : (value.contractors as Array<any>).map(ContractorToJSON)),
        'id': value.id,
    };
}


