import { InventoryVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { GetFilterParamsForGetRequests } from "models/common";
import {
  calculateFilterRequestParams,
  formatSortingRequestParams,
} from "utils";
import {
  ListRequestParams,
  mergeListParams,
} from "widgets/table/useTableUtils";

export const getFilterParamsForInventoryGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  const newParams: ListRequestParams = {
    ...params,
    filter: {
      ...params.filter,
      "category.isActive": true,
    },
  };

  const mergedParams = mergeListParams(existingParams, newParams);

  const filter = calculateFilterRequestParams<InventoryVm>(mergedParams);

  const order = formatSortingRequestParams(mergedParams, {
    "category.value": "category.name", // Filter needs category.value, sorting needs category.name, because Dictionary item is temporary translated on BE
  });

  const payload: ListRequestParams = {
    ...mergedParams,
    filter,
    order,
  };

  return { payload, mergedParams };
};
