/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageThumbnailMetadataVm
 */
export interface ImageThumbnailMetadataVm {
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnailMetadataVm
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnailMetadataVm
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageThumbnailMetadataVm
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageThumbnailMetadataVm
     */
    contentType?: string | null;
}

export function ImageThumbnailMetadataVmFromJSON(json: any): ImageThumbnailMetadataVm {
    return ImageThumbnailMetadataVmFromJSONTyped(json, false);
}

export function ImageThumbnailMetadataVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageThumbnailMetadataVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
    };
}

export function ImageThumbnailMetadataVmToJSON(value?: ImageThumbnailMetadataVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'width': value.width,
        'height': value.height,
        'contentType': value.contentType,
    };
}


