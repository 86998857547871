/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    WorkBookTaskItemReportExcelQuery,
    WorkBookTaskItemReportExcelQueryFromJSON,
    WorkBookTaskItemReportExcelQueryToJSON,
    WorkBookTaskItemReportListItemVmPaginatedSearchResponse,
    WorkBookTaskItemReportListItemVmPaginatedSearchResponseFromJSON,
    WorkBookTaskItemReportListItemVmPaginatedSearchResponseToJSON,
    WorkBookTaskItemReportQuery,
    WorkBookTaskItemReportQueryFromJSON,
    WorkBookTaskItemReportQueryToJSON,
} from '../models';

export interface ApiReportGetWorkBookTaskItemsExportPostRequest {
    xLUMENTransactionGuid?: string;
    workBookTaskItemReportExcelQuery?: WorkBookTaskItemReportExcelQuery;
}

export interface ApiReportGetWorkBookTaskItemsPostRequest {
    xLUMENTransactionGuid?: string;
    workBookTaskItemReportQuery?: WorkBookTaskItemReportQuery;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     * GetWorkBookTaskItemsExport
     */
    async apiReportGetWorkBookTaskItemsExportPostRaw(requestParameters: ApiReportGetWorkBookTaskItemsExportPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Report/GetWorkBookTaskItemsExport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkBookTaskItemReportExcelQueryToJSON(requestParameters.workBookTaskItemReportExcelQuery),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * GetWorkBookTaskItemsExport
     */
    async apiReportGetWorkBookTaskItemsExportPost(requestParameters: ApiReportGetWorkBookTaskItemsExportPostRequest): Promise<string> {
        const response = await this.apiReportGetWorkBookTaskItemsExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * GetWorkBookTaskItems
     */
    async apiReportGetWorkBookTaskItemsPostRaw(requestParameters: ApiReportGetWorkBookTaskItemsPostRequest): Promise<runtime.ApiResponse<WorkBookTaskItemReportListItemVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xLUMENTransactionGuid !== undefined && requestParameters.xLUMENTransactionGuid !== null) {
            headerParameters['X-LUMEN-Transaction-Guid'] = String(requestParameters.xLUMENTransactionGuid);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Report/GetWorkBookTaskItems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkBookTaskItemReportQueryToJSON(requestParameters.workBookTaskItemReportQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkBookTaskItemReportListItemVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * GetWorkBookTaskItems
     */
    async apiReportGetWorkBookTaskItemsPost(requestParameters: ApiReportGetWorkBookTaskItemsPostRequest): Promise<WorkBookTaskItemReportListItemVmPaginatedSearchResponse> {
        const response = await this.apiReportGetWorkBookTaskItemsPostRaw(requestParameters);
        return await response.value();
    }

}
