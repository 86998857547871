import { Button, Typography } from "antd";
import CardWithBrandLogo from "components/ui/CardWithBrandLogo";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./RegisterSuccess.module.scss";

const RegisterSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CardWithBrandLogo
      footerContent={
        <Link to="/login" replace>
          <Button type="link" className={styles.loginButton}>
            {t("register.login")}
          </Button>
        </Link>
      }
    >
      <div>
        <Typography.Title>{t("register.success.title")}</Typography.Title>

        <div className={styles.description}>
          {t("register.success.description")}
        </div>
      </div>
    </CardWithBrandLogo>
  );
};

export default RegisterSuccess;
