import dayjs from "dayjs";
import React from "react";
import { backendDateToDayJs } from "utils/date";
import { EmptyContent } from "./table-content/EmptyContent";

export type DateDisplayMode = "date" | "datetime";

export enum DateFormat {
  Date = "L",
  DateTime = "L LT",
  ServerDate = "YYYY-MM-DD",
}

interface DateDisplayProps {
  value?: string | number | Date | dayjs.Dayjs | null;
  format?: DateFormat;
}

// FIXME: NE ITT LEGYEN, KI KELL SZERVEZNI
const DateDisplay: React.FC<DateDisplayProps> = ({
  value,
  format = DateFormat.Date,
}) => {
  const dayjsObj = backendDateToDayJs(value);

  return <>{value && dayjsObj ? dayjsObj.format(format) : <EmptyContent />}</>;
};

export default DateDisplay;
