import { mdiLink, mdiPlus } from "@mdi/js";
import { Button, Space, Spin, Empty } from "antd";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "app/store";
import MobilePageLayout from "components/layout/MobilePageLayout";
import { useTranslation } from "react-i18next";
import FailureCard from "./FailureCard";
import styles from "./RepairersFailureList.module.scss";
import FloatingAttachBar from "./components/FloatingAttachBar";
import { FailureListItemVm } from "api/generated/lumen";
import { Permission } from "components/Permission";
import Claims from "features/auth/claims";

const RepairersFailureList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.failureList);
  const { isSaved: isFailureCreateModalSaved } = useSelector(
    (state) => state.failureCreate
  );
  const navigate = useNavigate();
  const [attachButtonIsActive, setAttachButtonIsActive] = useState(false);
  const [selectedFailures, setSelectedFailures] = useState<FailureListItemVm[]>(
    []
  );

  const { fetchNotSealedList, attach } = useSelector(
    (state) => state.loading.effects.failureList
  );

  // These funcionalities are not necessary at this point
  /* const options = useMemo<CheckboxOptionType[]>(
    () => [
      {
        value: "active",
        label: (
          <>
            {t("failures.categorySwitch.active")}
            <MaterialIcon path={mdiPlay} className={styles.icon} />
          </>
        ),
      },
      {
        value: "fixed",
        label: (
          <>
            {t("failures.categorySwitch.fixed")}
            <MaterialIcon
              path={mdiCheckCircleOutline}
              className={styles.icon}
            />
          </>
        ),
      },
    ],
    [t]
  );

  const handleCategoryChange = useCallback((e: RadioChangeEvent) => {
    console.log(e.target.value);
  }, []);

  const handleAddNewClick = useCallback(() => {
    console.log("handleAddNewClick");
  }, []); */

  const onFailureCardWrapperClick = useCallback(
    (failure: FailureListItemVm) => {
      if (!attachButtonIsActive) {
        navigate(`/failures/${failure.id}?deviceLayoutType=mobile`);

        return;
      }

      setSelectedFailures((oldValue) => {
        const alreadyAddedFailure = oldValue.find(
          (item) => item.id === failure.id
        );

        if (alreadyAddedFailure) {
          return oldValue.filter((item) => item.id !== failure.id);
        }

        return [...oldValue, failure];
      });
    },
    [attachButtonIsActive, navigate]
  );

  const handleOnAttachCancel = useCallback(() => {
    setAttachButtonIsActive(false);
  }, []);

  const fetchData = useCallback(
    () => dispatch.failureList.fetchNotSealedList(),
    [dispatch.failureList]
  );

  const failureList = useMemo(() => {
    if (!list || list.length < 2) {
      return list;
    }

    const sortedList = [...list];
    sortedList.sort(
      (a, b) =>
        (a.address?.city || "").localeCompare(b.address?.city || "") ||
        (a.address?.zip || "").localeCompare(b.address?.zip || "") ||
        (a.address?.street || "").localeCompare(b.address?.street || "") ||
        (a.address?.number || "").localeCompare(b.address?.number || "")
    );

    return sortedList;
  }, [list]);

  const handleOnAttach = useCallback(async () => {
    await dispatch.failureList.attach(
      selectedFailures.map(({ id }) => id || "")
    );

    setAttachButtonIsActive(false);

    await fetchData();
  }, [dispatch.failureList, fetchData, selectedFailures]);

  const handleNewButtonClick = useCallback(() => {
    dispatch.failureCreate.setIsVisible(true);
  }, [dispatch.failureCreate]);

  useEffect(() => {
    if (isFailureCreateModalSaved) {
      fetchData();
    }
  }, [fetchData, isFailureCreateModalSaved]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!attachButtonIsActive) {
      setSelectedFailures([]);
    }
  }, [attachButtonIsActive]);

  return (
    <MobilePageLayout
    // These funcionalities are not necessary at this point
    /* rightContent={
        <Space size="middle">
          <OverflowedButtonRadioGroup
            options={options}
            size="xxl"
            defaultValue={options[0].value}
            onChange={handleCategoryChange}
          />
          <IconButton
            path={mdiPlus}
            xxl
            onClick={handleAddNewClick}
            variant="warning"
          />
        </Space>
      } */
    >
      <Space direction="vertical" size="large" className={styles.space}>
        {fetchNotSealedList.loading ? (
          <div className={styles.spinWrapper}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Space direction="horizontal" size="large">
              <Permission
                acceptableClaims={[Claims.Failures.CREATE]}
                acceptableRoles={[]}
              >
                <Button
                  className={clsx(styles.attachButton, {
                    [styles.attachButtonIsActive]: attachButtonIsActive,
                  })}
                  icon={
                    <MaterialIcon
                      path={mdiPlus}
                      className={styles.attachIcon}
                    />
                  }
                  onClick={handleNewButtonClick}
                >
                  {t("common.new")}
                </Button>
              </Permission>

              {failureList.length >= 2 && (
                <Button
                  className={clsx(styles.attachButton, {
                    [styles.attachButtonIsActive]: attachButtonIsActive,
                  })}
                  icon={
                    <MaterialIcon
                      path={mdiLink}
                      className={styles.attachIcon}
                    />
                  }
                  onClick={() =>
                    setAttachButtonIsActive((oldValue) => !oldValue)
                  }
                >
                  {t("failure.actions.attach")}
                </Button>
              )}
            </Space>

            {failureList?.length ? (
              <Space size="middle" direction="vertical">
                {failureList.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => onFailureCardWrapperClick(item)}
                    className={clsx(styles.failureCardWrapper, {
                      [styles.selected]: !!selectedFailures.find(
                        (failure) => failure.id === item.id
                      ),
                    })}
                  >
                    <FailureCard data={item} />
                  </div>
                ))}
              </Space>
            ) : (
              <div className={styles.emptyWrapper}>
                <Empty />
              </div>
            )}
          </>
        )}
      </Space>

      {attachButtonIsActive && (
        <FloatingAttachBar
          selectedCount={selectedFailures.length}
          onCancel={handleOnAttachCancel}
          onAttach={handleOnAttach}
          attachButtonIsLoading={attach.loading}
        />
      )}
    </MobilePageLayout>
  );
};

export default RepairersFailureList;
