/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractorExcelVm
 */
export interface ContractorExcelVm {
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    headquarterZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    headquarterCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    headquarterAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    headquarterNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorExcelVm
     */
    status?: string | null;
}

export function ContractorExcelVmFromJSON(json: any): ContractorExcelVm {
    return ContractorExcelVmFromJSONTyped(json, false);
}

export function ContractorExcelVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractorExcelVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'headquarterZip': !exists(json, 'headquarterZip') ? undefined : json['headquarterZip'],
        'headquarterCity': !exists(json, 'headquarterCity') ? undefined : json['headquarterCity'],
        'headquarterAddress': !exists(json, 'headquarterAddress') ? undefined : json['headquarterAddress'],
        'headquarterNumber': !exists(json, 'headquarterNumber') ? undefined : json['headquarterNumber'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ContractorExcelVmToJSON(value?: ContractorExcelVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'headquarterZip': value.headquarterZip,
        'headquarterCity': value.headquarterCity,
        'headquarterAddress': value.headquarterAddress,
        'headquarterNumber': value.headquarterNumber,
        'status': value.status,
    };
}


