import { Select } from "antd";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useActivatedPublicTownsQuery from "hooks/queries/useActivatedPublicTownsQuery";
import { ItemProps } from "models/common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterOptionsLabelFromBeginning } from "utils";
import { GrapeAntdForm, requiredField } from "widgets/form";
import styles from "./TownsFieldset.module.scss";

const TownsFieldset: React.FC = () => {
  const { t } = useTranslation();
  const activatedTownsQuery = useActivatedPublicTownsQuery();

  const activatedTownsOptions = useMemo<ItemProps[]>(
    () =>
      (activatedTownsQuery.data?.items || []).map((type) => ({
        label: type.name || "",
        value: type.id || "",
      })),
    [activatedTownsQuery.data?.items]
  );

  return (
    <div className={styles.wrapper}>
      <p className={styles.introText}>{t("contractor.townsFieldsetIntro")}</p>
      <fieldset>
        <legend>{t("contractor.townsFieldset")}</legend>
        <FieldsetFieldsWrapper>
          <GrapeAntdForm.Item
            name="townIds"
            label={t("contractor.towns")}
            rules={[requiredField(t, undefined, { type: "array" })]}
          >
            <Select
              size="large"
              options={activatedTownsOptions}
              loading={activatedTownsQuery.isLoading}
              showSearch
              optionFilterProp="label"
              filterOption={filterOptionsLabelFromBeginning}
              mode="multiple"
            />
          </GrapeAntdForm.Item>
        </FieldsetFieldsWrapper>
      </fieldset>
    </div>
  );
};

export default TownsFieldset;
