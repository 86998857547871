import { Layout } from "antd";
import { FC, ReactNode, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./FullPageCardContent.module.scss";

interface FullPageCardContentProps {
  children?: ReactNode;
}

const FullPageCardContent: FC<FullPageCardContentProps> = ({ children }) => {
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // <Card></Card> is scrollable due to styles.card if content is tall enough, so when route changes we need to scroll up the Card
    (contentRef?.current?.childNodes[0] as HTMLDivElement)?.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout.Content className={styles.layoutContent}>
      <div className={styles.contentWrapper}>
        {children}
        <div className={styles.content} ref={contentRef}>
          <Outlet />
        </div>
      </div>
    </Layout.Content>
  );
};

export default FullPageCardContent;
