/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkbookTaskItemDto,
    WorkbookTaskItemDtoFromJSON,
    WorkbookTaskItemDtoFromJSONTyped,
    WorkbookTaskItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateWorkbookTaskCommand
 */
export interface UpdateWorkbookTaskCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    reasonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    statusId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    workbookId: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateWorkbookTaskCommand
     */
    statechangedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkbookTaskCommand
     */
    statechangedBy?: string | null;
    /**
     * 
     * @type {Array<WorkbookTaskItemDto>}
     * @memberof UpdateWorkbookTaskCommand
     */
    items?: Array<WorkbookTaskItemDto> | null;
}

export function UpdateWorkbookTaskCommandFromJSON(json: any): UpdateWorkbookTaskCommand {
    return UpdateWorkbookTaskCommandFromJSONTyped(json, false);
}

export function UpdateWorkbookTaskCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkbookTaskCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reasonId': !exists(json, 'reasonId') ? undefined : json['reasonId'],
        'statusId': json['statusId'],
        'taskId': json['taskId'],
        'workbookId': json['workbookId'],
        'statechangedDate': !exists(json, 'statechangedDate') ? undefined : (json['statechangedDate'] === null ? null : new Date(json['statechangedDate'])),
        'statechangedBy': !exists(json, 'statechangedBy') ? undefined : json['statechangedBy'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(WorkbookTaskItemDtoFromJSON)),
    };
}

export function UpdateWorkbookTaskCommandToJSON(value?: UpdateWorkbookTaskCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reasonId': value.reasonId,
        'statusId': value.statusId,
        'taskId': value.taskId,
        'workbookId': value.workbookId,
        'statechangedDate': value.statechangedDate === undefined ? undefined : (value.statechangedDate === null ? null : value.statechangedDate.toISOString()),
        'statechangedBy': value.statechangedBy,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(WorkbookTaskItemDtoToJSON)),
    };
}


