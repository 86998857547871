import { useDispatch, useSelector } from "app/store";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { LumenWorkbookTaskStatuses } from "models/common";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";

interface UseFailureViewWorkbookTaskCard {
  handleTaskCompleted: (
    id: string | undefined,
    taskId: string | undefined
  ) => Promise<void>;
  selectedTask: string | undefined;
  setWorkbookTaskStatusIsLoading: boolean | undefined;
  fetchWorkbookTaskReasonsIsLoading: boolean | undefined;
  fetchWorkbookTaskStatusesIsLoading: boolean | undefined;
  addNewTaskModalIsOpen: boolean;
  setAddNewTaskModalIsOpen: Dispatch<SetStateAction<boolean>>;
}

const useFailureViewWorkbookTaskCard = (): UseFailureViewWorkbookTaskCard => {
  const dispatch = useDispatch();
  const [selectedTask, setSelectedTask] = useState<string>();
  const { setWorkbookTaskStatus } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const workbookTaskStatusesQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenWorkbookTaskStatuses",
  });
  const workbookTaskReasonsQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenWorkbookTaskReasons",
  });

  const { workbook, failure } = useSelector((state) => state.failureView);
  const [addNewTaskModalIsOpen, setAddNewTaskModalIsOpen] = useState(false);

  const handleTaskCompleted = useCallback(
    async (id: string | undefined, taskId: string | undefined) => {
      const doneStatus = workbookTaskStatusesQuery.data?.find(
        ({ value }) => value === LumenWorkbookTaskStatuses.Done
      );

      if (
        typeof id !== "string" ||
        typeof workbook?.id !== "string" ||
        typeof taskId !== "string" ||
        typeof doneStatus?.id !== "string"
      ) {
        return;
      }

      setSelectedTask(id);

      await dispatch.failureView.setWorkbookTaskStatus({
        id,
        statusId: doneStatus?.id,
        taskId,
        workbookId: workbook.id,
      });

      if (typeof failure?.id === "string") {
        dispatch.failureView.fetchWorkbook(failure.id);
      }
    },
    [
      dispatch.failureView,
      failure?.id,
      workbook?.id,
      workbookTaskStatusesQuery.data,
    ]
  );

  useEffect(() => {
    dispatch.failureView.fetchInventoryItems();
  }, [dispatch.failureView]);

  return {
    handleTaskCompleted,
    selectedTask,
    setWorkbookTaskStatusIsLoading: setWorkbookTaskStatus.loading,
    fetchWorkbookTaskReasonsIsLoading: workbookTaskReasonsQuery.isLoading,
    fetchWorkbookTaskStatusesIsLoading: workbookTaskStatusesQuery.isLoading,
    addNewTaskModalIsOpen,
    setAddNewTaskModalIsOpen,
  };
};

export default useFailureViewWorkbookTaskCard;
