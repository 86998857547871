import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationHu from "./locales/hu.json";
import translationEn from "./locales/en.json";
import { SupportedLanguage } from "../../models/common";

const resources = {
  [SupportedLanguage.Hu]: {
    translation: translationHu,
  },
  [SupportedLanguage.En]: {
    translation: translationEn,
  },
};

const defaultLanguage = SupportedLanguage.Hu;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
