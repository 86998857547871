/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RegistrationStates {
    NotInvited = 'NotInvited',
    Invited = 'Invited',
    Registered = 'Registered'
}

export function RegistrationStatesFromJSON(json: any): RegistrationStates {
    return RegistrationStatesFromJSONTyped(json, false);
}

export function RegistrationStatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationStates {
    return json as RegistrationStates;
}

export function RegistrationStatesToJSON(value?: RegistrationStates | null): any {
    return value as any;
}

