import { createModel } from "@rematch/core";
import { api } from "api";
import {
  FailureListItemVm,
  FailureTypeVm,
  FilterLogicalOperator,
} from "api/generated/lumen";
import { RootModel } from "app/store";
import { mapToGetAllQuery } from "utils";
import { downloadFile } from "utils/file";
import {
  ListRequestParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";
import { FailureListRequestParams } from "../models";
import { getFilterParamsForFailureGetRequests } from "../utils";

interface FailuresListState {
  listParams: ListRequestParams;
  list: FailureListItemVm[];
  failureTypes: FailureTypeVm[];
  selectedIds: string[];
}

const initialState: FailuresListState = {
  listParams: {
    pageSize: 50,
    order: [
      {
        orderByType: OrderByType.Descending,
        orderBy: "reportedAt",
      },
    ],
  },
  list: [],
  failureTypes: [],
  selectedIds: [],
};

export const failureList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: FailureListItemVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    setSelectedIds(state, data: string[]) {
      state.selectedIds = data;
    },
    setFailureTypes(state, data: FailureTypeVm[]) {
      state.failureTypes = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureList } = dispatch;

    return {
      async fetchList(
        params: FailureListRequestParams = {},
        state
      ): Promise<void> {
        const { payload, mergedParams } = getFilterParamsForFailureGetRequests(
          params,
          state.failureList.listParams
        );

        const data = await api.lumen.failures.apiFailuresGetAllPost({
          getAllFailuresQuery: {
            ...mapToGetAllQuery(payload),
            statusFilter: payload.statusFilter,
            ...(payload?.onlyNotAssignedToRepairman !== undefined
              ? {
                  onlyNotAssignedToRepairman:
                    payload.onlyNotAssignedToRepairman,
                }
              : {}),
          },
        });

        const { items, ...pagination } = data;

        dispatch(
          failureList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async fetchNotSealedList(): Promise<void> {
        const data = await api.lumen.failures.apiFailuresGetAllPost({
          getAllFailuresQuery: {
            ...mapToGetAllQuery({
              order: [
                {
                  orderByType: OrderByType.Ascending,
                  orderBy: "deadline",
                },
              ],
              pageSize: -1,
              filter: {
                "status.isSealed": false,
                parentId: "", // this filter is needed in all cases, to get all the failures that are not assigned to another failure
              },
            }),
            filterLogicalOperator: FilterLogicalOperator.And,
          },
        });

        dispatch(
          failureList.setList({
            data: data.items || [],
            listParams: {},
          })
        );
      },

      async fetchFailureTypes(): Promise<void> {
        const { items } =
          await api.lumen.failureTypes.apiFailureTypesGetAllPost({
            getAllFailureTypesQuery: {
              pageSize: -1,
            },
          });

        dispatch(failureList.setFailureTypes(items || []));
      },

      async generateExcel(columnTitles: string[], state): Promise<void> {
        const { payload } = getFilterParamsForFailureGetRequests(
          state.failureList.listParams,
          state.failureList.listParams
        );

        const data = await api.lumen.failures.apiFailuresExportPostRaw({
          getAllFailuresExcelQuery: {
            ...mapToGetAllQuery(payload),
            statusFilter: payload.statusFilter,
            columnNames: columnTitles,
            pageSize: -1,
          },
        });

        downloadFile(data.raw);
      },

      async attach(attachedFailureIds: string[]): Promise<string> {
        return api.lumen.failures.apiFailuresAttachPost({
          addAttachedFailuresCommand: {
            attachedFailureIds,
          },
        });
      },
    };
  },
});
