import { Divider, DividerProps } from "antd";
import clsx from "clsx";
import React from "react";
import styles from "./SidebarDivider.module.scss";

const SidebarDivider: React.FC<DividerProps> = ({ className, ...rest }) => {
  return <Divider {...rest} className={clsx(styles.divider, className)} />;
};

export default SidebarDivider;
