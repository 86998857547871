import { ColumnType } from "antd/lib/table";

// visible - column visible - checkbox
// hidden - column not visible - checkbox
// alwaysVisible - column can not be hidden - lock icon
export enum ColumnState {
  Visible = "Visible",
  Hidden = "Hidden",
  AlwaysVisible = "AlwaysVisible",
}

export interface ExtendedColumnType<RecordType> extends ColumnType<RecordType> {
  defaultState?: ColumnState;
  currentState?: ColumnState;
  altTitle?: string;
  columnOrderFixed?: boolean;
}
