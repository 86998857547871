/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RefreshToken,
    RefreshTokenFromJSON,
    RefreshTokenFromJSONTyped,
    RefreshTokenToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    UserFilterType,
    UserFilterTypeFromJSON,
    UserFilterTypeFromJSONTyped,
    UserFilterTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    forename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    pwdHash?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isRegistered?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    canRegisterUntil?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    roleId?: string | null;
    /**
     * 
     * @type {UserFilterType}
     * @memberof User
     */
    userFilterType?: UserFilterType;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userFilterId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    orgName?: string | null;
    /**
     * 
     * @type {Array<RefreshToken>}
     * @memberof User
     */
    refreshTokens?: Array<RefreshToken> | null;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    role?: Role;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'pwdHash': !exists(json, 'pwdHash') ? undefined : json['pwdHash'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isRegistered': !exists(json, 'isRegistered') ? undefined : json['isRegistered'],
        'canRegisterUntil': !exists(json, 'canRegisterUntil') ? undefined : (json['canRegisterUntil'] === null ? null : new Date(json['canRegisterUntil'])),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'userFilterType': !exists(json, 'userFilterType') ? undefined : UserFilterTypeFromJSON(json['userFilterType']),
        'userFilterId': !exists(json, 'userFilterId') ? undefined : json['userFilterId'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
        'refreshTokens': !exists(json, 'refreshTokens') ? undefined : (json['refreshTokens'] === null ? null : (json['refreshTokens'] as Array<any>).map(RefreshTokenFromJSON)),
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
        'username': value.username,
        'pwdHash': value.pwdHash,
        'isDeleted': value.isDeleted,
        'isRegistered': value.isRegistered,
        'canRegisterUntil': value.canRegisterUntil === undefined ? undefined : (value.canRegisterUntil === null ? null : value.canRegisterUntil.toISOString()),
        'roleId': value.roleId,
        'userFilterType': UserFilterTypeToJSON(value.userFilterType),
        'userFilterId': value.userFilterId,
        'orgName': value.orgName,
        'refreshTokens': value.refreshTokens === undefined ? undefined : (value.refreshTokens === null ? null : (value.refreshTokens as Array<any>).map(RefreshTokenToJSON)),
        'role': RoleToJSON(value.role),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


