import { FC } from "react";
import { TableDropdownFooter } from "./TableDropdownFooter";
import styles from "./TableDropdown.module.scss";
import { Empty, Radio, Space, Spin } from "antd";
import { ExtendedFilterDropdownProps } from "./models";

export const RadioTableDropdown: FC<ExtendedFilterDropdownProps> = (props) => {
  const { setSelectedKeys, selectedKeys, filters, loading } = props;

  return (
    <div>
      <div className={styles.inputContainer}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spin />
          </div>
        ) : filters?.length ? (
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
          >
            <Space direction="vertical">
              {filters?.map((filter) => (
                <Radio value={filter.value} key={`${filter.value}`}>
                  {filter.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>

      <TableDropdownFooter {...props} />
    </div>
  );
};
