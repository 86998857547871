import { ModalProps } from "antd";
import Search from "antd/lib/input/Search";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import CustomModal from "components/ui/CustomModal";
import ChooseContractorToAssign from "features/failures/assign/components/ChooseContractorToAssign";
import ChooseRepairmenToAssign from "features/failures/assign/components/ChooseRepairmenToAssign";
import { FailureAssignType } from "features/failures/assign/FailureAssignModal";
import { FailureListRequestParams } from "features/failures/models";
import useFeature from "hooks/useFeature";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGrapeAntdForm } from "widgets/form";
import styles from "./FailureBulkAssignModal.module.scss";
import { useTranslation } from "react-i18next";

interface FailureBulkAssignModalProps extends ModalProps {
  type: FailureAssignType;
  fetchDataParams?: FailureListRequestParams;
}

export const FailureBulkAssignModal: React.FC<FailureBulkAssignModalProps> = ({
  visible,
  onCancel,
  type,
  fetchDataParams,
}) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [hasOtherContractor, setHasOtherContractor] = useState<boolean>(false);
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { assignableFailures, contractors, repairmen } = useSelector(
    (state) => state.failureBulkAssign
  );

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const {
    fetchContractorsForDevices,
    fetchContractorsForTowns,
    fetchRepairmen,
    assignFailuresToContractor,
    assignFailuresToRepairmen,
  } = useSelector((state) => state.loading.effects.failureBulkAssign);

  const filteredContractors = useMemo(
    () =>
      contractors.filter(({ name }) =>
        name?.toLowerCase().includes(searchKeyword.toLowerCase())
      ),
    [contractors, searchKeyword]
  );

  const filteredRepairmen = useMemo(
    () =>
      repairmen.filter(
        ({ familyname, forename, middlename }) =>
          familyname?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          forename?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          middlename?.toLowerCase().includes(searchKeyword.toLowerCase())
      ),
    [repairmen, searchKeyword]
  );

  const computedDescription = useMemo(() => {
    if (type === "contractor") {
      return t("failure.bulkAssignModal.typeContractor.description", {
        className: styles.highlighted,
      });
    }

    if (type === "repairmen") {
      if (hasOtherContractor) {
        return t("failure.bulkAssignModal.cantAssign.moreContactors");
      } else {
        return t("failure.bulkAssignModal.typeRepairmen.description", {
          className: styles.highlighted,
        });
      }
    }

    return "";
  }, [hasOtherContractor, type, t]);

  const fetchContractorsData = useCallback(() => {
    if (isMaintainedDevicesFeatureEnabled) {
      return dispatch.failureBulkAssign.fetchContractorsForDevices(
        assignableFailures
          .map((failure) => failure.deviceId || "")
          .filter((deviceId) => deviceId !== "")
      );
    }

    return dispatch.failureBulkAssign.fetchContractorsForTowns(
      assignableFailures.map((failure) => failure.address?.city || "")
    );
  }, [
    assignableFailures,
    dispatch.failureBulkAssign,
    isMaintainedDevicesFeatureEnabled,
  ]);

  useEffect(() => {
    if (visible && type === "contractor") {
      fetchContractorsData();
    }
    if (visible && type === "repairmen") {
      const firstNotEmptyContractorId =
        assignableFailures.find(
          (assignableFailure) => assignableFailure.contractor?.id
        )?.contractor?.id || "";

      setHasOtherContractor(
        assignableFailures.findIndex(
          (assignableFailure) =>
            assignableFailure.contractor?.id &&
            assignableFailure.contractor.id !== firstNotEmptyContractorId
        ) !== -1
      );

      if (hasOtherContractor) {
        dispatch.failureBulkAssign.setRepairmen([]);
      } else {
        dispatch.failureBulkAssign.fetchRepairmen(firstNotEmptyContractorId);
      }
    }
  }, [
    assignableFailures,
    dispatch.failureBulkAssign,
    fetchContractorsData,
    hasOtherContractor,
    type,
    visible,
  ]);

  const handleContractorFinish = useCallback(
    async (contractorId: string) => {
      await dispatch.failureBulkAssign.assignFailuresToContractor({
        contractorId,
        failureIds: assignableFailures.map(({ id }) => id || ""),
      });
    },
    [assignableFailures, dispatch.failureBulkAssign]
  );

  const handleRepairmenFinish = useCallback(
    async (repairmanIds: string[]) => {
      await dispatch.failureBulkAssign.assignFailuresToRepairmen({
        repairmanIds,
        failureIds: assignableFailures.map(({ id }) => id || ""),
      });
    },
    [assignableFailures, dispatch.failureBulkAssign]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  return (
    <CustomModal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      onOk={handleModalOk}
      title={
        type === "contractor"
          ? t("failure.bulkAssignModal.typeContractor.title")
          : t("failure.bulkAssignModal.typeRepairmen.title")
      }
      okButtonProps={{
        loading:
          assignFailuresToContractor.loading ||
          assignFailuresToRepairmen.loading,
        disabled: hasOtherContractor,
      }}
      cancelButtonProps={{
        disabled:
          assignFailuresToContractor.loading ||
          assignFailuresToRepairmen.loading,
      }}
      okText={t("failure.bulkAssignModal.okText")}
      formInstance={formUtils.form}
      className={styles.wrapper}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: computedDescription,
        }}
        className={clsx(
          styles.description,
          type === "repairmen" && hasOtherContractor ? styles.errorText : ""
        )}
      />

      <div className={styles.searchWrapper}>
        <Search
          size="large"
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder={t("failure.bulkAssignModal.searchPlaceholder")}
          loading={
            fetchContractorsForDevices.loading ||
            fetchContractorsForTowns.loading ||
            fetchRepairmen.loading
          }
          disabled={hasOtherContractor}
        />
      </div>

      {type === "contractor" && (
        <ChooseContractorToAssign
          formUtils={formUtils}
          onCancel={onCancel}
          contractors={filteredContractors}
          finishHandler={handleContractorFinish}
          failure={{}}
          fetchDataParams={fetchDataParams}
          contractorsLoading={
            fetchContractorsForDevices.loading ||
            fetchContractorsForTowns.loading
          }
        />
      )}

      {type === "repairmen" && (
        <ChooseRepairmenToAssign
          formUtils={formUtils}
          onCancel={onCancel}
          repairmen={hasOtherContractor ? [] : filteredRepairmen}
          finishHandler={handleRepairmenFinish}
          failure={{}}
          fetchDataParams={fetchDataParams}
        />
      )}
    </CustomModal>
  );
};
