import { mdiClose } from "@mdi/js";
import { Modal, ModalProps, Typography } from "antd";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import React from "react";
import styles from "./ItemChooserModal.module.scss";

interface ItemChooserModalProps extends ModalProps {
  title: string;
  longModal?: boolean;
}

const ItemChooserModal: React.FC<ItemChooserModalProps> = ({
  onCancel,
  title,
  longModal,
  children,
  className,
  ...rest
}) => {
  return (
    <Modal
      footer={null}
      width={900}
      {...rest}
      onCancel={onCancel}
      closeIcon={<MaterialIcon path={mdiClose} />}
      className={clsx(styles.modal, className, {
        [styles.longModal]: longModal,
      })}
    >
      <div className={styles.content}>
        <Typography.Title level={2} className={styles.title}>
          {title}
        </Typography.Title>

        {children}
      </div>
    </Modal>
  );
};

export default ItemChooserModal;
