import { mdiEye, mdiLinkVariantOff, mdiMapMarkerPlus } from "@mdi/js";
import { Button, Modal, Space } from "antd";
import { SpecialPlaceVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import IconButton from "components/ui/IconButton";
import AssignContractorToSpecialPlaceModal from "features/contractors/assign/AssignContractorToSpecialPlaceModal";
import useDumbTable from "hooks/useDumbTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ColumnConfigParams } from "widgets/table/useTableUtils";

const { confirm } = Modal;

const SpecialPlacesTab: React.FC = () => {
  const { t } = useTranslation();
  const { contractor, assignedSpecialPlaces } = useSelector(
    (state) => state.contractorView
  );
  const { fetchAssignedSpecialPlaces } = useSelector(
    (state) => state.loading.effects.contractorView
  );
  const dispatch = useDispatch();
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const navigate = useNavigate();

  const columnParams = useMemo<ColumnConfigParams<SpecialPlaceVm>[]>(
    () => [
      {
        key: "name",
        sort: false,
        title: t("specialPlace.name"),
      },
      {
        key: "description",
        sort: false,
        title: t("specialPlace.description"),
      },
    ],
    [t]
  );

  const handleDetachButtonClick = useCallback(
    async (id?: string) => {
      confirm({
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("contractor.confirmDeatachSpecialPlaceModal.title"),
        onOk: async () => {
          if (typeof contractor?.id !== "string" || typeof id !== "string") {
            return;
          }

          await dispatch.contractorView.detachSpecialPlace({
            contractorToDetachId: contractor.id,
            specialPlaceToDetachId: id,
          });

          dispatch.contractorView.fetchAssignedSpecialPlaces(contractor.id);
        },
      });
    },
    [contractor?.id, dispatch.contractorView, t]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/special-places/${id}`);
    },
    [navigate]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<SpecialPlaceVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiLinkVariantOff}
              onClick={() => handleDetachButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDetachButtonClick, handleViewButtonClick, t]
  );

  const handleAssignSpecialPlaceClick = useCallback(() => {
    setAssignModalOpen(true);
    dispatch.assignContractor.setContractor(contractor);
  }, [contractor, dispatch.assignContractor]);

  const customTableProps = useDumbTable({
    columnParams,
    list: assignedSpecialPlaces,
    actionColumnParams,
    loading: fetchAssignedSpecialPlaces.loading,
    headerOptions: (
      <Button
        type="primary"
        htmlType="submit"
        block
        icon={<MaterialIcon path={mdiMapMarkerPlus} />}
        onClick={() => handleAssignSpecialPlaceClick()}
      >
        {t("contractor.assignSpecialPlaceButtonTitle")}
      </Button>
    ),
  });

  const handleModalOk = useCallback(() => {
    if (typeof contractor?.id !== "string") {
      return;
    }

    dispatch.contractorView.fetchAssignedSpecialPlaces(contractor?.id);

    setAssignModalOpen(false);
  }, [contractor?.id, dispatch.contractorView]);

  useEffect(() => {
    if (typeof contractor?.id !== "string") {
      return;
    }

    dispatch.contractorView.fetchAssignedSpecialPlaces(contractor?.id);

    return () => {
      dispatch.contractorView.setAssignedSpecialPlaces([]);
    };
  }, [contractor?.id, dispatch.contractorView]);

  return (
    <>
      <CustomTable<SpecialPlaceVm>
        {...customTableProps}
        selectable={false}
        scroll={{ x: 1000 }}
      />

      <AssignContractorToSpecialPlaceModal
        visible={assignModalOpen}
        onCancel={() => setAssignModalOpen(false)}
        onOk={() => handleModalOk()}
      />
    </>
  );
};

export default SpecialPlacesTab;
