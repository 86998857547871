/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    SlaVm,
    SlaVmFromJSON,
    SlaVmFromJSONTyped,
    SlaVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailureTypeVm
 */
export interface FailureTypeVm {
    /**
     * 
     * @type {string}
     * @memberof FailureTypeVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FailureTypeVm
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureTypeVm
     */
    specialCase?: boolean | null;
    /**
     * 
     * @type {Array<DictionaryVm>}
     * @memberof FailureTypeVm
     */
    tasks?: Array<DictionaryVm> | null;
    /**
     * 
     * @type {Array<SlaVm>}
     * @memberof FailureTypeVm
     */
    slas?: Array<SlaVm> | null;
    /**
     * 
     * @type {string}
     * @memberof FailureTypeVm
     */
    description?: string | null;
}

export function FailureTypeVmFromJSON(json: any): FailureTypeVm {
    return FailureTypeVmFromJSONTyped(json, false);
}

export function FailureTypeVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureTypeVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'specialCase': !exists(json, 'specialCase') ? undefined : json['specialCase'],
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(DictionaryVmFromJSON)),
        'slas': !exists(json, 'slas') ? undefined : (json['slas'] === null ? null : (json['slas'] as Array<any>).map(SlaVmFromJSON)),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function FailureTypeVmToJSON(value?: FailureTypeVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'specialCase': value.specialCase,
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(DictionaryVmToJSON)),
        'slas': value.slas === undefined ? undefined : (value.slas === null ? null : (value.slas as Array<any>).map(SlaVmToJSON)),
        'description': value.description,
    };
}


