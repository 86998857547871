import { FC, ReactNode } from "react";
import styles from "./CardTitle.module.scss";
import { Row } from "antd";
import clsx from "clsx";

interface CardTitleProps {
  children?: ReactNode;
  className?: string;
}

const CardTitle: FC<CardTitleProps> = ({ children, className, ...rest }) => {
  return (
    <Row
      justify="space-between"
      wrap={false}
      align="top"
      className={clsx(styles.fixedTitle, className)}
      {...rest}
    >
      {children}
    </Row>
  );
};

export default CardTitle;
