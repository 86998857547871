import { AddressVm, NameVm } from "api/generated/lumen";
import { SupportedLanguage } from "models/common";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface UseLanguageSensitiveUtils {
  displayName: (name: NameVm) => string | undefined;
  displayAddress: (value?: AddressVm | undefined) => string;
}

const useLanguageSensitiveUtils = (): UseLanguageSensitiveUtils => {
  const { i18n } = useTranslation();

  const displayName = useCallback(
    ({ familyname, middlename, forename }: NameVm): string | undefined => {
      const namesInOrder =
        i18n.language === SupportedLanguage.Hu
          ? [familyname, middlename, forename].filter(Boolean)
          : [forename, middlename, familyname].filter(Boolean);

      return (namesInOrder.length && namesInOrder.join(" ")) || undefined;
    },
    [i18n.language]
  );

  const displayAddress = useCallback((value?: AddressVm) => {
    return `${value?.zip ?? ""} ${value?.city ?? ""}, ${value?.street ?? ""} ${
      value?.number ?? ""
    }`;
  }, []);

  return {
    displayName,
    displayAddress,
  };
};

export default useLanguageSensitiveUtils;
