import BrandImage from "components/ui/BrandImage";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SidebarContext } from "widgets/layout/sidebar-header-layout";
import styles from "./Logo.module.scss";

const Logo: React.FC = () => {
  const { open } = useContext(SidebarContext);

  return (
    <Link to="/">
      {open ? (
        <BrandImage subPath="logo.svg" className={styles.logo} />
      ) : (
        <BrandImage
          subPath="logo-collapsed.svg"
          className={styles.collapsedLogo}
        />
      )}
    </Link>
  );
};

export default Logo;
