/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    UserVm,
    UserVmFromJSON,
    UserVmFromJSONTyped,
    UserVmToJSON,
    Workbook,
    WorkbookFromJSON,
    WorkbookFromJSONTyped,
    WorkbookToJSON,
    WorkbookTaskItem,
    WorkbookTaskItemFromJSON,
    WorkbookTaskItemFromJSONTyped,
    WorkbookTaskItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkbookTaskVm
 */
export interface WorkbookTaskVm {
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    reasonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    statusId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    taskId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    workbookId?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkbookTaskVm
     */
    statechangedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WorkbookTaskVm
     */
    statechangedBy?: string | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof WorkbookTaskVm
     */
    reason?: DictionaryVm;
    /**
     * 
     * @type {UserVm}
     * @memberof WorkbookTaskVm
     */
    statechanger?: UserVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof WorkbookTaskVm
     */
    status?: DictionaryVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof WorkbookTaskVm
     */
    task?: DictionaryVm;
    /**
     * 
     * @type {Workbook}
     * @memberof WorkbookTaskVm
     */
    workbook?: Workbook;
    /**
     * 
     * @type {Array<WorkbookTaskItem>}
     * @memberof WorkbookTaskVm
     */
    workbookTaskItems?: Array<WorkbookTaskItem> | null;
}

export function WorkbookTaskVmFromJSON(json: any): WorkbookTaskVm {
    return WorkbookTaskVmFromJSONTyped(json, false);
}

export function WorkbookTaskVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbookTaskVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reasonId': !exists(json, 'reasonId') ? undefined : json['reasonId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'workbookId': !exists(json, 'workbookId') ? undefined : json['workbookId'],
        'statechangedDate': !exists(json, 'statechangedDate') ? undefined : (json['statechangedDate'] === null ? null : new Date(json['statechangedDate'])),
        'statechangedBy': !exists(json, 'statechangedBy') ? undefined : json['statechangedBy'],
        'reason': !exists(json, 'reason') ? undefined : DictionaryVmFromJSON(json['reason']),
        'statechanger': !exists(json, 'statechanger') ? undefined : UserVmFromJSON(json['statechanger']),
        'status': !exists(json, 'status') ? undefined : DictionaryVmFromJSON(json['status']),
        'task': !exists(json, 'task') ? undefined : DictionaryVmFromJSON(json['task']),
        'workbook': !exists(json, 'workbook') ? undefined : WorkbookFromJSON(json['workbook']),
        'workbookTaskItems': !exists(json, 'workbookTaskItems') ? undefined : (json['workbookTaskItems'] === null ? null : (json['workbookTaskItems'] as Array<any>).map(WorkbookTaskItemFromJSON)),
    };
}

export function WorkbookTaskVmToJSON(value?: WorkbookTaskVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reasonId': value.reasonId,
        'statusId': value.statusId,
        'taskId': value.taskId,
        'workbookId': value.workbookId,
        'statechangedDate': value.statechangedDate === undefined ? undefined : (value.statechangedDate === null ? null : value.statechangedDate.toISOString()),
        'statechangedBy': value.statechangedBy,
        'reason': DictionaryVmToJSON(value.reason),
        'statechanger': UserVmToJSON(value.statechanger),
        'status': DictionaryVmToJSON(value.status),
        'task': DictionaryVmToJSON(value.task),
        'workbook': WorkbookToJSON(value.workbook),
        'workbookTaskItems': value.workbookTaskItems === undefined ? undefined : (value.workbookTaskItems === null ? null : (value.workbookTaskItems as Array<any>).map(WorkbookTaskItemToJSON)),
    };
}


