/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeadlineStatusType,
    DeadlineStatusTypeFromJSON,
    DeadlineStatusTypeFromJSONTyped,
    DeadlineStatusTypeToJSON,
    ExamTypeVm,
    ExamTypeVmFromJSON,
    ExamTypeVmFromJSONTyped,
    ExamTypeVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExamVm
 */
export interface ExamVm {
    /**
     * 
     * @type {string}
     * @memberof ExamVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamVm
     */
    repairmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamVm
     */
    examTypeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExamVm
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {ExamTypeVm}
     * @memberof ExamVm
     */
    examType?: ExamTypeVm;
    /**
     * 
     * @type {DeadlineStatusType}
     * @memberof ExamVm
     */
    deadlineStatus?: DeadlineStatusType;
}

export function ExamVmFromJSON(json: any): ExamVm {
    return ExamVmFromJSONTyped(json, false);
}

export function ExamVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'repairmanId': !exists(json, 'repairmanId') ? undefined : json['repairmanId'],
        'examTypeId': !exists(json, 'examTypeId') ? undefined : json['examTypeId'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'examType': !exists(json, 'examType') ? undefined : ExamTypeVmFromJSON(json['examType']),
        'deadlineStatus': !exists(json, 'deadlineStatus') ? undefined : DeadlineStatusTypeFromJSON(json['deadlineStatus']),
    };
}

export function ExamVmToJSON(value?: ExamVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'repairmanId': value.repairmanId,
        'examTypeId': value.examTypeId,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'isDeleted': value.isDeleted,
        'examType': ExamTypeVmToJSON(value.examType),
        'deadlineStatus': DeadlineStatusTypeToJSON(value.deadlineStatus),
    };
}


