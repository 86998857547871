/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExamCommand
 */
export interface CreateExamCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateExamCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamCommand
     */
    repairmanId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamCommand
     */
    examTypeId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateExamCommand
     */
    expirationDate?: Date | null;
}

export function CreateExamCommandFromJSON(json: any): CreateExamCommand {
    return CreateExamCommandFromJSONTyped(json, false);
}

export function CreateExamCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExamCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'repairmanId': json['repairmanId'],
        'examTypeId': json['examTypeId'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
    };
}

export function CreateExamCommandToJSON(value?: CreateExamCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'repairmanId': value.repairmanId,
        'examTypeId': value.examTypeId,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
    };
}


