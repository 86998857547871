import { mdiChevronDoubleLeft } from "@mdi/js";
import clsx from "clsx";
import { MaterialIcon } from "components/MaterialIcon";
import React, { useContext } from "react";
import { SidebarContext } from "widgets/layout/sidebar-header-layout";
import styles from "./Collapser.module.scss";

const Collapser: React.FC = () => {
  const { open, setOpen } = useContext(SidebarContext);

  return (
    <button
      className={styles.collapser}
      onClick={() => setOpen((oldValue) => !oldValue)}
    >
      <MaterialIcon
        path={mdiChevronDoubleLeft}
        className={clsx({ [styles.collapsedIcon]: !open })}
      />
    </button>
  );
};

export default Collapser;
