/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    ImageMetadataVm,
    ImageMetadataVmFromJSON,
    ImageMetadataVmFromJSONTyped,
    ImageMetadataVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface InventoryVm
 */
export interface InventoryVm {
    /**
     * 
     * @type {string}
     * @memberof InventoryVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryVm
     */
    partNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryVm
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryVm
     */
    type?: string | null;
    /**
     * 
     * @type {ImageMetadataVm}
     * @memberof InventoryVm
     */
    image?: ImageMetadataVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof InventoryVm
     */
    category?: DictionaryVm;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryVm
     */
    isActivated?: boolean;
}

export function InventoryVmFromJSON(json: any): InventoryVm {
    return InventoryVmFromJSONTyped(json, false);
}

export function InventoryVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'image': !exists(json, 'image') ? undefined : ImageMetadataVmFromJSON(json['image']),
        'category': !exists(json, 'category') ? undefined : DictionaryVmFromJSON(json['category']),
        'isActivated': !exists(json, 'isActivated') ? undefined : json['isActivated'],
    };
}

export function InventoryVmToJSON(value?: InventoryVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'partNumber': value.partNumber,
        'name': value.name,
        'manufacturer': value.manufacturer,
        'type': value.type,
        'image': ImageMetadataVmToJSON(value.image),
        'category': DictionaryVmToJSON(value.category),
        'isActivated': value.isActivated,
    };
}


