import { DateFormat } from "widgets/table/DateDisplay";
import dayjs from "dayjs";

export const backendDateToDayJs = (
  value: string | number | Date | dayjs.Dayjs | null | undefined
): dayjs.Dayjs | undefined => {
  const dayjsObj = dayjs(value);

  // BE puts 0001-01-01T00:00:00Z into date field by default
  return dayjsObj.isValid() && dayjsObj.year() !== 1 ? dayjsObj : undefined;
};

export const formatDate = (
  value: Date | undefined,
  format: DateFormat
): string | undefined => {
  const dayjsObj = backendDateToDayJs(value);

  return value && dayjsObj ? dayjsObj.format(format) : undefined;
};

export const startOfDate = (value: Date | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }

  const dayjsObj = backendDateToDayJs(value);
  if (!dayjsObj) {
    return undefined;
  }

  return dayjsObj.startOf("day").format();
};
