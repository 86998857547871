import { mergeListParams } from "widgets/table/useTableUtils";
import { FailureListRequestParams } from "./models";
import { calculateFilterRequestParams } from "utils";
import { FailureVm } from "api/generated/lumen";
import { startOfDate } from "utils/date";
import { GetFilterParamsForGetRequests } from "models/common";

export const getTownNameFiltersForContractor = (
  townNames: string[]
): { [key: string]: string } =>
  townNames.reduce<{ [key: string]: string }>(
    (previous, current, currentIndex) => {
      previous[
        `@towns.Any(t${currentIndex} => @0.ToLower() == t${currentIndex}.Name.ToLower())`
      ] = current;

      return previous;
    },
    {}
  );

export const getFilterParamsForFailureGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  const newParams: FailureListRequestParams = {
    ...params,
    filter: {
      ...params.filter,
      parentId: "", // this filter is needed in all cases, to get all the failures that are not assigned to another failure
    },
  };

  const mergedParams = mergeListParams(existingParams, newParams);

  const filter: any = calculateFilterRequestParams<FailureVm>(mergedParams, {
    reportedAt: (value) => startOfDate(value),
    deadline: (value) => startOfDate(value),
    fixedDate: (value) => startOfDate(value),
    closedAt: (value) => startOfDate(value ?? undefined),
  });

  const modifiedFilter = modifyCityFilter(filter);

  const payload: FailureListRequestParams = {
    ...mergedParams,
    filter: modifiedFilter,
  };

  return { payload, mergedParams };
};

const modifyCityFilter = (filter: any) => {
  const city = filter["address.city"];
  if (!city) {
    return filter;
  }

  delete filter["address.city"];

  const modifiedFilter = {
    ...filter,
    "@address.city=@0": city,
  };

  return modifiedFilter;
};
