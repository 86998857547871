/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginCommand
 */
export interface LoginCommand {
    /**
     * 
     * @type {string}
     * @memberof LoginCommand
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginCommand
     */
    passwordHash: string;
}

export function LoginCommandFromJSON(json: any): LoginCommand {
    return LoginCommandFromJSONTyped(json, false);
}

export function LoginCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'passwordHash': json['passwordHash'],
    };
}

export function LoginCommandToJSON(value?: LoginCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'passwordHash': value.passwordHash,
    };
}


