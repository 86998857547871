import { ContactPersonVm } from "api/generated/lumen";
import { useSelector } from "app/store";
import useDumbTable from "hooks/useDumbTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { enumTranslate } from "utils/enumTranslate";
import CustomTable from "widgets/table/CustomTable";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import { ColumnConfigParams } from "widgets/table/useTableUtils";

const ContactsTab = () => {
  const { t } = useTranslation();
  const { contractor } = useSelector((state) => state.contractorView);

  const columnParams = useMemo<ColumnConfigParams<ContactPersonVm>[]>(
    () => [
      {
        title: t("contact.familyName"),
        key: "familyname",
        sort: false,
      },
      {
        title: t("contact.forename"),
        key: "forename",
        sort: false,
      },
      {
        title: t("contact.email"),
        key: "email",
        sort: false,
      },
      {
        title: t("contact.phone"),
        key: "phone", // note: fix custom table types sometime, it can accept string[] for nested props too by default
        sort: false,
        render: (value) => value?.number ?? <EmptyContent />,
      },
      {
        title: t("contact.registrationState"),
        key: "registrationState",
        sort: false,
        render: (value) => enumTranslate(value, "registrationState"),
      },
    ],
    [t]
  );

  const customTableProps = useDumbTable({
    columnParams,
    list: contractor?.contacts,
  });

  return (
    <>
      <CustomTable<ContactPersonVm>
        {...customTableProps}
        selectable={false}
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default ContactsTab;
