/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LostPasswordCommand
 */
export interface LostPasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof LostPasswordCommand
     */
    username: string;
}

export function LostPasswordCommandFromJSON(json: any): LostPasswordCommand {
    return LostPasswordCommandFromJSONTyped(json, false);
}

export function LostPasswordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): LostPasswordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
    };
}

export function LostPasswordCommandToJSON(value?: LostPasswordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
    };
}


