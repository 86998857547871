import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "app/store";
import { Container } from "components/layout/Container";
import React, { useCallback, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomForm from "components/form/CustomForm";
import { GrapeAntdForm, requiredField, useGrapeAntdForm } from "widgets/form";
import { RegisterFormValues } from "../models";
import { Rule } from "antd/lib/form";
import { hashSHA256 } from "utils/crypto";
import useRegister from "./useRegister";
import PasswordFormItem from "../components/PasswordFormItem";
import ConfirmPasswordFormItem from "../components/ConfirmPasswordFormItem";
import BrandImage from "components/ui/BrandImage";

const Register: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<RegisterFormValues>();
  const { checkInvitation, register } = useSelector(
    (state) => state.loading.effects.auth
  );
  const { checkInvitationData, passwordComplexity, passwordComplexityLoading } =
    useRegister({ userId });

  const handleFinish = useCallback(
    async (values: RegisterFormValues): Promise<void> => {
      await dispatch.auth.register({
        userName: values.userName,
        passwordHash: await hashSHA256(values.password),
      });
    },
    [dispatch.auth]
  );

  useEffect(() => {
    if (checkInvitationData?.userName && checkInvitationData?.orgName) {
      formUtils.form.setFieldsValue({
        userName: checkInvitationData.userName,
        companyName: checkInvitationData.orgName,
      });
    }
  }, [
    checkInvitationData?.orgName,
    checkInvitationData?.userName,
    formUtils.form,
  ]);

  useEffect(() => {
    dispatch.auth.logout();
  }, [dispatch.auth]);

  if (checkInvitation.error) {
    return <Navigate to="failed" replace />;
  }

  if (register.success) {
    return <Navigate to="success" replace />;
  }

  return (
    <Container>
      <Row justify="center">
        <Col xxl={12} xl={15} lg={18} span={24}>
          <Spin spinning={passwordComplexityLoading}>
            <Card>
              <Space direction="vertical" size="large">
                <BrandImage subPath="logo-colorful.svg" />
                <Typography.Title>{t("register.title")}</Typography.Title>

                <CustomForm formUtils={formUtils} onFinish={handleFinish}>
                  <Space direction="vertical" size="large">
                    <Row justify="center" gutter={[28, 16]}>
                      <Col span={12}>
                        <GrapeAntdForm.Item
                          name="userName"
                          label={t("register.email")}
                          rules={[requiredField(t)]}
                        >
                          <Input disabled size="large" />
                        </GrapeAntdForm.Item>
                      </Col>
                      <Col span={12}>
                        <GrapeAntdForm.Item
                          name="companyName"
                          label={t("register.company")}
                          rules={[requiredField(t)]}
                        >
                          <Input disabled size="large" />
                        </GrapeAntdForm.Item>
                      </Col>
                      <Col span={12}>
                        <PasswordFormItem
                          passwordComplexity={passwordComplexity}
                          passwordComplexityLoading={passwordComplexityLoading}
                        />
                      </Col>
                      <Col span={12}>
                        <ConfirmPasswordFormItem />
                      </Col>
                      <Col span={24}>
                        <GrapeAntdForm.Item
                          name="agreement"
                          valuePropName="checked"
                          required
                          rules={[
                            {
                              validator: (_: Rule, value: boolean) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(t("error.common.needToAccept"))
                                    ),
                            },
                          ]}
                        >
                          <Checkbox>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t("register.acceptTerms", {
                                  link: process.env.REACT_APP_TERMS_LINK,
                                }),
                              }}
                            />
                          </Checkbox>
                        </GrapeAntdForm.Item>
                      </Col>
                    </Row>

                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={register.loading}
                    >
                      {t("register.registerButton")}
                    </Button>
                  </Space>
                </CustomForm>
              </Space>
            </Card>
          </Spin>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
