import { NameVm } from "api/generated/lumen";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React from "react";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";

interface NameDisplayProps {
  value?: NameVm;
}

const NameDisplay: React.FC<NameDisplayProps> = ({ value }) => {
  const { displayName } = useLanguageSensitiveUtils();

  if (!value || !displayName(value)) {
    return <EmptyContent />;
  }

  return <>{displayName(value)}</>;
};

export default NameDisplay;
