import { Col, Input, Row, Switch } from "antd";
import CustomDatePicker from "components/CustomDatePicker";
import dayjs from "dayjs";
import { FormMode } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, requiredField } from "widgets/form";
import { DateFormat } from "widgets/table/DateDisplay";
import styles from "./PersonalDataFieldset.module.scss";

const disabledDate = (current: dayjs.Dayjs): boolean => {
  return current.isAfter(dayjs());
};

interface PersonalDataFieldsetProps {
  formMode: FormMode;
}

const PersonalDataFieldset: React.FC<PersonalDataFieldsetProps> = ({
  formMode,
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="center" gutter={[24, 0]} className={styles.row}>
      {formMode === "Edit" && (
        <Col span={24}>
          <GrapeAntdForm.Item
            name="isActive"
            valuePropName="checked"
            label={t("repairmen.status")}
          >
            <Switch
              checkedChildren={t("common.active")}
              unCheckedChildren={t("common.inactive")}
              className={styles.isActiveSwitch}
            />
          </GrapeAntdForm.Item>
        </Col>
      )}
      <Col span={12}>
        <GrapeAntdForm.Item
          name="familyname"
          label={t("repairmen.familyname")}
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={12}>
        <GrapeAntdForm.Item
          name="forename"
          label={t("repairmen.forename")}
          rules={[requiredField(t)]}
        >
          <Input size="large" />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={12}>
        <GrapeAntdForm.Item name="birthPlace" label={t("repairmen.birthPlace")}>
          <Input size="large" />
        </GrapeAntdForm.Item>
      </Col>
      <Col span={12}>
        <GrapeAntdForm.Item name="birthDate" label={t("repairmen.birthDate")}>
          <CustomDatePicker
            size="large"
            format={DateFormat.Date}
            disabledDate={disabledDate}
          />
        </GrapeAntdForm.Item>
      </Col>
    </Row>
  );
};

export default PersonalDataFieldset;
