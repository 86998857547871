import { WorkBookTaskItemReportListItemVm } from "api/generated/lumen";
import { FailureListRequestParams } from "features/failures/models";
import { GetFilterParamsForGetRequests } from "models/common";
import { calculateFilterRequestParams } from "utils";
import { startOfDate } from "utils/date";
import {
  ListRequestParams,
  mergeListParams,
} from "widgets/table/useTableUtils";

export const getFilterParamsForTaskItemReportGetRequests = (
  params: FailureListRequestParams,
  existingParams: FailureListRequestParams
): GetFilterParamsForGetRequests => {
  const mergedParams = mergeListParams(existingParams, params);

  const filter = calculateFilterRequestParams<WorkBookTaskItemReportListItemVm>(
    mergedParams,
    {
      failureReportedAt: (value) => startOfDate(value),
    }
  );

  const payload: ListRequestParams = {
    ...mergedParams,
    filter,
  };

  return { payload, mergedParams };
};
