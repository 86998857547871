import {
  mdiAlertPlus,
  mdiCloudSyncOutline,
  mdiEye,
  mdiMenuDown,
} from "@mdi/js";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  DataType,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

import { ContractorVm } from "api/generated/lumen";
import ListPageLayout from "components/layout/ListPageLayout";
import { useNavigate } from "react-router-dom";
import useDevicePropertiesQuery from "hooks/queries/useDevicePropertiesQuery";
import DevicePropertyDisplay from "components/ui/DevicePropertyDisplay";
import { DynamicDeviceVm } from "api/generated/lumen/models/DynamicDeviceVm";
import BooleanDisplay from "components/ui/BooleanDisplay";
import { Button, Dropdown, Menu, notification, Space, Tag } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import styles from "./MaintainedDevicesList.module.scss";
import AssignMaintainedDevicesToSpecialPlaceModal from "../assign/AssignMaintainedDevicesToSpecialPlaceModal";
import AssignMaintainedDevicesToContractorModal from "../assign/AssignMaintainedDevicesToContractorModal";

const MaintainedDevicesList: React.FC = () => {
  const { listParams, list, selectedIds } = useSelector(
    (state) => state.maintainedDevicesList
  );
  const { fetchList, syncDeviceData } = useSelector(
    (state) => state.loading.effects.maintainedDevicesList
  );
  const { isVisible: isFailureCreateModalVisible } = useSelector(
    (state) => state.failureCreate
  );
  const navigate = useNavigate();
  const devicePropertiesQuery = useDevicePropertiesQuery();
  const [assignToSpecialPlaceModalOpen, setAssignToSpecialPlaceModalOpen] =
    useState(false);
  const [assignToContractorModalOpen, setAssignToContractorModalOpen] =
    useState(false);
  const [resetSwitch, setResetSwitch] = useState(false); // Used to reset table selection

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnParams = useMemo<ColumnConfigParams<DynamicDeviceVm>[]>(
    () =>
      devicePropertiesQuery.isLoading
        ? []
        : [
            {
              key: "name",
              title: t("maintainedDevice.name"),
              sort: true,
              defaultState: ColumnState.AlwaysVisible,
              filterMode: FilterMode.SEARCH,
            },
            ...(devicePropertiesQuery.data || []).map<
              ColumnConfigParams<DynamicDeviceVm>
            >((property, index) => ({
              key: `deviceProperties[${index}]`,
              dataIndex: ["deviceProperties", index, "value"],
              title: property.key,
              sort: true,
              defaultState: ColumnState.AlwaysVisible,
              filterMode: FilterMode.SEARCH,
              render: (value?: string | null) => {
                return (
                  <DevicePropertyDisplay type={property.type} value={value} />
                );
              },
            })),
            {
              key: "isActive",
              title: t("maintainedDevice.status"),
              sort: true,
              defaultState: ColumnState.AlwaysVisible,
              filterMode: FilterMode.ACTIVE_INACTIVE,
              dataType: DataType.ACTIVE_INACTIVE,
              render: (value: boolean) => {
                return (
                  <BooleanDisplay
                    value={value}
                    trueDisplay={
                      <Tag color="success">{t("common.active")}</Tag>
                    }
                    falseDisplay={
                      <Tag color="error">{t("common.inactive")}</Tag>
                    }
                  />
                );
              },
            },
          ],
    [devicePropertiesQuery.data, devicePropertiesQuery.isLoading, t]
  );

  const handleTableSelectedChange = useCallback(
    (selectedIds: string[]) => {
      dispatch.maintainedDevicesList.setSelectedIds(selectedIds);
    },
    [dispatch.maintainedDevicesList]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/maintained-devices/${id}`);
    },
    [navigate]
  );

  const handleAddNewFailureClick = useCallback(
    (id?: string) => {
      dispatch.failureCreate.setPrePopulatedValues({
        deviceId: id,
      });

      dispatch.failureCreate.setIsVisible(true);
    },
    [dispatch.failureCreate]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<ContractorVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiAlertPlus}
              onClick={() => handleAddNewFailureClick(record.id)}
              title={t("maintainedDevice.addNewFailure")}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleAddNewFailureClick, handleViewButtonClick, t]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.maintainedDevicesList.fetchList(requestParams),
    [dispatch.maintainedDevicesList]
  );

  const handleSyncDeviceDataClick = useCallback(async () => {
    await dispatch.maintainedDevicesList.syncDeviceData();
    await dispatch.maintainedDevicesList.fetchList(listParams);
  }, [dispatch.maintainedDevicesList, listParams]);

  const handleBulkAttachToSpecialPlace = useCallback(async () => {
    setAssignToSpecialPlaceModalOpen(true);
  }, []);

  const handleBulkAttachToContractor = useCallback(async () => {
    setAssignToContractorModalOpen(true);
  }, []);

  const bulkActionsMenu = (
    <Menu
      items={[
        {
          label: t("maintainedDevice.bulkActions.attachToSpecialPlace"),
          key: "attachToSpecialPlace",
          onClick: () => handleBulkAttachToSpecialPlace(),
        },
        {
          label: t("maintainedDevice.bulkActions.attachToContractor"),
          key: "attachToContractor",
          onClick: () => handleBulkAttachToContractor(),
        },
      ]}
    />
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.MAINTAINED_DEVICES,
    headerOptions: (
      <Space>
        <Dropdown
          overlay={bulkActionsMenu}
          trigger={["click"]}
          disabled={selectedIds.length < 1}
        >
          <Button>
            <span>{t("maintainedDevice.bulkActions.placeholder")}</span>
            <MaterialIcon
              path={mdiMenuDown}
              className={styles.bulkActionsIcon}
            />
          </Button>
        </Dropdown>
        <Button
          icon={<MaterialIcon path={mdiCloudSyncOutline} />}
          type="primary"
          onClick={handleSyncDeviceDataClick}
          loading={syncDeviceData.loading}
        >
          {t("maintainedDevice.syncDeviceData")}
        </Button>
      </Space>
    ),
    resetListParams: dispatch.maintainedDevicesList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.maintainedDevicesList.reset,
  });

  const handleAssignMaintainedDevicesToSpecialPlaceModalOk = useCallback(() => {
    setResetSwitch(true);

    notification.success({
      message: t("maintainedDevice.assignSpecialPlaceModal.success"),
    });

    setAssignToSpecialPlaceModalOpen(false);

    setResetSwitch(false);
  }, [t]);

  const handleAssignMaintainedDevicesToContractorModal = useCallback(() => {
    setResetSwitch(true);

    notification.success({
      message: t("maintainedDevice.assignContractorModal.success"),
    });

    setAssignToContractorModalOpen(false);

    setResetSwitch(false);
  }, [t]);

  useEffect(() => {
    if (!isFailureCreateModalVisible) {
      dispatch.failureCreate.setPrePopulatedValues({
        deviceId: undefined,
      });
    }
  }, [dispatch.failureCreate, isFailureCreateModalVisible]);

  return (
    <ListPageLayout title={t("maintainedDevice.list.title")}>
      <CustomTable<DynamicDeviceVm>
        {...customTableProps}
        scrollX={1000}
        onSelectedChange={handleTableSelectedChange}
        resetSwitch={resetSwitch}
      />

      <AssignMaintainedDevicesToSpecialPlaceModal
        visible={assignToSpecialPlaceModalOpen}
        onCancel={() => setAssignToSpecialPlaceModalOpen(false)}
        onOk={handleAssignMaintainedDevicesToSpecialPlaceModalOk}
      />

      <AssignMaintainedDevicesToContractorModal
        visible={assignToContractorModalOpen}
        onCancel={() => setAssignToContractorModalOpen(false)}
        onOk={handleAssignMaintainedDevicesToContractorModal}
      />
    </ListPageLayout>
  );
};

export default MaintainedDevicesList;
