/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    district?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    door?: string | null;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'door': !exists(json, 'door') ? undefined : json['door'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'zip': value.zip,
        'county': value.county,
        'city': value.city,
        'district': value.district,
        'street': value.street,
        'number': value.number,
        'floor': value.floor,
        'door': value.door,
    };
}


