/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface InventoryExcelVm
 */
export interface InventoryExcelVm {
    /**
     * 
     * @type {string}
     * @memberof InventoryExcelVm
     */
    partNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryExcelVm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryExcelVm
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryExcelVm
     */
    type?: string | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof InventoryExcelVm
     */
    category?: DictionaryVm;
}

export function InventoryExcelVmFromJSON(json: any): InventoryExcelVm {
    return InventoryExcelVmFromJSONTyped(json, false);
}

export function InventoryExcelVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryExcelVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'category': !exists(json, 'category') ? undefined : DictionaryVmFromJSON(json['category']),
    };
}

export function InventoryExcelVmToJSON(value?: InventoryExcelVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partNumber': value.partNumber,
        'name': value.name,
        'manufacturer': value.manufacturer,
        'type': value.type,
        'category': DictionaryVmToJSON(value.category),
    };
}


