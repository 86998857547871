import React from "react";
import { Helmet } from "react-helmet";

interface DocumentTitleProps {
  title?: string;
}

const appTitle = process.env.REACT_APP_APP_NAME;

const DocumentTitle: React.FC<DocumentTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} - ${appTitle}` : appTitle}</title>
    </Helmet>
  );
};

export default DocumentTitle;
