/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmFromJSONTyped,
    ContractorVmToJSON,
    DeadlineStatusType,
    DeadlineStatusTypeFromJSON,
    DeadlineStatusTypeFromJSONTyped,
    DeadlineStatusTypeToJSON,
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    DocumentMetadataVm,
    DocumentMetadataVmFromJSON,
    DocumentMetadataVmFromJSONTyped,
    DocumentMetadataVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    RegistrationStates,
    RegistrationStatesFromJSON,
    RegistrationStatesFromJSONTyped,
    RegistrationStatesToJSON,
} from './';

/**
 * 
 * @export
 * @interface RepairmanVm
 */
export interface RepairmanVm {
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    birthPlace?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RepairmanVm
     */
    birthDate?: Date;
    /**
     * 
     * @type {AddressVm}
     * @memberof RepairmanVm
     */
    address?: AddressVm;
    /**
     * 
     * @type {PhoneVm}
     * @memberof RepairmanVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    userId?: string | null;
    /**
     * 
     * @type {ContractorVm}
     * @memberof RepairmanVm
     */
    contractor?: ContractorVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof RepairmanVm
     */
    status?: DictionaryVm;
    /**
     * 
     * @type {boolean}
     * @memberof RepairmanVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {RegistrationStates}
     * @memberof RepairmanVm
     */
    registrationState?: RegistrationStates;
    /**
     * 
     * @type {DeadlineStatusType}
     * @memberof RepairmanVm
     */
    deadlineStatus?: DeadlineStatusType;
    /**
     * 
     * @type {Array<DocumentMetadataVm>}
     * @memberof RepairmanVm
     */
    documents?: Array<DocumentMetadataVm> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    familyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairmanVm
     */
    forename?: string | null;
}

export function RepairmanVmFromJSON(json: any): RepairmanVm {
    return RepairmanVmFromJSONTyped(json, false);
}

export function RepairmanVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairmanVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthPlace': !exists(json, 'birthPlace') ? undefined : json['birthPlace'],
        'birthDate': !exists(json, 'birthDate') ? undefined : (new Date(json['birthDate'])),
        'address': !exists(json, 'address') ? undefined : AddressVmFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'contractor': !exists(json, 'contractor') ? undefined : ContractorVmFromJSON(json['contractor']),
        'status': !exists(json, 'status') ? undefined : DictionaryVmFromJSON(json['status']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'registrationState': !exists(json, 'registrationState') ? undefined : RegistrationStatesFromJSON(json['registrationState']),
        'deadlineStatus': !exists(json, 'deadlineStatus') ? undefined : DeadlineStatusTypeFromJSON(json['deadlineStatus']),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentMetadataVmFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'familyname': !exists(json, 'familyname') ? undefined : json['familyname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
    };
}

export function RepairmanVmToJSON(value?: RepairmanVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthPlace': value.birthPlace,
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate.toISOString()),
        'address': AddressVmToJSON(value.address),
        'phone': PhoneVmToJSON(value.phone),
        'email': value.email,
        'userId': value.userId,
        'contractor': ContractorVmToJSON(value.contractor),
        'status': DictionaryVmToJSON(value.status),
        'isActive': value.isActive,
        'registrationState': RegistrationStatesToJSON(value.registrationState),
        'deadlineStatus': DeadlineStatusTypeToJSON(value.deadlineStatus),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentMetadataVmToJSON)),
        'id': value.id,
        'familyname': value.familyname,
        'middlename': value.middlename,
        'forename': value.forename,
    };
}


