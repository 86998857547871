import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { mdiDrag, mdiLock } from "@mdi/js";
import { Checkbox, Typography } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnState, ExtendedColumnType } from "./ExtendedColumnType";
import styles from "./TableSettingsDragAndDrop.module.scss";
import { TableSettingsUtils } from "./useTableSettingsUtils";

interface DraggableItemProps<T> {
  column: ExtendedColumnType<any>;
  index: number;
  handleChangeColumnVisibility: (
    visible: boolean,
    column: ExtendedColumnType<T>
  ) => void;
}

function DraggableItem<T = any>({
  column,
  index,
  handleChangeColumnVisibility,
}: DraggableItemProps<T>): JSX.Element {
  const alwaysVisible = column.currentState === ColumnState.AlwaysVisible;

  return (
    <Draggable
      key={String(column.key)}
      draggableId={String(column.key)}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={styles.draggableItem}
        >
          <MaterialIcon path={mdiDrag} className={styles.grabIcon} />
          {alwaysVisible ? (
            <MaterialIcon
              path={mdiLock}
              className={`${styles.actionElement} locked`}
            />
          ) : (
            <Checkbox
              onChange={(e) =>
                handleChangeColumnVisibility(e.target.checked, column)
              }
              checked={column.currentState === ColumnState.Visible}
              className={styles.actionElement}
            />
          )}
          <Typography.Text
            ellipsis
            className={`${styles.itemText} ${alwaysVisible ? "locked" : ""}`}
          >
            {(column?.altTitle as string) ?? column.title}
          </Typography.Text>
        </div>
      )}
    </Draggable>
  );
}

function TableSettingsDragAndDrop<T>(
  props: TableSettingsUtils<T>
): JSX.Element {
  const { t } = useTranslation();

  // NOTE: drag handle render error with react-beautiful-dnd and react18 hack
  // although @hello-pangea/dnd is used, we still need this hack for react18 somehow...
  // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return (
    <>
      <div className={styles.title}>{t("table-settings.header")}</div>
      <DragDropContext onDragEnd={props.onDragEnd}>
        {enabled && (
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className={styles.draggableArea}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {props.tempColumns
                  .filter(
                    (column) =>
                      column.title && column.key && !column.columnOrderFixed
                  )
                  .map((column, index) => (
                    <DraggableItem
                      column={column}
                      index={index}
                      handleChangeColumnVisibility={
                        props.handleChangeColumnVisibility
                      }
                      key={`${String(column.key)}_${index}`}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </>
  );
}

export default TableSettingsDragAndDrop;
